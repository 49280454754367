enum TaskType {
    StartESGAssessment = "StartESGAssessment",
    ContinueESGAssessment = "ContinueESGAssessment",
    ContinueESGAssessmentForOnboarding = "ContinueESGAssessmentForOnboarding",
    StartESGAssessmentForOnboarding = "StartESGAssessmentForOnboarding"
}

enum TaskStatus {
    Pending = "Pending",
    InProgress = "In Progress",
    Resent = "Resent"
}

enum TerminationStatus {
    Rejected = "Rejected",
    WithdrawalRequested= "Withdrawal Requested"
}


export { TaskStatus, TaskType, TerminationStatus };

