import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef, ViewChild, KeyValueDiffers } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

export type DropdownItem = {
  internalCode: string,
  displayText: string,
};

@Component({
  selector: 'tsm-single-dropdown',
  templateUrl: './single-dropdown.component.html',
  styleUrls: ['./single-dropdown.component.sass']
})
export class SingleDropdownComponent implements OnInit {
  @Input() dropdownItems: DropdownItem[] = [];
  @Input() placeholder: string = '';
  @Input() public resetSelectionEvent: Subject<boolean>;
  @Output() selectionChangedEvent = new EventEmitter();
  @ViewChild('dropPanel') dropPanel: ElementRef;

  showPanel = false;
  private selectedItem?: DropdownItem;
  private focusedIndex = -1;

  constructor(private hostElement: ElementRef) { }

  ngOnInit(): void {
    this.resetSelectionEvent?.subscribe(() => this.onResetSelection());
  }

  swapPanel() {
    this.showPanel = !this.showPanel;
    if (this.showPanel) {
      this.focusedIndex = -1; 
    }
  }
  togglePanel() {
    this.showPanel = !this.showPanel;
    if (this.showPanel) {
      this.focusedIndex = -1; 
    }
  }


  get selectedValue(): string {
    if (!this.selectedItem) {
      return '';
    }

    return this.selectedItem.displayText;
  }

  onItemSelected(event: KeyboardEvent, item: DropdownItem) {
    this.selectedItem = item;
    this.selectionChangedEvent.emit(item);
    this.showPanel = false;
    event.stopPropagation(); 

  }

  private onResetSelection() {
    this.selectedItem = null;
  }

  @HostListener('document:click', ['$event'])
  documentClick(event) {
    if (this.dropPanel.nativeElement.contains(event.target)) {
      return;
    }
    if (this.showPanel) {
      this.showPanel = false;
    }
  }
  @HostListener('focusout', ['$event'])
  onFocusOut(event: FocusEvent) {
    if (!this.hostElement.nativeElement.contains(event.relatedTarget as Node)) {
      this.showPanel = false;
    }
  }

  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
      if (!this.showPanel && event.key === 'Enter') {
          event.preventDefault(); 
          this.swapPanel(); 
      } else if (this.showPanel) {
          if (event.key === 'ArrowDown') {
              event.preventDefault();
              this.focusedIndex = (this.focusedIndex + 1) % this.dropdownItems.length;
          } else if (event.key === 'ArrowUp') {
              event.preventDefault(); 
              this.focusedIndex = (this.focusedIndex - 1 + this.dropdownItems.length) % this.dropdownItems.length;
          } else if (event.key === 'Enter') {
              event.preventDefault(); 
              if (this.focusedIndex !== -1 && this.focusedIndex < this.dropdownItems.length) {
                  this.onItemSelected(event, this.dropdownItems[this.focusedIndex]);
              } else {
                  this.showPanel = false; 
              }
          }
      }
  }




  ngOnDestroy(): void {
    this.resetSelectionEvent?.unsubscribe();
  }
}
