<tsm-secondary-banner [redirect]="back"></tsm-secondary-banner>
<sh-alert *ngIf="showAlert" [props]="alertProps"></sh-alert>
<div class="supplier-information-box-container mt-5">
  <sh-supplier-information-box
    [supplier]="supplier"
    [userType]="userType"
    [esgStatus]="esgStatus"
    [parentSupplier]="parentSupplier"
    [showSuppplierEsgStatus]="showSuppplierEsgStatus"
    (countryServedChanged)="getCountryServed($event)"
    changeLocAuthId="changeLocation=tsm"
    [isShowStatus]="isShowStatus"
  ></sh-supplier-information-box>
</div>
<div class="mx-5 my-5 pt-2">
  <tsm-tab-bar [tabs]="tabs"></tsm-tab-bar>

  <div *ngFor="let tab of tabs">
    <div *ngIf="tab.id === 'Relationships' && tab.active">
      <tsm-supplier-relationship
        [supplierTsmId]="supplierTsmId"
        [supplierName]="supplier?.supplierName"
        [confirmParentMode]="confirmParentMode"
        [isSupplier]="isSupplier"
        [isSupplierParent]="isSupplierParent"
        [subSupplierMode]="subSupplierMode"
        [displayButtons]="displayButtons"
        [isIndirect]="supplier?.indirect"
      ></tsm-supplier-relationship>
    </div>
    <div *ngIf="tab.id === 'Scoring' && tab.active && isReviewDataAvailabe">
      <tsm-scoring [reviewData]="reviewData"></tsm-scoring>
    </div>
    <!-- suppliers tab -->
    <div *ngIf="tab.id === 'Suppliers' && tab.active">
      <tsm-sub-suppliers
        [supplierTsmId]="supplierTsmId"
        [displayButtons]="displayButtons"
      ></tsm-sub-suppliers>
      <tsm-historical-sub-suppliers
        [supplierTsmId]="supplierTsmId"
      ></tsm-historical-sub-suppliers>
    </div>
  </div>
</div>
<tsm-pop-up-bv hidden="true"></tsm-pop-up-bv>
