import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { EsgQuestionnaireService } from "@lsl16/sushub-frontend";
import { SubheaderPropsType } from "../../models/SubheaderProps";
import { BuyerDashboardSubheader } from "../../components/subheader/props/buyerDashboard";
import { IndividualUserManagementSubheader } from "../../components/subheader/props/individualUserManagement";
import { EsgQuestionnaireSubheader } from "../../components/subheader/props/supplierEsgQuestionnaire";
import { SupplierAdminBoardSubheader } from "src/app/components/subheader/props/supplierAdminBoard";
import { gdprDashboardSubheader } from "src/app/components/subheader/props/gdprDashboard";
import { EsgQuestionnaireSubheaderViewOnly } from "../../components/subheader/props/supplierEsgQuestionnaireViewOnly";
import { AssessmentReportSubheader } from "../../components/subheader/props/sustainabilityAssessmentReport";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SystemAdminBoardSubheader } from "src/app/components/subheader/props/systemAdminBoard";
import { SupplierGeneralProfileService } from "../supplier-general-profile.service";
import { SupplierService } from "../supplier/supplier.service";
import { TerminationRequestBoardSubheader } from "src/app/components/subheader/props/terminationRequestDashboard";
import { CountryServedView } from "src/app/components/subheader/props/countryServedView";
import { manageRequestSaApprove } from "src/app/components/subheader/props/manageRequestSaApprove";
import { emailTemplateManager } from "src/app/components/subheader/props/emailTemplateManager";

import _ from "lodash";

@Injectable({
    providedIn: "root"
})
export class SubheaderService {
    private routeSupplierAdminboard = "/supplier-admin-board";
    private routeTerminationRequestBoard = "/termination-request-board";
    private routeManageRequestSaApprove = "/manage-updates-approval-board";

    private subheaderPropsMap = new Map<string, any>();

    constructor(
        private router: Router,
        private authService: AuthService,
        private esgQuestionnaireService: EsgQuestionnaireService,
        private ngbModalService: NgbModal,
        private supplierProfileService: SupplierGeneralProfileService,
        private route: ActivatedRoute,
        private supplierService: SupplierService
    ) {
        this.subheaderPropsMap.set("/esg-questionnaire", new EsgQuestionnaireSubheader(esgQuestionnaireService, ngbModalService, supplierProfileService, route, supplierService, authService));
        this.subheaderPropsMap.set(this.routeSupplierAdminboard, new SupplierAdminBoardSubheader());
        this.subheaderPropsMap.set("/gdpr-dashboard", new gdprDashboardSubheader());
        this.subheaderPropsMap.set(
            "/esg-questionnaire-ViewOnly",
            new EsgQuestionnaireSubheaderViewOnly(esgQuestionnaireService, router, supplierProfileService, route, supplierService, authService)
        );
        this.subheaderPropsMap.set("/system-admin-board", new SystemAdminBoardSubheader());
        this.subheaderPropsMap.set("/country-served-view", new CountryServedView());
        this.subheaderPropsMap.set("/email-template-manager", new emailTemplateManager());
        this.subheaderPropsMap.set(this.routeTerminationRequestBoard, new TerminationRequestBoardSubheader());
        this.subheaderPropsMap.set(this.routeManageRequestSaApprove, new manageRequestSaApprove());
       
    }

    /**
     * Description: creates an instance of a subheader props class based on pageUrl
     * and invokes its getProps() function to return customised props for the shared subheader component
     * Params: pageUrl: string
     * Output: Subheader props object of type SubheaderPropsType
     */
    getSubheaderProps = async (pageUrl: string): Promise<SubheaderPropsType> => {
        if (pageUrl.startsWith("/esg-questionnaire/")) {
            const paramArry = pageUrl.split("/");
            const paramString = paramArry[3].split('?')[1];
            const queryParams = new URLSearchParams(paramString);
            const viewOnlyStr = queryParams.get('viewOnly');
            let isViewOnly = false;

            if(!_.isEmpty(viewOnlyStr)){
              isViewOnly = eval(viewOnlyStr);
            }else {
              isViewOnly = await this.esgQuestionnaireService.checkQuestionnaireViewOnlyWithId(paramArry[3]);
            }
            if (isViewOnly) {
                pageUrl = "/esg-questionnaire-ViewOnly";
            } else {
                pageUrl = pageUrl.substring(0, 18);
            }
        }

        let propsService = this.subheaderPropsMap.get(pageUrl);

        //TODO - improve service so this isn't needed
        // For routes with route parameters (as string matching won't work)
        if (!propsService) {
            if (pageUrl.startsWith("/buyer/user/")) {
                return new IndividualUserManagementSubheader(this.router)?.getProps();
            }

            // for supplier admin board route with query parameter
            if (pageUrl.startsWith(`${this.routeSupplierAdminboard}?`)) {
                propsService = this.subheaderPropsMap.get(this.routeSupplierAdminboard);
            }

            if (pageUrl.startsWith(`${this.routeTerminationRequestBoard}?`)) {
                propsService = this.subheaderPropsMap.get(this.routeTerminationRequestBoard);
            }
        }

        return propsService?.getProps();
    };
}
