import { Component, Input, OnInit } from "@angular/core";
import { TabType } from "src/app/models/Tabs";

@Component({
    selector: "tsm-tab-bar",
    templateUrl: "./tab-bar.component.html",
    styleUrls: ["./tab-bar.component.sass"]
})
export class TabBarComponent implements OnInit {
    @Input() tabs: TabType[];
    tabJson: any

    constructor() { }

    ngOnInit(): void {
        this.tabJson = [""];
    }

    ngAfterContentChecked() {
        this.translateTabLabels();
    }

    private translateTabLabels() {
        if (localStorage.getItem("multiLanguage")) {
            const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
            if (multiLanguageJson.body != undefined && this.tabs.length > 0) {
                const multiLanguageLabels = multiLanguageJson.body.main.tabBar;
                this.tabJson = [];
                this.tabs.forEach(tab => {
                    if (tab.hasOwnProperty('id')) {
                        this.tabJson.push(multiLanguageLabels[tab.id]);
                    }
                });
            }
        }
    }

    onClick(selectedTab) {
        this.tabs.forEach(tab => {
            if (tab.hasOwnProperty('title')) {
                tab.active = tab.id === selectedTab.id;
            }
        });
    }
}
