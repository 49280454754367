import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SupplierClassificationService } from '@lsl16/sustainability-shared-components';
import { Subject } from 'rxjs';
import { HeaderEvents, HeaderService } from 'src/app/services/header/header-service';

@Component({
  selector: 'tsm-supplier-score-filter',
  templateUrl: './supplier-score-filter.component.html',
  styleUrls: ['./supplier-score-filter.component.sass']
})
export class SupplierScoreFilterComponent {
  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;
  supplierScore: any[] = [
    {
      Code: "Leader",
      Text: "Leader"
    },
    {
      Code: "Strategic",
      Text: "Strategic"
    },
    {
      Code: "Established",
      Text: "Established"
    },
    {
      Code: "Emerging",
      Text: "Emerging"
    },
    {
      Code: "Nascent",
      Text: "Nascent"
    },
  ];
  values: any[];

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }

  constructor(private headerService: HeaderService,
    private supClassificationService: SupplierClassificationService) { }

  async ngOnInit(): Promise<void> {
    this.subscribeEvents();
    await this.refreshData();
  }

  async refreshData() {
    this.loadTranslations();
    // await this.initCategories();
  }

  async initCategories() {
    this.supplierScore = await this.supClassificationService.getCategories();
  }

  dropdownChanged(selectedValues) {
    this.values = selectedValues;
    this.valueChanged.emit(this.values);
  }

  clear() {
    this.values = [];
    this.supplierScore.forEach(c => {
      c.checked = false;
    });
  }

  private loadTranslations() {
    if (localStorage.getItem('multiLanguage')) {
        const multilanguageJson = JSON.parse(localStorage.getItem('multiLanguage'));
        if (multilanguageJson.body) {
            this.labelTranslations = multilanguageJson.body.main.SaRetriggeringProcessFlow.supplierScore;
        }
    }
  }

  private subscribeEvents() {
    this.clearFilter.subscribe(() => this.clear());

    this.headerService.eventEmit.subscribe((event) => {
        if (event === HeaderEvents.multiLanguageChanged) {
          this.loadTranslations();
        }
    });
  }

}
