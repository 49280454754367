import { Component, OnInit } from '@angular/core';
import { SubheaderPropsType } from 'src/app/models/SubheaderProps';

@Component({
  selector: 'tsm-sa-retriggering-container',
  templateUrl: './sa-retriggering-container.component.html',
  styleUrls: ['./sa-retriggering-container.component.sass']
})
export class SaRetriggeringContainerComponent implements OnInit {

  subheaderProps: SubheaderPropsType;

  tabs = [
    {
      id: "Search",
      title: "Search Suppliers",
      icon: '',
      active: true
    }
  ];
  constructor() { }

  ngOnInit(): void {
    this.subheaderProps = {
      isWelcomeMessage: false,
      heading: ""
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.subheaderProps.heading = multilanguageJson.body.main.saRetriggering.massSaHeading;
      }
    }
  }

}
