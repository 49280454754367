import { isString } from "lodash";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
    ButtonPropsType,
    DynamicFormComponent,
    ComponentConfig,
    SupplierRelationshipService,
    WorkflowService,
    TaxTypeService
} from "@lsl16/sustainability-shared-components";
import { ActivatedRoute, Router } from "@angular/router";
import { MetadataService } from "../../services/metadata/metadata.service";

import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { InviteSupplierApiResponse, InviteSubSupplierApiResponse, InviteSupplierEvents, InviteSupplierModel } from "src/app/models/InviteSupplier";
import { SupplierInviteService } from "src/app/services/inviteSupplier/supplier-invite.service";
import { PopUpConfirmRejectInviteSupplierComponent } from "src/app/components/pop-up-confirm-reject-invite-supplier/pop-up-confirm-reject-invite-supplier.component";
import { Observable, Subscription } from "rxjs";

@Component({
    selector: 'tsm-invite-parent-supplier',
    templateUrl: './invite-parent-supplier.component.html',
    styleUrls: ['./invite-parent-supplier.component.sass']
})
export class InviteParentSupplierComponent implements OnInit {
    configs: Array<ComponentConfig> = [];
    @ViewChild("form", { static: false }) form: DynamicFormComponent;

    confirmButton: ButtonPropsType;
    rejectButton: ButtonPropsType;
    cancelButton: ButtonPropsType;
    noCommentError: boolean;
    invalidCommentError: boolean;
    commentDisabled: boolean = false;
    workflowId: string;
    inviteMode: string;
    formValues: any;
    initialConfigs: Array<ComponentConfig> = [];
    alltaxType: any;
    selectedCountry: string;
    taxItems: any;
    taxData: any;
    countrySubscription: Subscription;
  
    selected: Observable<string>;
      taxTypes: Array<ComponentConfig> = [];

    model: InviteSupplierModel = {
        childTsmId: '',
        supplierName: '',
        internationalcompanyname: '',
        countryLocation: '',
        companyWebsite: '',
        companyDomainName: '',
        dunsNumber: '',
        marketUnit: [],
        countryServed: [''],
        supplierPOCFirstName: '',
        supplierPOCLastName: '',
        supplierPOCEmail: '',
        comment: '',
         BusinessRegistrationNumberTaxIdentificationNumber:''
    };

    translations = {
        inviteParentHeader: '',
        inviteSubSupplierHeader: '',
        header: '',
        pocFirstName: '',
        pocLastName: '',
        pocEmail: '',
        comment: '',
        inviteSupplierInfoMsg: '',
        noCommentErrorMsg: '',
        invalidCommentErrorMsg: '',
        cancel: '',
        reject: '',
        confirmInvitation: '',
    }

    constructor(
        private router: Router,
        private metadataService: MetadataService,
        private supplierInviteService: SupplierInviteService,
        private supplierRelationshipService: SupplierRelationshipService,
        private ngbModalService: NgbModal,
        private route: ActivatedRoute,
        private workflowService: WorkflowService,
        private taxTypeService: TaxTypeService,
    ) {

    }

    async ngOnInit(): Promise<void> {
        this.initButtons();
        this.workflowId = this.route.snapshot.paramMap.get("workflowId");
        await this.loadSupplierData(this.workflowId);
        this.configs = await this.metadataService.gettingAddSupplierConfig();
        this.adjustControlTypes(this.configs);
        this.filterConfigs();
        this.supplierInviteService.eventEmit.subscribe((event) => this.handleSupplierInviteEvent(event));
        this.selectedCountry=this.model.countryLocation;
            this.alltaxType = await this.taxTypeService.getCountryTaskType(this.selectedCountry);
      let taxTypeStartIndex = this.configs.findIndex(x => x.fieldset[0].isDynamicTaxField);
        if (taxTypeStartIndex > -1) {
            this.configs.splice(taxTypeStartIndex, this.taxTypes.length)
            this.taxTypes = [];
        }
        this.taxTypes = [];
        const localTaxfeilds = [];
        let foundDropdownTaxType = null;

        for(let i =0; i< this.alltaxType.taxData.length; i++) {
        if (this.alltaxType.taxData[i].isDropdown === true && this.alltaxType.taxData[i].isHide === false) {
                foundDropdownTaxType = this.alltaxType.taxData[i].taxType;
                     this.configs.forEach((config) => {
                    if (config.fieldset[0].name === "brnTaxIdType" || config.fieldset[0].name === "brnTaxId") {
                        config.fieldset[0].hide = false;
                    }
                })
                break;
            }
        }

        this.alltaxType.taxData.forEach((tax) => {
            if(tax.isDropdown === true &&  tax.isHide === false ){
                localTaxfeilds.push({Code: tax.taxType , Text: tax.taxType})
            }
            else if(tax.isDropdown !== true && tax.isHide !== true){
            const cleanedTaxType = tax.taxType.replace(/[^\w ]/gi, '').replace(/\s/g, '')
            let taxfield = {
                type: 'layout',
                fieldset: [
                    {
                        "index": "0",
                        "questionSetId": "bcf38bd8-4198-48fa-a6fd-37b837e193af",
                        "section": "gettingAddSupplierConfig",
                        "questionOrder": 6,
                        "width": "100%",
                        "required": false, // Making fields are not mandatory for new buyer supplier creation 
                        "templateOptions": {
                            "tooltip": false,
                            "canFilter": false,
                            "label": tax.taxType,
                            "placeholder": "Insert " + tax.taxType
                        },
                        "validateOn": "change",
                        "name": cleanedTaxType,
                        "type": "input",
                        "isDynamicTaxField": true
                    }
                ],
                templateOptions: {}
            }
            this.configs.push(taxfield);
            this.taxTypes.push(taxfield);    

        }        
        });
        this.configs.forEach((config) => {
            if(config.fieldset[0].name === "brnTaxIdType") {
               config.fieldset[0].data=localTaxfeilds

           }
        })
        const brnTaxIdConfig = this.configs.find(config => config.fieldset[0].name === 'brnTaxId');
        if (brnTaxIdConfig) {
            brnTaxIdConfig.fieldset[0].defaultValue = this.model.BusinessRegistrationNumberTaxIdentificationNumber;
        }
        const brnTaxIdTypeConfig = this.configs.find(config => config.fieldset[0].name === 'brnTaxIdType');
        if (brnTaxIdTypeConfig && foundDropdownTaxType) {
            brnTaxIdTypeConfig.fieldset[0].defaultValue = foundDropdownTaxType;

        }
    this.configs = [...this.configs];
        this.configs.sort((a, b) => {
            const orderA = a.fieldset[0].questionOrder || 0;
            const orderB = b.fieldset[0].questionOrder || 0;
            return orderA - orderB;
        });
    }

    initButtons(): void {
        this.confirmButton = {
            label: "",
            color: "white",
            fontSize: 16,
            backgroundColor: "blue",
            borderColor: "blue",
            height: 48,
            width: 240,
            padding: "10px",
            icon: "assets/icons/confirm_tick.svg",
            onClick: async () => {
                await this.confirmInvitation();
            }
        };

        this.rejectButton = {
            label: '',
            backgroundColor: 'white',
            borderColor: 'lightBlue',
            color: 'purple',
            fontSize: 16,
            fontFamily: 'Graphik-Medium',
            width: 115,
            height: 48,
            padding: '16px 24px',
            margin: '0 0 0 2px',
            hoverBackgroundColor: 'white',
            hoverBorderColor: 'lightBlue',
            hoverColor: 'purple',
            icon: 'assets/icons/x-grey.svg',
            clickDuration: 100,
            iconHeight: 16,
            iconWidth: 16,
            onClick: () => {
                this.handleRejectButtonClick();
            },
        };

        this.cancelButton = {
            label: '',
            backgroundColor: 'white',
            borderColor: 'lightBlue',
            color: 'purple',
            fontSize: 16,
            fontFamily: 'Graphik-Medium',
            width: 115,
            height: 48,
            padding: '16px 24px',
            margin: '0 0 0 2px',
            hoverBackgroundColor: 'white',
            hoverBorderColor: 'lightBlue',
            hoverColor: 'purple',
            onClick: () => {
                this.navigateToDashboard();
            },
        };
    }

    rejectButtonDisabledProps: ButtonPropsType = {
        label: '',
        backgroundColor: 'white',
        borderColor: 'grey',
        color: 'grey',
        disabled: true,
        iconHeight: 16,
        iconWidth: 16,
        fontSize: 16,
        fontFamily: 'Graphik-Medium',
        width: 115,
        height: 48,
        padding: '16px 24px',
        margin: '0 0 0 2px',
        icon: 'assets/icons/Reject_Disabled.svg'
    }

    async loadSupplierData(workflowId: string) {
        if (this.route.snapshot.routeConfig.path === "add-sub-supplier/:workflowId") {
            const response = await this.workflowService.getWorkflowById<InviteSubSupplierApiResponse>(workflowId);
            this.inviteMode = "subsupplier";
            this.model = {
                ...response.context.subSupplier,
                internationalcompanyname: response.context.subSupplier.companyLegalName,
                childTsmId: response.context.supplierTsmId
            }
        } else {
            const response = await this.workflowService.getWorkflowById<InviteSupplierApiResponse>(workflowId);
            this.inviteMode = "parent";
            this.model = {
                ...response.context,
                internationalcompanyname: response.context.companyLegalName
            }
        }

        // handle old workflow data(where only single MU was allowed)
        if (this.model.marketUnit && isString(this.model.marketUnit)) {
            this.model.marketUnit = [this.model.marketUnit.toString()];
        }

    }

    filterConfigs(): void {
        this.configs = this.configs?.filter((cfg) => cfg.fieldset[0].name !== "globalUltimateParentDUNS")
    }

    adjustControlTypes(configs: ComponentConfig[]): void {
        const multiSelect = "multi-selector";
        this.setMultiSelectDropDown(configs, "marketUnit", multiSelect);
        this.setMultiSelectDropDown(configs, "countryServed", multiSelect);
    }

    setMultiSelectDropDown(configs: ComponentConfig[], controlName: string, controlType: string): void {
        const control = this.form.getConfig(controlName, configs);
        if (control) {
            control.type = controlType;
        }
    }

    isValidComment(): boolean {
        const isValid = /^[\s\t\r\n]*\S+/.test(this.model.comment);
        return isValid;
    }

    onKeyUp() {
        this.noCommentError = false;
        this.invalidCommentError = false;
    }

    async confirmInvitation(): Promise<void> {
        try {
            this.confirmButton.disabled = true;
            this.rejectButton = this.rejectButtonDisabledProps;
            this.confirmButton.isProcessing = true;
            const payload = {
                supplierTsmId: this.model.childTsmId,
                comment: this.model.comment,
                workflowId: this.workflowId
            }
            await this.supplierInviteService.confirmInvitedSupplier(this.inviteMode, payload);

            this.supplierInviteService.eventEmit.emit("confirmSuccess");
        } catch (error) {
            console.error("Failed to approve parent supplier: ", error);
            this.supplierInviteService.eventEmit.emit("error");
            this.confirmButton.disabled = null;
        }
        this.confirmButton.isProcessing = null;
    }

    handleRejectButtonClick(): void {
        if (this.model.comment && this.isValidComment()) {
            this.openRejectConfirmationPopUp();
            return;
        }
        if (!this.model.comment) {
            this.noCommentError = true;
            return;
        }
        if (!this.isValidComment()) {
            this.invalidCommentError = true;
            return;
        }
    }

    private handleSupplierInviteEvent(eventType: InviteSupplierEvents): void {
        if (eventType === "confirmSuccess" || eventType === "rejectSuccess") {
            window.scrollTo(0, 0);
            this.freezeButtons();
            this.commentDisabled = true;
        }
    }

    private freezeButtons(): void {
        this.cancelButton = {
            ...this.cancelButton,
            disabled: true,
            borderColor: null,
            color: "mediumGrey"
        }

        this.rejectButton = {
            ...this.rejectButton,
            disabled: true,
            color: "mediumGrey",
            borderColor: null,
            icon: 'assets/icons/Reject_Disabled.svg'
        }

        this.confirmButton = {
            ...this.confirmButton,
            disabled: true,
            backgroundColor: "mediumGrey",
            borderColor: "mediumGrey"
        };
    }

    private openRejectConfirmationPopUp() {
        const rejectConfirmationModalOptions: NgbModalOptions = {
            centered: true,
            animation: true,
            windowClass: 'add-parent-modal-window',
            backdropClass: 'modal-custom-backdrop',
        };
        if (!this.ngbModalService.hasOpenModals()) {
            const modalReference = this.ngbModalService.open(PopUpConfirmRejectInviteSupplierComponent, rejectConfirmationModalOptions);
            modalReference.componentInstance.childTsmId = this.model.childTsmId;
            modalReference.componentInstance.comment = this.model.comment;
            modalReference.componentInstance.inviteMode = this.inviteMode;
            modalReference.componentInstance.workflowId = this.workflowId;
            let confirmModal = <HTMLElement>document.getElementsByTagName("ngb-modal-window")[0];
            if (confirmModal) {
                confirmModal.setAttribute("aria-hidden", "false");
            }
            let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
            if (tsmRoot) {
                tsmRoot.setAttribute("aria-hidden", "false");
            }
        }
    }

    navigateToDashboard(): void {
        this.router.navigateByUrl("/dashboard-search");
    }

    ngAfterContentChecked(): void {
        if (localStorage.getItem("multiLanguage")) {
            const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
            this.translations = multiLanguageJson.body.main.inviteSupplier;
            if (this.inviteMode === "subsupplier") {
                this.translations.header = this.translations.inviteSubSupplierHeader;
            } else {
                this.translations.header = this.translations.inviteParentHeader;
            }
            this.cancelButton.label = this.translations.cancel;
            this.rejectButton.label = this.translations.reject;
            this.confirmButton.label = this.translations.confirmInvitation;
        }

        if (this.form?.formGroup?.controls.countryServed) {
            this.supplierRelationshipService.eventEmit.emit("MU#" + this.model.marketUnit);
            this.supplierRelationshipService.eventEmit.emit({ type: "prefillValues", value: this.model.countryServed });
        }
    }
}
