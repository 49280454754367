<div class="modal-header">
    <img class="modal-icon" [src]="popData.iconUrl" alt="icon-popData" />
    <h4 class="modal-title">{{'supplier.body.main.popUpDeleteUser.title' | translate}}</h4>
</div>
<div class="modal-body">
    <div class="modal-body-content">{{'supplier.body.main.popUpDeleteUser.content' | translate}}</div>
    <div>
    <sh-button ngbAutofocus [props]="backButtonProps"> </sh-button>
    <sh-button ngbAutofocus [props]="confirmButtonProps" style="float: right;"> </sh-button>
    </div>
</div>