<div class="modal-header">
    <h4 class="modal-title">{{profileDetailsJson.profileTitle}}</h4>
    <div ngbDropdown class="d-inline-block" *ngIf="editButton">
      <button class="btn btn-outline-secondary language-button" id="dropdownBasicProfileDetails" ngbDropdownToggle>{{ "currentLanguage" | translate }}</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasicProfileDetails">
        <button ngbDropdownItem *ngFor="let item of languageList" (click)="switchLanguage(item.value)" class="language-button">{{item.name}}</button>
      </div>
    </div>
</div>
<sh-alert [ngStyle]="{'display': verifyAlertVisiable ? 'block' : 'none'}" [props]="VerifyAlertProps"></sh-alert>
<div class="modal-body">
    <div class="modal-body-content">
        <form class="detailform">
            <qnr-dynamic-form [configs]="configs" [model]="model" [context]="context" #form></qnr-dynamic-form>
            <div class="modal-footer-content" *ngIf="headerRole() === 'supplier' && showText">
                <div style="padding: 0px 8px 0px 0px;">
                    <img class="modal-icon" src="assets/icons/info-blue.svg"  alt="icon-info" />
                </div>
                <div> {{ "supplier.body.main.profileDetails.preferredCommunicationsLanguage" | translate }} </div> 
            </div>
            <div class="d-flex flex-row-reverse">
                <sh-button ngbAutofocus [props]="buttonProps"></sh-button>
                <sh-button ngbAutofocus [props]="cancelProps" class="cancel-btn"></sh-button>
            </div>
        </form>  
    </div>
</div>
