import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminBoardLogItem } from '@lsl16/sustainability-shared-components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteUserConfig } from 'src/app/components/pop-up-delete-user/deleteUser';
import { PopUpDeleteUserComponent } from 'src/app/components/pop-up-delete-user/pop-up-delete-user.component';
import { PopUpInactiveComponent } from 'src/app/components/pop-up-inactive/pop-up-inactive.component';
import { LoginUser } from 'src/app/models/LoginUser';
import { AdminBoardService } from 'src/app/services/adminboard/admin-board.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'tsm-supplier-user-profile',
  templateUrl: './supplier-user-profile.component.html',
  styleUrls: ['./supplier-user-profile.component.sass']
})
export class SupplierUserProfileComponent implements OnInit {
  public jumpUrl: string = "supplier-admin-board";
  public b2cUsers: AdminBoardLogItem[];

  private supplierIdForSystemSupplierAdmin?: string;

  loggedInUser: LoginUser;
  roles: any = [];
  user: any;
  displayRoleList:any = [];

  ROLE_TYPE_MAP = {
    buyer: "1",
    supplier: "2",
    authority: "3"
  };
  SUPPLIER_ROLE_LIST = [
    {Code: "1" , Text: "General supplier user"},
    {Code: "2" , Text: "Supplier admin"},
    {Code: "3" , Text: "Inactive"}];

  deleteUserConfig: DeleteUserConfig = {
      type: "delete-user",
      options: {},
      user: {},
      deleteReq: {}
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private adminBoardService: AdminBoardService,
    private authService: AuthService,
    private modalService: ModalService,
    private ngbModalService: NgbModal) { }

  async ngOnInit() {
    this.handleRouteParams();
    this.setJumpUrl();

    this.deleteUserConfig.options = this.modalService.getOptions(this.deleteUserConfig.type);
    const userId = this.route.snapshot.paramMap.get("id");
    this.loggedInUser = this.authService.getLoginUser();
    
    // query user information
    this.user = await this.queryUserById(userId);
    
    // role list
    this.roles = this.SUPPLIER_ROLE_LIST;
    if (this.user.role) {
      const roleArray = this.user.role
      for (const role of this.roles) {
        if (roleArray.includes(parseInt(role.Code))) {
          this.displayRoleList.push(role.Text);
        }
      }
    }
    this.user.displayRole = this.displayRoleList.join(", ")
    return;
  }

  private setJumpUrl() {
    if (this.authService.isSystemSupplierAdmin()) {
      this.jumpUrl = `system-supplier-admin-board?supplierTsmId=${this.supplierIdForSystemSupplierAdmin}`
    }
  }

  private handleRouteParams() {
    this.route.queryParamMap.subscribe(paramMap => {
      this.supplierIdForSystemSupplierAdmin = paramMap.get('supplierId');
    });
  }
  /**
   * shared component outer event
   * include update user role and delete user
   * @param value {opt: opt, value: value}
   */
  async outerEvent(value) {
    const outerData = JSON.parse(value);
    if (outerData.opt === "updateUserRole") {
      await this.updateUserRole(outerData.value);
    } else if (outerData.opt === "deleteUser") {
      await this.deleteUser();
    }
  }
  /**
   * update user role
   * @param role role id
   */
  async updateUserRole(role) {
    let countIsActive = 0;
    this.b2cUsers = await this.adminBoardService.getAdminBoardLog(this.getTsmSupplierId());
    const usersObj = this.b2cUsers.filter(i=>i.userId);
    Object.getOwnPropertyNames(usersObj).forEach(function (key) {
      if (key && key != "length") {
        const isActive = !usersObj[key].currentRole.includes(3);
        if (isActive) {
          countIsActive++;
        }
      }
    });
    let isInactive = usersObj.find((obj) => obj.userId === this.user.userId).currentRole.includes(3);
    if (!role.includes(3) && isInactive) {
      countIsActive++;
    }
    if (countIsActive <= 20 || role.includes(3)) {
      const roleMapping = {
        email: this.user.email,
        tsmId: usersObj[0].extension_tsm_tsmId,
        schemaId: this.ROLE_TYPE_MAP.supplier,
        roleId: role
      };
      await this.adminBoardService.updateUserRole(this.user.userId, roleMapping);
    } else {
      this.openInactivePopUp();
    }
  }

  async deleteUser() {
    const deleteReq = {
      email: this.user.email,
      tsmId: this.getTsmSupplierId()
    };
    const confirmType = this.deleteUserConfig;
    confirmType.user = this.user;
    confirmType.deleteReq = deleteReq;
    const modalReference = this.ngbModalService.open(PopUpDeleteUserComponent, confirmType.options);
    modalReference.componentInstance.confirmType = confirmType;
    let tsmRoot  =  <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden","false");
    }
    if (this.authService.isSystemSupplierAdmin()){
      modalReference.componentInstance.redirectUrl = this.jumpUrl;
    }
    
  }

  async queryUserById(userId): Promise<any> {
    const user = await this.adminBoardService.getAdminBoardLogItem(userId);
    if (!(user && user.userId)) {
      await this.router.navigateByUrl(this.jumpUrl);
    }
    this.b2cUsers = await this.adminBoardService.getAdminBoardLog(this.getTsmSupplierId());
    const usersObj = this.b2cUsers;
    for (let key in usersObj) {
      if (user.userName === usersObj[key].email) {
        var b2cUsers = usersObj[key];
      }
    }
    const currentUser = {
      userId: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.userName,
      extension_tsm_defaultProfile: user.extension_tsm_defaultProfile !== null ? user.extension_tsm_defaultProfile : b2cUsers.extension_tsm_defaultProfile,
      role: null,
      displayRole: null
    };

    const currentAuthObj = JSON.parse(b2cUsers.extension_tsm_TSM);
    // convert current role
    const currentProfileId = this.ROLE_TYPE_MAP.supplier;
    const currentRoleList = currentAuthObj[currentProfileId];
    currentUser.role = currentRoleList;

    return currentUser;
  }

  openInactivePopUp(): void {
    const service = this.ngbModalService;
    if (!service.hasOpenModals()) {
      const modalReference = service.open(PopUpInactiveComponent, {
          centered: true,
          size: "lg",
          scrollable: true,
          windowClass: "countdown-modal",
          backdropClass: "modal-custom-backdrop",
          backdrop: "static",
          keyboard: false
      });
    }
  }
  
  private getTsmSupplierId(): string {
    if (this.authService.isSystemSupplierAdmin()) {
      return this.supplierIdForSystemSupplierAdmin;
    }

    return this.loggedInUser.tsmId;
  }
}
