import { AuthService } from './../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { SubsidiarySupplierUser } from 'src/app/models/SubsidiarySupplierUser';

@Component({
  selector: 'tsm-subsidiary-supplier-admin-board-user-item',
  templateUrl: './subsidiary-supplier-admin-board-user-item.component.html',
  styleUrls: ['./subsidiary-supplier-admin-board-user-item.component.sass']
})
export class SubsidiarySupplierAdminBoardUserItemComponent implements OnInit {
  @Input() model: SubsidiarySupplierUser;
  editBtnProps: ButtonPropsType;
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.setButtonProps();
  }

  setButtonProps(): void {
    this.editBtnProps = {
      label: "",
      color: "purple",
      fontSize: 14,
      backgroundColor: "white",
      borderColor: "palePurple",
      hoverColor: "purple",
      hoverBackgroundColor: "white",
      hoverBorderColor: "palePurple",
      height: 39,
      width: 96,
      padding: "10px",
      iconPosition: "left",
      onClick: () => this.navigateToEditSubsidiaryPage()
    };
  }

  private navigateToEditSubsidiaryPage() {
    this.router.navigateByUrl(`supplier-admin-board/${this.model.childTsmId}/${this.model.userId}`);
  }

  public showEditUser(): boolean {
    // only show edit button to supplier admins
    return this.authService.isSupplierAdmin();
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multilanguageJson.body != undefined) {
        const labelTranslations = multilanguageJson.body.main.subsidiarySupplierUser;
        this.editBtnProps.label = labelTranslations.editBtn;
      }
    }
  }
}
