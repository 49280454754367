<div class="card container-fluid user-profile">
    <div class="row row-cols-auto no-gutters">
        <div class="col-1" style="margin-top: 4vh">
            <img class="user-image" src="assets/images/image-empty-state-grey.jpg" alt="icon-empty-state" />
        </div>
        <div class="col-11 ps-5 user-details">
            <div class="row no-gutters mt-4">
                <div class="col-1 icon-col">
                    <img src="assets/icons/icon-profile.svg" alt="username-icon" />
                </div>
                <span id="userName" class="col user-data" >{{currentUser?.firstName}} {{currentUser?.lastName}}</span>
            </div>
            <div class="row no-gutters mt-4">
                <div class="col-1 icon-col">
                    <img src="assets/images/icon-email.png" style="width: 21px" alt="email-icon" />
                </div>
                <span id="userEmail" class="col user-data">
                    {{currentUser?.email}}
                </span>
            </div>
            <ng-content></ng-content>
        </div>
        <div *ngIf="showSaveButton" class="save save-button">
            <sh-button [props]="currentButtonProps"></sh-button>
        </div>
        <div *ngIf="showSaveSuccess" class="save save-status">
            <svg id="data-saved-icon"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                class="saved-message-icon"
            >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                    class="svg"
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                />
            </svg>
            <span id="data-saved-message-label" class="saved-message">{{translatedLabels.saveSuccessButtonLabel}}</span>
        </div>
    </div>
</div>
