import { EventEmitter, Injectable } from "@angular/core";
import { XMLSerializedAsObject } from 'xmlbuilder2/lib/interfaces';
import { WorkerWrapper } from 'src/app/workers/worker-base';

/**
 * Common XML Service so that multiple services can use the same worker instance
 */
@Injectable({
  providedIn: "root"
})
export class XmlConversionWorkerService {
  private worker: Worker = undefined;
  private workerWrapper: WorkerWrapper;

  constructor() {
    if (!this.worker) {
      this.worker = new Worker(new URL('../../workers/xml-conversion.worker', import.meta.url));
      this.workerWrapper = new WorkerWrapper(this.worker);
    }
  }

  public async convertJsonToXmlString(json: XMLSerializedAsObject) {
    return this.workerWrapper.sendAsync<string>({
      type: "GENERATE_XML_FROM_JSON",
      payload: json
    });
  }

  public async convertXmlToJsonString(xml: string) {
    return this.workerWrapper.sendAsync<string>({
      type: "GENERATE_JSON_FROM_XML",
      payload: { xml }
    });
  }

  public async readFileAndConvertXmlToJsonString(file: File) {
    return this.workerWrapper.sendAsync<XMLSerializedAsObject>({
      type: "GENERATE_JSON_FROM_XML",
      payload: { file }
    });
  }
}