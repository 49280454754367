<div class="modal-header">
  <h4 class="modal-title">
    {{ modalTranslation.title }}
  </h4>
</div>
<sh-alert *ngIf="isAlertVisible" [props]="verifyAlertProps"></sh-alert>
<div class="modal-body">
  <div class="warning-container confirm-noparent-info-box" *ngIf="warningMessage">
    <div class="warning-message" id="warning-message" [innerHTML] = "warningMessage">
    </div>
  </div>

  <div class="modal-body-content">
    <tsm-specify-parent
      [supplier]="requestingSupplier"
      mode="subsupplier"
      (onSearchParent)="onSearchParent($event)"
      (onParentDunsNumberChanged)="onSubsidiaryDunsNumberChanged($event)"
      [parentDunsNumber]="subsidiaryDunsNumber"
    >
    </tsm-specify-parent>
    <div *ngIf="noRecordFound">
      <div class="row norecord-found" id="noRecordMessage" >
        {{ modalTranslation.noRecordFound }}          
      </div>
      <div class="row inviteSupplier">
        <sh-button [props]="inviteNewSupplierButtonProps"></sh-button>
      </div>
    </div>       
    <div *ngIf="confirmSupplier">
      <tsm-confirm-parent
          [confirmSupplier]="confirmSupplier"
          mode="subsupplier"
          ></tsm-confirm-parent>
    </div>
    <div class="dform mt-24" *ngIf="confirmSupplier">
      <qnr-dynamic-form [context]="context" [configs]="configs" #form></qnr-dynamic-form>
    </div>
  </div>
</div>
<div class="modal-buttons">
  <sh-button [props]="cancelButtonProps"></sh-button>
  <sh-button ngbAutofocus [props]="currentConfirmButtonProps"></sh-button>
</div>
