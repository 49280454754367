
import { EventEmitter, Component, Input, Output, OnInit } from '@angular/core';
import { each } from 'lodash';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { FilterData, ReportType, EsgReportService } from '../../services/esgReport/esg-report.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { SystemAdminService } from 'src/app/services/systemAdmin/system-admin.service';
export type RequestStatusEvent = {
  status: "Error" | "Success";
}




export type ApplyFilterEvent = {
  reportType: ReportType;
  filters: FilterData[];
}

@Component({
  selector: 'tsm-sa-retriggering-preview',
  templateUrl: './sa-retriggering-preview.component.html',
  styleUrls: ['./sa-retriggering-preview.component.sass']
})
export class SaRetriggeringPreviewComponent implements OnInit {
  public translatedLabels = ''
  public errorMessage = ''
  public showErrorMesage = false;
  displayValidationList = false  // to show or hide validation container
  sharedButtonProps: ButtonPropsType = {
    label: "",
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 20px',
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
  };
  deSelectAll: ButtonPropsType = {
    label: "Deselect All",
    color: "purple",
    clickBorderColor: "purple",
    backgroundColor: "lightPurple",
    hoverBorderColor: "purple",
    fontSize: 16,
    height: 48,
    width: 180,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    onClick: () => {
      this.toggleAllCheckboxesDeselect();
    }
  };
  selectAll: ButtonPropsType = {
    ...this.sharedButtonProps,
    label: "Select All", 
    onClick: () => {
      this.toggleAllCheckboxes();
    }
  };
  reTriggerSABtn: ButtonPropsType = {
    ...this.sharedButtonProps,
    label: "Retrigger SA",
    onClick: () => {
      this.reTriggerSA();
    }
  };
  public reportPreview: any;
  public filters: FilterData[] = [];
  payload: { [key: string]: any } = {};
  columns;
  currentPage = 1;
  pageSize = 30;
  rows = [];
  ColumnMode = ColumnMode;
  @Input() applyFilterEvent: EventEmitter<ApplyFilterEvent>;
  @Output() requestStatusEventEmitter = new EventEmitter<RequestStatusEvent>(true);
  requestPurpose: any = [
    {
      Text: "Sustainability Assessment update for existing supplier",
      Code: "Sustainability Assessment update for existing supplier"
    }
  ]
  requestPurposeData = "Sustainability Assessment update for existing supplier";
  updateReasonData: any = [
    {
      Text: "Update scope 3 responses for existing supplier",
      Code: "Update scope 3 responses for existing supplier"
    },
    {
      Text: "Others",
      Code: "Others"
    }
  ]
  updateReasonSelected = 'Others'
  checkBoxState = {}; // Object to maintain checkbox state for each row
  allChecked = false;
  pageSizeOptions = [30, 50, 100]; // for now i have added page 10 and 20
  loading = false;
  constructor(
    private esgReportService: EsgReportService,
    private systemAdminService: SystemAdminService
  ) { }

  ngOnInit(): void {
    if (this.applyFilterEvent) {
      this.applyFilterEvent.subscribe(next => this.handleApplyFilterEvent(next));
    }
    
  }
  get showEmptyMessage() {
    return !this.displayValidationList
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multiLanguageJson.body != undefined) {
        this.translatedLabels = multiLanguageJson.body.main.saReportPreview.noSearchMessage;
        this.errorMessage = multiLanguageJson.body.main.saReportPreview.filterSAErrorMessage;
      }
    }
  }

  private handleApplyFilterEvent(event: any) {
    this.displayValidationList = false;
    this.showErrorMesage = false;
    this.filters = event.filters;
    this.toggleAllCheckboxesDeselect();
    this.fetchPreviewRecords(event);
  }


  async fetchPreviewRecords(filterEvent: ApplyFilterEvent) {
    // temp
    const array = filterEvent.filters;
    let filterNameArray = [];
    let filterValueArray = [];
    this.columns = [];
    this.payload = {};
    array.forEach(element => {
      filterNameArray.push(element.filterName);
      filterValueArray.push(element.filterValue);
    });
    for (let i = 0; i < filterNameArray?.length; i++) {
      this.payload[filterNameArray[i]] = filterValueArray[i];
    }
    try {
      // removed the attribute from payload if it is not selected
      function clean(obj) {
        var propNames = Object.getOwnPropertyNames(obj);
        for (var i = 0; i < propNames.length; i++) {
          var propName = propNames[i];
          if (obj[propName] === null || obj[propName] === undefined || obj[propName].length === 0) {
            delete obj[propName];
          }
        }
      }
      clean(this.payload)
      this.reportPreview = await this.esgReportService.filteredReTriggeringSA(this.payload);
      if(this.reportPreview?.msg || this.handleProgressEvent(this.reportPreview)) { 
        this.displayValidationList = false;
        this.requestStatusEventEmitter.emit({ status: 'Error' });
        const errorMessage = this.reportPreview?.msg ? this.reportPreview?.msg.msg : 'Internal server error.'
        this.systemAdminService.eventEmit.emit({
          type: "addError",
          message: errorMessage
        });
        return
      }
      this.displayValidationList = true;
      this.rows = this.reportPreview
      this.rows.forEach((v) => {
        v['action1'] = 'action1'
        v['action2'] = 'action2'
        v['action3'] = 'action3'
        v['dropdownValue']= this.updateReasonData[1]
        v['countryListTsmCode'] = v['servedCountryList']
      })
      this.columns = [
        { prop: 'supplierName', name: 'SUPPLIER NAME', isLeftPinnedColumn: true, width: 210 },
        { prop: 'marketUnit', name: 'MARKET UNIT', width: 190 },
        { prop: 'servedCountryList', name: 'COUNTRY', width: 180 },
        { prop: 'esgSubmissionDate', name: 'CURRENT SA SUBMISSION DATE', width: 220, colType: "Date" },
        { prop: 'countryServed', name: 'SA COUNTRY', width: 190 },
        { prop: 'status', name: 'ESG STATUS', width: 250 },
        { prop: 'action1', name: 'REQUEST PURPOSE', width: 210 },
        { prop: 'action2', name: 'UPDATE REASON', width: 200 },
        { prop: 'action3', name: 'SELECTED', width: 190 ,isRightPinnedColumn: true,},
      ];
      this.handleReportPreview(this.reportPreview);
      this.requestStatusEventEmitter.emit({ status: 'Success' });
    }
    catch (error) {
      this.requestStatusEventEmitter.emit({ status: 'Error' });
      this.showErrorMesage = true;
      this.displayValidationList = false;
      console.error(`Failed to fetch report preview: ${error}`);
    }
  }

  handleProgressEvent(event: ProgressEvent<any>): boolean {
    if (event.type === 'error') {
      return true
    } else {
      return false
    }
  }

  handleReportPreview(reportPreview: any) {
    if (reportPreview?.length > 0) {
      this.formatDates(reportPreview);
    } 
  }

  private formatDates(reportPreview: any): any {
    each(this.columns, (col, i) => {
      if (col.colType === 'Date') {
        this.reportPreview.forEach((row) => {
          if (row['esgSubmissionDate']) {
            row['esgSubmissionDate'] = row['esgSubmissionDate'].split('T')[0];
          }
          if (row['servedCountryList']) {
            const names = row['servedCountryList'].map(item => item.name).join(', ');
            row['servedCountryList'] = names;
          }
          if (row['marketUnit']) {
            const names = this.getUniqueMarketUnit(row['countryListTsmCode'])
            row['marketUnit'] =names;
          }
        });
      }
    });
    return reportPreview;
  }
  getUniqueMarketUnit(countryList) {
    const uniqueCodes = new Set(countryList.map(country => country.marketUnit));
    return Array.from(uniqueCodes).join(', ');
 }
  toggleAllCheckboxes() {
    this.allChecked = true;
    const currentPageRows = this.rows.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    currentPageRows.forEach(row => {
      this.checkBoxState[row.taskId] = this.allChecked;
    });
  }

  toggleAllCheckboxesDeselect() {
    this.allChecked = false;
    this.checkBoxState = {}
  }

  onPageChange(pageNumber) {
    this.allChecked = false;
    this.currentPage = pageNumber;
  }
  onCheckboxChange(row, $event) {
    if($event.target.checked) {
      this.checkBoxState[row.taskId] = true;
    }
  }
  
  onPageSizeChange(newSize) {
    this.pageSize = newSize;
    this.currentPage = 1; // Optionally reset to the first page
    if(this.allChecked){
      this.toggleAllCheckboxes();
    }
    
  }

  openSupplier(id: string) {
    let url = "/#/supplier-profile/" + id;
    window.open(url, "_blank");
  }

  onDropdownSelected(row: any, selectedValue: any): void {
    // Find the index of the current row in the rowData array
    const rowIndex = this.rows.indexOf(row);
  
    // Make a copy of the row object to avoid mutating the original rowData array
    const updatedRow = { ...row };
  
    // Update the property in the row object with the selected value
    updatedRow.dropdownValue = selectedValue;
  
    // Replace the original row object in the rowData array with the updated one
    this.rows[rowIndex] = updatedRow;
  
    // You can also log the updated row object for debugging purposes
    console.log('Updated row:', updatedRow);
    console.log( this.rows)
  
    // Add any additional logic here, such as sending the updated data to a server
  }

  async reTriggerSA(): Promise<void> {
    const checkBoxStateCount = Object.keys(this.checkBoxState).length;
    if (checkBoxStateCount) {
      if(checkBoxStateCount > 5000) {
        this.systemAdminService.eventEmit.emit({
          type: "addError",
          message: 'Warning: A maximum of 5000 records are permitted to trigger at a time.'
        });
        return
      }
      this.loading = true;
      const filterData = this.rows.filter(filter => this.checkBoxState[filter.taskId])
      let userId = JSON.parse(localStorage.getItem("userInfo")).pocEmail;
      let requestData = filterData.map((item) => {
        const countryServed = item.countryListTsmCode ? item.countryListTsmCode.map(item => item.tsmCode) : [];
        return {
          esgQuestionnaireId: item.esgQuestionnaireId + "#" + countryServed[0],
          countryServed: countryServed,
          error: '',
          pocEmail: userId,
          reTriggeredstatus: 'NEW',
          supplierTsmId: item.supplierTsmId,
          updateReason: item.dropdownValue.Code,
          supplierName: item.supplierName,
          requestPurpose: this.requestPurposeData        
        }
      });
      const response = await this.esgReportService.reTriggeringSA(requestData);
      this.loading = false;
      if (response.status === 200) {
        this.systemAdminService.eventEmit.emit({
          type: "addSuccess",
          message: response.msg.msg
        });

        this.rows = this.rows.filter(filter => (!this.checkBoxState[filter.taskId]))
        this.displayValidationList = this.rows.length ? true : false;
        this.toggleAllCheckboxesDeselect()
      } else {
        this.systemAdminService.eventEmit.emit({
          type: "addError",
          message: 'Internal server error.'
        });
      }
    } else {
      this.systemAdminService.eventEmit.emit({
        type: "addError",
        message: 'Please select sustainability assessment for re-triggered'
      });
    }
  }
}
