import { Component, OnInit, ViewChild } from "@angular/core";
import {
    ButtonPropsType,
    DynamicFormComponent,
    ComponentConfig,
    DynamicContext,
    AlertPropsType,
    HttpService
} from "@lsl16/sustainability-shared-components";
import { MetadataService } from "../../services/metadata/metadata.service";
import { BlacklistService } from "../../services/blacklist/blacklist.service";
import { AdminBoardService } from "../../services/adminboard/admin-board.service";
import { AuthService } from "../../services/auth/auth.service";
import { SupplierGeneralProfileService } from "../../services/supplier-general-profile.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginUser } from "../../models/LoginUser";
import { responseEnum } from "../../../utilities/response-enum";
import { environment } from "src/environments/environment";

@Component({
    selector: "tsm-create-new-user",
    templateUrl: "./create-new-user.component.html",
    styleUrls: ["./create-new-user.component.sass"]
})
export class CreateNewUserComponent implements OnInit {
    configs: Array<ComponentConfig> = [];
    model: any;
    context: DynamicContext;
    @ViewChild("form", { static: false }) form: DynamicFormComponent;
    saveError: boolean;
    saveErrorAlertProps: AlertPropsType = {
        type: "alert-error",
        message: "There is a technical issue, please try again or contact us.",
        icon: "assets/images/icon-error.svg",
        iconFill: "#cc1e32",
        iconClose: "assets/images/icon-cross-blue.svg"
    };
    nextButton: ButtonPropsType;
    props: ButtonPropsType;
    loggedInUser: LoginUser;
    supplierName: string;
    supplierAdminBoard = "supplier-admin-board";
    errMsgKey: string;
    errMsgValue: string;
    supplierTsmId: string;
    defaultProfile: string = "supplier";
    isSystemSupplierAdmin: boolean;
    isLatam: boolean = false; 
    countryServedData: any = [];
    countryServedResponse: any = [];

    constructor(
        private metadataService: MetadataService,
        private blacklistService: BlacklistService,
        private adminBoardService: AdminBoardService,
        private authService: AuthService,
        private supplierGeneralProfileService: SupplierGeneralProfileService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private HttpService: HttpService
    ) {}

    async ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.supplierTsmId = params["supplierTsmId"];
        });
        this.loggedInUser = this.authService.getLoginUser();
        this.context = new DynamicContext();
        const blacklist = await this.blacklistService.returnBlacklist("c8aa5c00-588a-44d6-a08c-071dc9219cbe");
        // set context value for email blacklist validator
        this.context.set("blacklist", blacklist);
        this.configs = await this.metadataService.gettingCreateNewUserConfig();
        this.isSystemSupplierAdmin = this.authService.isSystemSupplierAdmin();
        if (this.isSystemSupplierAdmin) {
            this.supplierAdminBoard = `system-supplier-admin-board?supplierTsmId=${this.supplierTsmId}`;
        }
        else {
            this.supplierTsmId = this.loggedInUser.tsmId;
            this.defaultProfile = this.loggedInUser.defaultProfile;
        }
        const supplier = await this.supplierGeneralProfileService.getSupplierById(this.supplierTsmId);
        this.supplierName = supplier.supplierName;
        this.supplierGeneralProfileService.eventEmitForCS.emit(supplier.saCountryServed);

        this.initButtons();
        localStorage.setItem("saCountryServed", JSON.stringify(supplier.saCountryServed));
    }

    initButtons() {
        this.nextButton = {
            label: "Confirm & Create User",
            color: "white",
            fontSize: 16,
            backgroundColor: "blue",
            borderColor: "blue",
            height: 48,
            padding: "10px",
            onClick: async () => {
                await this.save();
            }
        };
    }

    async save(): Promise<any> {
        const currentCountryLoc = String(localStorage.getItem("currentCountryLocation"));
        if (this.form.validate()) {
            this.nextButton.isProcessing = true;
            const values = this.form.values();
            const authRole = [1];
            if (values.role === "2") {
                authRole.push(2);
            }
            const userParam = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.emailAddress,
                role: authRole,
                extension_tsm_defaultProfile: this.defaultProfile,
                extension_tsm_tsmId: this.supplierTsmId,
                supplierName: this.supplierName,
                emailType: "AddNewSupplierUser",
                tsmOrganisationId: "c8aa5c00-588a-44d6-a08c-071dc9219cbe",
                tsm_req: true
            };
            // to identify if the supplier country served is LATAM
            const countryServedDataUrl = `${environment.tsmBackendServiceURL}/countries/activeCountryServed`;
            this.countryServedData = await this.HttpService.GetPromise(countryServedDataUrl);
            this.countryServedResponse = this.countryServedData.filter(i => (i.marketUnit === 'LATAM')).map(j => j.countryServedName);
            this.countryServedResponse.forEach(countryName => {
                if (currentCountryLoc.includes(countryName)) {
                    this.isLatam = true;
                }
            });
            if(this.isLatam){
                userParam.emailType = "AddNewSupplierUserLATAM";
            }              
            const response = await this.adminBoardService.createUser(userParam);
            this.nextButton.isProcessing = false;
            if (response && response.code === 1) {
                if (this.isSystemSupplierAdmin) {
                    this.router.navigate(["/system-supplier-admin-board"], {
                        queryParams: {
                            supplierTsmId: this.supplierTsmId
                        }
                    });
                }
                else {
                    await this.router.navigateByUrl("/supplier-admin-board");
                }
            } else {
                this.errMsgKey = "There is a technical issue, please try again or contact us.";
                if (response.code === responseEnum.create_user_same_error.code) {
                  this.errMsgKey  =
                        "The user being added is your login user.";
                } else if (response.code === responseEnum.create_user_domain_error.code) {
                  this.errMsgKey  =
                        "The user being added must have the same email domain (e.g. @xyz.com) as the supplier admin.";
                }
                else if (response.code === responseEnum.b2c_email_used.code) {
                  this.errMsgKey =
                        "This email address is already in use. Please provide a different email address for the supplier.";
                }
                else if (response.code === responseEnum.create_user_duplicate_poc.code) {
                    this.errMsgKey  = responseEnum.create_user_duplicate_poc.msg;
                }
                // alert error if reponse API call fails
                this.saveError = true;
                this.saveErrorAlertProps = {
                    type: "alert-requestVerifiedFaild",
                    message:this.errMsgKey,
                    icon: "assets/images/icon-error.svg",
                    iconFill: "#cc1e32",
                    iconClose: "assets/images/icon-cross-blue.svg",
                    onDismiss: this.onAlertDismiss
                };
                window.scrollTo(0, 0);
            }
        }
    }

    onAlertDismiss = (): void => {
        this.saveError = false;
    };

    ngAfterContentChecked(){
        let createNewUser = localStorage.getItem('multiLanguage');
        let resultNewUser;

        if (createNewUser) {
            resultNewUser = JSON.parse(createNewUser);
        }
        this.errMsgValue = resultNewUser.body.main.createNewUser[this.errMsgKey];
        this.saveErrorAlertProps = {
          type: "alert-requestVerifiedFaild",
          message:this.errMsgValue!=undefined ? this.errMsgValue :this.errMsgKey,
          icon: "assets/images/icon-error.svg",
          iconFill: "#cc1e32",
          iconClose: "assets/images/icon-cross-blue.svg",
          onDismiss: this.onAlertDismiss
        };
        this.configs.forEach(cc => {
            cc.fieldset.forEach(ff => {
            if(resultNewUser){
                const result = resultNewUser.body.main.createNewUser;
                if (result.hasOwnProperty(ff.name)) {
                    if (result[ff.name]) {
                        ff.templateOptions.label = result[ff.name].label;
                        if (ff.templateOptions.placeholder) {
                            ff.templateOptions.placeholder = result[ff.name].placeholder;
                        }
                        if (ff.errorMessage) {
                            if (ff.errorMessage.required) {
                                ff.errorMessage.required = result[ff.name].required;
                            }
                            if (ff.errorMessage.email) {
                                ff.errorMessage.email = result[ff.name].email;
                            }
                        }
                    }
                }
                if (ff.data) {
                    if (result[ff.name].data) {
                        let dataRes = result[ff.name].data;
                        ff.data.forEach((dd, index) => {
                            if (dataRes.hasOwnProperty(dd.Code)) {
                                dd.Text = dataRes[dd.Code]
                            }
                        })

                    }

                }
            }
        })
        })
    }
}
