import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { SupplierRelationshipService } from "@lsl16/sustainability-shared-components";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root"
})
export class ParentConfirmSupplierGuard  {
    constructor(
        private router: Router,
        private supplierRelationshipService: SupplierRelationshipService,
        private authService: AuthService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        try {
            if (!this.authService.isValidToken()) {
                this.authService.logout();
                return false;
            }

            const paramId = route.params.id;
            
            if (this.authService.getRole() === "buyer") {
                this.router.navigateByUrl(`/supplier-profile/${paramId}`);
                return false;
            }

            // parent accessing supplier confirmation
            const response = await this.supplierRelationshipService.hasAccessToParentSupplier(paramId);
            if (response?.parent) { return true };

            // subsidiary accessing parent profile
            if (route?.routeConfig?.path === "parent-supplier-profile/:id" && response?.subsidiary) { return true; }

            // We cannot route to the supplier-profile, otherwise the extension_defaultProfile is overriden
            // which will result in an invalid user_profile state in dynamo
            // Slash routes to the home page of each supplier, i.e. the supplier that is set in the defaultProfile
            this.router.navigateByUrl(`/`);
            return false;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}
