<div class="container-fluid mb-5">
  <div class="main_bg">
    <div class="container container-fluid first-container d-flex">
      <div class="search-and-catagory mx-5 px-1 row justify-content-between">
        <div class="search-container">
          <div
            class="search-textbox d-flex rounded-pill bg-white ps-3 pe-3 py-1"
          >
            <input
              id="companyInput"
              type="text"
              class="pb-1"
              size="30"
              placeholder="{{ translations.searchByCompany }}"
              [(ngModel)]="searchCriteria.companyName"
              aria-label="input-text"
              (keydown)="searchKeyDown($event)"
              (input)="blockNonPrintableCharComp()"
              maxlength="5000"
              autofocus
            />
          </div>
          <div
            class="search-textbox d-flex rounded-pill bg-white ps-3 pe-3 py-1"
          >
            <input
              id="locationInput"
              type="text"
              class="pb-1"
              size="30"
              placeholder="{{ translations.searchByLocation }}"
              [(ngModel)]="searchCriteria.location"
              aria-label="input-text"
              (keydown)="searchKeyDown($event)"
              (input)="blockNonPrintableCharLoc()"
              maxlength="5000"
            />
          </div>
          <div class="sh-btn flex-row-reverse">
            <sh-button [props]="searchButtonProps"> </sh-button>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="searchExecuted && limitExceeded"
      class="mx-5 info-bar-too-many-users"
    >
      <img src="assets/icons/info-light-blue.svg" id="tooManyUsersImg" alt="icon-info" />
      <div style="padding-left: 12px" id="tooManyUsersMsg">
        {{ translations.tooManyUsersInfo }}
      </div>
    </div>

    <div class="page-content mx-5">
      <tsm-subsidiary-supplier-admin-board-users
        [users]="users"
        [searchExecuted]="searchExecuted"
        [errorOccurred]="errorOccurred"
      >
      </tsm-subsidiary-supplier-admin-board-users>
    </div>
  </div>
</div>
