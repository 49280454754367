<tsm-secondary-banner></tsm-secondary-banner>
<sh-alert *ngIf="showAlertsuccess"  [props]="successAlert"></sh-alert>
<div class="container">
    <div class="row">
        <div class="col my-5">
            <span class="instructions">Now select the action you'd like to take with this supplier</span>
        </div>
       
            
        </div>
    <div class="row">
        <div class="col-4" tsmPermission="onboardingSendReq=tsm&sup">
            <tsm-options-card *ngIf="onboarding" [options]="onboarding" ></tsm-options-card>
        </div>
        <div class="col-4" tsmPermission="sustainabilitySendReq=tsm&sph">
            <div *ngIf="esgAssessment">
                <!-- Non-parent role card   -->
                <tsm-options-card *ngIf="hasConfirmedParent === false" [options]="esgAssessment" [supplier]="supplier"></tsm-options-card>
                <!-- Child Indicates the role card  -->
                <tsm-options-card *ngIf="hasConfirmedParent === true && parentNotSa === false" [options]="esgAssessment" [supplier]="supplier"></tsm-options-card>
                <tsm-options-card *ngIf="hasConfirmedParent === true && parentNotSa === true" [options]="esgAssessmentProps" (refreshData)="setSuccessAlertProps()" [userType]="userType" [esgStatus]="esgStatus" [supplier]="supplier"></tsm-options-card>
            </div>
            
        </div>
        <div class="col-4">
            <tsm-options-card *ngIf="showCard" [options]="viewRelatedProfilesProps" ></tsm-options-card>
        </div>
    </div>
</div>
