<div class="row">
  <sh-alert *ngIf="alertVisible" [props]="alertProps" style="width: 100%"></sh-alert>
</div>
<div class="videoLink">
  <div *ngIf="!isShowVideo" class="open-link" >
    <marquee><span class="mr75"><a class="ethics-link" href="https://www.accenture.com/in-en/about/company/business-ethics" target="_blank">There are new changes in Accenture's Supplier Standard of Conduct. Click here to learn more</a></span> <span (click)="isShowVideo = !isShowVideo" tabindex="0">Join Accenture in shaping a greener and more sustainable future through TSM. Click here to learn more</span></marquee>
  </div>
  <div *ngIf="isShowVideo" class="close-link" (click)="isShowVideo = !isShowVideo">
    [x close]
  </div>
  <div class="video-container">
    <div *ngIf="isShowVideo" class="tsm-video">
      <iframe class="iframe-container" name="iframe_video" loop="1" src="https://www.youtube.com/embed/yY3O5SN4jVY?si=-K1b19K9ceEfsVQK&rel=0&border=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
  </div>
</div>
<tsm-select-drop-down-box [profileData]="profileData" (reaload)="reload($event)" [disabled]="isLoadingSupplier">
</tsm-select-drop-down-box>
<div class="supplier-information-box-container mt-5">
  <sh-supplier-information-box #SupplierInformationBoxComponent *ngIf="supplier" [supplier]="supplier"
    [userType]="userType" editBtnAuthId="editSupplierProfile=tsm" changeLocAuthId="changeLocation=tsm"
    (outerEvent)="openProfilePopup('edit-profile-detail')" (countryServedChanged)="getCountryServed($event)"
    [parentSupplier]="parentSupplier" [changeLocationDisabled]="isLoadingSupplier" [esgStatus]="esgStatus"
    [showSuppplierEsgStatus]="showSuppplierEsgStatus" [isShowStatus]="isShowStatus" [isDuplicate]="isDuplicate"></sh-supplier-information-box>
</div>
<div class="mx-5 my-5 pt-2">
  <tsm-tab-bar [tabs]="tabs"></tsm-tab-bar>
  <div *ngFor="let tab of tabs">
    <div class="row" *ngIf="tab.active && tab.id === 'Actions'">
      <tsm-supplier-onboarding [supplier]="supplier" style="width: 100%" [buyerId]="buyerId" [isDuplicate]="isDuplicate"></tsm-supplier-onboarding>
      <tsm-sv-esg-assessment [supplier]="supplier" style="width: 100%" [buyerId]="buyerId" [isDuplicate]="isDuplicate"></tsm-sv-esg-assessment>
    
</div>

    <div *ngIf="tab.active && tab.id === 'Relationships'">
      <tsm-supplier-relationship [supplierTsmId]="supplierTsmId" [isSupplier]="isSupplier"
        [isSupplierParent]="isSupplierParent" [isIndirect]="supplier.indirect" [isDuplicate]="isDuplicate"></tsm-supplier-relationship>
    </div>

    <div *ngIf="tab.id === 'Scoring' && tab.active && isReviewDataAvailabe">
      <tsm-scoring [reviewData]="reviewData"></tsm-scoring>
    </div>

    <!-- suppliers tab -->
    <div *ngIf="tab.id === 'Suppliers' && tab.active">
      <tsm-sub-suppliers [supplierTsmId]="supplierTsmId" [displayButtons]="true" [isDuplicate]="isDuplicate"></tsm-sub-suppliers>
      <tsm-historical-sub-suppliers [supplierTsmId]="supplierTsmId"></tsm-historical-sub-suppliers>
    </div>
    
    <div *ngIf="tab.id === 'ActivityLog' && tab.active && this.userType=='supplier'">
      <tsm-activity-log [supplierTsmId]="supplierTsmId"></tsm-activity-log>
    </div>
  </div>
</div>
<!-- *ngIf="loading" -->
<div class="loadEffect" style="position: absolute; left: 50%; top: 50%; z-index: 1000" *ngIf="loading">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>