<div class="row row-cols-auto hover_pointer align-items-center">
    <div class="col-1">
        <div class="subtitle ellipsis make-sno-center" [title]="model.id">{{itemindex}}</div>
    </div>
    <div class="col-3">
        <h6 class="subtitle ellipsis" [title]="model.email_type">{{model.email_type}}</h6>
    </div>
    <div class="col-1">
        <div class="subtitle ellipsis" [title]="model.market_units">{{model.market_units || "NA"}}</div>
    </div>
    <div class="col-2">
        <h6 class="subtitle ellipsis" [title]="model.language_type">{{model.language_type || "English"}}</h6>
    </div>
    <div class="col-3">
        <h6 class="subtitle ellipsis" [title]="model.subject">{{model.subject}}</h6>
    </div>
    <div class="col-2" style="display: flex;">
        <div>
            <sh-button [props]="editButton"></sh-button>
        </div>
        <div>
            <sh-button [props]="deleteButton"></sh-button>
        </div>
    </div>
</div>