import { SystemAdminService } from './../systemAdmin/system-admin.service';
import { Injectable } from '@angular/core';

export type UserRoleDetail = {
  profileId: number,
  roleId: number,
  roleName: string,
  roleIdentifier: string,
  roleDescription: string,
}

export type UserRoleDependencyEntry = Pick<
  UserRoleDetail,
  'profileId' | 'roleId'
> & { entries: number[] };

export type UserRoleDependencies = {
  topDown: UserRoleDependencyEntry[];
  bottomUp: UserRoleDependencyEntry[];
};

// Mapping of profile id to a string for display in the UI
const PROFILE_TYPE_MAP: { [id: number]: string } = {
  1: "Buyer Roles",
  2: "Supplier Roles",
  3: "Authority Admin",
  4: "System Admin"
};

const TSM_SUPER_ADMIN_DETAIL: UserRoleDetail = {
  profileId: 4,
  roleId: 1,
  roleName: "TSM Super Admin",
  roleIdentifier: "super_admin",
  roleDescription: "TSM Supplier Admin",
};

const ROLE_DEPENDENCIES: UserRoleDependencies = {
  topDown: [
    {
      profileId: 1,
      roleId: 1,
      entries: [5],
    },
  ],
  bottomUp: [
    {
      profileId: 1,
      roleId: 5,
      entries: [1],
    },
  ],
};

function isUserRoleDetail(obj: any): obj is UserRoleDetail {
  const role = obj as UserRoleDetail;
  return role.profileId != null && 
         role.roleDescription != null &&
         role.roleId != null &&
         role.roleIdentifier != null &&
         role.roleName != null;
}

@Injectable({
  providedIn: 'root',
})
export class RoleResolutionService {
  private cachedRoleDetails: UserRoleDetail[] | null = null;
  private rolesLastFetched = 0

  constructor(private systemAdminService: SystemAdminService) {
  }

  public getProfileTypeMap(): {[id: number]: string} {
    return PROFILE_TYPE_MAP;
  }

  public getRoleDependencies(): UserRoleDependencies {
    return ROLE_DEPENDENCIES;
  }

  public async getAllUserRoleDetails(): Promise<UserRoleDetail[]> {
    const roleDetails = await this.getCachedUserRoleDetails();

    return [
      ...roleDetails,
      TSM_SUPER_ADMIN_DETAIL
    ]
  }

  // Return a string containing the user roles for an internal user
  // All supplier roles are filtered
  public async getRoleDetailsForExtensionString(
    extensionString: string,
    isSystemAdmin: boolean,
  ): Promise<UserRoleDetail[]> {
    const result: UserRoleDetail[] = [];
    const roleDetails = await this.getCachedUserRoleDetails();
    const extension = JSON.parse(extensionString);

    if (isSystemAdmin) {
      result.push(TSM_SUPER_ADMIN_DETAIL);
    }

    for (let currentRole of roleDetails) {
      if (extension[currentRole.profileId] && extension[currentRole.profileId].includes(currentRole.roleId)) {
        result.push(currentRole);
      }
    }

    return result;
  }

  // Retrieve user role information from the backend
  private async fetchUserRoleDetails(): Promise<UserRoleDetail[]> {

    try{
      const response = await this.systemAdminService.getUserRoles();

      if (!Array.isArray(response)) {
        console.log(`Response is not an array: ${response}`);
        return [];
      }
  
      const validUserRoles = response.filter(element => isUserRoleDetail(element));
  
      if (validUserRoles.length === 0) {
        console.log(`API response contains no valid user role detail: ${response}.`);
      }
  
      if (validUserRoles.length !== response.length) {
        console.log(`The api response contains some elements, which are no user role details: ${response}`);
      }
  
      return validUserRoles;

    }
    catch(error){
      console.log("failed to fetch userRoles")
      return[];
    }
  }

  // Retrieve user role information either from the cache or from the backend
  private async getCachedUserRoleDetails(): Promise<UserRoleDetail[]> {
    const timeNow = Date.now();
    
    if(this.cachedRoleDetails === null || timeNow > this.rolesLastFetched + 5 * 60 * 1000) {
      this.cachedRoleDetails = await this.fetchUserRoleDetails();
      this.rolesLastFetched = timeNow;
    } 
    return this.cachedRoleDetails;
  }
}
