import { Component, Input, OnInit } from '@angular/core';
import { ButtonPropsType, SupplierRelationshipService, ParentRejectRelationship, AlertPropsType } from '@lsl16/sustainability-shared-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


type parentRejectRelationshipModalLiterals = {
  title: string;
  infoTitle: string;
  infoMessage: string;
  submitRejectBtn: string;
  cancelBtn: string;
  errorAlertText: string
}

@Component({
  selector: 'tsm-pop-up-parent-reject-relationship',
  templateUrl: './pop-up-parent-reject-relationship.component.html',
  styleUrls: ['./pop-up-parent-reject-relationship.component.sass']
})

export class PopUpParentRejectRelationshipComponent implements OnInit {
  @Input() confirmType: {
    subsidiaryTsmId: string,
    subsidiaryCompanyName: string
  };
  subsidiaryTsmId: string;
  subsidiaryCompanyName: string;
  rejectReason: any;
  isAlertVisible: boolean = false;
  modalTranslation: parentRejectRelationshipModalLiterals;

  submitRejectButtonProps: ButtonPropsType = {
    label: '',
  };

  enabledSubmitRejectButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    iconPosition: "left",
    onClick: () => this.rejectRelationship()
  };

  disabledSubmitRejectButtonProps: ButtonPropsType = {
    ...this.enabledSubmitRejectButtonProps,
    backgroundColor: 'gray',
    hoverBackgroundColor: 'gray',
    disabled: true
  };

  cancelButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    width: 115,
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
    onClick: () => {
      this.activeModal.close();
    },
  };

  verifyAlertProps: AlertPropsType = {
    type: "alert-profileVerifiedFaild",
    message: "errorAlertText",
    icon: "assets/images/icon-error.svg",
    iconFill: "#ffe8e6",
    iconClose: "assets/images/icon-cross-blue.svg",
    onDismiss: () => {
      this.isAlertVisible = false;
    }
  };


  constructor(private activeModal: NgbActiveModal, private supplierRelationshipService: SupplierRelationshipService) { }

  ngOnInit(): void {
    this.submitRejectButtonProps = this.disabledSubmitRejectButtonProps;
    if (this.confirmType) {
      this.subsidiaryTsmId = this.confirmType.subsidiaryTsmId;
      this.subsidiaryCompanyName = this.confirmType.subsidiaryCompanyName;
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.modalTranslation =
          multilanguageJson.body.main.parentRejectRelationship;
        this.modalTranslation.infoTitle = this.modalTranslation.infoTitle.replace('$subsidiaryCompanyName', this.subsidiaryCompanyName);

        this.setButtonLabels();
        this.setAlertMessage();
      }
    }
  }

  setButtonLabels(): void {
    this.cancelButtonProps.label = this.modalTranslation.cancelBtn;
    this.submitRejectButtonProps.label = this.modalTranslation.submitRejectBtn;
  }

  onKeyUp() {
    const isValid = /^[\s\t\r\n]*\S+/.test(this.rejectReason);
    if (this.rejectReason && isValid) {
      this.submitRejectButtonProps = this.enabledSubmitRejectButtonProps;
    }
    else {
      this.submitRejectButtonProps = this.disabledSubmitRejectButtonProps;
    }
  }

  async rejectRelationship(): Promise<void> {
    try {
      const parentChildRelationResponse = await this.supplierRelationshipService.getParentChildRelation(this.subsidiaryTsmId);
      const parentRejectRelationshipPayload: ParentRejectRelationship = {
        parentTsmId: parentChildRelationResponse.parentTsmId,
        childTsmId: this.subsidiaryTsmId,
        rejectReason: this.rejectReason,
        host: window.location.origin
      }
      await this.supplierRelationshipService.rejectRelationshipByParent(parentRejectRelationshipPayload);
      this.activeModal.close();
    } catch (error) {
      console.error(error);
      this.isAlertVisible = true;
    }
  }

  setAlertMessage(): void {
    this.verifyAlertProps.message = this.modalTranslation.errorAlertText;
  }

}
