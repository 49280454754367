import { XMLSerializedAsObject as ParsedSupplierListFile } from 'xmlbuilder2/lib/interfaces';
export { ParsedSupplierListFile };

export type SupplierListResponseReportRow = {
  rowNumber: number;
  supplierTsmId: string;
  name: string;
  countryServed: string[];
  marketUnit?: string;
  validations: string[];
  errors: unknown[];
  success: boolean;
  esgStatus: string;
  [key: string]: unknown;
};

export type SupplierListResponse = {
  report: SupplierListResponseReportRow[];
  fileValidations: string[];
  duration: number;
  [key: string]: unknown;
};

export type SelectedSupplierListFileEvent = {
  filename: string;
  contents: ParsedSupplierListFile;
  validations: SupplierListResponse;
  hasErrors: boolean;
};

export type DeletedSupplierListFileEvent = {
  deleted: boolean;
  hasErrors: boolean;
};

export type ErrorResponse = {
  msg?: string;
};

export type SupplierListFileEvent = SelectedSupplierListFileEvent | DeletedSupplierListFileEvent;

export enum MassInviteEventTypes {
  NewFile = "NewFileUploaded",
  MIProcess_Start = "ConfirmedMassInvite",
  MIProcess_Done = "MassInviteProcessCompleted",
  MIProcess_Fail = "MassInviteProcessFailed"
}

export type MassInviteEvents = {
  name: MassInviteEventTypes,
  value?: any;
};

