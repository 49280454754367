<div class="modal-header">
  <h4 class="modal-title">
    {{ modalTranslation.title }}
  </h4>
</div>
<div class="modal-body">
    <div class="msg-container">
        <div *ngIf="this.modalTranslation.closeBtn !='LATAM'" class="infor-icon">
            <img class="info-title-icon" src="assets/icons/info-blue.svg" alt="icon-msg" />
        </div>            
        <div class="infor-message" id="infoMsg" [innerHTML]="modalTranslation.msg"></div>

    </div>
</div>
<div class="modal-buttons">
  <sh-button [props]="cancelButtonProps" ></sh-button>
</div>
