import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  ButtonPropsType,
  HttpService
} from "@lsl16/sustainability-shared-components"
import { environment } from "src/environments/environment";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationBoxComponent } from "src/app/components/confirmation-box/confirmation-box.component";

@Component({
  selector: 'tsm-pop-up-upload-file',
  templateUrl: './pop-up-upload-file.component.html',
  styleUrls: ['./pop-up-upload-file.component.sass']
})
export class PopUpUploadFileComponent implements OnInit {
  constructor(private httpService: HttpService, public ngbModalService: NgbModal, public activeModal: NgbActiveModal, public supplierGeneralProfileService: SupplierGeneralProfileService,) { }
  @Input() confirmType;
  @Output() getlist = new EventEmitter;
  file: FileList;
  creatbydisable: boolean = false;
  languledisable: boolean = false;
  Displaydisable: boolean = false;
  ifupload: boolean;
  userData: string = "";
  submit: boolean = true;
  comments: string = "";
  loading: boolean = false;
  languageData: string = "";
  CategoryData: string = "";
  haserro: boolean;
  Languagebackground: string;
  creatbybackground: string;
  displayText: string = "";
  cancel: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    width: 112,
    borderRadius: 25,
    borderColor: "purple",
    margin: "0 15px 0 0",
    onClick: async () => {
      this.activeModal.close()
    }
  }
  draft: ButtonPropsType = {
    label: "Upload as a draft",
    padding: "11px",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    borderColor: "purple",
    margin: "0 15px 0 0",
    onClick: async () => {
      this.submited("draft")
    }
  }
  publish: ButtonPropsType = {
    label: "Publish",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    width: 113,
    borderRadius: 25,
    onClick: async () => {
      this.submited("Publish")
    }
  }
  save: ButtonPropsType = {
    label: "Save",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    width: 113,
    borderRadius: 25,
    onClick: async () => {
      this.submited("save")
    }
  }
  user: any = [
    {
      Text: "Authority User",
      Code: "Authority User"
    }, {
      Text: "Buyer",
      Code: "Buyer"
    }, {
      Text: "Supplier",
      Code: "Supplier"
    }
  ]
  Category: any = [
    {
      Text: "User Guide",
      Code: "User Guide"
    }, {
      Text: "FAQ",
      Code: "FAQ"
    }
  ]
  language: any = [
    {
      Text: "Bahasa Indonesia",
      Code: "Bahasa Indonesia"
    }, {
      Text: "Chinese",
      Code: "Chinese"
    }, {
      Text: "English",
      Code: "English"
    }, {
      Text: "French",
      Code: "French"
    }, {
      Text: "German",
      Code: "German"
    }, {
      Text: "Italian",
      Code: "Italian"
    }, {
      Text: "Japanese",
      Code: "Japanese"
    }, {
      Text: "Portuguese",
      Code: "Portuguese"
    }, {
      Text: "Spanish",
      Code: "Spanish"
    }, {
      Text: "Thai",
      Code: "Thai"
    }, {
      Text: "Vietnamese",
      Code: "Vietnamese"
    }, {
      Text: "LATAM Spanish",
      Code: "LatamSpanish"
    }
  ]

  ngOnInit(): void {
    if (this.confirmType.title === "edit") {
      this.languageData = this.confirmType.formDate.language
      this.CategoryData = this.confirmType.formDate.category
      this.displayText = this.confirmType.formDate.displayText
      this.comments = this.confirmType.formDate.documentComments
      this.userData = this.confirmType.formDate.userType
      if (this.userData === "Authority User" || this.userData === "Buyer") {
        this.creatbydisable = true;
        this.languledisable = true;
        this.creatbybackground = "#DFE3E6"
        this.Languagebackground = "#DFE3E6"
        if (this.userData === "Authority User") {
          this.Displaydisable = true
        } else {
          this.Displaydisable = false
        }
      }
    }

  }
  handleSupplierListFileEvent(data) {
    if (this.userData === "Authority User") {
      this.displayText = data?.file ? data.file[0].name : "";
    }
    this.haserro = data.hasErrors
    if (!data.hasErrors) {
      this.file = data.file
    }
    this.ifupload = data.ifupload
    this.checkData()
  }
  getSingleDate(e: any) {
    this.userData = e.Text
    if (e.Text === "Authority User" || e.Text === "Buyer") {
      this.creatbydisable = true;
      this.languledisable = true;
      this.creatbybackground = "#DFE3E6"
      this.Languagebackground = "#DFE3E6"
      this.CategoryData = "User Guide"
      this.languageData = "English"
      if (e.Text === "Authority User") {
        this.Displaydisable = true;
        this.displayText = this.file ? this.file[0].name : "";
      } else {
        this.Displaydisable = false
        this.displayText = ""
      }
    } else {
      this.creatbydisable = false;
      this.languledisable = false;
      this.creatbybackground = "#F6F6F9"
      this.Languagebackground = "#F6F6F9"
      this.CategoryData = ""
      this.languageData = ""
      this.Displaydisable = false
      this.displayText = ""
    }

    this.checkData()
  }
  change() {
    this.checkData()
  }
  getCategoryDate(e: any) {
    this.CategoryData = e.Text
    this.checkData()
  }
  getLanguageDate(e: any) {
    this.languageData = e.Text
    this.checkData()
  }
  async submited(status) {
    let formData: FormData = new FormData()
    if (this.file) {
      const file = this.file[0]
      formData.append("attachment", file)
    }
    formData.append("displayText", this.displayText)
    formData.append("language", this.languageData)
    formData.append("category", this.CategoryData)
    formData.append("documentComments", this.comments)
    formData.append("userType", this.userData)

    let add = true
    const formDate = this.confirmType.formDate
    if (status === "Publish" && this.userData === "Authority User") {
      for (let i = 0; i < formDate.length; i++) {
        if (formDate[i].status === "Published" && formDate[i].userType === "Authority User") {
          const confirmType = {
            windowClass: "modal-faq",
            title: "Published",
            centered: true,
            size: "lg",
          }
          const modalReference = this.ngbModalService.open(ConfirmationBoxComponent, confirmType);
          modalReference.componentInstance.confirmType = confirmType;
          add = false
          this.activeModal.close()
          return false
        }
      }
    }
    if (this.confirmType.title === "upload") {
      if (add) {
        if (status === "draft") {
          formData.append("status", "1")
        } else {
          formData.append("status", "2")
        }
        if (status === "Publish") {
          const confirmType = {
            windowClass: "modal-faq",
            title: "Publish",
            centered: true,
            size: "lg",
            type: "add",
            formData: formData
          }
          const modalReference = this.ngbModalService.open(ConfirmationBoxComponent, confirmType);
          modalReference.componentInstance.confirmType = confirmType;
        } else {
          this.loading = true
          const baseUrl = `${environment.tsmBackendServiceURL}/document/upload`
          await this.httpService.PostPromise(baseUrl, formData)
          this.loading = false
        }
      }
    }
    if (this.confirmType.title === "edit") {
      formData.append("documentId", this.confirmType.formDate.documentId)
      this.loading = true
      const baseUrl = `${environment.tsmBackendServiceURL}/document/update`
      await this.httpService.PostPromise(baseUrl, formData)
      this.loading = false
    }
    this.supplierGeneralProfileService.eventEmit.emit("renovateDate");
    this.activeModal.close()
  }
  textChange() {
    if (this.comments) {
      if (this.confirmType.title === "edit" && this.userData !== "Authority User") {
        if (this.languageData !== "" && this.CategoryData !== "" && this.displayText !== "" && this.ifupload) {
          this.save = {
            label: "Save",
            padding: "11px",
            backgroundColor: "blue",
            color: "white",
            fontSize: 16,
            disabled: false,
            fontFamily: "Graphik-Medium",
            height: 48,
            borderRadius: 25,
            margin: "0 15px 0 0",
            onClick: async () => {
              this.submited("save")
            }
          }
        }
      } else if (this.confirmType.title === "edit" && this.userData === "Authority User") {
        if (this.languageData !== "" && this.CategoryData !== "" && this.ifupload) {
          this.save = {
            label: "Save",
            padding: "11px",
            backgroundColor: "blue",
            color: "white",
            fontSize: 16,
            disabled: false,
            fontFamily: "Graphik-Medium",
            height: 48,
            borderRadius: 25,
            margin: "0 15px 0 0",
            onClick: async () => {
              this.submited("save")
            }
          }
        }
      }
    }

  }
  checkData() {
    if (this.confirmType.title === "edit" && this.userData === "Authority User") {
      if (this.languageData === "" || this.CategoryData === "" || !this.ifupload) {
        this.save = {
          label: "Save",
          backgroundColor: "gray",
          color: "white",
          fontSize: 16,
          disabled: true,
          fontFamily: "Graphik-Medium",
          height: 48,
          width: 113,
          borderRadius: 25,
          onClick: async () => {
            this.submited("save")
          }
        }
      } else {
        this.save = {
          label: "Save",
          padding: "11px",
          backgroundColor: "blue",
          color: "white",
          fontSize: 16,
          disabled: false,
          fontFamily: "Graphik-Medium",
          height: 48,
          borderRadius: 25,
          margin: "0 15px 0 0",
          onClick: async () => {
            this.submited("save")
          }
        }
      }
    } else if (this.confirmType.title === "edit" && this.userData !== "Authority User") {
      if (this.languageData === "" || this.CategoryData === "" || this.displayText === "" || !this.ifupload) {
        this.save = {
          label: "Save",
          backgroundColor: "gray",
          color: "white",
          fontSize: 16,
          disabled: true,
          fontFamily: "Graphik-Medium",
          height: 48,
          width: 113,
          borderRadius: 25,
          onClick: async () => {
            this.submited("save")
          }
        }
      } else {
        this.save = {
          label: "Save",
          padding: "11px",
          backgroundColor: "blue",
          color: "white",
          fontSize: 16,
          disabled: false,
          fontFamily: "Graphik-Medium",
          height: 48,
          borderRadius: 25,
          margin: "0 15px 0 0",
          onClick: async () => {
            this.submited("save")
          }
        }
      }
    }
    if (this.confirmType.title === "upload") {
      if (this.languageData === "" || this.CategoryData === "" || this.displayText === "" || this.userData === "" || this.file === undefined || this.haserro) {
        this.draft = {
          label: "Upload as a draft",
          padding: "11px",
          backgroundColor: "gray",
          color: "white",
          fontSize: 16,
          fontFamily: "Graphik-Medium",
          height: 48,
          borderRadius: 25,
          margin: "0 15px 0 0",
          disabled: true,
          onClick: async () => {
            this.submited("draft")
          }
        }
        this.publish = {
          label: "Publish",
          backgroundColor: "gray",
          color: "white",
          fontSize: 16,
          disabled: true,
          fontFamily: "Graphik-Medium",
          height: 48,
          width: 113,
          borderRadius: 25,
          onClick: async () => {
            this.submited("Publish")
          }
        }
      } else {
        this.draft = {
          label: "Upload as a draft",
          padding: "11px",
          backgroundColor: "blue",
          color: "white",
          fontSize: 16,
          disabled: false,
          fontFamily: "Graphik-Medium",
          height: 48,
          borderRadius: 25,
          margin: "0 15px 0 0",
          onClick: async () => {
            this.submited("draft")
          }
        }
        this.publish = {
          label: "Publish",
          backgroundColor: "blue",
          color: "white",
          fontSize: 16,
          disabled: false,
          fontFamily: "Graphik-Medium",
          height: 48,
          width: 113,
          borderRadius: 25,
          onClick: async () => {
            this.submited("Publish")
          }
        }
      }
    }
  }
  blockNonPrintableChar() {
    let printablevalue = this.displayText;
    printablevalue = printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.displayText = printablevalue;
  }

}
