import { Component, OnInit, Input } from '@angular/core';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'tsm-pop-up-notification-redirect-error',
  templateUrl: './pop-up-notification-redirect-error.component.html',
  styleUrls: ['./pop-up-notification-redirect-error.component.sass']
})
export class PopUpNotificationRedirectErrorComponent implements OnInit {

  @Input() confirmType;
  buttonProps: ButtonPropsType = {
    label: "",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 115,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "darkBlue",
    hoverColor: "white"
  };
  translations = {
    "ok": '',
    "title": '',
    "content": ''
  };

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: ModalService,
    public ngbModalService: NgbModal
  ) { }

  ngOnInit() {
    this.buttonProps.onClick = this.activeModal.close;
  }

  ngAfterContentChecked(): void {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      this.translations = multiLanguageJson.body.main.notifications[this.confirmType.msgLabel];
      this.buttonProps.label = this.translations.ok;
    }
  }
}
