import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ButtonPropsType,
  ParentChildHistory,
  ParentChildRelation,
  SupplierRelationshipService,
  GlobalSupplierRelationshipConfirmStatus
} from '@lsl16/sustainability-shared-components';
import { SvSupplierProfileComponent } from '../supplier-profile-containter/sv-supplier-profile/sv-supplier-profile.component';
import { isEmpty, countBy } from "lodash";
import { Subscription } from 'rxjs';

type ShowParentStatus = "noParent" | "showParent";

@Component({
  selector: 'tsm-supplier-relationship',
  templateUrl: './supplier-relationship.component.html',
  styleUrls: ['./supplier-relationship.component.sass']
})
export class SupplierRelationshipComponent implements OnInit, OnDestroy {
  @Input() supplierTsmId: string;
  @Input() supplierName: string;
  @Input() confirmParentMode: boolean = false;
  @Input() isSupplierParent: boolean;
  @Input() isSupplier: boolean;
  @Input() subSupplierMode: boolean = false;
  @Input() displayButtons: boolean = false;
  @Input() isIndirect: boolean = false;  
  @Input() isDuplicate: boolean = false;

  public showAction: boolean = false;
  public showParent: ShowParentStatus = "noParent";
  public showSecifyParent: boolean = false;
  public translatedLabels = {
    specifyParent: '',
    relationshipHistory: ''
  };
  private parentChildRelation?: ParentChildRelation;
  private relationUpdateEventSubscription?: Subscription = null;
  private relationChangedEventSubscription?: Subscription = null;

  specifyParent: ButtonPropsType = {
    label: "",
    disabled: false,
    color: "white",
    backgroundColor: "blue",
    borderColor: "blue",
    hoverColor: "white",
    hoverBackgroundColor: "darkBlue",
    hoverBorderColor: "blue",
    onClick: async () => {
      await this.svSupplierProfileComponent.shouldOpenAddSupplierParentModal('specifyParent');
      this.svSupplierProfileComponent.openAddSupplierParentModal(true);
    }
  };
  showHistory: boolean = false;
  parentChildHistory: ParentChildHistory;
  isParentSupplier: 'no' | 'awaitingConfirmation' | 'yes' = 'no';

  constructor(
    private relationshipService: SupplierRelationshipService,
    private svSupplierProfileComponent: SvSupplierProfileComponent
  ) { }

  async ngOnInit(): Promise<void> {
    await this.fetchData();
    this.subscribeEvent();
  }

  async ngOnChanges(): Promise<void> {
    this.specifyParent.disabled = this.isDuplicate;
    await this.fetchData();
  }

  private subscribeEvent() {
    if (!this.relationChangedEventSubscription) {
      this.relationChangedEventSubscription = this.relationshipService.getSupplierRelationChangedEvent()
        .subscribe(next => this.handleSupplierRelationChanged(next));
    }

    if (!this.relationUpdateEventSubscription) {
      this.relationUpdateEventSubscription = this.relationshipService.getSupplierRelationUpdateSuccessEvent()
        .subscribe(() => this.handleSupplierRelationUpdatedEvent())
    }
  }

  private handleSupplierRelationUpdatedEvent() {
    this.fetchData();
  }

  private async fetchData() {
    await Promise.all([
      this.updateSupplierRelation(),
      this.getParentChildHistory(),
      this.getIsParentSupplier()
    ]);
  }

  private async updateUiFlags() {
    this.showAction = this.confirmParentMode &&
      this.parentChildRelation?.status !== 'MutuallyConfirmed' &&
      this.parentChildRelation?.status !== "Open" &&
      this.parentChildRelation?.parentStatus !== 'Rejected';

    if (this.subSupplierMode) {
      this.showSecifyParent = this.displayButtons && this.hasNoParent(this.parentChildRelation);
    } else {
      this.showSecifyParent = !this.confirmParentMode && this.hasNoParent(this.parentChildRelation);
    }

    if (this.isIndirect) {
      this.showSecifyParent = false;
    }

    if (this.hasNoParent(this.parentChildRelation)) {
      this.showParent = "noParent";
      return;
    }

    this.showParent = "showParent";
  }

  private hasNoParent(relation: ParentChildRelation) {
    return !this.isValidRelationship(relation) || relation?.status === 'NoParent';
  }

  private isValidRelationship(relation?: ParentChildRelation) {
    // the supplier has no parent relation when the id property is empty
    return !!relation?.childTsmId;
  }

  private async handleSupplierRelationChanged(supplierTsmId: string) {
    if (supplierTsmId === this.supplierTsmId) {
      await this.fetchData();
    }
  }

  private async updateSupplierRelation() {
    this.parentChildRelation = await this.relationshipService.getParentChildRelation(this.supplierTsmId);
    await this.updateUiFlags();
  }

  private async getParentChildHistory() {
    this.showHistory = false;
    const parentChildHistory = await this.relationshipService.getParentChildHistory(this.supplierTsmId);
    if (!isEmpty(parentChildHistory)) {
      this.parentChildHistory = parentChildHistory;
      this.showHistory = true;
    }
  }

  private async getIsParentSupplier() {
    const children = await this.relationshipService.getParentChildRelationForParent(this.supplierTsmId);

    this.isParentSupplier = 'no';
    if (Array.isArray(children)) {
      const childCount: Record<GlobalSupplierRelationshipConfirmStatus, number> = countBy(children, 'status');

      if (childCount.MutuallyConfirmed > 0) {
        this.isParentSupplier = 'yes';
      } else if (childCount.AwaitingParentConfirmation > 0) {
        this.isParentSupplier = 'awaitingConfirmation';
      }
    }
  }

  ngOnDestroy() {
    if (this.relationChangedEventSubscription) {
      this.relationChangedEventSubscription.unsubscribe();
    }

    if (this.relationUpdateEventSubscription) {
      this.relationUpdateEventSubscription.unsubscribe();
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.translatedLabels = multilanguageJson.body.main.supplierRelationshipAsSupplier;
        this.specifyParent.label = this.translatedLabels.specifyParent;
      }
    }
  }
}
