import { FilterData, ReportType, EsgReportService, ReportPreview, TimeoutError } from '../../services/esgReport/esg-report.service';
import { EventEmitter, Component, Input, Output, OnInit } from '@angular/core';
import { each } from 'lodash';
import { AppComponent } from 'src/app/app.component';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';

export type RequestStatusEvent = {
  status: "Error" | "Success";
}

export type ApplyFilterEvent = {
  reportType: ReportType;
  filters: FilterData[];
}


type PreviewState = 'initial' | 'NoRecordsFound' | 'RecordsFound' | 'TimeoutError';

@Component({
  selector: 'tsm-ra-report-preview',
  templateUrl: './ra-report-preview.component.html',
  styleUrls: ['./ra-report-preview.component.sass']
})
export class RaReportPreviewComponent {

  @Input() applyFilterEvent: EventEmitter<ApplyFilterEvent>;
  @Output() requestStatusEventEmitter = new EventEmitter<RequestStatusEvent>(true);
  // @Input() totalReportsCount?: number = null;
  
  public reportPreview: any;
  public filters: FilterData[] = [];
  public previewState: PreviewState = 'initial';
  public statusLiterals: any;
  public reportType: ReportType;
  footerHeight:any;
  private doHideTooltip: boolean = true;
  fullNameArr;
  maxLabelLengthName: number = 40;
  // isPropTruncated: boolean = false;
  downloadReport: boolean = false;
  public countInfoLabel = "";
  payload: { [key: string]: any } = {};
  columns;

  public translatedLabels = {
    noSearchMessage: "",
    emptySearchMessage: "",
    countInfoMessage: "",
    timeoutErrorMessage: "",
  }
  public showTimeoutError = false;
  public timeoutErrorLabel = "";
 
  downloadButton: ButtonPropsType = {
    label: "Download Full Report",
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    width: 370,
    icon: "assets/icons/file_download-24px.svg",
    iconPosition: "left",
    onClick: () => {
      this.clickDownload();
    }
  };
 
  enableDownloadButton: ButtonPropsType = {
    ...this.downloadButton,
    disabled: false,
    backgroundColor: 'blue',
    isProcessing: false
  };
 
  disableDownloadButton: ButtonPropsType = {
    ...this.downloadButton,
    disabled: true,
    backgroundColor: 'mediumGrey',
    isProcessing: true
  };
 

  
  constructor(private reportService: EsgReportService, private appComponent: AppComponent) { }

  ngOnInit(): void {

    if (this.applyFilterEvent) {
      this.applyFilterEvent.subscribe(next => this.handleApplyFilterEvent(next));
    }
    this.requestStatusEventEmitter.subscribe(() => {
      this.cleanupTimeoutError();
    });
  }
  async fetchPreviewRecords(filterEvent: ApplyFilterEvent) {
    const array = filterEvent.filters;
    let filterNameArray = [];
    let filterValueArray = [];
    this.columns = [];
    this.payload = {};
    array.forEach(element => {
      filterNameArray.push(element.filterName);
      filterValueArray.push(element.filterValue);
    });
    for (let i = 0; i < filterNameArray?.length; i++) {
      this.payload[filterNameArray[i]] = filterValueArray[i];
    }
    this.payload['downloadReport'] = this.downloadReport;
    try {
      this.reportPreview = await this.reportService.generateRaReport(this.payload);
      const rearrangedData1 = { ...this.reportPreview.reportHeadersCommon };
      Object.keys(rearrangedData1).forEach(key => {
        this.columns.push({ prop: key, name: rearrangedData1[key], width: 190 });
      });
      if(this.reportPreview.reportHeadersQuestions){
        const rearrangedData = { ...this.reportPreview.reportHeadersQuestions };
        Object.keys(rearrangedData).forEach(key => {
          this.columns.push({ prop: key, name: rearrangedData[key], width: 190 });
        });
      }
      if(this.columns){
        this.createValidationResultTooltipText();    
      }
      this.handleReportPreview(this.reportPreview);

      this.requestStatusEventEmitter.emit({ status: 'Success' });
      if(this.reportPreview?.reportStatus === false){
        this.previewState = 'TimeoutError';
      }
    }
    catch (error) {
      this.requestStatusEventEmitter.emit({ status: 'Error' });
      console.error(`Failed to fetch report preview: ${error}`);
    }
  }

  private handleApplyFilterEvent(event: ApplyFilterEvent) {
    this.filters = event.filters;
    this.reportType = event.reportType;
    this.fetchPreviewRecords(event);
  }
  
  handleReportPreview(reportPreview: any) {
    this.countInfoLabel = this.translatedLabels.countInfoMessage.replace("${totalCount}", reportPreview?.answerdata?.length.toString());
    if (reportPreview.answerdata?.length > 0) {
      this.previewState = 'RecordsFound';
    } else {
      this.previewState = 'NoRecordsFound';
    }
  }

  private createValidationResultTooltipText() {
    this.fullNameArr = []
    this.columns.forEach(element => {
      this.fullNameArr.push(element["name"]);
      if(element["name"] && element["name"].length > this.maxLabelLengthName){
        element["name"] = `${element["name"]
        .substring(0, this.maxLabelLengthName)
        .trim()}...`;
      }
    });
  }

  showTooltip(evt: MouseEvent, columnIndex:any): void {
    if(this.fullNameArr){
      this.doHideTooltip = false;
      setTimeout(() => {
        if (!this.doHideTooltip) {
          let fullLabeldisplay;
          if(this.fullNameArr[columnIndex].length > this.maxLabelLengthName){
            fullLabeldisplay = this.fullNameArr[columnIndex];
            this.appComponent.showGlobalTooltip(
              fullLabeldisplay,
              '',
              evt.pageX - 200,
              evt.pageY + 20
            );
          }
        }
      }, 20);
    } else {
      return;
    }
    
  }
  hideTooltip(): void {
    this.doHideTooltip = true;
    setTimeout(() => {
      if (this.doHideTooltip) {
        this.appComponent.hideGlobalTooltip();
      }
    }, 30);
  }

  async clickDownload() {
    try {
      this.disableButton();
      await this.reportService.downloadRaReport(this.payload, true);
      this.enableButton();
    }
    catch (error) {
      if (error instanceof TimeoutError) {
        this.handleTimeoutError();
        return;
      }
 
      console.error(error);
      this.enableButton();
    }
  }
 
  private handleTimeoutError() {
    this.showTimeoutError = true;
    this.disableButton(false);
  }
 
  private cleanupTimeoutError() {
    this.showTimeoutError = false;
    this.enableButton();
  }
 
  enableButton() {
    this.downloadButton = this.enableDownloadButton;
  }
 
  disableButton(processing = true) {
    this.downloadButton = this.disableDownloadButton;
    this.downloadButton.isProcessing = processing;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multiLanguageJson.body != undefined) {
        this.translatedLabels = multiLanguageJson.body.main.saReportPreview;
        const buttonLabel = (this.reportType === "supplier_records") ?
          multiLanguageJson.body.main.saReportPreview.supplierDownloadButtonLabel : multiLanguageJson.body.main.saReportPreview.downloadButtonLabel;
        this.timeoutErrorLabel = multiLanguageJson.body.main.saReportPreview.donwloadTimeoutErrorMessage;
      }
      if (multiLanguageJson && multiLanguageJson.questionnire && multiLanguageJson.questionnire.reviewSummary) {
        if (multiLanguageJson.questionnire.reviewSummary.statusLiterals) {
          this.statusLiterals = multiLanguageJson.questionnire.reviewSummary.statusLiterals;
        }
      }
    }
  }
}
