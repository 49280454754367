import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'tsm-supplier-name',
  templateUrl: './supplier-name.component.html',
  styleUrls: ['./supplier-name.component.sass']
})
export class SupplierNameComponent implements OnInit {
  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;
  supplierName = '';

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }

  constructor() { }

  ngOnInit(): void {
    this.clearFilter.subscribe(() => this.clear());
  }
  inputChanged() {
    this.valueChanged.emit(this.supplierName);
    let printablevalue= this.supplierName;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.supplierName = printablevalue; 
  }
  clear() {
    this.supplierName = '';
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.supplierName;
      }
    }
  }
}
