import { Component, Injectable, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Options } from "src/app/models/Options";
import { AddParentConfig, ProfileConfig } from "src/app/components/profile-details/profile";
import { ProfileDetailsComponent } from "src/app/components/profile-details/profile-details.component";
import { PopUpWelcomeComponent } from "src/app/components/pop-up-welcome/pop-up-welcome.component";
import { AuthService } from "src/app/services/auth/auth.service";
import { B2cService } from "src/app/services/b2c/b2c.service";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { BaseSupplierProfileComponent } from "../base-supplier-profile/base-supplier-profile.component";
import {
  AlertPropsType,
  ModalConfig,
  ButtonPropsType,
  HttpService,
  GlobalSupplierRelationshipConfirmStatus,
  SupplierRelationUpdateSuccessEventType,
  SAStatus
} from "@lsl16/sustainability-shared-components";
import { ModalService } from "src/app/services/modal/modal.service";
import { Subscription } from "rxjs";
import { EsgQuestionnaireService, GeneralSupplierType } from "@lsl16/sushub-frontend";
import { ActivatedRoute } from "@angular/router";
import { SupplierHubService } from "src/app/services/supplierHub/supplier-hub.service";
import { SupplierService } from "src/app/services/supplier/supplier.service";
import { EventEmitter, Output } from "@angular/core";
import { environment } from "src/environments/environment";
import { ModalType } from "src/app/services/modal/types";
import { welcomeModalData } from "src/app/services/modal/welcomeModalData";
import { ReportService } from "src/app/services/authority/report.service";
import { AdminBoardService } from "src/app/services/adminboard/admin-board.service";
import { ConfirmSupplierParentCreationComponent } from '../../confirm-supplier-parent-creation/confirm-supplier-parent-creation.component';
import { SupplierUpdateSaComponent } from '../../supplier-update-sa/supplier-update-sa.component';
import { MessagePopUpComponent } from '../../../components/message-pop-up/message-pop-up.component';
import { SupplierRelationshipService } from "@lsl16/sustainability-shared-components";
import { isEmpty } from 'lodash';
import { countryList } from "../../../../utilities/const/country-list";
import { EventService } from "src/app/services/EventService.service";
import { NotificationService } from "src/app/services/notification/notification.service";
import { PopUpDuplicateComponent } from "src/app/components/pop-up-duplicate/pop-up-duplicate.component";

type messagePopUpModalLiterals = {
  title: string;
  msg: string;
  closeBtn: string;
}
@Component({
  selector: "tsm-sv-supplier-profile",
  templateUrl: "./sv-supplier-profile.component.html",
  styleUrls: ["./sv-supplier-profile.component.sass"]
})
@Injectable({
  providedIn: "root"
})
export class SvSupplierProfileComponent extends BaseSupplierProfileComponent implements OnInit, OnDestroy {
  @Output() outerEvent: EventEmitter<string> = new EventEmitter();
  @ViewChild("onboardingCard", { static: false }) onboardingCard;
  @ViewChild('SupplierInformationBoxComponent', { static: false }) SupplierInformationBoxComponent;
  private relationUpdateSuccessSubscription: Subscription;
  private relationChangedSubscription: Subscription;
  private notificationEventSubscription?: Subscription;
  public isLoadingSupplier = true;

  private translations = {
    awaitingParentCardTitle: "",
    awaitingParentMessage: "",
    confirmedParentMessage: "",
    saCreatedFromSubsidiary: "",
    awaitingInviteApprovalMessage: "",
    ownSA: ""
  };
  private currentlyServedCountrySAStatus = '';
  private currentlyServedCountryTaskSAStatus = '';
  /*profile-detail pop-up */
  env = environment.env;
  supplierHubUrlEndpoint = environment.supplierHubRedirectBaseSupplierUrl;
  editProfileDetailData: ProfileConfig = {
    type: "edit-profile-detail",
    options: {},
    supplier: {}
  };
  ProfileDetailStrictData: ProfileConfig = {
    type: "profile-detail-strict",
    options: {},
    supplier: {}
  };
  welcomeModalConfig: { data: ModalType } & ModalConfig = {
    data: welcomeModalData,
    type: "welcome",
    options: {}
  };
  addParentModalConfig: AddParentConfig = {
    type: "add-parent",
    options: {},
    supplier: {},
    confirmSupplier: {},
    taskId: '',
    hideNoParent: false
  };
  updateSaModalConfig: AddParentConfig = {
    type: "update-sa",
    options: {},
    supplier: {},
    confirmSupplier: {},
    taskId: '',
    hideNoParent: false
  };
  messagePopUpModalConfig: ProfileConfig = {
    type: "message-pop-up",
    options: {},
    supplier: {}
  };
  messagePopUpModalTranslation: messagePopUpModalLiterals;
  userprofileData: any;
  profileData: any;
  content: any = [];
  esgAssessmentProps: Options;
  //Applicable to both but different
  onboardingProps: Options;
  onboardingCardShow: boolean = false;
  oneBoardingStatus: number = null;
  alertVisible: boolean;
  alertProps: AlertPropsType;
  supplierGeneralProfileEventEmitSubscription: Subscription;
  saveEsgEventEmitSubscription: any;
  LoginUserInfo: any;
  viewOnlyFlag: boolean;
  inviteFlag: boolean;
  countryServedMapping: string;
  sharedButtonProps: ButtonPropsType;
  private baseUrl?: string;
  assessment: boolean = false;
  onboardingStart: boolean = false;
  confirmSupplierTsmId: string;
  loading: boolean = false;
  relationshipStatus: GlobalSupplierRelationshipConfirmStatus;
  taskId: string;
  parentTsmId: string;
  completeQuestion: string;
  parentSupplier: GeneralSupplierType;
  needSendEmail = true;
  esgStatus: string = "";
  showSuppplierEsgStatus: boolean = false;
  updateButtonProps: ButtonPropsType;
  isSupplierStart: string = "buyer";
  workflowId: string;
  supplierUpdateSAInfo: any = [];
  section: string;
  existUpdateSADatafalg: boolean = false;
  notificationUrl: string = '';
  private document = document;
  isShowStatus: boolean = true;
  countryServedList:any
  isShowVideo: boolean = false;

  constructor(
    public supplierGeneralProfileService: SupplierGeneralProfileService,
    private router: Router,
    private route: ActivatedRoute,
    private ngbModalService: NgbModal,
    private modalService: ModalService,
    public authService: AuthService,
    private esgQuestionnaireService: EsgQuestionnaireService,
    private supplierService: SupplierService,
    supplierHubService: SupplierHubService,
    private httpService: HttpService,
    private b2cService: B2cService,
    public reportService: ReportService,
    private adminBoardService: AdminBoardService,
    private supplierRelationshipService: SupplierRelationshipService,
    private eventService: EventService,
    private notificationService: NotificationService
  ) {
    super(supplierGeneralProfileService, authService, supplierHubService, reportService, supplierRelationshipService);
  }

  async ngOnInit(): Promise<void> {
    try{
      await this.supplierService.setIsUpdateButtonTriggerStatus(false);
      this.disableForLoading();
      this.env = environment.env;
      this.baseUrl = `${environment.tsmBackendServiceURL}/userprofile`;
      this.createInitialButtonProps();
      /*profile-detail pop-up */
      this.editProfileDetailData.options = this.modalService.getOptions(this.editProfileDetailData.type);
      this.ProfileDetailStrictData.options = this.modalService.getOptions(this.ProfileDetailStrictData.type);
      let routeParam = this.route.snapshot.paramMap.get("id");
      this.selectedSupplierTsmId = routeParam;
      this.supplierTsmId = routeParam;
      await this.refreshData(false);
      this.eventService.psCallbackEmit.subscribe(
        async (event) => {
          if (this.isSupplierStatusPending()) {
            await this.openFirstProfilePopup();
          }
        });
      localStorage.setItem("currentCountryLocation", this.supplier.countryLocation);
      localStorage.setItem("saCountryServed", JSON.stringify(this.supplier.saCountryServed));
      this.eventService.privacyStatementEmit.emit({ country: this.supplier.countryLocation, needCallback: true });
      this.endableAfterLoading();
      if (localStorage.getItem(this.userprofileData.extension_tsm_defaultProfile)) {
        this.SupplierInformationBoxComponent.changeCountryServed(this.countryServed)
      }
      this.addActivityLogTab(this.userType)
    } catch (e) {
       console.error(e)
    } finally {
      this.endableAfterLoading();
      await this.openPopUp();
    }
  }

  private disableForLoading() {
    this.isLoadingSupplier = true;
  }

  private endableAfterLoading() {
    this.isLoadingSupplier = false;
  }

  private createInitialButtonProps() {

    this.sharedButtonProps = {
      label: "Start Now",
      disabled: false,
      width: 220,
      color: "white",
      backgroundColor: "blue",
      borderColor: "blue",
      hoverColor: "white",
      hoverBackgroundColor: "blue",
      hoverBorderColor: "blue"
    };


    this.updateButtonProps = {
      label: "updateSA",
      disabled: false,
      icon: "assets/icons/icon-edit.svg",
      clickIcon: "assets/icons/icon-edit.svg",
      width: 150,
      color: "white",
      backgroundColor: "blue",
      borderColor: "blue",
      hoverColor: "white",
      hoverBackgroundColor: "blue",
      hoverBorderColor: "blue",
      class: 'update-button-class'
    };

    this.createInitialSACardProps();

    this.onboardingProps = {
      ...this.onboarding,
      buttonDisplay: false,
      description: "Complete the onboarding form to share your business's contact details.",
      buttonProps: {
        ...this.sharedButtonProps,
        label: "Start Now"
      },
      onClick: () => {
        this.redirectToOnboarding();
      },
      showButton: true
    };
  }

  private createInitialSACardProps() {
    const isOnlyGeneralSupplier = this.authService.isGeneralSupplier() && !this.authService.isSupplierAdmin();

    this.esgAssessmentProps = {
      ...this.esgAssessment,
      buttonDisplay: false,
      description: "Share how your business operates from a responsible business perspective.",
      buttonProps: {
        ...this.sharedButtonProps,
        label: "Start Now"
      },
      onClick: () => {
        this.handleAssessmentButtonClicked();
      },
      onUpdateSAButtonClick: () => {
        this.openUpdateSAModal();
      },
      manageUsersButtonProps: {
        ...this.sharedButtonProps,
        color: "purple",
        backgroundColor: "lightPurple",
        borderColor: "lightPurple",
        hoverColor: "purple",
        hoverBackgroundColor: "lightPurple",
        hoverBorderColor: "lightPurple",
        label: "Manage Users",
        tooltipText: isOnlyGeneralSupplier ? "generalSupplierManageUsersTooltip" : "manageUsersTooltip"
      },
      onManageUsersButtonClick: () => {
        this.redirectToAdminBoard();
      },
      showButton: false
    };
  }

  private async initBasedOnRelationshipStatus() {
    if (this.relationshipStatus === 'MutuallyConfirmed') {
      await this.initBasedOnParentAssessment();
    } else {
      await this.initBasedOnSubsidiaryAssessment();
    }
  }

  private async initBasedOnParentAssessment() {
    const response = await this.fetchAssessmentStatus(this.parentTsmId);
    if (!response) {
      console.error("Get parent assessment status failed!");
      return;
    }
    const inclueStatus = this.supplier?.saCountryServed?.includes(this.countryServed);
    if (response &&
      (response.msg == "no data" ||
        this.esgStatus === 'Submitted and Approved')) {
      this.inviteFlag = false;
    } else {
      this.inviteFlag = inclueStatus && true;
      this.questionnaireId = response.esgQuestionnaireId;
      this.isSupplierStart = response.requestFrom;
      this.supplierUpdateSAInfo = response.supplierUpdateSAInfo;
      this.updatePropsBasedOnRelationshipStatus();
      await this.sustainStart("sustainStart=tsm&sph");
    }
  }

  private updatePropsBasedOnRelationshipStatus() {
    if (this.relationshipStatus === 'MutuallyConfirmed') {
      this.esgAssessmentProps.manageUsersButtonProps.width = 280;
      this.esgAssessmentProps.showButton = true;
      this.esgAssessmentProps.showParentHandlingSAInfo = "parentHandlingSAInfo";
      this.esgAssessmentProps.buttonProps = {
        label: "Review Parent Assessment",
        disabled: false,
        width: 280,
        color: "white",
        backgroundColor: "blue",
        borderColor: "blue",
        hoverColor: "white",
        hoverBackgroundColor: "blue",
        hoverBorderColor: "blue"
      }
      this.esgAssessmentProps.onClick = () => this.redirectToParentAssessment();
      this.esgQuestionnaireService.eventEmit.emit(
        `buttonStatusChanged#${JSON.stringify(this.esgAssessmentProps.buttonProps)}`
      );
    } else if (this.relationshipStatus === "Open") {
      this.createInitialButtonProps();
      this.esgAssessmentProps.showButton = true
    }
    else {
      const btnShow = (this.isParentSupplier() || this.relationshipStatus === "NoParent") && !this.existUpdateSADatafalg
        && ["Validation Completed", "Rejected"].includes(this.currentlyServedCountryTaskSAStatus);
      if (this.esgAssessmentProps.manageUsersButtonProps) this.esgAssessmentProps.manageUsersButtonProps.width = btnShow ? 313 : 220;
      this.esgAssessmentProps.showParentHandlingSAInfo = "";
      this.esgAssessmentProps.relationshipInfoText = undefined;
    }

    this.esgQuestionnaireService.eventEmit.emit(
      `manageUsersButtonStatusChanged#${JSON.stringify(this.esgAssessmentProps.manageUsersButtonProps)}`
    );
  }

  private async redirectToParentAssessment() {
    const parentSupplierInfo = await this.supplierGeneralProfileService.getSupplierById(this.parentTsmId);
    const assignedToEmail = this.authService.getLoginUser().email;
    let dyRoute = "/esg-questionnaire/survey-getting-started";
    if (this.canReviewAssessment(this.esgStatus)) {
      dyRoute = "/esg-questionnaire/survey-review-summary";
    } else {
      dyRoute = this.getRedirectRoute(this.section);
    }

    this.esgQuestionnaireService.appliedFilter = {};
    this.router.navigate([dyRoute, this.questionnaireId], {
      queryParams: {
        supplierName: parentSupplierInfo.supplierName,
        countryServed: this.countryServed,
        supplierTsmId: this.parentTsmId,
        assignedToEmail: assignedToEmail,
        isMutuallyConfirmed: true,
        childTsmId: this.supplierTsmId,
        childSupplierName: this.supplier.supplierName,
        esgStatus: this.esgStatus,
        isScope3Supplier: this.isScope3Supplier(parentSupplierInfo),
        viewOnly: await this.checkQuestionnaireViewOnly()
      }
    });
    window.scroll(0, 0);
  }

  async checkQuestionnaireViewOnly(): Promise<boolean> {
    // calculate SA completion state
    let isAssessmentCompleted = false;
    const currentRole = this.authService.getRole();
    // search the newest task status
    const reqDataAssess = {
      country: this.countryServed,
      supplierTsmId: this.supplierTsmId,
      buyerTsmId: this.buyerId
    };
    const assessmentStatus = await this.supplierService.getAssessmentStatus(reqDataAssess);
    const taskSAStatus = assessmentStatus.status;
    if (currentRole === 'authority') {
      isAssessmentCompleted = taskSAStatus ? taskSAStatus === "Completed"
        || taskSAStatus === "Update Required"
        || taskSAStatus === "Validation Completed"
        || taskSAStatus === "In Progress"
        || taskSAStatus === "Awaiting update approval"
        || taskSAStatus === "Rejected" : false;
    } else {
      isAssessmentCompleted = taskSAStatus ? taskSAStatus === "Completed"
        || taskSAStatus === "Validation Completed"
        || taskSAStatus === "Awaiting update approval"
        || taskSAStatus === "Rejected" : false;
    }

    const isParentAssessment = this.esgQuestionnaireService.checkIfParentAssessment(this.supplierTsmId);

    let isAllowedToEditParentAssessment = false;
    if (isParentAssessment) {
      isAllowedToEditParentAssessment = await this.esgQuestionnaireService.checkIfAllowedToEditParentAssessment();
    }

    const isBuyerExpert = this.authService.isBuyerExpert();
    const viewOnly = isAssessmentCompleted || (isParentAssessment && !isAllowedToEditParentAssessment) || isBuyerExpert;
    return viewOnly;
  }

  async openPopUp(): Promise<void> {
    try {
      // Retrieve the logged-in user's email (assuming you have a method or service to get it)
      //const userEmail = this.authService.getUserEmail();

      // Get the user's profile data by email
      const b2cUser = this.authService.decodeToken();
      const userProfile = await this.supplierGeneralProfileService.getProfileByEmail(b2cUser.email);

      // Check if the 'mergeProfilesAcknowledgment' key exists in the profile
      if (userProfile.extension_tsm_mergeProfilesAcknowledgement && userProfile.extension_tsm_mergeProfilesAcknowledgement === true) {
          // If the key exists, open the PopUpDuplicateComponent
          this.ngbModalService.open(PopUpDuplicateComponent);
      }
    } catch (error) {
      console.error('Error while checking mergeProfilesAcknowledgment:', error);
    }
  }

  async getprofile(): Promise<any> {
    const b2cUser = this.authService.decodeToken();
    const userprofileData = await this.supplierGeneralProfileService.getProfileByEmail(b2cUser.email);
    this.profileData = userprofileData;
    userprofileData.userId = b2cUser.userId;
    return userprofileData;
  }

  /**
   * grant supplier admin access to first supplier user
   */
  private grantSupplierAdmin(): void {
    const userId = this.authService.getLoginUser()?.userId;
    this.adminBoardService.grantSupplierAdminUser(userId).then(resp => {
      if (resp?.authRefresh === "required") {
        this.authService.setAuthRefresh(resp.authRefresh).then(r => {
          this.adminBoardService.eventEmit.emit("grant-supplier-admin-reload");
        });
      }
    });
  }

  private GetCountryServed(): string {
    let countryServed = "";
    if (this.supplier?.countryServed?.length > 0) {
      this.countryServedList = this.supplier.saCountryServed
      countryServed = this.supplier.countryServed[0];
    }
    return countryServed;
  }

  ngOnDestroy(): void {
    this.esgQuestionnaireService.setSubmittedAlert(false);
    this.esgQuestionnaireService.setReSubmittedAlert(false);
  }

  redirectToEsgAssessment = async () => {
    const assignedToEmail = this.authService.getLoginUser().email;

    const buttonText = this.esgAssessmentProps.buttonProps.label;
    let dyRoute;
    let isFromContinueOrUpdateButton = false;
    if (buttonText === "Continue") {
      dyRoute = this.getRedirectRoute(this.section);
      if (this.isSupplierStart == "supplier" && this.supplierUpdateSAInfo.length > 0
        && this.supplierUpdateSAInfo[0].updateReason == "Change Inclusion & Diversity consent") {
        isFromContinueOrUpdateButton = true;
        dyRoute = "/esg-questionnaire/survey-supplier-i-d";
      }
    } else if (this.canReviewAssessment(this.esgStatus)) {
      dyRoute = "/esg-questionnaire/survey-review-summary";
    } else {
      dyRoute = "/esg-questionnaire/survey-getting-started";
    }
    let isUpdateButtonTrigger = await this.supplierService.getIsUpdateButtonTriggerStatus();
    if (isUpdateButtonTrigger) {
      dyRoute = "/esg-questionnaire/survey-supplier-i-d";
      isFromContinueOrUpdateButton = true;
      this.isSupplierStart = "supplier";
    }
    this.esgQuestionnaireService.appliedFilter = {};
    this.router.navigate([dyRoute, this.questionnaireId], {
      queryParams: {
        supplierName: this.supplier.supplierName,
        countryServed: this.countryServed,
        supplierTsmId: this.supplierTsmId,
        assignedToEmail: assignedToEmail,
        esgStatus: this.esgStatus,
        isScope3Supplier: this.isScope3Supplier(this.supplier),
        isSupplierStart: this.isSupplierStart,
        isFromContinueOrUpdateButton: isFromContinueOrUpdateButton,
        viewOnly: await this.checkQuestionnaireViewOnly()
      }
    })
      .then(() => {
        if (this.viewOnlyFlag) {
          this.esgQuestionnaireService.eventEmit.emit("viewOnly");
        }
      });
    window.scroll(0, 0);
  };

  redirectToOnboarding = async () => {
    const userInfo = this.authService.getLoginUser();
    const b2cUserInfo = await this.b2cService.getUser(userInfo.userId);
    let email = b2cUserInfo && b2cUserInfo.userName ? b2cUserInfo.userName : "";
    this.countryServedMapping = countryList['Data']?.transferToSupplierHub[`${this.countryServed}`];
    const selectedLanguage = localStorage.getItem('current language');
    window.location.href =
      this.supplierHubUrlEndpoint +
      "/tsmsearch?key1=" +
      this.supplierTsmId +
      "&key2=" +
      this.countryServedMapping +
      "&email=" +
      email + `&env=${this.env}` +"&language="+selectedLanguage;
    window.scroll(0, 0);
  };

  async openProfilePopup(modalChoice: string): Promise<void> {
    if (!this.supplier) {
      await this.initialiseSupplier();
    }
    if (modalChoice === "edit-profile-detail") {
      const confirmType = this.editProfileDetailData;
      let confirmTypeSupplier = {
        ...this.supplier,
        currentcountryServed: this.countryServed
      };
      confirmType.supplier = confirmTypeSupplier;
      const modalReference = this.ngbModalService.open(ProfileDetailsComponent, confirmType.options);
      modalReference.componentInstance.confirmType = confirmType;
      let tsmRoot = <HTMLElement>document.getElementsByTagName('tsm-root')[0];
      if (tsmRoot) {
        tsmRoot.setAttribute('aria-hidden', 'false');
      }
    }
    if (modalChoice === "profile-detail-strict") {
      const confirmType = this.ProfileDetailStrictData;
      let confirmTypeSupplier = {
        ...this.supplier,
        currentcountryServed: this.countryServed
      };
      confirmType.supplier = confirmTypeSupplier;
      const modalReference = this.ngbModalService.open(ProfileDetailsComponent, confirmType.options);
      modalReference.componentInstance.confirmType = confirmType;
      let tsmRoot = <HTMLElement>document.getElementsByTagName('tsm-root')[0];
      if (tsmRoot) {
        tsmRoot.setAttribute('aria-hidden', 'false');
      }
    }
  }

  async openFirstProfilePopup(): Promise<void> {
    //modify for profile first auto pop up  multiLanguage button show
    localStorage.setItem("multiLanguageButtonShow", JSON.stringify(true));
    if (this.ngbModalService.hasOpenModals()) {
      return;
    }
    if (!this.supplier) {
      await this.initialiseSupplier();
    }
    const confirmType = this.ProfileDetailStrictData;
    let confirmTypeSupplier = {
      ...this.supplier,
      currentcountryServed: this.countryServed
    };
    confirmType.supplier = confirmTypeSupplier;
    const modalReference = this.ngbModalService.open(ProfileDetailsComponent, confirmType.options);
    modalReference.componentInstance.confirmType = confirmType;
    let tsmRoot = <HTMLElement>document.getElementsByTagName('tsm-root')[0];
    if (tsmRoot) {
      tsmRoot.setAttribute('aria-hidden', 'false');
    }
  }

  async reload(event) {
    if (this.isLoadingSupplier) {
      return;
    }

    this.disableForLoading();

    if (this.route.routeConfig.path !== 'parent-supplier-profile/:id') {
      const queryUserProfileUrl = `${this.baseUrl}/update`;
      await this.httpService.PostPromise(queryUserProfileUrl, event);
    }
    await this.refreshData(true);
    localStorage.setItem("currentCountryLocation", this.supplier.countryLocation);
    localStorage.setItem("saCountryServed", JSON.stringify(this.supplier.saCountryServed));
    this.eventService.privacyStatementEmit.emit({ country: this.supplier.countryLocation, needCallback: true, isFetchPSVersion: true });
    this.endableAfterLoading();
  }

  async refreshData(isReload = false) {
    this.unsubscribeEvents();
    this.refreshAlerts();
    await this.resetLocalStorage();
    this.supplierGeneralProfileService.eventEmit.emit("refreshPocProfilePermission");
    await this.initialiseSupplier();
    await this.fetchParentData();
    this.collectReviewData();
    this.esgAssessmentProps.supplierTsmId = this.supplierTsmId;
    this.supplierGeneralProfileSubscription();
    this.countryServed = this.GetCountryServed();
    if (localStorage.getItem(this.userprofileData.extension_tsm_defaultProfile)) {
      this.countryServed = localStorage.getItem(this.userprofileData.extension_tsm_defaultProfile)
      this.supplier.editCountryServed = this.countryServed
    }
    this.supplierGeneralProfileService.eventEmitForCS.emit(this.countryServedList);
    this.userType = "supplier";
    this.showAlert();
    if (isReload) { this.createInitialButtonProps(); }
    await Promise.all([
      this.saSubmit(),
      this.refreshRelationshipStatus(),
      this.initBasedOnRelationshipStatus(),
      this.getOnboardingStatus(),
      this.subscribeEvent(),
      this.calculateShowSupplierEsgStatus()
    ]);
  }

  refreshAlerts() {
    this.alertVisible = false;
    this.relationshipService.eventEmit.emit("refreshAlerts");
  }

  async sustainStart(buttonPermissionId) {
    await this.authService.handleButtonPermission();
    const btnPer = localStorage.getItem("buttonPermission");
    const perArr = buttonPermissionId.split("=");
    const authData = JSON.parse(btnPer);
    const perId = perArr[0];
    const perPlatform = perArr[1];
    const platFormArr = perPlatform.split("&");
    for (let i = 0; i < platFormArr.length; i++) {
      const buttonList = authData[platFormArr[i]];
      if (!(buttonList && perId in buttonList && buttonList[perId] > 0)) {
        if (buttonPermissionId.indexOf("sustainStart") > -1) {
          this.assessment = true;
        } else {
          this.onboardingStart = true;
        }
      } else {
        if (buttonPermissionId.indexOf("sustainStart") > -1) {
          this.assessment = false;
        } else {
          this.onboardingStart = false;
        }
      }
    }
  }

  isSupplierStatusPending(): boolean {
    return this.supplier && this.supplier.status === "Pending";
  }

  supplierGeneralProfileSubscription = (): void => {
    this.supplierGeneralProfileEventEmitSubscription = this.supplierGeneralProfileService.eventEmit.subscribe(
      async (event) => {
        let emitArry = event.split("#");
        if (emitArry[0] === "verifySuccess") {
          this.countryServed = emitArry[1];
          await this.initialiseSupplierWithCountryServed(this.countryServed);
          this.getprofile()
          this.alertProps = {
            type: "alert-profileVerifiedSuccess",
            message: "Your profile is now verified.",
            icon: "assets/images/icon-tick.svg",
            iconFill: "#d5eed9",
            iconClose: "assets/images/icon-cross-green.svg",
            onDismiss: () => {
              this.alertVisible = false;
            }
          };

          this.alertVisible = true;
        }
        if (event === "refreshSupplier") {
          await this.initialiseSupplier();
        }
        if (event === "openWelcomeModal") {
          this.openWelcomePopup();
        }
        if (event === "welcome") {
          this.openProfilePopup("profile-detail-strict");
        }
      }
    );
  };

  /**
   * Checks whether to show the alert on page load
   */
  showAlert = (): void => {
    const isReSubmitted = this.esgQuestionnaireService.getReSubmittedAlert();
    if (this.esgQuestionnaireService.getSubmittedAlert() || isReSubmitted) {
      const message = isReSubmitted ? "Survey re-submission was successful." : "You have successfully completed the survey.";
      this.alertProps = {
        type: "alert-success",
        message: message,
        icon: "assets/icons/tick.svg",
        iconFill: "#155724",
        iconClose: "assets/images/icon-cross-green.svg",
        onDismiss: () => {
          this.esgQuestionnaireService.setSubmittedAlert(false);
          this.esgQuestionnaireService.setReSubmittedAlert(false);
          this.alertVisible = false;
        }
      };
      this.alertVisible = true;
    }
  };

  async getCountryServed(val) {
    if (this.countryServed !== val && !this.isLoadingSupplier) {
      this.disableForLoading();
      this.countryServed = val;
      this.supplierGeneralProfileService.eventEmitForCS.emit(this.countryServed);
      this.onboardingCardShow = false;
      await this.initBasedOnRelationshipStatus();
      await this.getOnboardingStatus();
      this.calculateShowSupplierEsgStatus();
      this.endableAfterLoading();
    }
    localStorage.setItem(this.userprofileData.extension_tsm_defaultProfile, val)
  }

  private initBasedOnSubsidiaryAssessment = async () => {
    const response = await this.fetchAssessmentStatus(this.supplierTsmId);
    if (!response) {
      console.error("Get subsidiary assessment status failed!");
      return;
    }
    this.currentlyServedCountryTaskSAStatus = response.status;
    const inclueStatus = this.supplier?.saCountryServed?.includes(this.countryServed);
    this.inviteFlag = !(response && response.msg == "no data") && inclueStatus;
    this.updatePropsBasedOnRelationshipStatus();
    if (this.updatePropsForAwaitingParentConfirmation() || this.updatePropsForAwaitingInviteApproval()) return;
    this.taskId = response.taskId;
    this.currentlyServedCountrySAStatus = response.status;
    this.workflowId = response.workflowId;
    this.buttonStatus(response);
    this.questionnaireId = response.esgQuestionnaireId;
    this.isSupplierStart = response.requestFrom;
    this.supplierUpdateSAInfo = response.supplierUpdateSAInfo;
    await this.sustainStart("sustainStart=tsm&sph");
  };

  private updatePropsForAwaitingInviteApproval = (): boolean => {
    if (this.relationshipStatus === 'AwaitingInviteApproval') {
      this.esgAssessmentProps = {
        ...this.esgAssessment,
        title: this.translations.awaitingParentCardTitle,
        relationshipInfoText: this.translations.awaitingInviteApprovalMessage,
        displayRelationStatus: true,
        showButton: false,
        supplierTsmId: this.supplierTsmId
      }
      return true;
    }
    return false;
  }

  private updatePropsForAwaitingParentConfirmation = (): boolean => {
    if (this.relationshipStatus === 'AwaitingParentConfirmation') {
      this.esgAssessmentProps = {
        ...this.esgAssessment,
        title: this.translations.awaitingParentCardTitle,
        relationshipInfoText: this.translations.awaitingParentMessage,
        displayRelationStatus: true,
        showButton: false,
        supplierTsmId: this.supplierTsmId
      }
      return true;
    }
    return false;
  }

  private calculateShowSupplierEsgStatus() {
    this.showSuppplierEsgStatus = this.esgStatus && this.esgStatus !== SAStatus.notYetInitiated && this.esgStatus !== SAStatus.withdrew;
  }

  private padTo2Digits(num: Number) {
    return num.toString().padStart(2, '0');
  }

  private formatDate(date: Date) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('.');
  }

  private isParentSupplier = () => this.parentTsmId === undefined || this.parentTsmId === this.supplierTsmId;

  private getTaskId = async (supplierTsmId, countryServed) => {
    const reqDataAssess = {
      country: countryServed,
      supplierTsmId: supplierTsmId,
      buyerTsmId: this.buyerId
    };
    let assessmentStatus = await this.supplierService.getAssessmentStatus(reqDataAssess);
    return assessmentStatus;
  }

  private fetchAssessmentStatus = async (supplierTsmId) => {
    const reqDataAssess = {
      country: this.countryServed,
      supplierTsmId: supplierTsmId,
      buyerTsmId: this.buyerId
    };
    const assessmentStatus = await this.supplierService.getAssessmentStatus(reqDataAssess);
    this.section = assessmentStatus ? assessmentStatus.section : "";
    this.esgStatus = assessmentStatus ? assessmentStatus.esgStatus : "";
    this.existUpdateSADatafalg = assessmentStatus ? assessmentStatus.existUpdateSADatafalg : false;

    if (this.isParentSupplier() && assessmentStatus.originalTaskId && assessmentStatus.originalTask) {
      const dateFound = assessmentStatus?.originalTask?.creationDate || false;
      let date: Date;
      if (dateFound) {
        date = new Date(assessmentStatus.originalTask.creationDate);
      }
      this.esgAssessmentProps.isAssessmentCreatedFromSubsidiary = true;
      this.esgAssessmentProps.description = this.translations.saCreatedFromSubsidiary;
      this.esgAssessmentProps.subsidiaryAssessmentData = {
        subsidiaryName: assessmentStatus?.originalTask?.supplier?.supplierName || "",
        initialPointOfContact: assessmentStatus?.originalTask?.supplier?.supplierAdmin?.userName || "",
        creationDate: dateFound ? this.formatDate(date) : ""
      }
    }
    else {
      this.esgAssessmentProps.isAssessmentCreatedFromSubsidiary = false;
      this.esgAssessmentProps.description = this.translations.ownSA;
      this.esgAssessmentProps.subsidiaryAssessmentData = null;
    }

    return assessmentStatus;
  };

  public async buttonStatus(resp: any) {
    this.viewOnlyFlag = false;
    const btnShow = (this.isParentSupplier() || this.relationshipStatus === "NoParent") && !this.existUpdateSADatafalg;
    this.esgAssessmentProps.manageUsersButtonProps.width = 220;
    this.esgAssessmentProps.showUpdateSAButton = false;
    if (resp.status) {
      switch (resp.status) {
        case "Pending":
          this.esgAssessmentProps.buttonProps = {
            label: "Start Now",
            disabled: false,
            width: 220,
            color: "white",
            backgroundColor: "blue",
            borderColor: "blue",
            hoverColor: "white",
            hoverBackgroundColor: "blue",
            hoverBorderColor: "blue"
          };
          this.esgQuestionnaireService.eventEmit.emit(
            `buttonStatusChanged#${JSON.stringify(this.esgAssessmentProps.buttonProps)}`
          );
          break;
        case "In Progress":
          this.esgAssessmentProps.buttonProps = {
            label: "Continue",
            disabled: false,
            width: 220,
            color: "white",
            backgroundColor: "blue",
            borderColor: "blue",
            hoverColor: "white",
            hoverBackgroundColor: "blue",
            hoverBorderColor: "blue"
          };
          this.esgQuestionnaireService.eventEmit.emit(
            `buttonStatusChanged#${JSON.stringify(this.esgAssessmentProps.buttonProps)}`
          );
          break;
        case "Completed":
          this.esgAssessmentProps.buttonProps = {
            label: "View",
            disabled: false,
            width: 220,
            color: "white",
            backgroundColor: "greyDark",
            borderColor: "greyDark",
            hoverColor: "white",
            hoverBackgroundColor: "greyDark",
            hoverBorderColor: "greyDark"
          };
          this.esgQuestionnaireService.eventEmit.emit(
            `buttonStatusChanged#${JSON.stringify(this.esgAssessmentProps.buttonProps)}`
          );
          break;
        case "Validation Completed":
          this.esgAssessmentProps.buttonProps = {
            label: "View",
            disabled: false,
            width: btnShow ? 150 : 220,
            color: "white",
            backgroundColor: "greyDark",
            borderColor: "greyDark",
            hoverColor: "white",
            hoverBackgroundColor: "greyDark",
            hoverBorderColor: "greyDark"
          };
          this.esgQuestionnaireService.eventEmit.emit(
            `buttonStatusChanged#${JSON.stringify(this.esgAssessmentProps.buttonProps)}`
          );
          this.viewOnlyFlag = true;
          //1.Current latest SA status is Validation completed,
          //3 The supplier is not a child company in a existing PC relationship.
          if (btnShow) {
            this.esgAssessmentProps.showUpdateSAButton = true;
            this.esgAssessmentProps.updateSAButtonProps = {
              ...this.updateButtonProps,
              color: "purple",
              backgroundColor: "lightPurple",
              borderColor: "lightPurple",
              hoverColor: "purple",
              hoverBackgroundColor: "lightPurple",
              hoverBorderColor: "lightPurple",
              label: "updateSA",
              margin: "0 0px 0 10px",
              class: 'update-button-class'
            };
            this.esgQuestionnaireService.eventEmit.emit(
              `updateSAButtonChanged#${JSON.stringify(this.esgAssessmentProps.updateSAButtonProps)}`
            );
            this.esgAssessmentProps.manageUsersButtonProps.width = 313;
            this.viewOnlyFlag = false;
          }
          break;
        case "Update Required":
          this.esgAssessmentProps.buttonProps = {
            label: "Update Assessment",
            disabled: false,
            width: 220,
            color: "white",
            backgroundColor: "blue",
            borderColor: "blue",
            hoverColor: "white",
            hoverBackgroundColor: "blue",
            hoverBorderColor: "blue"
          };
          this.esgQuestionnaireService.eventEmit.emit(
            `buttonStatusChanged#${JSON.stringify(this.esgAssessmentProps.buttonProps)}`
          );
          this.viewOnlyFlag = false;
          break;
        case "Rejected":
          this.esgAssessmentProps.buttonProps = {
            label: "View",
            disabled: false,
            width: btnShow ? 150 : 220,
            color: "white",
            backgroundColor: "greyDark",
            borderColor: "greyDark",
            hoverColor: "white",
            hoverBackgroundColor: "greyDark",
            hoverBorderColor: "greyDark"
          };
          this.esgQuestionnaireService.eventEmit.emit(
            `buttonStatusChanged#${JSON.stringify(this.esgAssessmentProps.buttonProps)}`
          );
          if (btnShow) {
            this.esgAssessmentProps.showUpdateSAButton = true;
            this.esgAssessmentProps.updateSAButtonProps = {
              ...this.updateButtonProps,
              color: "purple",
              backgroundColor: "lightPurple",
              borderColor: "lightPurple",
              hoverColor: "purple",
              hoverBackgroundColor: "lightPurple",
              hoverBorderColor: "lightPurple",
              label: "updateSA",
              margin: "0 0px 0 10px",
              class: 'update-button-class'
            };
            this.esgQuestionnaireService.eventEmit.emit(
              `updateSAButtonChanged#${JSON.stringify(this.esgAssessmentProps.updateSAButtonProps)}`
            );
            this.esgAssessmentProps.manageUsersButtonProps.width = 313;
            this.viewOnlyFlag = false;
          }
          break;
      }
    }
  }

  private subscribeEvent = async () => {
    this.relationUpdateSuccessSubscription = this.relationshipService.getSupplierRelationUpdateSuccessEvent()
      .subscribe(next => this.handleSupplierRelationUpdateSuccessEvent(next));

    this.relationChangedSubscription = this.relationshipService.getSupplierRelationChangedEvent()
      .subscribe(next => this.handleOnRelationStatusChanged(next));

    this.saveEsgEventEmitSubscription = this.esgQuestionnaireService.eventEmit.subscribe(async (event) => {
      let isString = false;
      if (typeof event === "string") {
        isString = true;
      }
      if (!isString) {
        return;
      }
      let operateType = "";
      let emitArry = event.split("#");
      if (emitArry[0] === "saveSuccess") {
        operateType = "SAVE_AND_CLOSE";
        await this.changeStatus(operateType, emitArry[1], emitArry[2]);
      }
      // The only needed correct changeStatus("SUBMIT") is called by saSubmit()
      /*if (emitArry[0] === "submitSuccess") {
        operateType = "SUBMIT";
        await this.changeStatus(operateType, emitArry[1], emitArry[2]);
      }*/
    });

    await this.subscribeNotificationService();
  }

  private subscribeNotificationService = async () => {
    this.notificationUrl = '';
    this.notificationEventSubscription = this.notificationService.getRefreshNotificationsEvent()
      .subscribe(async (event) => {
        if (event.name === "redirectToQuestionnaire") {
          this.notificationUrl = event.value;
          await this.handleNotificationButtonClick();
        }
      });
  }

  private handleNotificationButtonClick = async () => {
    this.loading = true;
    if (await this.shouldOpenAddSupplierParentModal()) {
      await this.openAddSupplierParentModal();
    } else {
      this.document.location.href = this.notificationUrl;
    }
    this.loading = false;
  }

  private saSubmit = async () => {
    if (sessionStorage.getItem('questionComplete')) {
      let completeArry = sessionStorage.getItem('questionComplete').split("#")
      if (completeArry[0] === "submitSuccess") {
        let operateType = "SUBMIT";
        await this.changeStatus(operateType, completeArry[1], completeArry[2]);
        sessionStorage.removeItem('questionComplete');
        sessionStorage.removeItem('questionCompleteSaveSuccess');
      }
    } else {
      if (sessionStorage.getItem('questionCompleteSaveSuccess')) {
        let completeArry = sessionStorage.getItem('questionCompleteSaveSuccess').split("#")
        if (completeArry[0] === "saveSuccess") {
          let operateType = "SAVE_AND_CLOSE";
          await this.changeStatus(operateType, completeArry[1], completeArry[2]);
          sessionStorage.removeItem('questionCompleteSaveSuccess')
          await this.initBasedOnRelationshipStatus();
        }
      }
    }

  }

  async changeStatus(val: string, questionnaireId: string, countryServed: string) {
    sessionStorage.removeItem('questionComplete');
    sessionStorage.removeItem('questionCompleteSaveSuccess');
  }

  private fetchTaskEmail = async (questionnaireId: string, countryServed: string): Promise<string> => {
    if (!this.relationshipStatus) {
      await this.refreshRelationshipStatus();
    }

    let assignedToEmail = this.authService.getLoginUser().email;
    if (this.relationshipStatus === 'MutuallyConfirmed') {
      const result = await this.esgQuestionnaireService.getQuestionnaireTask(
        this.buyerId,
        this.parentTsmId,
        questionnaireId,
        countryServed
      );
      assignedToEmail = result.length > 0 ? result[0].assignedToEmail : '';
    }
    return assignedToEmail;
  }

  setButtonDisabledProp = (disabled: boolean): void => {
    this.onboardingProps = {
      ...this.onboardingProps,
      buttonProps: {
        ...this.onboardingProps.buttonProps,
        disabled: disabled
      }
    };
  };

  getOnboardingStatus = async () => {
    const userInfo = this.authService.getLoginUser();
    if(this.authService.getRole() === "supplier" && !JSON.parse(userInfo.SUP)['2'].length) {
      return;
    }
    const b2cUserInfo = await this.b2cService.getUser(userInfo.userId);
    let PocEmail = b2cUserInfo && b2cUserInfo.userName ? b2cUserInfo.userName : "";
    this.countryServedMapping = countryList['Data']?.transferToSupplierHub[`${this.countryServed}`];
    const countryGroupSa = countryList['Data']?.countryGroupSa[`${this.countryServed}`];

    const supplierReqData = {
      source: "supplier",
      supplierTsmId: this.supplierTsmId,
      supplierHubMappedCountryServed: this.countryServedMapping,
      PocEmail: PocEmail,
      supplierName: this.supplier?.supplierName,
      countryServed: this.countryServed,
      countryGroupSa: countryGroupSa
    };
    let resp = await this.supplierService.getOnboardingStatus(supplierReqData);
    let status = 0; //0: Start now; 1:Pending Profile(s); 2:View Profile(s); 3: Don't show
    if (!resp) {
      console.error("Get Onboarding status failed!");
      status = 0;
      return;
    }

    //If the automated SPH invite is executed, we need to ensure we have the user's updated permissions
    if (resp.authRefresh) {
      await this.authService.setAuthRefresh(resp.authRefresh);
      await this.initBasedOnSubsidiaryAssessment();
    }
    this.grantSupplierAdmin();

    if (resp.onboardingMessage == "There are no Onboarding Requests found") {
      status = 3; // Don't show
    }
    let arr = resp.onboardingDetails;
    if (arr !== undefined && arr.length > 0) {
      for (let i = 0, len = arr.length; i < len; i++) {
        if (len == 1 && arr[i].questionaireStatus == "Requestor Draft Completed") {
          status = 0; // Start now
          break;
        } else if (len == 1 && arr[i].questionaireStatus == "Requestor Draft InComplete") {
          status = 3; // Don't show
          break;
        } else if (len >= 1 && arr[i].dashboardStatus === "Pending") {
          status = 1; // Pending Profile(s)
          break;
        } else {
          status = 2; // View Profile(s)
        }
      }
      await this.controlOnboardingButtonStatus(status);
    } else {
      this.onboardingCardShow = false;
    }
    this.oneBoardingStatus = status;
  };

  async controlOnboardingButtonStatus(status: number) {
    if (typeof status == "number") {
      this.onboardingCardShow = true;
      switch (status) {
        case 0:
          this.onboardingProps.buttonProps = {
            label: "Start Now",
            disabled: false,
            width: 220,
            color: "white",
            backgroundColor: "blue",
            borderColor: "blue",
            hoverColor: "white",
            hoverBackgroundColor: "blue",
            hoverBorderColor: "blue"
          };
          break;
        case 1:
          this.onboardingProps.buttonProps = {
            label: "Pending Profile(s)",
            disabled: false,
            width: 220,
            color: "white",
            backgroundColor: "blue",
            borderColor: "blue",
            hoverColor: "white",
            hoverBackgroundColor: "blue",
            hoverBorderColor: "blue"
          };
          break;
        case 2:
          this.onboardingProps.buttonProps = {
            label: "View Profile(s)",
            disabled: false,
            width: 220,
            color: "purple",
            backgroundColor: "transparent",
            borderColor: "lightBlue",
            hoverColor: "white",
            hoverBackgroundColor: "blue",
            hoverBorderColor: "white"
          };
          break;
        case 3:
          this.onboardingCardShow = false;
          break;
      }
      await this.sustainStart("onboardingStart=tsm&sup");
      if (this.onboardingCardShow) {
        this.onboardingCard?.setRequestButtonProps();
      }
    } else {
      this.onboardingCardShow = false;
    }
  }

  openWelcomePopup(): void {
    if (this.ngbModalService.hasOpenModals()) {
      return;
    }
    this.welcomeModalConfig.options = this.modalService.getOptions(this.welcomeModalConfig.type);
    this.welcomeModalConfig.data = this.modalService.getWelcomeData();
    const modalType = this.welcomeModalConfig;
    const modalReference = this.ngbModalService.open(PopUpWelcomeComponent, modalType.options);
    modalReference.componentInstance.modalType = modalType.type;
    modalReference.componentInstance.modalData = modalType.data;
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  redirectToAdminBoard = async () => {
    this.router.navigateByUrl("/supplier-admin-board");
  };

  async resetLocalStorage() {
    this.userprofileData = await this.getprofile();
    const user = this.userprofileData.profileData[this.userprofileData.extension_tsm_defaultProfile];
    const userInfo = {
      tsmId: this.userprofileData.extension_tsm_defaultProfile,
      pocEmail: this.userprofileData.email,
      extension_tsm_privacyStatement: this.userprofileData.extension_tsm_privacyStatement,
      extension_tsm_defaultRole: this.userprofileData.extension_tsm_defaultRole,
      extension_tsm_systemAdmin: this.userprofileData.extension_tsm_systemAdmin,
      extension_tsm_TSM: user.extension_tsm_TSM,
      extension_tsm_SUP: user.extension_tsm_SUP,
      extension_tsm_SPH: user.extension_tsm_SPH,
      userId: this.userprofileData.userId
    };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));;
  }

  public async openAddSupplierParentModal(hideNoParent: boolean = false): Promise<void> {
    // avoid opening the modal twice at the sametime
    if (this.ngbModalService.hasOpenModals()) {
      return;
    }

    this.addParentModalConfig.hideNoParent = hideNoParent;
    this.addParentModalConfig.supplier = this.supplier;
    this.addParentModalConfig.taskId = this.taskId;
    this.addParentModalConfig.confirmSupplier = {};
    if (!isEmpty(this.confirmSupplierTsmId)) {
      let response = await this.supplierGeneralProfileService.getSupplierById(this.confirmSupplierTsmId);
      if (response) {
        this.addParentModalConfig.confirmSupplier = {
          ...response,
          pocEmail: response.supplierAdmin ? response.supplierAdmin.userName : "",
          pocName: response.supplierAdmin ? `${response.supplierAdmin.firstName} ${response.supplierAdmin.lastName}` : ""
        };
      }
    }
    this.addParentModalConfig.options = {
      scrollable: true,
      animation: true,
      windowClass: 'add-parent-modal-window',
      backdropClass: 'modal-custom-backdrop',
    };
    this.addParentModalConfig.redirectToEsgAssessment = this.redirectToEsgAssessment;
    this.addParentModalConfig.url = this.notificationUrl;
    const modalReference = this.ngbModalService.open(ConfirmSupplierParentCreationComponent, this.addParentModalConfig.options);
    modalReference.componentInstance.confirmType = this.addParentModalConfig;
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  private async handleAssessmentButtonClicked() {
    this.loading = true;
    if (await this.shouldOpenAddSupplierParentModal()) {
      await this.openAddSupplierParentModal();
    } else {
      await this.redirectToEsgAssessment();
    }
    this.loading = false;
  }

  public async shouldOpenAddSupplierParentModal(mode?: string): Promise<boolean> {
    if (this.supplier.indirect) {
      return false;
    }
    if (this.servedCountryHasStartedTheAssessment() && mode !== 'specifyParent') {
      return false;
    }
    this.confirmSupplierTsmId = "";
    const [children, relation] = await Promise.all([
      this.relationshipService.getParentChildRelationForParent(this.supplierTsmId),
      this.relationshipService.getParentChildRelation(this.supplierTsmId)
    ]);
    if (Array.isArray(children)) {
      const validParentStatus: GlobalSupplierRelationshipConfirmStatus[] = ['AwaitingParentConfirmation', 'MutuallyConfirmed'];
      const validChildSuppliers = children.filter(child => validParentStatus.includes(child.status));
      // active, valid subsidiary suppliers -> is a valid parent --- show no parent dialog
      if (validChildSuppliers.length) {
        return false;
      }
    }
    const validStatus = ['Open', 'NoParent'];
    if (relation && relation.status && !validStatus.includes(relation.status)) {
      return false;
    }

    //relationship status is empty/no-parent and parent found--- show parent found dialog
    if (this.supplier.possibleParentTsmId && !isEmpty(this.supplier.possibleParentTsmId)) {
      this.confirmSupplierTsmId = this.supplier.possibleParentTsmId;
      return true;
    }

    //no parent found and relationship status empty---show no parent dialog
    if (!relation.status) {
      return true;
    }
    return false;
  }

  private servedCountryHasStartedTheAssessment(): boolean {
    return this.currentlyServedCountrySAStatus !== 'Pending';
  }

  async handleSupplierRelationUpdateSuccessEvent(eventType: SupplierRelationUpdateSuccessEventType) {
    // update supplier information for these events
    if (
      eventType === "ParentConfirmed" ||
      eventType === "RelationshipRequestWithdrawn" ||
      eventType === "ParentRelationshipRequestCancelled"
    ) {
      await this.initialiseSupplier();
      await this.refreshRelationshipStatus();
      await this.initBasedOnRelationshipStatus();
    }

    if (eventType === "SubsidiaryRelationshipRequestCancelled") {
      await this.relationshipService.getSubsidiarySuppliersCount(this.supplier.supplierTsmId);
    }
  }

  async refreshRelationshipStatus(): Promise<void> {
    const res = await this.relationshipService.getParentChildRelation(this.supplierTsmId);
    this.relationshipStatus = res.status;
    this.parentTsmId = res.parentTsmId;
    this.esgAssessmentProps.showButton = this.isStartContinueButtonVisible();
  }

  isStartContinueButtonVisible(): boolean {
    if (this.relationshipStatus === 'AwaitingParentConfirmation' || this.relationshipStatus === 'MutuallyConfirmed') {
      return false;
    }
    return true;
  }

  private async handleOnRelationStatusChanged(supplierTsmId: string): Promise<void> {
    if (supplierTsmId === this.supplierTsmId) {
      await this.refreshRelationshipStatus();
      this.createInitialButtonProps();
      await this.initBasedOnRelationshipStatus();
    }
  }

  getRedirectRoute(section: string): string {
    let dyRoute: string;
    switch (section) {
      case "getting-started":
        dyRoute = "/esg-questionnaire/survey-getting-started";
        break;
      case "human-social":
        dyRoute = "/esg-questionnaire/survey-human-social";
        break;
      case "supplier-i-d":
        dyRoute = "/esg-questionnaire/survey-supplier-i-d";
        break;
      case "environment":
        dyRoute = "/esg-questionnaire/survey-environment";
        break;
      case "es-mgt":
        dyRoute = "/esg-questionnaire/survey-es-mgt";
        break;
      default:
        if (this.canReviewAssessment(this.esgStatus)) {
          dyRoute = "/esg-questionnaire/survey-review-summary";
        } else {
          dyRoute = "/esg-questionnaire/survey-getting-started";
        }
        break;
    }
    return dyRoute;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body) {
        this.translations = multilanguageJson.body.main.svSupplierProfile;
        if (this.relationshipStatus === 'AwaitingParentConfirmation') {
          this.esgAssessmentProps.relationshipInfoText = this.translations.awaitingParentMessage;
          this.esgAssessmentProps.title = this.translations.awaitingParentCardTitle;
        } else if (this.relationshipStatus === 'AwaitingInviteApproval') {
          this.esgAssessmentProps.relationshipInfoText = this.translations.awaitingInviteApprovalMessage;
          this.esgAssessmentProps.title = this.translations.awaitingParentCardTitle;
        }
        if (this.esgAssessmentProps?.isAssessmentCreatedFromSubsidiary) {
          this.esgAssessmentProps.description = this.translations.saCreatedFromSubsidiary;
        } else {
          if (this.esgAssessmentProps && this.translations) {
            this.esgAssessmentProps.description = this.translations.ownSA;
          }
        }
      }
    }
  }

  private unsubscribeEvents() {
    if (this.relationChangedSubscription) {
      this.relationChangedSubscription.unsubscribe();
    }
    if (this.relationUpdateSuccessSubscription) {
      this.relationUpdateSuccessSubscription.unsubscribe();
    }
    if (this.notificationEventSubscription) {
      this.notificationEventSubscription.unsubscribe();
    }
  }

  public async openUpdateSAModal(hideNoParent: boolean = false): Promise<void> {
    // avoid opening the modal twice at the sametime
    if (this.ngbModalService.hasOpenModals()) {
      return;
    }

    this.updateSaModalConfig.supplier = this.supplier;
    this.updateSaModalConfig.supplier["currentCountry"] = this.countryServed;
    this.updateSaModalConfig.options = {
      scrollable: true,
      animation: true,
      backdrop: 'static',
      windowClass: 'update-sa-modal-window',
      backdropClass: 'modal-custom-backdrop',
    };
    this.updateSaModalConfig.redirectToEsgAssessment = this.redirectToEsgAssessment;
    const modalReference = this.ngbModalService.open(SupplierUpdateSaComponent, this.updateSaModalConfig.options);
    modalReference.componentInstance.confirmType = this.updateSaModalConfig;
    modalReference.componentInstance.confirmType.workflowId = this.workflowId;
    modalReference.result.then((result) => {
      if (result == 'other Reason submit') {
        this.refreshData(false);
      }
      if (result == "task status has changed") {
        this.refreshData(false);
        this.getModalTranslation();
        this.openNotificationModal();
      }
    })
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  public async openNotificationModal(): Promise<void> {
    // avoid opening the modal twice at the sametime
    if (this.ngbModalService.hasOpenModals()) {
      return;
    }

    this.messagePopUpModalConfig.supplier.modalTranslation = this.messagePopUpModalTranslation;
    this.messagePopUpModalConfig.options = {
      scrollable: true,
      animation: true,
      backdrop: 'static',
      windowClass: 'message-pop-up-modal-window',
      backdropClass: 'modal-custom-backdrop',
    };
    const modalReference = this.ngbModalService.open(MessagePopUpComponent, this.messagePopUpModalConfig.options);
    modalReference.componentInstance.confirmType = this.messagePopUpModalConfig;
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  getModalTranslation() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.messagePopUpModalTranslation = multilanguageJson.body.main.messagePopUpModal;
      }
    }
  }


}
