import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { BaseContainerComponent } from "src/app/components/base-container/base-container.component";
import { Subject } from "rxjs";
import { AuthService } from "../../services/auth/auth.service";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { filter, takeUntil } from "rxjs/operators";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { Router } from "@angular/router";
import { PowerBiAuthService } from "src/app/services/auth/power-bi-auth.service";

@Component({
    selector: "tsm-landing-container",
    templateUrl: "./landing-container.component.html",
    styleUrls: ["./landing-container.component.sass"]
})
export class LandingContainerComponent extends BaseContainerComponent implements OnInit, OnDestroy {
    ownToken: boolean;
    isShowWelcomePage:boolean;
    private readonly _destroying$ = new Subject<void>();
    constructor(
        private router: Router,
        public authService: AuthService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private powerbiAuthService: PowerBiAuthService,
    ) {
        super(authService);
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                ),
                takeUntil(this._destroying$)
            )
            .subscribe(async (result: EventMessage) => {
                const payload = <AuthenticationResult>result.payload;

                if (payload.idToken) {
                    this.authService.saveToken(payload.idToken);
                    await this.authService.handleButtonPermission();
                }

                this.ownToken = !!payload.idToken;
            });
    }
    async ngOnInit(): Promise<void> {
        this.ownToken = !!localStorage.getItem("sustain_access_token");
        this.isShowWelcomePage = !await this.authService.JudgeShowWelcomePage(false, true)
        if (!this.isShowWelcomePage)
            this.router.navigateByUrl("/dashboard-search");

        this.powerbiAuthService.tryRedirectToLoginPage();
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
