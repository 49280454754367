import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ButtonPropsType, Popup } from "@lsl16/sustainability-shared-components";
import { EventService } from 'src/app/services/EventService.service';
import { Router } from "@angular/router";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";

@Component({
  selector: 'tsm-pop-up-countryserved-existing',
  templateUrl: './pop-up-countryserved-existing.component.html',
  styleUrls: ['./pop-up-countryserved-existing.component.sass']
})
export class PopUpCountryservedExistingComponent implements OnInit {

  constructor(
    public eventService: EventService,
    private activeModal: NgbActiveModal,
    private modalService: ModalService,
    private router: Router,
    private supplierGeneralProfileService: SupplierGeneralProfileService,
  ) { }
  
  @Input() confirmType;
  modalData: Popup;
  countryNotCreated: any;
  countryCreated: any;
  saCountryServed: any;
  popStatus: string;
  propStatus: string;
  notifyType: string;
  createData: any;
  supplierTsmId: string;
  supplierName: string;
  supplierProfileCountryServed: any;
  marketUnit: string;
  countryNeedCreated: any;
  updateCountryServed: any;
  supplierCountryServed: any;
  existingCountries: string = '';
  notExistingCountries: string = '';
  parentTsmId: string = '';
  parentCountryServed: any;
  parentSaCountryServed: any;

  cancelButtonProps: ButtonPropsType = {
      label: "Cancel",
      backgroundColor: "white",
      color: "darkBlue",
      fontSize: 16,
      fontFamily: "Graphik-Medium",
      borderRadius: 25,
      borderColor: 'gray',
      height: 48,
      padding: "14px 5px",
      onClick: () => {
        this.activeModal.close();
      }
  };
  
  confirmButtonProps: ButtonPropsType = {
    label: "Confirm",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    padding: "16px 15px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    onClick: async () => {
      await this.confirm();
    }
  };

  viewDetailsButtonProps: ButtonPropsType = {
    label: "View Details",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    padding: "16px 15px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    onClick: async () => {
      await this.viewDetails();
    }
  };

  ngOnInit() {
    this.countryNotCreated = '';
    this.notifyType = this.confirmType.notifyType;
    this.propStatus = this.confirmType.propStatus;
    this.countryNotCreated = this.confirmType.countryNotCreated;
    this.countryCreated = this.confirmType.countryCreated;
    this.saCountryServed = this.confirmType.saCountryServed;
    this.supplierTsmId = this.confirmType.supplierTsmId;
    this.supplierName = this.confirmType.supplierName;
    this.countryNeedCreated = this.confirmType.countryNeedCreated;
    this.updateCountryServed = this.confirmType.updateCountryServed;
    this.supplierCountryServed = this.confirmType.supplierCountryServed;
    if (this.confirmType.parentTsmId) {
      this.parentTsmId = this.confirmType.parentTsmId;
      this.parentCountryServed = this.confirmType.parentCountryServed;
      this.parentSaCountryServed = this.confirmType.parentSaCountryServed;
    }
    this.modalData = this.modalService.getCountryNodifyData(this.notifyType);
    this.existingCountries = this.countryCreated.toString(); // for text of condition A and B
    this.notExistingCountries = this.countryNotCreated.toString(); // for text of condition B
    this.accessbility()
  };

  accessbility() {
    let tsmRoot = document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden","false");
    }
  }

  async confirm () {
    this.confirmButtonProps.isProcessing = true;
    await this.redirectSa();
    this.activeModal.close();
  };

  async viewDetails() {
    this.viewDetailsButtonProps.isProcessing = true;
    await this.redirectBvEsgView();
    this.activeModal.close();
  }

  async redirectSa () {
    await this.updateCSToProfile();
    this.router.navigate(["/sustainability-assessment-new", this.supplierTsmId, this.countryNotCreated.join(',')], { queryParams: {
      supplierTsmId: this.supplierTsmId,
      countryServed: this.countryNeedCreated,
      supplierName: this.supplierName
    } });
  }

  async redirectBvEsgView () {
    await this.updateCSToProfile();
    this.router.navigate(['/supplier-profile', this.supplierTsmId], {
      queryParams: { countryServed: this.countryNeedCreated[0] },
    });
    this.eventService.eventEmit.emit('initTable-conditionA');
  }

  async updateCSToProfile() { // updated countryServed will not display in create SA feature
    const supplierCountryServed = [...new Set(this.supplierCountryServed.concat(this.updateCountryServed))]; // supplier or child
    const newData = {
      countryServed: supplierCountryServed,
      saCountryServed: (this.saCountryServed ? [...new Set(this.saCountryServed.concat(this.updateCountryServed))] : this.updateCountryServed)
    };
    let result = await this.supplierGeneralProfileService.updateSupplier(this.supplierTsmId, newData);
    this.updateRecordTime(this.supplierTsmId, this.supplierCountryServed, this.updateCountryServed, result);
    if (this.parentTsmId) { // has parent
      const parentCountryServed = [...new Set(this.parentCountryServed.concat(this.updateCountryServed))];
      const newData = {
        countryServed: parentCountryServed,
        saCountryServed: (this.parentSaCountryServed ? [...new Set(this.parentSaCountryServed.concat(this.updateCountryServed))] : this.updateCountryServed)
      };
      let result = await this.supplierGeneralProfileService.updateSupplier(this.parentTsmId, newData);
      this.updateRecordTime(this.parentTsmId, this.parentCountryServed, this.updateCountryServed, result);
    }
  }

  async updateRecordTime(tsmId, countryServed, updateCountryServed, result) {
    let samePart = countryServed.filter(item => updateCountryServed.includes(item));
    let difference = updateCountryServed.filter(item=>!samePart.includes(item))
    if(difference.length !== 0 && result.$metadata.httpStatusCode == 200) {
      await this.supplierGeneralProfileService.recordTime(tsmId, difference) 
    }
  }

  ngAfterContentChecked() {
    this.accessbility();
  }

}