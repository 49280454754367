import { Component, OnInit } from '@angular/core';
import {  HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { SupplierService } from "src/app/services/supplier/supplier.service";

@Component({
  selector: 'tsm-supplierhub2esg',
  templateUrl: './supplierhub2esg.component.html',
  styleUrls: ['./supplierhub2esg.component.sass']
})
export class Supplierhub2esgComponent implements OnInit {

  constructor(private httpService: HttpService,private router: Router,
    private supplierService: SupplierService,) { }
  
  ngOnInit(): void {
    
    this.getData()
    
  }
  async getData(){
    let parameterUrl = window.location.href.split("?")
    let parameter = parameterUrl[1].split("&")
    const supplierTsmId = parameter[0].split("=")[1]
    const countryServed = parameter[1].split("=")[1]
    const supplierName = parameter[1].split("=")[1]

    let esgstatus = null;
    let assignedToEmail = null;
    let questionnaireId =null

    const url = `${environment.susHubESGServiceURL}/esg-questionnaire/current/assessment?supplierTsmId=${supplierTsmId}&countryServed=${countryServed}`;
    const data:any = await this.httpService.GetPromise(url);

    const  dataStatus ={
      supplierTsmId : supplierTsmId,  
      country : countryServed,
      buyerTsmId:"c8aa5c00-588a-44d6-a08c-071dc9219cbe"
     }
    esgstatus = await this.supplierService.getAssessmentStatus(dataStatus);
    assignedToEmail = esgstatus.assignedToEmail;
    if(data){
      questionnaireId = data.questionnaireId;
    }else{
      questionnaireId = esgstatus.esgQuestionnaireId
    }
    //8ae539af-772a-4fd6-ab07-9a4dc4f92056?supplierName=yutest1201 
    this.router.navigate(["/esg-questionnaire/survey-getting-started", questionnaireId], {
        queryParams: { supplierName: supplierName,
          countryServed:countryServed,
          supplierTsmId:supplierTsmId,
          assignedToEmail:assignedToEmail }
    })
  }
}