import { ChangeDetectorRef } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SubSupplier } from '@lsl16/sustainability-shared-components';
import { CustomStyles } from 'src/app/models/Styles';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HeaderEvents, HeaderService } from 'src/app/services/header/header-service';

type historicalSubSupplierLiterals = {
  rejectionReasonTitle: string;
}
@Component({
  selector: 'tsm-historical-sub-supplier-item',
  templateUrl: './historical-sub-supplier-item.component.html',
  styleUrls: ['./historical-sub-supplier-item.component.sass']
})
export class HistoricalSubSupplierItemComponent implements OnInit {
  @Input() item: SubSupplier;
  @ViewChild("rejectReasonContainer", { static: false }) rejectReasonContainer: ElementRef;

  labelTranslations: historicalSubSupplierLiterals;
  relationStatusStyles: CustomStyles;
  tooltipMessage: string;
  isTextClamped: boolean = true;
  displayLink: boolean = false;

  constructor(
    public authService: AuthService,
    private headerService: HeaderService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscribeEvents();
    this.loadTranslations();
    this.setStyles();
    this.shouldLinkDisplayed();
  }

  ngAfterViewInit() {
    this.isTextClamped = this.rejectReasonContainer.nativeElement.scrollHeight > this.rejectReasonContainer.nativeElement.clientHeight;
    this.cdRef.detectChanges();
  }

  loadTranslations() {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multiLanguageJson) {
        this.labelTranslations = multiLanguageJson.body.main.subSupplier;
        this.labelTranslations = { ...this.labelTranslations, ...multiLanguageJson.questionnire.reviewSummary.reviewSummaryHeader };
        this.tooltipMessage = `<div><b>${this.labelTranslations.rejectionReasonTitle}</b></div>${this.item.rejectionReason} `
      }
    }
  }

  subscribeEvents() {
    this.headerService.eventEmit.subscribe((event) => {
      if (event === HeaderEvents.multiLanguageChanged) {
        this.loadTranslations();
        this.setStyles();
      }
    });
  }

  setStyles() {
    this.relationStatusStyles = {
      label: this.getStatusText(),
      valueStyle: { 'background-color': '#F6E9E9', 'color': '#B10B02' }
    }
  }

  getStatusText(): string {
    if (this.labelTranslations && this.labelTranslations[this.item.status.toLowerCase()]) {
      return this.labelTranslations[this.item.status.toLowerCase()];
    } else {
      return this.item.status;
    }
  }

  private shouldLinkDisplayed(): void {
    const userRole = this.authService.getRole();
    if (userRole === 'buyer' || userRole === 'authority') {
      this.displayLink = this.item.supplierTsmId != undefined;
    }
  }

  private navigateToSubSupplierProfile(): void {
    if (this.authService.getRole() !== 'supplier') {
      const redirectUrl = `supplier-profile/${this.item.supplierTsmId}`;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([redirectUrl]);
    }
  }
}
