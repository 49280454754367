<div *ngIf="ownToken; else loadEffect">
    <app-dashboard *ngIf="isShowWelcomePage && role === 'buyer'"></app-dashboard>
    <app-dashboard *ngIf="isShowWelcomePage && role === 'authority'"></app-dashboard>
    <tsm-supplier-profile-container *ngIf="role === 'supplier'"></tsm-supplier-profile-container>
</div>
<ng-template #loadEffect>
  <div style="min-height: 100px"></div>
  <div class="sk-chase">
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
  </div>
</ng-template>
