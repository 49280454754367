import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FilterValue } from './../services/esgReport/esg-report.service';
import { FilterType } from "../services/esgReport/esg-report.service";
import { SupplierCardType } from "./SupplierCard";
import { GeneralSupplierType } from '@lsl16/sushub-frontend';

export type CategoryFilterType = {
  filter: FilterNameType;
  count: number;
};

export type FilterNameType = "All" | "Active" | "Pending" | "Terminated";

export type SearchReturnType = {
  numberOfPages: number;
  suppliers: GeneralSupplierType[];
  categoryFilters: CategoryFilterType[];
};

export type AdvancedSearchType = SupplierCardType & {
  assessmentStatus?: [];
  credentials?: [
    {
      id: string;
      label: string;
      value: boolean;
    }
  ];
  ESGPerformance?: [
    {
      id: string;
      label: string;
      value: boolean;
    },
    {
      id: string;
      label: string;
      value: boolean;
    },
    {
      id: string;
      label: string;
      value: boolean;
    }
  ];
  categoryFilter?: FilterNameType;
};
export type MultiItem = {
  id: string;
  label: string;
  value: boolean;
};

export enum SearchTriggerEnum {
  CategoryTrigger = "categoryTrigger",
  PageChangeTrigger = "pageChangeTrigger",
  QuickSearchTrigger = "quickSearchTrigger",
  FirstLoadTrigger = "firstLoadTrigger",
  AdvancedSearchTrigger = "advancedSearchTrigger",
  UseCacheSearchTrigger = "useCacheSearchTrigger",
  Other = "other"
}

export type ReportSearchType = {
  [index in FilterType]?: FilterValue | null;
};

export function isNgbDateStruct(obj: any): obj is NgbDateStruct {
  const value = obj as NgbDateStruct;
  return value.day !== undefined
    && value.month !== undefined
    && value.year !== undefined
}