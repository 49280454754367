
import {Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges, ChangeDetectorRef} from "@angular/core";
import {ElementRef, HostListener, ViewChild} from "@angular/core";
import {environment} from "src/environments/environment";
import {AuthService} from "src/app/services/auth/auth.service";
import { HttpService } from "@lsl16/sustainability-shared-components";

@Component({
  selector: "tsm-select-drop-down-box-admin",
  templateUrl: "./select-drop-down-box-admin.component.html",
  styleUrls: ["./select-drop-down-box-admin.component.sass"]
})

export class SelectDropDownBoxAdminComponent implements OnInit, OnChanges {
  @Output() private reaload = new EventEmitter();
  @Input() profileData: any;
  @Input() eventAdmin: any;
  @Input() supplierNameForSystemSupplierAdmin: string;
  @Input() isSubsidiaryAccessingParent: boolean;
  defaultProfile: string = "";
  gradeOptions: any = [];
  dropDownOpen: boolean = false;
  data: any = [];
  baseUrl: string = "";
  isSystemSupplierAdmin: boolean;
  isSupplierAdmin: boolean;
  supplierSelectDropDownBox = {label:''};
  @ViewChild("inputPanel") inputPanel: ElementRef;

  constructor(
    private httpService: HttpService,
    public authService: AuthService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.baseUrl = `${environment.tsmBackendServiceURL}/userprofile`;
    this.isSystemSupplierAdmin = this.authService.isSystemSupplierAdmin();
    this.isSupplierAdmin = this.authService.isSupplierAdmin();
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes["profileData"]) {
      this.profileData = changes["profileData"]["currentValue"];
      if (this.profileData && this.profileData.profileData !== undefined) {
        this.defaultProfile = this.profileData.profileData[this.profileData.extension_tsm_defaultProfile].profileName;
        this.setOptions();
      }
    }
    if (changes["eventAdmin"]) {
      this.defaultProfile = changes["eventAdmin"]["currentValue"];
      console.log(changes["eventAdmin"]);
    }
    if (changes["supplierNameForSystemSupplierAdmin"]) {
      this.defaultProfile = changes["supplierNameForSystemSupplierAdmin"]["currentValue"];
    }
  }

  setmodel(item) {
    for (let key in this.profileData.profileData) {
      if (this.profileData.profileData[key].profileName === item) {
        if (this.isSupplierAdmin || this.authService.isGeneralSupplier()) {
          this.defaultProfile = item;
        }
      }
    }
    this.dropDownOpen = false;
    for (let key in this.profileData.profileData) {
      if (this.profileData.profileData[key].profileName === item) {
        this.getprofile(key);
      }
    }
  }

  async getprofile(extension_tsm_defaultProfile): Promise<any> {
    const b2cUser = this.authService.decodeToken();
    const data = {
      name: "",
      pocEmail: b2cUser.email,
      extension_tsm_defaultProfile: extension_tsm_defaultProfile,
    };
    if (!this.isSupplierAdmin) {
      data.name = this.defaultProfile;
    }
    this.reaload.emit(data);
  }

  async dropdownopen() {
    await this.reloadProfile();
    this.dropDownOpen = !this.dropDownOpen
  }

  async reloadProfile(): Promise<any> {
    const b2cUser = this.authService.decodeToken();
    const queryUserProfileUrl = `${this.baseUrl}/getUserprofile/${b2cUser.email}`;
    this.profileData = await this.httpService.GetPromise(queryUserProfileUrl);
    this.setOptions();
  }

  setOptions() {
    this.gradeOptions = [];
    for(let key  in  this.profileData.profileData){
      this.gradeOptions.push(this.profileData.profileData[key].profileName)
    }
  }

  mouseleave() {
    // this.dropDownOpen = false;
  }
  
  @HostListener("document:click", ["$event"])
  documentClick(event) {
    if (this.inputPanel && this.inputPanel.nativeElement.contains(event.target)) {
      return;
    }
    this.dropDownOpen = false;
  }
  ngAfterContentChecked(): void {
    if (localStorage.getItem("multiLanguage")) {
        const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
        if (multilanguageJson && multilanguageJson.body.main.selectDropDownBox) {
            this.supplierSelectDropDownBox = multilanguageJson.body.main.selectDropDownBox;
        }
    }
    this.cdRef?.detectChanges();
  }
}

