<div  class="modal-header" >
    <h4 class="modal-title">Standard Process</h4>
</div>
<div class="modal-body">
    <div class="modal-body-content">
      <div class="modal-body-item">
        <div class="modal-body-item-img">
          <img src="assets/icons/New_Action.svg" class="modal-icon" alt=""/>
        </div>
        <div class="modal-body-item-after"></div>
      </div>
      <div class="modal-body-item-title">
        Pending Requestor Submission
        <div class="modal-body-item-title-comment">
          Requestor is processing the form
        </div>
      </div>
    </div>
    <div class="modal-body-content">
      <div class="modal-body-item">
          <div class="modal-body-item-img">
              <img src="assets/icons/Action.svg" class="modal-icon" alt=""/>
          </div>
          <div class="modal-body-item-after"></div>
        </div>
        <div class="modal-body-item-title">
          Pending Supplier Action
          <div class="modal-body-item-title-comment">
            Requestor has completed the form
          </div>
        </div>
    </div>
    <div class="modal-body-content">
      <div class="modal-body-item">
          <div class="modal-body-item-img">
              <img src="assets/icons/Action.svg" class="modal-icon" alt=""/>
          </div>
          <div class="modal-body-item-after"></div>
        </div>
        <div class="modal-body-item-title">
          Pending Supplier Submission
          <div class="modal-body-item-title-comment">
            Supplier accepted the invitation, pending submission
          </div>
        </div>
    </div>
    <div class="modal-body-content">
      <div class="modal-body-item">
          <div class="modal-body-item-img">
              <img src="assets/icons/Action.svg" class="modal-icon" alt=""/>
          </div>
          <div class="modal-body-item-after"></div>
        </div>
        <div class="modal-body-item-title">
          Pending Internal Review
          <div class="modal-body-item-title-comment">
            The onboarding form is undergoing AP review
          </div>
        </div>
    </div>
    <div class="modal-body-content">
      <div class="modal-body-item">
          <div class="modal-body-item-img">
              <img src="assets/icons/Action.svg" class="modal-icon" alt=""/>
          </div>
          <div class="modal-body-item-after"></div>
        </div>
        <div class="modal-body-item-title">
          Pending Risk Result
          <div class="modal-body-item-title-comment">
            The onboarding form is undergoing Risk Assessment
          </div>
        </div>
    </div>
    <div class="modal-body-content">
      <div class="modal-body-item">
          <div class="modal-body-item-img">
              <img src="assets/icons/Onboarded.svg" class="modal-icon" alt=""/>
          </div>
          <div class="modal-body-item-after modal-body-item-last"></div>
        </div>
        <div class="modal-body-item-title">
          Onboarded
          <div class="modal-body-item-title-comment">
            The onboarding process has been completed
          </div>
        </div>
    </div>
    <div class="modal-body-content">
      <div class="modal-body-item">
          <div class="modal-body-item-img">
              <img src="assets/icons/Cancelled.svg" class="modal-icon" alt=""/>
          </div>
        </div>
        <div class="modal-body-item-title">
          Cancelled
          <div class="modal-body-item-title-comment">
            The onboarding process has been terminated
          </div>
        </div>
    </div><br>
    <div class="modal-body-content">
      <div class="modal-body-item">
          <div class="modal-body-item-img">
              <img src="assets/icons/Deactivated.svg" class="deactivated-icon" alt=""/>
          </div>
        </div>
        <div class="modal-body-item-title">
          Deactivated
          <div class="modal-body-item-title-comment">
            The supplier onboarding profile has been deactivated <br>as all linked SAP IDs are blocked.
          </div>
        </div>
    </div><br>
    <div class="d-flex">
        <sh-button ngbAutofocus  [props]="buttonProps1" style="width: 120px;"> </sh-button>
    </div>
</div>