import { Component, Input, OnInit } from "@angular/core";
import { SupplierCard } from "@lsl16/sustainability-shared-components";
import { countryList } from "src/utilities/const/country-list";
import { countryServedList } from "src/utilities/const/country-served";
import { CountryServedService } from "../../services/countryServedData/country-served.service";

@Component({
    selector: "tsm-supplier-card-list",
    templateUrl: "./supplier-card-list.component.html",
    styleUrls: ["./supplier-card-list.component.sass"]
})
export class SupplierCardListComponent implements OnInit {
    @Input() suppliers: SupplierCard[];
    @Input() enableIndirectTag: boolean = false;

    constructor(
        private countryServedService: CountryServedService
    ) { }

    async ngOnInit(): Promise<void> {
        countryList['Data'] = await this.countryServedService.countryList();
    }
}
