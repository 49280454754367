import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportService } from '@lsl16/sustainability-shared-components';
import { Subject } from 'rxjs';
import { SupplierRelationshipService } from "@lsl16/sustainability-shared-components";

@Component({
  selector: 'tsm-market-unit-filter',
  templateUrl: './market-unit-filter.component.html',
  styleUrls: ['./market-unit-filter.component.sass']
})
export class MarketUnitFilterComponent implements OnInit {
  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;

  values: any[];
  marketUnit: any[];

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }

  constructor(private reportService: ReportService) { }

  async ngOnInit(): Promise<void> {
    this.marketUnit = await this.reportService.getAllMarketUnit();
    this.clearFilter.subscribe(() => this.clear());
  }

  dropdownChanged(selectedValues) {
    this.values = selectedValues;
    this.valueChanged.emit(this.values);
  }

  clear() {
    this.values = [];
    this.marketUnit.forEach(mu => {
      mu.checked = false;
    });
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.marketUnit;
      }
    }
  }
}
