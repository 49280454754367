<div *ngIf="opened" class="modal-header">
    <h4 class="modal-title">{{ modalData.title }}</h4>
</div>
<div *ngIf="opened" class="modal-body">
    <div class="modal-body-content">
        <span style="margin-left: 10px;" class="modal-body-content-text">{{modalData.content}}</span>
    </div>
    <div class="d-flex flex-row-reverse">
        <sh-button ngbAutofocus [props]="buttonProps2"> </sh-button>
    </div>
</div>