import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';

@Component({
  selector: 'tsm-pop-up-custom-grid-table',
  templateUrl: './pop-up-custom-grid-table.component.html',
  styleUrls: ['./pop-up-custom-grid-table.component.sass']
})
export class PopUpCustomGridTableComponent implements OnInit {
  @Input() allColumns;
  @Input() columns;
  @Output() dataEmitted = new EventEmitter<any>();
  buttonProps1: ButtonPropsType = {
    label: 'Back',
    backgroundColor: 'white',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    borderColor: 'purple',
  };

  buttonProps2: ButtonPropsType = {
    label: 'Save',
    backgroundColor: 'white',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    borderColor: 'purple',
    onClick: () => {
      this.updateColumns();
    }
  };

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    const tsmRoot  =  document.getElementsByTagName("tsm-root")[0] as HTMLElement;
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
    this.buttonProps1.onClick = this.activeModal.close;
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  resetColumns(){
    this.columns=this.allColumns
  }

  updateColumns(){
    this.dataEmitted.emit(this.columns);
  }
}
