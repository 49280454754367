import { Component, Input, OnInit } from '@angular/core';
import { ParentChildHistoryItem } from '@lsl16/sustainability-shared-components';
import { isEmpty } from "lodash";

type parentChildHistoryLiterals = {
  SubsidiaryContinuedWithoutParent: string,
  SubsidiaryRejectedProposedParent: string,
  SubsidiaryConfirmedParent: string,
  SubsidiaryInvitedParent: string,
  ParentRejectedSubsidiary: string,
  ParentConfirmedSubsidiary: string,
  SubsidiaryWithdrewRequest: string,
  ParentCancelledRelationship: string,
  SubsidiaryCancelledRelationship: string,
  BuyerRejectedParentInvite: string,
  BuyerConfirmedParentInvite: string,
  rejectReason: string,
  comment: string,
  na: string
}

@Component({
  selector: 'tsm-parent-child-history-item',
  templateUrl: './parent-child-history-item.component.html',
  styleUrls: ['./parent-child-history-item.component.sass']
})
export class ParentChildHistoryItemComponent implements OnInit {
  @Input() item: ParentChildHistoryItem;
  initJson: parentChildHistoryLiterals;
  commentProps: any = {
    tooltip: true,
    defaultIcon: './assets/icons/info-blue.svg',
    activeIcon: './assets/icons/info.svg'
  }
  isParent: boolean;
  isCommentEnabled: boolean;

  constructor() { }

  ngOnInit(): void {
    this.initMultiLanguage();
    this.updateCommentProps();
    this.isParent = !isEmpty(this.item.parent);
    this.isCommentEnabled = !isEmpty(this.item.payload.rejectReason) || !isEmpty(this.item.payload.comment);
  }

  ngAfterContentChecked(): void {
    this.initMultiLanguage();
    this.updateCommentProps();
  }

  updateCommentProps() {
    if (this.item.payload?.rejectReason) {
      const commentHeading = this.item.payload.event === 'ParentRejectedSubsidiary' ? this.initJson.rejectReason : this.initJson.comment;
      this.commentProps = {
        ...this.commentProps,
        tooltipMessage: `<div><b>${commentHeading}</b></div>${this.item.payload.rejectReason} `
      }
    }
    else if (!isEmpty(this.item.payload.comment)) {
      this.commentProps = {
        ...this.commentProps,
        tooltipMessage: `<div><b>${this.initJson.comment}</b></div>${this.item.payload.comment} `
      }
    }
  }

  initMultiLanguage() {
    const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
    this.initJson = JSON.parse(JSON.stringify(multiLanguageJson.body.main.parentChildHistory));
  }

  translate(action: string): string {
    return this.initJson[action] || '';
  }
}
