<div class="container">
  <div class="row">
    <div class="col-5">
      <div class="parent-label-container">
        <img
          class="icon"
          src="assets/images/Group-InActive.svg"
          alt="icon-parent"
        />
        <p id="parentNameLabel" class="parent-label">
          {{ labelTranslations.parent }}
        </p>
      </div>
      <div (mouseenter)="showParentTitle()"
      (mouseleave)="hideParentTitle()">
        <div id="parentNameContent"  class="tooltip-body"  *ngIf="!showLinkText()" class="parent-name">
          {{ parentData.name.length > 10 ? parentData.name.slice(0, 10) + '...' : parentData.name }}
        </div>
        
        <a id="parentNameContentLink"
          *ngIf="showLinkText()"
          (click)="navigateToParentProfile()"
          class="parent-name-link"
          >{{parentData.name | slice:0:25 }} ...
          </a
        > 
        <div *ngIf="showParentTitleFlag" class="tooltip-body-title">
          {{parentData.name}}
        </div>
      </div>
      
      <div id="parentCountry" class="parent-country">{{ parentData.country }}</div>
      <div class="relationship-status">
        <sh-parent-relationship-status
          [supplierTsmId]="supplierTsmId"
        ></sh-parent-relationship-status>
      </div>
      <sh-button
        id="revokeRelationshipButton"
        *ngIf="showRevokeRelationshipButton"
        [props]="revokeRelationshipButtonProps"
      ></sh-button>
    </div>
    <div class="col-7">
      <div class="row">
        <div class="col-6 col-lg-4">
          <div class="info-container">
            <p class="info-label">{{ labelTranslations.parentDuns }}</p>
            <div id="parentDunsNumber" class="info-content">{{ parentData.dunsNumber }}</div>
          </div>
          <div class="info-container">
            <p class="info-label">{{ labelTranslations.website }}</p>
            <div class="info-content">
              <div  (mouseenter)="showWebsite()"
              (mouseleave)="hideWebsite()"  >
              <a id="parentWebsite" class="info-content-a" href="{{ parentData.website }}">
                {{ parentData.website.length > 10 ? parentData.website.slice(0, 10) + '...' : parentData.website }}

               </a>
            </div>
              <div *ngIf="showWebsiteFlag" class="tooltip-body">
                {{ parentData.website }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-4">
          <div class="info-container">
            <p class="info-label">{{ labelTranslations.parentStatus }}</p>
            <div id="parentStatus" class="info-content">{{ parentData.parentStatus }}</div>
          </div>
          <div class="info-container">
            <p class="info-label">{{ labelTranslations.subsidiaryStatus }}</p>
            <div id="childStatus" class="info-content">{{ parentData.childStatus }}</div>
          </div>
        </div>
        <div class="col-lg-4">
          <div
            *ngIf="parentChildRelation?.parentStatus === 'Rejected'"
            class="info-container rejection-column"
          >
            <p id="rejectReasonLabel" class="info-label">
              {{ labelTranslations.rejectReason }}
            </p>
            <div id="rejectReasonContent" class="info-content long-text">
              {{ parentChildRelation.rejectReason }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
