<div class="container-fluid mb-5">
    <div class="wel-row">
        <div class="wel-row-card">
            <div class="tsm-card">
                <!-- <p class="tsm-title-one">Welcome to the </p> -->
                <h5 class="tsm-title-two">Welcome to True Supplier Marketplace</h5>
                <div class="tsm-title-content">
                    As a Buyer, you can manage your suppliers and supply chain all in one place.
                    This includes onboarding suppliers and sending sustainability assessments.
                </div>
            <div class="row row-cols-auto">
                <input class="checkbox" type="checkbox" aria-label="checkbox" ng-true-value="true" ng-false-value="false" [(ngModel)]="isDonotShowPage"/>
                <p class="checkboxText">Do not show this page next time</p>
            </div>
                <div class="startButton">
                    <sh-button [props]="startButton" class="pe-4"></sh-button>
                </div>
            </div>
        </div>
        <div>
            <div class="tsm-video">
                <iframe class="iframe-container" loop="1" src="https://www.youtube.com/embed/yY3O5SN4jVY?si=-K1b19K9ceEfsVQK&rel=0&border=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="pictureArea">
                <img class="picture" src="assets/images/image-forest.png" alt="image-landing-page-forest" />
                <div class="textArea" style="margin-left: 30px;">
                    <span class="text-one">Accenture plans to become a market leader in sustainability</span>
                    <span class="text-two">Net Zero by 2025</span>
                    <ul class="text-three">
                        <li>We've committed to achieve net-zero emissions by 2025</li>
                        <li>Move to zero waste</li>
                        <li>Plan for water risk</li>
                    </ul>
                    <a class="text-four" target="_blank"
                        href="https://www.accenture.com/gb-en/about/corporate-citizenship/environment-charts">
                        Learn more here
                        <img src="assets/icons/icon-vector.svg" style="margin-left: 10px;" alt="icon-vector" />
                    </a>
                </div>
            </div>
        </div>
        
    </div>
</div>