<div *ngIf="!noRecordsFound">

<div class="title">{{ labelTranslations.title }}</div>

<div class="sub-supplier-container">
    <div class="row itemlist"  [ngClass]="{'grid-scroll-right': historicalSubSupplieritems && historicalSubSupplieritems.length > 10}">
      <div class="col width-10">
        <div class="subtitle">{{ labelTranslations.name }}</div>
      </div>
      <div class="col width-10">
        <div class="subtitle">{{ labelTranslations.location }}</div>
      </div>
      <div class="col width-15">
        <div class="subtitle">{{ labelTranslations.pointOfContact }}</div>
      </div>
      <div class="col width-10">
        <div class="subtitle">{{ labelTranslations.countriesServed }}</div>
      </div>      
      <div class="col fixed-width-col">
        <div class="subtitle">{{ labelTranslations.relationStatus }}</div>
      </div>
      <div class="col width-25">
        <div class="subtitle">{{ labelTranslations.rejectionReason }}</div>
      </div>
    </div>

    <div sushubGridScroll [rows]="10">
      <div class="row-item" *ngFor="let historicalSubSupplier of historicalSubSupplieritems">
        <tsm-historical-sub-supplier-item [item]="historicalSubSupplier"> </tsm-historical-sub-supplier-item>
      </div>
    </div>
  </div>
</div>