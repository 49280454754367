import { Component, OnInit } from '@angular/core';
import { BaseSupplierFilterComponent } from 'src/app/components/base-filter/base-supplier-filter.component';
import { FilterData, FilterType, fromToDateFilter, ReportType } from 'src/app/services/esgReport/esg-report.service';

@Component({
  selector: 'tsm-request-multiple-sa-search',
  templateUrl: './request-multiple-sa-search.component.html',
  styleUrls: ['./request-multiple-sa-search.component.sass']
})
export class RequestMultipleSaSearchComponent extends BaseSupplierFilterComponent implements OnInit {

  private filtersToApply: FilterType[] = ['supplierName', 'marketUnit', 'countryServed', 'requestorEmail', 'esgStatus', 'requestPurpose', 'esgSubmissionDateFrom', 'esgSubmissionDateTo', 'requestDateFrom', 'requestDateTo', 'includeIndirect', 'supplierCategory'];
  private emailTypes: FilterType[] = ['requestorEmail'];
  private dateTypes: fromToDateFilter[] = [{ from: 'esgSubmissionDateFrom', to: 'esgSubmissionDateTo' },
  { from: 'requestDateFrom', to: 'requestDateTo' }]
  reportType: ReportType = "supplier_records";

  constructor() {
    super()
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initButtons();
    this.initFilterCriteria();
  }

  initFilterCriteria() {
    this.filterCriteria = {
      supplierName: "",
      marketUnit: null,
      countryServed: null,
      requestorEmail: "",
      esgStatus: null,
      requestPurpose: null,
      esgSubmissionDateFrom: null,
      esgSubmissionDateTo: null,
      requestDateFrom: null,
      requestDateTo: null,
      includeIndirect: false
    };
    this.filterCriteriaPayload = {
      supplierName: "",
      marketUnit: null,
      countryServed: null,
      requestorEmail: "",
      esgStatus: null,
      requestPurpose: null,
      esgSubmissionDateFrom: null,
      esgSubmissionDateTo: null,
      requestDateFrom: null,
      requestDateTo: null,
      includeIndirect: false
    }
  }

  initButtons() {
    this.clearFilterButton = {
      label: "",
      color: "purple",
      clickBorderColor: "purple",
      backgroundColor: "lightPurple",
      hoverBorderColor: "purple",
      fontSize: 16,
      height: 48,
      width: 180,
      onClick: async () => {
        this.clearFilters();
      }
    };
    this.applyFilterButton = {
      label: "",
      color: "white",
      fontSize: 16,
      backgroundColor: "blue",
      borderColor: "blue",
      height: 48,
      width: 180,
      onClick: async () => {
        this.applyFilters();
      }
    };
  }

  applyFilters() {
    this.invalidFields = [];
    this.filterCriteriaPayload = { ...this.filterCriteria };
    this.emailTypes.forEach((filter) => {
      this.checkEmail(filter);
    });

    this.dateTypes.forEach((filter) => {
      this.validateDates(filter);
      this.formatPayloadDate(filter);
    });

    if (Object.keys(this.invalidFields).length > 0) {
      return
    }
    const filterData: FilterData[] = this.filtersToApply.filter(filter => this.filterCriteriaPayload[filter])
      .map(filter => ({
        filterName: filter,
        filterValue: this.filterCriteriaPayload[filter]
      }));
    this.setFilterButtonsDisabledProps(false);
    this.applyFilterEventEmitter.emit({ reportType: this.reportType, filters: filterData });
    this.scheduleWaitingMessage();
  }

  clearFilters() {
    this.initFilterCriteria();
    this.clearFilter.next(true);
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {        
        this.applyFilterButton.label = multilanguageJson.body.main.reportFilters.applyFilter;
        this.clearFilterButton.label = multilanguageJson.body.main.reportFilters.clearFilter;
        this.waitingMessageLabel = multilanguageJson.body.main.reportFilters.waitingMessage;
      }
    }
  }

}
