import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';

@Component({
  selector: 'tsm-pop-up-status',
  templateUrl: './pop-up-status.component.html',
  styleUrls: ['./pop-up-status.component.sass'],
})
export class PopUpStatusComponent implements OnInit {
  buttonProps1: ButtonPropsType = {
    label: 'Back',
    backgroundColor: 'white',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    borderColor: 'purple',
  };

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    const tsmRoot  =  document.getElementsByTagName("tsm-root")[0] as HTMLElement;
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
    this.buttonProps1.onClick = this.activeModal.close;
  }
}
