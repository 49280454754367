<div class="modal-header">
    <h4 class="modal-title" [ngStyle]="{'display': showSaveBtn ? 'block' : 'none'}">{{EditorTitle}}</h4>
    <h4 class="modal-title" [ngStyle]="{'display': !showSaveBtn ? 'block' : 'none'}">{{EditorSuccess}}</h4>
</div>
<div class="modal-body">
    <div class="body-content">
        <div class="modal-body-content" [ngStyle]="{'display': showSaveBtn ? 'block' : 'none'}">
            <div class="modal-body-content">
                <label class="select-label" for="category">{{emailTypeLabel}} <span class="red">{{tip}}</span> </label>
                <div [title]="emailTypeInfo" rows="5" cols="90" maxlength="500">{{emailTypeInfo}}</div>
            </div>
            <div class="modal-body-content">
                <label class="select-label" for="question">{{questionLabel}} <span class="red">{{tip}} </span> </label>
                <textarea class="question-input" [title]="subjectInfo" [(ngModel)]="subjectInfo" rows="5" cols="90"
                    maxlength="500" (ngModelChange)="textChange()"></textarea>
            </div>
            <div class="modal-body-content">
                <label class="select-label" for="answer">{{answerLabel}} <span class="red">{{tip}}</span></label>
                <textarea class="textarea-text" [title]="templateInfo" [(ngModel)]="templateInfo" rows="5" cols="90"
                    (ngModelChange)="textChange()">{{templateMessage}}</textarea>
                <div></div>
            </div>
            <div class="modal-body-content">
                <label class="select-label" for="question">Preview </label>
                <div class="preview-text" [(ngModel)]="templateInfo" [innerHtml]="templateInfo | html"
                    contenteditable="false"></div>
            </div>
        </div>

        <div *ngIf="isPublish" class="modal-body-content">
            <p class="deleteFaq">{{publishTip}}</p>
        </div>
        <div class="button-content" [ngStyle]="{'display': showSaveBtn ? 'block' : 'none'}">
            <sh-button ngbAutofocus [props]="cancelProps"></sh-button>
            <sh-button class="saveButton" ngbAutofocus [props]="saveProps"></sh-button>
        </div>
        <div class="button-content" [ngStyle]="{'display': !showSaveBtn ? 'block' : 'none'}">
            <sh-button ngbAutofocus [props]="closeProps"></sh-button>
        </div>
    </div>
</div>