import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tsm-termination-request-log',
  templateUrl: './termination-request-log.component.html',
  styleUrls: ['./termination-request-log.component.sass']
})
export class TerminationRequestLogComponent implements OnInit {
  @Input() items: Array<any>;
  @Input() currentPage: number;

  constructor() { }

  ngOnInit(): void { }
}
