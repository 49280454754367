import { Injectable } from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class LeaveDashboardSearch {
  canDeactivate(): boolean {
    sessionStorage.setItem('dashBoardSearchScrollTop', JSON.stringify(document.documentElement.scrollTop))
    return true;
  }
}
