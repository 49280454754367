<sh-alert [ngStyle]="{'display': successAlertVisible ? 'block' : 'none'}" [props]="successAlert" id="alertSuccess"></sh-alert>
<sh-alert [ngStyle]="{'display': failureAlertVisible ? 'block' : 'none'}" [props]="failureAlert" id="alertFail"></sh-alert>
<tsm-subheader [props]= "reportProps" id="header"></tsm-subheader>
<div class="container-fluid mb-5"  id="body">
  <div class="main_bg">
    <div class="container container-fluid first-container d-flex">
      <div class="form shadow-sm m-5 p-5">
        <div class="form-group">
          <form style="display: flex; width: 100%;">
            <div class="col-6">
              <tsm-download-report-date-filter
              [invalidValue]="invalidValue"
              (dateFromChanged)="
                filterCriteriaChanged($event, 'esgSubmissionDateFrom')
              "
              (dateToChanged)="
                filterCriteriaChanged($event, 'esgSubmissionDateTo')
              "
              >
              </tsm-download-report-date-filter>
            </div>
            <div class="align-self-end" style="margin-left:auto;">
              <sh-button [props]="downloadFilterButton"></sh-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
    
</div>