import { Component, OnInit, ViewChild } from '@angular/core';
import { MetadataService } from "../../services/metadata/metadata.service";
import {
  ButtonPropsType,
  ComponentConfig,
  DynamicContext,
  DynamicFormComponent,
  Localization,
  ProposeParentInvitePayloadModel,
  SubSupplierPayload,
  SubSupplierRelationshipService,
  SupplierRelationshipService,
  TaxTypeService
} from '@lsl16/sustainability-shared-components';
import { Observable, Subscription } from 'rxjs';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSupplierType } from '@lsl16/sushub-frontend';
import { SupplierGeneralProfileService } from 'src/app/services/supplier-general-profile.service';
import { FormControl, FormGroup } from '@angular/forms';


export enum InviteType {
  Parent,
  SubSupplier
}

type inviteNewSupplierModalLiterals = {
  cancelBtn: string;
  backBtn: string;
  confirmAndSaveBtn: string;
  infoMessage: string
}

@Component({
  selector: 'tsm-invite-new-supplier',
  templateUrl: './invite-new-supplier.component.html',
  styleUrls: ['./invite-new-supplier.component.sass']
})
export class InviteNewSupplierComponent implements OnInit {
  public newSupplierDunsNumber: string;
  public invitingSupplierTsmId: string;
  public proposedSupplier: string;
  public inviteType: InviteType;
  @ViewChild("form", { static: false }) form: DynamicFormComponent;
  configs: Array<ComponentConfig> = [];
  model: GeneralSupplierType;
  initJson: any;
  context: DynamicContext = new DynamicContext();
  public modalTranslation: inviteNewSupplierModalLiterals;
  public title: string;
  alltaxType: any;
  selectedCountry: string;
  taxItems: any;
  taxData: any;
  formValues: any;
  callingComponent: String = "tsm-invite-new-supplier";
  initialConfigs: Array<ComponentConfig> = [];
  countrySubscription: Subscription;
  countryLocationSubscription: Subscription;

  selected: Observable<string>;
  taxTypes: Array<ComponentConfig> = [];
  sharedButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    width: 115,
    height: 48,
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
  };

  confirmAndSaveButtonProps: ButtonPropsType = {
    label: 'Confirm and Save',
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    width: 225,
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    onClick: async () => {
      await this.save();
    }
  };

  cancelButtonProps: ButtonPropsType = {
    ...this.sharedButtonProps,
    label: 'Cancel',
    padding: '16px 20px',
    onClick: () => {
      this.ngbModalService.dismissAll();
    },
  };

  backButtonProps: ButtonPropsType = {
    ...this.sharedButtonProps,
    label: 'Back',
    padding: '16px 20px',
    onClick: () => {
      this.activeModal.close();
    },
  };

  closeBtnEvent(close: boolean) {
    if (close) {
      this.activeModal.close();
    }
  }

  constructor(
    private activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private supplierGeneralProfileService: SupplierGeneralProfileService,
    private supplierRelationshipService: SupplierRelationshipService,
    private subSupplierService: SubSupplierRelationshipService,
    private taxTypeService: TaxTypeService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initMultiLanguage();
    this.context.set("supplierTsmId", this.invitingSupplierTsmId);
    this.model = {
      ...this.model,
      dunsNumber: this.newSupplierDunsNumber,
    };
    this.selected = this.taxTypeService.selectedCountry$;

    this.countrySubscription = this.taxTypeService.selectedCountry$.subscribe(
      async (country) => {
        if (!country) {
          return;
        }
        this.selectedCountry = country;
        this.alltaxType = await this.taxTypeService.getCountryTaskType(country);
        this.resetTaxFields();

        let taxTypeStartIndex = this.configs.findIndex(
          (x) => x.fieldset[0].isDynamicTaxField
        );
        if (taxTypeStartIndex > -1) {
          this.configs.splice(taxTypeStartIndex, this.taxTypes.length);
          this.taxTypes = [];
        }
        this.taxTypes = [];
        const localTaxfeilds = [];

        for (let i = 0; i < this.alltaxType.taxData.length; i++) {
          if (this.alltaxType.taxData[i].isDropdown === true && this.alltaxType.taxData[i].isHide ===false) {
            this.configs.forEach((config) => {
              if (
                config.fieldset[0].name === "brnTaxIdType" ||
                config.fieldset[0].name === "brnTaxId"
              ) {
                config.fieldset[0].hide = false;
              }
            });

            break;
          }
        }

        this.alltaxType.taxData.forEach((tax) => {
          if (tax.isDropdown === true && tax.isHide===false) {
            localTaxfeilds.push({ Code: tax.taxType, Text: tax.taxType });
          } else if (tax.isDropdown !== true && tax.isHide!==true) {
            const cleanedTaxType = tax.taxType
              .replace(/[^\w ]/gi, '')
              .replace(/\s/g, '');
            let taxfield = {
              type: 'layout',
              fieldset: [
                {
                  index: '0',
                  questionSetId: 'cf511ac4-315b-459d-8d65-26c930fff86c',
                  section: 'gettingInviteNewSupplierConfig',
                  questionOrder: 7,
                  width: '100%',
                  required: false, // Making fields are not mandatory for new buyer supplier creation
                  templateOptions: {
                    tooltip: false,
                    canFilter: false,
                    label: tax.taxType,
                    placeholder: 'Insert ' + tax.taxType,
                  },
                  validateOn: 'change',
                  name: cleanedTaxType,
                  type: 'input',
                  isDynamicTaxField: true,
                },
              ],
              templateOptions: {},
            };
            this.configs.push(taxfield);
            this.taxTypes.push(taxfield);
          }
        });
        this.configs.forEach((config) => {
          if (config.fieldset[0].name === "brnTaxIdType") {
            config.fieldset[0].data = localTaxfeilds;
                           // Prepopulate if there is only one dropdown value
            if (localTaxfeilds.length === 1) {
            this.form.formGroup.controls['brnTaxIdType'].setValue(localTaxfeilds[0].Code);
                        }
          }
        });
        this.configs = [...this.configs];
        this.configs.sort((a, b) => {
          const orderA = a.fieldset[0].questionOrder || 0;
          const orderB = b.fieldset[0].questionOrder || 0;
          return orderA - orderB;
        });
        
      }
    );
    console.log("form",this.form)
    setTimeout(() => {
      this.countryLocationSubscription = this.form.formGroup.get('countryLocation')?.valueChanges.subscribe((value) => {
        if (!value) {
          this.resetTaxFields();
        }
      });
    }, 0);

  }
  resetTaxFields() {
    this.taxTypes.forEach(taxType => {
      let fieldName = taxType.fieldset[0].name;
     
      this.form.formGroup.get(fieldName)?.setValue(null);
      
      this.form.formGroup.removeControl(fieldName);
    });
    let taxTypeStartIndex = this.configs.findIndex(x => x.fieldset[0].isDynamicTaxField);
    if (taxTypeStartIndex > -1) {
      this.configs.splice(taxTypeStartIndex, this.taxTypes.length);
      this.taxTypes = [];
    }

    this.configs.forEach(config => {
      if (config.fieldset[0].name === "brnTaxIdType" || config.fieldset[0].name === "brnTaxId") {
        config.fieldset[0].hide = true;
        config.fieldset[0].data = [];
        this.form.formGroup.get(config.fieldset[0].name)?.setValue(null);

      }
    });

    this.configs = [...this.configs];

  }

  initMultiLanguage() {
    const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
    this.initJson = multiLanguageJson?.body.main.inviteNewSupplier;
    Localization.localizeControls(this.configs, this.initJson);
  }

  setButtonLabels() {
    this.cancelButtonProps.label = this.modalTranslation.cancelBtn;
    this.confirmAndSaveButtonProps.label =
      this.modalTranslation.confirmAndSaveBtn;
    this.backButtonProps.label = this.modalTranslation.backBtn;
  }

  buildProposeSubSupplierInvitePaylod(): SubSupplierPayload {
    const formControls = this.form.formGroup.controls;
    let countryServedValue = formControls.countryServed.value;
    if (typeof countryServedValue === "string") {
      countryServedValue = [formControls.countryServed.value];
    }
    const payload = {
      subSupplier: {
        supplierName: formControls.supplierName.value,
        companyLegalName: formControls.companyLegalName.value,
        companyWebsite: formControls.companyWebsite.value,
        location: formControls.countryLocation.value,
        companyDomainName: formControls.companyDomainName.value,
        dunsNumber: formControls.dunsNumber.value,
        countryServed: countryServedValue,
      },
      POC: {
        userName: formControls.primaryContactEmail.value,
        firstName: formControls.primaryContactFirstName.value,
        lastName: formControls.primaryContactLastName.value,
      },
      taxData: {
        brnTaxIdType: formControls.brnTaxIdType.value,
        brnTaxId: formControls.brnTaxId.value
      },
    };
    if (this.taxTypes.length > 0) {
      this.taxTypes.forEach((taxType) => {
        let fieldName = taxType.fieldset[0].name;
        let taxTypeValue = this.form.values()[fieldName]?.trim();
        payload.taxData[fieldName] = taxTypeValue;
      });
    }
    return payload;
  }

  buildProposeParentInvitePayload(): ProposeParentInvitePayloadModel {
    const formControls = this.form.formGroup.controls;
    let countryServedValue = formControls.countryServed.value;
    if (typeof countryServedValue === "string") {
      countryServedValue = [formControls.countryServed.value];
    }
    const payload = {
      childTsmId: this.invitingSupplierTsmId,
      proposedParent: this.proposedSupplier,
      host: window.location.origin,
      parent: {
        supplierName: formControls.supplierName.value,
        companyLegalName: formControls.companyLegalName.value,
        companyWebsite: formControls.companyWebsite.value,
        location: formControls.countryLocation.value,
        companyDomainName: formControls.companyDomainName.value,
        dunsNumber: formControls.dunsNumber.value,
        countryServed: countryServedValue,
      },
      POC: {
        userName: formControls.primaryContactEmail.value,
        firstName: formControls.primaryContactFirstName.value,
        lastName: formControls.primaryContactLastName.value,
      },
      taxData: {
        brnTaxIdType: formControls.brnTaxIdType.value,
        brnTaxId: formControls.brnTaxId.value
      },
    };
    if (this.taxTypes.length > 0) {
      this.taxTypes.forEach((taxType) => {
        let fieldName = taxType.fieldset[0].name;
        let taxTypeValue = this.form.values()[fieldName]?.trim();
        payload.taxData[fieldName] = taxTypeValue;
      });
    }
    return payload;
  }

  async save(): Promise<void> {
    try {
      if (this.form.validate()) {
        this.confirmAndSaveButtonProps.isProcessing = true;

        if (this.inviteType === InviteType.Parent) {
          const payload = this.buildProposeParentInvitePayload();
          await this.supplierRelationshipService.proposeParentInvite(payload);
        } else if (this.inviteType === InviteType.SubSupplier) {
          const payload = this.buildProposeSubSupplierInvitePaylod();
          await this.subSupplierService.proposeSubSupplierInvite(
            payload,
            this.invitingSupplierTsmId
          );
        } else {
          throw new console.error('unknown invite type');
        }

        this.supplierGeneralProfileService.eventEmit.emit('refreshSupplier');
        this.ngbModalService.dismissAll();
      }
    } catch (error) {
      console.error('Failed to invite new supplier');
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.modalTranslation = multilanguageJson.body.main.inviteNewSupplier;
        if (this.inviteType === InviteType.Parent) {
          this.title =
            multilanguageJson.body.main.inviteNewSupplier.titleParent;
        } else {
          this.title = multilanguageJson.body.main.inviteNewSupplier.title;
        }
        this.setButtonLabels();
      }
    }
  }
  ngOnDestroy(): void {
    if (this.countryLocationSubscription) {
      this.countryLocationSubscription.unsubscribe();
    }
    if (this.countrySubscription) {
      this.countrySubscription.unsubscribe();
    }
    this.formValues = this.form.formGroup.value;
    console.log('Form Values:', this.formValues);
    this.taxTypeService.updateSelectedCountry("");
}

}
