<div class="modal-header">
    <h4 class="modal-title">
      {{ modalTranslation.title}}
    </h4>
</div>
  <div class="modal-body">
    <div class="modal-body-content">
      <div class="reason-container">
          <div>
              <label class="reason-label" for="reason_sua">{{ modalTranslation.terminateReason }}</label>
              <span *ngIf="reasonRequired" style="color: #d30e0e">*</span>
              <tsm-select-input id="reason_sua"
                  [height]="inputHeight"
                  [fontSize]="fontSize"
                  [color]="color"
                  [drapDownData]="terminateReasonArr" 
                  [multiple]="false"
                  [dropDownViewOnly]="buttonDisabled"
                  (SingleDateSend)="getSingleDate($event)">
              </tsm-select-input>
          </div>
      </div>
  
      <div *ngIf="displayPage; else emptyPage">
          <div *ngIf="displayPage">
              <div class="changeConsent-msg-container">
                  <div class="infor-icon">
                      <img class="info-title-icon" src="assets/icons/info-blue.svg" alt="icon-update-sa" />
                  </div>            
                  <div class="infor-message" id="changeConsentInfoMsg">
                      {{ modalTranslation.confirmDelete }}
                  </div>
              </div>       
          </div>
      </div>
  
      <ng-template #emptyPage>
          <div class="changeConsent-msg-container">
          </div>
      </ng-template>
      
    </div>
    <div class="modal-buttons">
        <sh-button [props]="cancelButtonProps"></sh-button>
        <div *ngIf="displayPage === true && !loading" class="terminateBtn-container">
            <span class="terminate"
                [ngClass]="displayPage ? 'update-pop-up-button' : ''"
                (click)="submit()">
                <img class="terminate-iconF" src="assets/icons/icon-bin-white.svg" alt="delete-user-icon"/>&nbsp;{{  modalTranslation.terminateBtn }}
            </span>
        </div>
        <div *ngIf="displayPage === false || loading" class="terminateBtn-grey-container">
            <span class="terminate"
                [ngClass]="displayPage ? 'update-pop-up-button' : ''">
                <img class="terminate-iconS" src="assets/icons/icon-bin-white.svg" alt="delete-user-icon"/>&nbsp;{{  modalTranslation.terminateBtn }}
            </span>
        </div>
    </div>
  </div>

  <div class="loadEffect" style="position: fixed;left: 50%;top:30%;z-index: 1000;" *ngIf="loading">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
  </div>
