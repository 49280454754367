import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileConfig } from 'src/app/components/profile-details/profile';
import { SupplierService } from "src/app/services/supplier/supplier.service";
import { EventService } from "src/app/services/EventService.service";

type termReasonModalLiterals = {
  title: string;
  terminateReason: string;
  confirmDelete: string;
  cancelBtn: string;
  terminateBtn: string;
}

@Component({
  selector: 'tsm-pop-up-buyer-terminate-reason',
  templateUrl: './pop-up-buyer-terminate-reason.component.html',
  styleUrls: ['./pop-up-buyer-terminate-reason.component.sass']
})
export class PopUpBuyerTerminateReasonComponent implements OnInit {
  @Input() confirmType: ProfileConfig;
  modalTranslation: termReasonModalLiterals = {
    title : "Terminate",
    cancelBtn : "Cancel",
    terminateReason : "Reason for terminating the relationship with this supplier",
    confirmDelete : "Do you confirm to terminate the supplier for the selected reason?",
    terminateBtn : "Terminate"
  };
  displayPage: boolean;
  reason: string;
  reasonRequired: boolean = true;
  supplierInfo: any;
  submitData: any;
  inputHeight: string;
  fontSize: string;
  color: string;
  buttonDisabled: boolean = false;
  terminateReasonArr: any;
  duplicateSupplier: string;
  invalidOnboarding: string;
  createdByMistake: string;
  vendorRelationTerminated: string;
  agingRequest: string;
  loading: boolean = false;

  public cancelButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    width: 115,
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
    class: 'update-pop-up-button',
    onClick: () => {
      this.activeModal.close();
    },
  };
 
  constructor( 
    private activeModal: NgbActiveModal,
    private supplierService: SupplierService,
    private router: Router,
    private eventService: EventService
  ) { }

  async ngOnInit(): Promise<void> {
    this.terminateReasonArr = [
      {
        Code: "Duplicate supplier",
        Text: "Duplicate supplier",
      },
      {
        Code: "Invalid onboarding",
        Text: "Invalid onboarding",
      },
      {
        Code: "Created by mistake",
        Text: "Created by mistake",
      },
      {
        Code: "Vendor relation terminated",
        Text: "Vendor relation terminated",
      },
      {
        Code: "Aging request",
        Text: "Aging request",
      },
    ];
    
    this.duplicateSupplier = this.terminateReasonArr[0].Code;
    this.invalidOnboarding = this.terminateReasonArr[1].Code;
    this.createdByMistake = this.terminateReasonArr[2].Code;
    this.vendorRelationTerminated = this.terminateReasonArr[3].Code;
    this.agingRequest = this.terminateReasonArr[4].Code;
    this.displayPage = false;
    this.reasonRequired = true;
    this.inputHeight = "38px";
    this.fontSize = "15px";
    this.color = "#6964A7";
    this.supplierInfo = this.confirmType.supplier;
  }
  
  private setButtonLabels(): void {
    this.cancelButtonProps.label = this.modalTranslation.cancelBtn;
  }

  ngAfterContentChecked() {
    this.setButtonLabels();
  }

  cancelButtonDisable(): void{
    this.cancelButtonProps = {
        label: '',
        backgroundColor: 'gray',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Graphik-Medium',
        width: 115,
        height: 48,
        disabled: true,
        padding: '16px 24px',
        margin: '0 0 0 2px',
        hoverBackgroundColor: 'gray',
        hoverColor: 'white'
    };
  }

  getSingleDate(e: any) {
    this.displayPage = true;
    this.reason = e.Code;
  }

  async submit() {
    this.cancelButtonDisable();
    this.buttonDisabled = true;
    this.loading = true;
    if (this.displayPage) {
      // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.submitData = {
        "supplierTsmId": this.supplierInfo.supplierTsmId,
        "terminateReason": this.reason,
        // "createBy": userInfo.pocEmail,
      };
      const response = await this.supplierService.terminateSA(this.submitData);
      this.loading = false;
      if (response && response.code === 1) { 
           sessionStorage.setItem('useCache', "false")
           sessionStorage.setItem('needCache', "true")     
           this.activeModal.close("terminate success");
           await this.router.navigateByUrl("/dashboard-search");
           this.eventService.eventEmit.emit("terminateSuccess");        
      } else {
           console.error("create new task failed!");
           return;
      }

    } 
  }

}
