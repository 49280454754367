<div class="row">
  <div class="col width-15">
    <b class="col-label">{{ labels.supplierTsmId }}</b>
  </div>
  <div class="col width-10">
    <b class="col-label">{{ labels.supplierName }}</b>
  </div>
  <div
    (mousemove)="showTooltip($event, labelType.countryServed)"
    (mouseleave)="hideTooltip()"
    class="col width-10"
  >
    <b class="col-label">{{ labels.countryServed }}</b>
  </div>
  <div class="col max-width-10 width-5 row-number-wrap">
    <b class="col-label">{{ labels.rowNumber }}</b>
  </div>
  <div
    (mousemove)="showTooltip($event, labelType.validationResults)"
    (mouseleave)="hideTooltip()"
    class="col width-30"
  >
    <div *ngIf="rowHasError; else successSymbol">
      <img
        class="status-icon"
        src="assets/images/Warning_Icon.svg"
        alt="Error Icon"
      />
      <b class="col-label">{{ labels.validationResults }}</b>
    </div>
    <ng-template #successSymbol>
      <img
        class="status-icon"
        src="assets/images/Validation_Icon.svg"
        alt="Success Icon"
      />
      <b class="col-label">{{ labels.successMessage }}</b>
    </ng-template>
  </div>
</div>
