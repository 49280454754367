import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { EmailTemplateService } from '../../../../app/services/emailTemplateManager/email-template-manager';

@Component({
  selector: 'email-template-manager-log',
  templateUrl: './email-template-manager-log.component.html',
  styleUrls: ['./email-template-manager-log.component.sass']
})

export class EmailTemplateManagerLogComponent implements OnInit {
  @Input() items: Array<any>;
  @Input() currentPage: number;
  @Output() requery = new EventEmitter()
  @Output()
  templateSearchResultsEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  constructor(private emailTemplateService: EmailTemplateService) { }

  allEmailTypes: any = [];
  searchCriteria: any = {
    emailType: [],
    marketUnit: [],
    language: []
  }
  templateSearchCancelButton: { label: string; color: string; fontSize: number; backgroundColor: string; borderColor: string; hoverColor: string; hoverBackgroundColor: string; hoverBorderColor: string; height: number; width: number; padding: string; icon: string; iconPosition: string; onClick: () => void; };
  templateSearchSearchButton: { label: string; color: string; fontSize: number; backgroundColor: string; borderColor: string; hoverColor: string; hoverBackgroundColor: string; hoverBorderColor: string; height: number; width: number; padding: string; icon: string; clickIcon: string; iconPosition: string; iconAlt: string; onClick: () => void; };
  addButton: { label: string; backgroundColor: string; color: string; fontSize: number; fontFamily: string; height: number; borderRadius: number; padding: string; margin: string; onClick: () => Promise<void>; };
  allMarketUnits: any[];
  allLanguages: any[];

  async ngOnInit(): Promise<void> {
    this.templateSearchSearchButton = {
      label: "Search",
      color: "white",
      fontSize: 16,
      backgroundColor: "purple",
      borderColor: "lightPurple",
      hoverColor: "purple",
      hoverBackgroundColor: "lightPurple",
      hoverBorderColor: "lightPurple",
      height: 40,
      width: 120,
      padding: "10px",
      icon: "assets/icons/icon-search.svg",
      clickIcon: "assets/icons/icon-search.svg",
      iconPosition: "right",
      iconAlt: "icon-search",
      onClick: () => {
        this.filterTemplateData(this.searchCriteria);
      }
    };
    this.addButton = {
      label: " 十  Add New Template",
      backgroundColor: "blue",
      color: "white",
      fontSize: 16,
      fontFamily: "Graphik-Medium",
      height: 48,
      borderRadius: 25,
      padding: "14px 10px",
      margin: "0 0 0 2px",
      onClick: async () => {
      }
    }
    this.templateSearchCancelButton = {
      label: "Clear",
      color: "purple",
      fontSize: 16,
      backgroundColor: "white",
      borderColor: "lightPurple",
      hoverColor: "purple",
      hoverBackgroundColor: "white",
      hoverBorderColor: "purple",
      height: 42,
      width: 120,
      padding: "10px",
      icon: "",
      iconPosition: "left",
      onClick: () => {
        this.searchCriteria.emailType = []
        this.searchCriteria.marketUnit = []
        this.searchCriteria.language = []
        this.templateSearchResultsEventEmitter.emit({
          emailtype: [],
          marketunit: [],
          language: []
        });
      }
    }
    await this.setInitialVal();
  }
  reQuery(): void {
    this.requery.emit()
  }
  pageNumber: number = 1;
  totalNumberOfPages: number = 0;
  pageParams: any = {
    page: 1,
    pageSize: 10
  };

  async setInitialVal(): Promise<void> {
    /* 
       Market unit and language will be from countries table which is yet to be migrated to PostgresSql 
       So Based on MK Unit and Language Search is on hold 
       */
    let mkList = ['ANZ', 'SEA', 'Greater China']
    this.allMarketUnits = mkList.map(item => {
      return {
        "Text": item,
        "Code": item
      }
    });
    let languageList = [
      "English", "french", "Thai", "chinese", "japanese",
      "italiano",
      "portuguese",
      "german",
      "spanish",
      "indonesian",
      "brazil",
      "latamspanish"]
    this.allLanguages = languageList.map(item => {
      return {
        "Text": item,
        "Code": item
      }
    });
    await this.getAllEmailTypes();
  }
  async saveSectionEvent(e: any, dropdown: any): Promise<void> {
    this.searchCriteria[dropdown] = e;
  }

  async filterTemplateData(searchCriteria: any): Promise<void> {
    if (this.pageNumber) {
      this.pageParams.page = this.pageNumber
    }
    let emailTypeList = [...searchCriteria.emailType];
    this.templateSearchResultsEventEmitter.emit({
      emailtype: emailTypeList,
      marketunit: [],
      language: []
    });
  }

  async getAllEmailTypes(): Promise<void> {
    const response = await this.emailTemplateService.getData();
    if (response && response?.length > 0) {
      this.allEmailTypes = response.map(item => {
        return {
          "Text": item.type,
          "Code": item.type
        }
      });
    }
  }
}
