<div class="supplier-validation-list">
  <div class="row itemlist row-padding">
    <div class="col width-15">
      <b class="col-header">{{ labels.supplierTsmId }}</b>
    </div>
    <div class="col width-10">
      <b class="col-header">{{ labels.supplierName }}</b>
    </div>
    <div class="col width-10">
      <b class="col-header">{{ labels.country }}</b>
    </div>
    <div class="col max-width-10 width-5 row-number-wrap">
      <b class="col-header">{{ labels.rowNumber }}</b>
      <img
        class="help-icon"
        src="..\..\..\..\assets\icons\icon-help-enabled.svg"
        width="24"
        alt="help icon"
        (mousemove)="showTooltip($event)"
        (mouseleave)="hideTooltip()"
      />
    </div>
    <div class="col width-30">
      <b class="col-header">{{ labels.validationResults }}</b>
    </div>
  </div>
  <div
    class="row-item row-padding"
    *ngFor="let validationItem of sortedValidationResults"
  >
    <tsm-supplier-validation-item
      [rowData]="validationItem"
      [massInviteProcessed]="massInviteProcessed"
    ></tsm-supplier-validation-item>
  </div>
</div>
