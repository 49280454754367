import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "./services/auth/auth.service";
import { ModalService } from "./services/modal/modal.service";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from "@azure/msal-angular";
import { EventMessage, EventType, AuthenticationResult, AuthError } from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { b2cPolicies } from "./b2c-config";
import { ModalConfig, ModalType } from "./services/modal/types";
import { privacyStatementModalData } from "./services/modal/privacyStatementModalData";
import { PopUpPrivacyComponent } from "./components/pop-up-privacy/pop-up-privacy.component";
import { AlertPropsType, HttpService } from "@lsl16/sustainability-shared-components";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../environments/environment";
import { EventService } from "src/app/services/EventService.service";
import { B2cService } from "./services/b2c/b2c.service";
import { NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

interface IdTokenClaims extends AuthenticationResult {
    idTokenClaims: {
        acr?: string;
    };
}

@Component({
    selector: "tsm-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.sass"]
})
export class AppComponent implements OnInit, OnDestroy {
    role = "";
    title = "tsm";
    login = false;
    private readonly destroying$ = new Subject<void>();
    saveErrorAlertProps: AlertPropsType = {
        type: "alert-error",
        message: "There is a technical issue, please try again or contact us.",
        icon: "assets/images/icon-error.svg",
        iconFill: "#cc1e32",
        iconClose: "assets/images/icon-cross-blue.svg"
    };
    private baseUrl?: string;
    privacyStatementModalConfig: { data: ModalType } & ModalConfig = {
        data: privacyStatementModalData,
        type: "privacy-statement-strict",
        options: {}
    };
    result: any;

    constructor(
        private titleService: Title,
        private router: Router,
        private authService: AuthService,
        private modalService: ModalService,
        private httpService: HttpService,
        private ngbModalService: NgbModal,
        public translate: TranslateService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private eventService: EventService,
        private b2cService: B2cService
    ) {
        translate.addLangs(["en", "cn"]);
        translate.setDefaultLang("en");
        this.baseUrl = `${environment.tsmBackendServiceURL}/userprofile`;
        this.setTitle();
    }

    private setTitle() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            const title = this.getTitleForCurrentRoute();
            this.titleService.setTitle(title);
        });
    }

    private getTitleForCurrentRoute(): string {
        let title = 'True Supplier Marketplace';
        // Attempt to find the child route with a title
        let child = this.router.routerState.snapshot.root;
        while (child.firstChild) {
            child = child.firstChild;
        }
        if (child.data['title']) {
            title = "True Supplier Marketplace | " + child.data['title'];
        }
        return title;
    }

    ngOnInit(): void {
        localStorage.setItem("current language", 'english');
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                ),
                takeUntil(this.destroying$)
            )
            .subscribe(async (result: EventMessage) => {
                //Upon succesfully getting a new token/logging in, remove the authRefresh localStorage value as it's no longer needed
                this.authService.removeAuthRefresh();

                const payload: IdTokenClaims = <AuthenticationResult>result.payload;
                if (payload.idToken) {
                    this.authService.saveToken(payload.idToken);
                    await this.authService.getTSMUserInfo();
                    await this.authService.handleButtonPermission();
                }

                this.login = !!payload.idToken;

                // We need to reject id tokens that were not issued with the default sign-in policy.
                // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
                // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview

                if (payload.idTokenClaims?.acr === b2cPolicies.names.forgotPassword) {
                    window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
                    return this.authService.logout();
                } else if (payload.idTokenClaims["acr"] === b2cPolicies.names.editProfile) {
                    window.alert("Profile has been updated successfully. \nPlease sign-in again.");
                    return this.authService.logout();
                }

                this.handleDeepLink();

                return result;
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
                ),
                takeUntil(this.destroying$)
            )
            .subscribe((result: EventMessage) => {
                if (result.error instanceof AuthError) {
                    // Check for forgot password error
                    // Learn more about AAD error codes at
                    // https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
                    if (result.error.message.includes("AADB2C90118")) {
                        // login request with reset authority
                        const resetPasswordFlowRequest = {
                            scopes: ["openid"],
                            authority: b2cPolicies.authorities.forgotPassword.authority
                        };
                        // no need for forgot password so commnent
                    }
                }
            });
        this.login = !!localStorage.getItem("sustain_access_token");
        if (this.login) {
            this.authService.getTSMUserInfo();
        }
        this.eventService.privacyStatementEmit.subscribe((params: any) => {
            const country = !params.country ? "" : params.country;
            const isFooterPopup = !params.isFooterPopup ? false : true;
            const needCallback = !params.needCallback ? false : true;
            const isFetchPSVersion = !params.isFetchPSVersion ? false : true;
            this.popPrivacyStatement(country, isFooterPopup, needCallback, isFetchPSVersion);
        });
    }

    changeStrToObj(strJson: string, obj: any): void {
        try {
            var newObj = JSON.parse(strJson);
            obj.privacyStatement = newObj.privacyStatement;
            obj.chinaPrivacyStatement = newObj.chinaPrivacyStatement;
        } catch {
            obj.privacyStatement = strJson;
        }
    }

    async checkIsNeedPopupPrivacyStatement(country: string, isFetchPSVersion: boolean): Promise<boolean> {
        // get the latest privacy statement
        let latestPrivacy = { privacyStatement: "0.0.0", chinaPrivacyStatement: "0.0.0" };
        let strLatestPrivacy = await this.modalService.getPrivacyStatementVersion(isFetchPSVersion);
        if (!!strLatestPrivacy) {
            this.changeStrToObj(strLatestPrivacy, latestPrivacy);
        }
        // get user's privacy
        let userPrivacy = { privacyStatement: "0.0.0", chinaPrivacyStatement: "0.0.0" };
        const pocEmail = this.authService.getLoginUser().email
        const response = await this.b2cService.getUser(pocEmail);
        if (response && this.authService.getRole() === "supplier") {
            await this.b2cService.insertNames();
        }
        if (response && response.extension_tsm_privacyStatement) {
            this.changeStrToObj(response.extension_tsm_privacyStatement, userPrivacy)
        }
        const needSavePrivacy = { privacyStatement: userPrivacy.privacyStatement, chinaPrivacyStatement: userPrivacy.chinaPrivacyStatement };
        // compute whether need to pop up
        let latestVersion = [];
        let acceptedVersion = [];
        if (country === "China/Mainland") {
            latestVersion = latestPrivacy.chinaPrivacyStatement.split(".");
            acceptedVersion = userPrivacy.chinaPrivacyStatement.split(".");
            needSavePrivacy.chinaPrivacyStatement = latestPrivacy.chinaPrivacyStatement;
        } else {
            latestVersion = latestPrivacy.privacyStatement.split(".");
            acceptedVersion = userPrivacy.privacyStatement.split(".");
            needSavePrivacy.privacyStatement = latestPrivacy.privacyStatement;
        }
        let acceptedLatestVersion = true;
        if (latestVersion && acceptedVersion) {
            for (let i = 0; i < latestVersion.length; i++) {
                if (latestVersion[i] > acceptedVersion[i]) {
                    acceptedLatestVersion = false;
                    localStorage.setItem("needSavePrivacy", JSON.stringify(needSavePrivacy));
                    break;
                }
            }
        }
        return !acceptedLatestVersion;
    }

    async popPrivacyStatement(country: string, isFooterPopup: boolean = false, needCallback: boolean = false, isFetchPSVersion: boolean = false): Promise<void> {
        let isPopupPS = false;
        if (isFooterPopup) {
            isPopupPS = true;
        } else {
            isPopupPS = await this.checkIsNeedPopupPrivacyStatement(country, isFetchPSVersion);
        }
        // need to pop up modal
        if (isPopupPS) {
            // build privacy statement
            this.privacyStatementModalConfig.options = this.modalService.getOptions(
                this.privacyStatementModalConfig.type
            );
            this.privacyStatementModalConfig.exitViaButtonCallback = this.modalService.getExitViaButtonCallback(
                this.privacyStatementModalConfig.type
            );
            this.privacyStatementModalConfig.data = await this.modalService.getPrivacyStatementData(
                this.privacyStatementModalConfig.type
            );
            // set timeout for loading page
            setTimeout(() => { }, 500);
            // pop up modal
            const modalReference = this.ngbModalService.open(PopUpPrivacyComponent, this.privacyStatementModalConfig.options);

            if (!isFooterPopup) {
                modalReference.result.then(this.privacyStatementModalConfig.exitViaButtonCallback);
            }
            modalReference.componentInstance.modalType = this.privacyStatementModalConfig.type;
            modalReference.componentInstance.modalData = this.privacyStatementModalConfig.data;
            modalReference.componentInstance.privacyFlag = isFooterPopup;
            modalReference.componentInstance.country = country;

            let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
            if (tsmRoot) {
                tsmRoot.setAttribute("aria-hidden", "false");
            }
        } else {
            if (needCallback) {
                this.eventService.psCallbackEmit.emit("popUpEditProfile");
            }
        }
    }

    ngOnDestroy(): void {
        this.destroying$.next(undefined);
        this.destroying$.complete();
    }

    private handleDeepLink(): void {
        const url = sessionStorage.getItem('deepLink');
        if (!url) {
            return;
        }

        let relativeUrl = url.replace(`${window.origin}`, '');

        if (!relativeUrl.startsWith('/#')) {
            return;
        }

        relativeUrl = relativeUrl.replace('/#', '');
        sessionStorage.removeItem('deepLink');

        this.router.navigateByUrl(relativeUrl);
    }

    private translateLanguage(): void {
        const language = localStorage.getItem("current language");
        if (null != language && this.authService.getRole() === "supplier") {
            this.translate.use(language);
        } else {
            this.translate.use("english");
        }
    }

    showGlobalTooltip(header: string, content: string, left: number, top: number, maxWidth: number = 400): void {
        const t: HTMLDivElement = <HTMLDivElement>document.getElementById("globalTooltip");
        (<HTMLDivElement>t.childNodes[0]).innerText = header;
        (<HTMLDivElement>t.childNodes[1]).innerText = content;
        const s = t.style;
        s.left = left + 'px';
        s.top = top + 'px';
        s.maxWidth = maxWidth + "px";
        s.opacity = "1";
    }

    // to display the tooltip above the cursor with large contents that won't get hidden beyond the screen at footer
    showGlobalTooltipOnTop(header: string, content: string, left: number, top: number, maxWidth: number = 400): void {
        const t: HTMLDivElement = <HTMLDivElement>document.getElementById("globalTooltip");
        (<HTMLDivElement>t.childNodes[0]).innerText = header;
        (<HTMLDivElement>t.childNodes[1]).innerText = content;
        const s = t.style;
        s.left = left + 'px';
        s.top = top - t.clientHeight + 'px';
        s.maxWidth = maxWidth + "px";
        s.opacity = "1";
    }

    public hideGlobalTooltip(): void {
        const t: HTMLDivElement = <HTMLDivElement>document.getElementById("globalTooltip");
        const s = t.style;
        s.opacity = "0";
        s.left = null;
        s.top = null;
        s.maxWidth = null;
        (<HTMLDivElement>t.childNodes[0]).innerText = null;
        (<HTMLDivElement>t.childNodes[1]).innerText = null;
    }
}
