<div class="row itemlist itemlist-row">
    <div class="col-1" style="max-width: 5%">
        <h6 class="subtitle">ID</h6>
    </div>
    <div class="col-2" style="padding-left: 2rem">
        <div class="subtitle">SUPPLIER PROFILE</div>
    </div>
    <div class="col-1" style="padding-left: 0">
        <h6 class="subtitle">COUNTRY SERVED</h6>
    </div>
    <div class="col-1" style="padding-left: 1.5rem">
        <div class="subtitle" style="min-width: 210px">WITHDRAWAL REQUESTOR</div>
    </div>
    <div class="col-2" style="padding-left: 6.5rem">
        <h6 class="subtitle" style="min-width: 140px">WITHDRAWAL REASON</h6>
    </div>
    <div class="col-1" style="padding-left: 4.75rem">
        <div class="subtitle" style="margin-top: 16px">WITHDRAWAL REQUEST DATE</div>
    </div>
    <div class="col-2" style="padding-left: 9rem">
        <h6 class="subtitle">STATUS</h6>
    </div>
    <div class="col-2" style="padding-left: 6rem">
        <h6 class="subtitle">ACTION</h6>
    </div>
</div>
<div *ngFor="let item of items; let i = index" style="border-bottom: solid 1px rgba(78, 78, 135, 0.2)" class="datalist-row">
    <tsm-termination-request-log-item [model]="item" [id]="10*(currentPage-1)+i+1"></tsm-termination-request-log-item>
</div>