<div *ngIf="opened" class="modal-header">
    <img class="modal-icon" [src]="popData.iconUrl" alt="icon-popData" />
    <h4 class="modal-title">{{ 'supplier.popUp.continuePop.title' | translate }}</h4>
</div>
<div *ngIf="opened" class="modal-body">
    <div *ngIf = "!flag" class="modal-body-content" [innerHTML]="popData.content"></div>
    <div *ngIf = "flag" class="modal-body-content" [innerHTML]="popNewData.content"></div>
    <div style="margin-bottom: 20px;">
    <span class="time">{{msg}}</span>
    </div>
    <div *ngIf = "!flag">
    <sh-button ngbAutofocus [props]="buttonProps1"> </sh-button>
    <sh-button ngbAutofocus [props]="buttonProps" style="float: right;"> </sh-button>
    </div>

    <div *ngIf = "flag" class="d-flex flex-row-reverse">
        <sh-button ngbAutofocus [props]="buttonProps2"> </sh-button>
    </div>
</div>