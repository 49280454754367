<tsm-user-secondary-banner id="secondary-banner" [jumpUrl]="backUrl"></tsm-user-secondary-banner>
<tsm-user-profile-container id="user-profile-container" [userId]="user.userId" (saveClickedEvent)="onSaveButtonClicked()"
    #userProfileContainer>
    <div class="row no-gutters mt-4">
        <div class="col-1 icon-col role-icon-container">
            <img src="assets/icons/icon-services.svg" alt="icon-services" />
        </div>
        <div class="col">
            <span id="checkbox-label" class="user-data">{{translations.checkboxLabel}}</span>
            <input id="checkbox" type="checkbox" [(ngModel)]="user.saEditRights" (change)="onCheckboxValueChanged()" class="check-box" />
        </div>
    </div>
</tsm-user-profile-container>