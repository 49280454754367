import { Component, OnInit, ViewChild } from "@angular/core";
import {
    ButtonPropsType,
    DynamicFormComponent,
    ComponentConfig,
    DynamicContext
} from "@lsl16/sustainability-shared-components";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { SupplierService } from "../../services/supplier/supplier.service";
import { SupplierGeneralProfileService } from "../../services/supplier-general-profile.service";
import { MetadataService } from "../../services/metadata/metadata.service";
import { GeneralSupplierType } from '@lsl16/sushub-frontend';
import { countryList } from "../../../utilities/const/country-list";
import { Subscription } from "rxjs";
import { AuthService } from "../../services/auth/auth.service";
@Component({
    selector: "add-geography-served",
    templateUrl: "./add-geography-served.component.html",
    styleUrls: ["./add-geography-served.component.sass"]
})
export class AddGeographyServedComponent implements OnInit {
    configs: Array<ComponentConfig> = [];
    model: any;
    context: DynamicContext;
    @ViewChild("form", { static: false }) form: DynamicFormComponent;
    nextButton: ButtonPropsType;
    props: ButtonPropsType;
    supplierTsmId: string;
    supplier: GeneralSupplierType;
    existingCountry: string[];
    supplierName: string;
    countryCapList: any;
    countryServedString: string;
    noticeCountryList: any;

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private metadataService: MetadataService,
        private supplierService: SupplierService,
        private SupplierGeneralProfileService: SupplierGeneralProfileService
    ) {}

    async ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.supplierTsmId = params["supplierTsmId"];
            this.supplierName = params["supplierName"];
            this.countryCapList = params["countryCapList"]
        });
        this.supplier = await this.SupplierGeneralProfileService.getSupplierById(this.supplierTsmId);
        this.existingCountry = this.supplier.countryServed;

        this.context = new DynamicContext();
        this.context.set("existingCountry", this.existingCountry);
        this.configs = await this.metadataService.gettingAddGeographyServedConfig();
        this.setCountryCapListIntoConfigs(this.configs, this.countryCapList);
        this.initButtons();
    }

    setCountryCapListIntoConfigs(configs: any, countryCapList: any) {
        configs[1].fieldset[0]['countryCapList'] = countryCapList;
    }

    initButtons() {
        this.nextButton = {
            label: "Submit & Continue",
            color: "white",
            fontSize: 16,
            backgroundColor: "blue",
            borderColor: "blue",
            height: 48,
            width: 204,
            padding: "10px",
            onClick: async () => {
                await this.save();
            }
        };
    }

    async save(): Promise<any> {
        if (this.form.validate()) {
            this.nextButton.isProcessing = true
            const values = this.form.values();
            this.noticeCountryList = values
            const countryServedArray = [];
            if (typeof values.countryServed !== "string") {
                for (let i = 0; i < values.countryServed.length; i++) {
                    countryServedArray.push(values.countryServed[i]);
                    if (i === 0) {
                        this.countryServedString = values.countryServed[i];
                    } else { 
                        this.countryServedString = this.countryServedString + "," + values.countryServed[i];
                    }
                }
            } else {
                countryServedArray.push(values.countryServed);
                this.countryServedString = values.countryServed;
            }

            /**
             * get list of tsmCode for concat
             */
            let countryAry = [];
            let countryTsmCode = [];
            if (typeof this.countryCapList == 'string') {
                countryAry = this.countryCapList.split(',');
            } else {
                countryAry = this.countryCapList;
            }
            for (let a = 0; a < countryAry.length; a++) {
                let keys = Object.keys(countryList['Data'].mapping);
                for (let b = 0; b < keys.length; b++) {
                    if (countryList['Data'].mapping[keys[b]] == countryAry[a]) {
                        countryTsmCode.push(keys[b]);
                    }
                }
            }
            values.countryServed = countryTsmCode.concat(countryServedArray);
            
            values.pocEmail = this.authService.getLoginUser().email;
            var result = await this.SupplierGeneralProfileService.updateSupplier(this.supplierTsmId, values);
            if(result.$metadata.httpStatusCode == 200) {
                await this.SupplierGeneralProfileService.recordTime(this.supplierTsmId, this.countryServedString.split(',')) 
            }
            this.nextButton.isProcessing = false
            this.goNext();
        }
    }

    goNext = (): void => {
        /**
         * set params for create task
         * type of currentCountryServedNext should be string
         */
        let countryAry = this.countryServedString.split(',')
        this.noticeCountryList.countries = countryAry;
        this.router.navigate(["supplier-action", this.supplierTsmId, this.countryServedString, this.noticeCountryList], {
            queryParams: {
                supplierName: this.supplierName
            }
        });
    };
}
