import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubheaderPropsType } from 'src/app/models/SubheaderProps';
import { PopUpMergeProfileComponent } from 'src/app/views/pop-up-merge-profile/pop-up-merge-profile.component';
import { SupplierGeneralProfileService } from 'src/app/services/supplier-general-profile.service';
import { SupplierService } from 'src/app/services/supplier/supplier.service';
 import { AuthService } from "src/app/services/auth/auth.service";
 import { DatePipe } from '@angular/common';
 import { ButtonPropsType } from '@lsl16/sustainability-shared-components';

 @Component({
  selector: 'tsm-create-merge-profile',
  templateUrl: './create-merge-profile.component.html',
  styleUrls: ['./create-merge-profile.component.sass']
})
export class CreateMergeProfileComponent implements OnInit {
  @Input() isDuplicate:boolean = false;
  subheaderProps: SubheaderPropsType;
  mergeButton: any;
  secondaryProfileId: string;
  primaryProfileId: string;
  secondaryProfileName: string; // Variable to store fetched profile name
  primaryProfileName: string; // Variable to store fetched profile name
  errorMessage: string; // Variable for error message
  secondaryProfileErrorMessage: string = '';
  primaryProfileErrorMessage: string = '';
  saList: any;
  saList2: any;
  loading: boolean;

  enableMergeButton: ButtonPropsType = {
    label: 'Merge',
      color: 'white',
      fontSize: 16,
      backgroundColor: 'blue',
      borderColor: 'blue',
      height: 48,
      width: 180,
      onClick: () => {
        this.openDialog();
      }
  };
  disableMergeButton: ButtonPropsType = {
    ...this.enableMergeButton,
    backgroundColor: 'lightGrey',
    hoverBackgroundColor: 'gray',
    borderColor: 'mediumGrey',
    isProcessing: false,
    disabled: true
  };
  constructor(public modalService: NgbModal, private supplierService: SupplierGeneralProfileService, private supplierSaService: SupplierService, private authService: AuthService) {} // Inject SupplierGeneralProfileService
 
  ngOnInit(): void {
    this.subheaderProps = {
      isWelcomeMessage: false,
      headingPrefix: "System Admin",
      headingPrefixColor: "grey",
      heading: 'Merge Profiles'
    };
    this.mergeButton = this.disableMergeButton;
 
  }
 
  async fetchProfile(profileType: string): Promise<void> {
    // Clear specific error messages before fetching new profiles
    if (profileType === 'secondaryProfileId') {
      this.secondaryProfileErrorMessage = '';
    } else if (profileType === 'primaryProfileId') {
      this.primaryProfileErrorMessage = '';
    }
  
    if (this.primaryProfileId && this.secondaryProfileId && this.primaryProfileId === this.secondaryProfileId) {
      this.errorMessage = 'The Primary and Secondary Profile TSM IDs provided are identical. Please input distinct IDs for each.';
      return;
    }
  
    try {
      // Fetch secondary profile
      if (profileType === 'secondaryProfileId' && this.secondaryProfileId){
      await this.validatingSecondaryTsmId();
      }
  
      // Fetch primary profile
      if (profileType === 'primaryProfileId' && this.primaryProfileId){
      await this.validatingPrimaryTsmId();
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
      if (profileType === 'secondaryProfileId') {
        this.secondaryProfileErrorMessage = 'Error fetching secondary profile. Please try again later.';
      } else if (profileType === 'primaryProfileId') {
        this.primaryProfileErrorMessage = 'Error fetching primary profile. Please try again later.';
      }
    }
  }

  async validatingSecondaryTsmId(){
    if (this.secondaryProfileId) {
      this.loading = true;
      const secondaryProfile = await this.supplierService.getSupplierById(this.secondaryProfileId);
      this.secondaryProfileName = secondaryProfile.supplierName;

      //Fetch secondary profile's SA List
      const saListData2 = {
        supplierTsmId: this.secondaryProfileId,
        buyerTsmId: this.authService.getLoginUser().tsmId
      };
      if(this.secondaryProfileName!='')this.saList2 = await this.supplierSaService.getAssessmentList(saListData2);
      this.loading = false;
      
      await this.validatingPrimaryTsmId();
      // Validation for secondary profile
      if (!['Active', 'Pending', 'Terminated', 'Onboarded'].includes(secondaryProfile.status)) {
        this.secondaryProfileErrorMessage = 'Please enter valid TSM ID';
        return;
      }
      if (secondaryProfile.status === 'Terminated') {
        this.secondaryProfileErrorMessage = 'Please enter valid TSM ID which is not terminated.';
        return;
      }

      // Check for duplicate profile
      // const isDuplicateResp = await this.supplierService.duplicateCheck(this.secondaryProfileId);
      this.isDuplicate = this.secondaryProfileName.substring(0, 10).toLowerCase() === 'duplicate-';
      if (this.isDuplicate) {
        this.secondaryProfileErrorMessage = 'This secondary profile is already merged.';
        return;
      }
    }
  }

  async validatingPrimaryTsmId(){
    if (this.primaryProfileId) {
      this.loading = true;
      const primaryProfile = await this.supplierService.getSupplierById(this.primaryProfileId);
      this.primaryProfileName = primaryProfile.supplierName;
      const secondaryProfile = await this.supplierService.getSupplierById(this.secondaryProfileId);
      this.secondaryProfileName = secondaryProfile.supplierName;

      //Fetch primary profile's SA List
      const saListData = {
        supplierTsmId: this.primaryProfileId,
        buyerTsmId: this.authService.getLoginUser().tsmId
      };
      if(this.primaryProfileName!='')this.saList = await this.supplierSaService.getAssessmentList(saListData);
      this.loading = false;

      // Validation for primary profile
      if (!['Active', 'Pending', 'Terminated', 'Onboarded'].includes(primaryProfile.status)) {
        this.primaryProfileErrorMessage = 'Please enter valid TSM ID';
        return;
      }
      if(primaryProfile.status === 'Pending'){
        if(secondaryProfile.status !== 'Pending'){
        this.primaryProfileErrorMessage = 'Please enter valid TSM ID which is either Active or Onboarded';
        return;
        }
      }
      else if (!['Active', 'Onboarded'].includes(primaryProfile.status)) {
        this.primaryProfileErrorMessage = 'Please enter valid TSM ID which is either Active or Onboarded';
        return;
      }
      let isDuplicatePrimary = this.primaryProfileName.substring(0, 10).toLowerCase() === 'duplicate-';
      if (isDuplicatePrimary) {
        this.primaryProfileErrorMessage = 'This secondary profile is already merged.';
        return;
      }
      this.primaryProfileErrorMessage = '';
      return;
    }
  }
 
  async onProfileIdChange(flag: string): Promise<void> {
    // Clear names and error message if IDs are empty
    if (!this.primaryProfileId) {
      this.primaryProfileName = '';
      this.primaryProfileErrorMessage = '';
      this.saList = '';
    }
    if (!this.secondaryProfileId) {
      this.secondaryProfileName = '';
      this.secondaryProfileErrorMessage = '';
      this.saList2 = '';
    }
  
    // Clear error message if the primary and secondary IDs are distinct
    if (this.primaryProfileId && this.secondaryProfileId && this.primaryProfileId !== this.secondaryProfileId) {
      this.errorMessage = '';
    }
  
    // Set error if primary and secondary profile IDs are identical
    if (this.primaryProfileId && this.secondaryProfileId && this.primaryProfileId === this.secondaryProfileId) {
      this.primaryProfileErrorMessage = '';
      this.secondaryProfileErrorMessage = '';
      this.errorMessage = 'The Primary and Secondary Profile TSM IDs provided are identical. Please input distinct IDs for each.';
      this.mergeButton = this.disableMergeButton;
      return;
    }
  
    // // Clear error messages conditionally if IDs are distinct or empty
    // if (!this.primaryProfileId || (this.primaryProfileId && this.secondaryProfileId && this.primaryProfileId !== this.secondaryProfileId)) {
    //   this.primaryProfileErrorMessage = '';
    // }
    // if (!this.secondaryProfileId || (this.primaryProfileId && this.secondaryProfileId && this.primaryProfileId !== this.secondaryProfileId)) {
    //   this.secondaryProfileErrorMessage = '';
    // }
    
    // Clear general error if both fields are distinct or either is empty
    if (!this.secondaryProfileId || !this.primaryProfileId || (this.primaryProfileId !== this.secondaryProfileId)) {
      this.errorMessage = '';
    }
  
    // Fetch profiles and handle specific errors
    if (this.secondaryProfileId && flag === 'updatingSecondaryProfileId') {
      this.secondaryProfileName = '';
      this.saList2 = '';
      await this.fetchProfile('secondaryProfileId');
    }
  
    if (this.primaryProfileId && flag === 'updatingPrimaryProfileId') {
      this.primaryProfileName = '';
      this.saList = '';
      await this.fetchProfile('primaryProfileId');
    }
    if((this.primaryProfileId && this.secondaryProfileId) && (!this.secondaryProfileErrorMessage && !this.primaryProfileErrorMessage && !this.errorMessage)) {
      this.mergeButton = this.enableMergeButton;
    } else {
      this.mergeButton = this.disableMergeButton;
    }
  }          
 
  async openDialog(): Promise<void> {
    if (this.primaryProfileId && this.secondaryProfileId && this.primaryProfileId === this.secondaryProfileId) {
      this.errorMessage = 'The Primary and Secondary Profile TSM IDs provided are identical. Please input distinct IDs for each.';
      return;
    }
    if(!this.primaryProfileId || !this.secondaryProfileId) return;
 
    if (this.errorMessage || this.primaryProfileErrorMessage || this.secondaryProfileErrorMessage) return; // Prevent dialog opening if there's an error
 
    const modalRef = this.modalService.open(PopUpMergeProfileComponent);
    modalRef.componentInstance.secondaryProfileId = this.secondaryProfileId;
    modalRef.componentInstance.primaryProfileId = this.primaryProfileId;
    modalRef.componentInstance.secondaryProfileName = this.secondaryProfileName; // Pass the profile names to the modal component
    modalRef.componentInstance.primaryProfileName = this.primaryProfileName;
    modalRef.result.then(
      (result) => {
        if (result === 'resetFields') {
          this.resetFields(); // Reset the input fields
        } else {
          console.log('Profiles merged', result);
        }
      },
      () => {
        console.log('Modal dismissed');
      }
    );
  }
  resetFields(): void {
    this.secondaryProfileId = '';
    this.primaryProfileId = '';
    this.secondaryProfileName = '';
    this.primaryProfileName = '';
    this.errorMessage = '';
    this.primaryProfileErrorMessage = '';
    this.secondaryProfileErrorMessage = '';
    this.saList = '';
    this.saList2 = '';
  }

  getCountryServed(servedCountryList: any[]): string {
    return servedCountryList.map(country => country.name).join(', ');
  }
}