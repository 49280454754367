import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../services/auth/auth.service";
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { Popup } from "../../models/Popup";
import { ModalService } from "src/app/services/modal/modal.service";
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: "tsm-pop-up",
    templateUrl: "./pop-up.component.html",
    styleUrls: ["./pop-up.component.sass"]
})

// @dynamic
export class PopUpComponent implements OnInit {
    public static interval = 600;
    static msg: string = "";
    static flag = false;
    opened = false;
    popData: Popup;
    popNewData: Popup;
    buttonProps: ButtonPropsType = {
        label: "Extend",
        backgroundColor: "blue",
        color: "white",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        width: 115,
        height: 48,
        padding: "16px 22px",
        margin: "0 0 0 2px",
        hoverBackgroundColor: "darkBlue",
        hoverColor: "white"
    };

    buttonProps1: ButtonPropsType = {
        label: "End",
        backgroundColor: "white",
        color: "purple",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        width: 115,
        height: 48,
        padding: "16px 22px",
        margin: "0 0 0 2px",
        hoverBackgroundColor: "purple",
        hoverColor: "white",
        borderColor: "grey",
        onClick: () => this.logout()
    };

    buttonProps2: ButtonPropsType = {
        label: "Continue",
        backgroundColor: "blue",
        color: "white",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        width: 115,
        height: 48,
        padding: "16px 22px",
        margin: "0 0 0 2px",
        hoverBackgroundColor: "darkBlue",
        hoverColor: "white",
        onClick: () => this.logout()
    };
    static param: any;
    constructor(
        public activeModal: NgbActiveModal,
        private authService: AuthService,
        private modalService: ModalService,
        public ngbModalService: NgbModal,
        private translate: TranslateService
    ) { }

    ngOnInit() {
        this.popData = this.modalService.getPopData();
        this.buttonProps.onClick = () => {
            clearTimeout(PopUpComponent.param);
            this.activeModal.close();
            PopUpComponent.interval = 1800;
        };
        this.popNewData = this.modalService.getNewPopData();
        this.open();
    }

    resetTime(time: number) {
        function countdown() {
            let s = time % 60;
            let m = Math.floor(time / 60) % 60;
            PopUpComponent.msg = (m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s;
            if (time == 0) {
                PopUpComponent.flag = true;
            }
            if (--time >= 0) {
                PopUpComponent.param = setTimeout(countdown, 1000);
            }
        }
        countdown();
    }

    logout() {
        this.authService.logout();
    }

    open() {
        const service = this.ngbModalService;
        let objTime = {
            init: 0,
            opened: false,
            time: () => {
                objTime.init += 1;
                if (objTime.init === PopUpComponent.interval) {
                    if (!service.hasOpenModals()) {
                        const modalReference = service.open(PopUpComponent, {
                            centered: true,
                            size: "lg",
                            scrollable: true,
                            windowClass: "countdown-modal",
                            backdropClass: "modal-custom-backdrop",
                            backdrop: "static",
                            keyboard: false
                        });
                        this.resetTime(300);
                    }
                    objTime.opened = true;
                    clearInterval(testUser);
                    objTime.init = 0;
                }
            },
            eventFun: function () {
                clearInterval(testUser);
                objTime.init = 0;
                testUser = setInterval(objTime.time, 1000);
            }
        };
        this.opened = true;

        let testUser = setInterval(objTime.time, 1000);
        let body = document.querySelector("html");
        body.addEventListener("click", objTime.eventFun);
        body.addEventListener("keydown", objTime.eventFun);
        body.addEventListener("mousemove", objTime.eventFun);
        body.addEventListener("mousewheel", objTime.eventFun);
    }

    get msg() {
        return PopUpComponent.msg;
    }

    get flag() {
        return PopUpComponent.flag;
    }

    ngAfterContentChecked() {
        let langJson: any;
        this.translate.get('supplier.popUp').subscribe({
            next: (res: any) => {
                langJson = res
                let langObj = JSON.parse(JSON.stringify(langJson));
                if (langObj.continuePop) {
                    this.popData.content = langObj.extendPop.content;
                }
                if (langObj.extendPop) {
                    this.popNewData.content = langObj.continuePop.content;
                }
            }
        });
    }
}
