import { Component, OnInit, OnChanges, Input, Output, OnDestroy, AfterContentChecked, EventEmitter, SimpleChanges } from "@angular/core";
import { HttpService, ReportService, GlobalSupplierRelationshipConfirmStatus } from "@lsl16/sustainability-shared-components";
import { ButtonPropsType, SAStatus, getEsgStatusLabel, SupplierRelationshipService } from "@lsl16/sustainability-shared-components";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/auth/auth.service";
import { SupplierService } from "src/app/services/supplier/supplier.service";
import { BuyerService } from "src/app/services/buyer/buyer.service";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BvPopUpTerminationComponent } from "src/app/components/bv-pop-up-termination/bv-pop-up-termination.component";
import { PopUpCountryServedNotifyComponent } from "src/app/components/pop-up-country-served-notify/pop-up-country-served-notify.component";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { EventService } from "src/app/services/EventService.service";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { TaskStatus, TaskType, TerminationStatus } from "src/app/components/bv-esg-assessment/assessment-constant";
import { PopUpSaStatusComponent } from "src/app/components/pop-up-sa-status/pop-up-sa-status.component";
import { PopUpSelectCountryServedComponent } from "src/app/components/pop-up-select-country-served/pop-up-select-country-served.component";
import _ from "lodash";
import { EsgQuestionnaireService, GeneralSupplierType } from "@lsl16/sushub-frontend";
import { countryList } from "src/utilities/const/country-list";
import { AddParentConfig } from "src/app/components/profile-details/profile";
import { SupplierUpdateSaComponent } from "src/app/views/supplier-update-sa/supplier-update-sa.component";
import { SvEsgJumpParameters } from "src/app/models/SvEsgJumpParameters";
import { isEmpty } from 'lodash';
import { ConfirmSupplierParentCreationComponent } from 'src/app/views/confirm-supplier-parent-creation/confirm-supplier-parent-creation.component';
import { TranslateService } from '@ngx-translate/core';
import { HeaderEvents, HeaderService } from "src/app/services/header/header-service";

@Component({
  selector: "tsm-sv-esg-assessment",
  templateUrl: "./sv-esg-assessment.component.html",
  styleUrls: ["./sv-esg-assessment.component.sass"]
})
export class SvEsgAssessmentComponent implements OnInit {
  @Input() supplier: any;
  @Input() allMu: any;
  @Input() buyerId: any;
  @Input() allCountry: any;
  @Input() isScope3KeySup: boolean = false;
  @Input() isDuplicate: boolean = true;
  @Output() private withdrawSuccessAlert = new EventEmitter<any>();
  @Output() refershTerminateCallBack = new EventEmitter();
  constructor(
    private esgQuestionnaireService: EsgQuestionnaireService,
    config: NgbModalConfig,
    private reportService: ReportService,
    private httpService: HttpService,
    private authService: AuthService,
    private supplierService: SupplierService,
    private buyerService: BuyerService,
    public eventService: EventService,
    public supplierGeneralProfileService: SupplierGeneralProfileService,
    public ngbModalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    protected relationshipService: SupplierRelationshipService,
    private supplierRelationshipService: SupplierRelationshipService,
    private translate: TranslateService,
    private headerService: HeaderService
  ) {
    config.backdrop = "static";
  }

  tipMsgReleationship = "";
  tipMsgCountry = "Location of goods and services provided";
  isOnlyGeneralSupplier: boolean;
  sharedButtonProps: ButtonPropsType = {
    label: "",
    fontSize: 16,
    height: 48,
    padding: "10px 0px 10px 0px",
    borderColor: "black-12",
    fontFamily: "Graphik"
  };
  manageUsersButton: ButtonPropsType = {
    ...this.sharedButtonProps,
    width: 165,
    color: "white",
    backgroundColor: "blue",
    label: "Manage Users",
    disabled: false,
    tooltipText: "generalSupplierManageUsersTooltip",
    onClick: () => {
      this.onManageUsersButtonClick();
    },
  };

  public isEmptyList = false;
  tempMarketUnit: any = [];
  tempLocation: any = [];
  searchCriteria: any = {
    countryLocation: [],
    marketUnit: [],
    saStatus: []
  };
  oldSearchCriteria: any = _.cloneDeep(this.searchCriteria);

  ProfileDetailStrictData = {
    options: {},
    supplier: {},
    centered: true,
    size: "lg",
    windowClass: "countdown-termination",
  };

  allAssessmentList: any = [];
  exhibitAssessmentList: any = [];
  allCountryServedCodeList: any = [];
  withdrawItem: any;
  withdrawSASub: any;
  withdrawSuccessSub: any;
  initTableSub: any;

  userInfo: any;
  userRole: any;
  isBuyerExpert = false;
  isGeneralSupplier = false;
  isSupplierAdmin = false;
  isSystemSupplierAdmin = false;
  selectedSupplierTsmId: string;
  isChildSupplier = false;
  isSupplierRequestForm = false;
  isParent: boolean = false;
  buttonJson: any;
  esgData: any;
  optionsCardData: any;
  svSupplierProfileData: any;
  subSupplierData: any;
  esgStatusLabel: any;
  esgStatusLabelStatus: any;
  esgSupplierAction: any;
  esgActionStatus: any;

  pcMapping: any;
  waitingConfirmMapping: boolean = false;
  SCOPE3_KEY_SUPPLIER: string = "SCOPE3_KEY_SUPPLIER";
  updateSaModalConfig: AddParentConfig = {
    type: "update-sa",
    options: {},
    supplier: {},
    confirmSupplier: {},
    taskId: '',
    hideNoParent: false
  };
  esgJumpParameters: SvEsgJumpParameters;
  addParentModalConfig: AddParentConfig = {
    type: "add-parent",
    options: {},
    supplier: {},
    confirmSupplier: {},
    taskId: '',
    hideNoParent: false
  };
  buyerTsmId: string = 'c8aa5c00-588a-44d6-a08c-071dc9219cbe';
  async ngOnInit(): Promise<void> {
    this.refreshList();
  }
  // initial list data
  private async initialAssessmentList(): Promise<void> {
    const supBuyIdData = {
      supplierTsmId: this.selectedSupplierTsmId,
      buyerTsmId: this.buyerId
    };
    const saList = await this.supplierService.getSupplierAssessmentList(supBuyIdData);

    if (saList) {
      if (saList.code === 200) {
        await this.supplierGeneralProfileService.setChangeConsentFlag(saList.data)
        this.exhibitAssessmentList = saList.data;
      }
    }
  }

  private initialGlobalVariable(): void {
    this.selectedSupplierTsmId = this.supplier.supplierTsmId;
    this.userInfo = this.authService.getLoginUser();
    this.userRole = this.userInfo.defaultProfile;
    this.isBuyerExpert = this.authService.isBuyerExpert();
    this.isGeneralSupplier = this.authService.isGeneralSupplier();
    this.isSupplierAdmin = this.authService.isSupplierAdmin();
    this.isSystemSupplierAdmin = this.authService.isSystemSupplierAdmin();
    this.isOnlyGeneralSupplier = this.authService ? this.authService.isGeneralSupplier() && !this.authService.isSupplierAdmin() : true;
    this.isParent = this.esgQuestionnaireService.checkIfParentAssessment(this.selectedSupplierTsmId);
    this.manageUsersButton.disabled = this.isDuplicate;
  }

  onManageUsersButtonClick = async () => {
    if (this.isParent) {
      this.router.navigateByUrl(`/supplier-admin-board?supplierTsmId=${this.selectedSupplierTsmId}`);
    } else {
      this.router.navigateByUrl('/supplier-admin-board');
    }
  }

  onRequestAssButtonButtonClick = async () => {
    const confirmType = {
      options: {},
      centered: true,
      size: "lg",
      windowClass: "modal-country-notify",
      supplierTsmId: this.selectedSupplierTsmId,
      parentTsmId: this.pcMapping?.parentTsmId,
      hasParent: this.isChildSupplier,
      csList: (this.supplier.saCountryServed ? this.supplier.saCountryServed : [])
    };
    const modalReference = this.ngbModalService.open(PopUpSelectCountryServedComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
  }

  showStatusPopup(): void {
    const modalReference = this.ngbModalService.open(PopUpSaStatusComponent);
  }

  upperFirstWord(strWord: string): string {
    return strWord[0]?.toUpperCase() + strWord?.substring(1);
  }

  ngAfterContentChecked(): void {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multiLanguageJson.body !== undefined) {
        this.buttonJson = multiLanguageJson.body.sharedComponent.buttonLable;
        if (this.manageUsersButton && this.buttonJson.hasOwnProperty(this.manageUsersButton.tooltipText)) {
          this.buttonJson.manageUsersTooltip = this.buttonJson[this.manageUsersButton.tooltipText];
        }
      }
    }
    this.loadTranslations();
    this.subscribeEvents();
  }

  loadTranslations() {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multilanguageJson.body != undefined) {
        this.svSupplierProfileData = multilanguageJson.body.main.svSupplierProfile.saCreatedFromSubsidiary;
        this.subSupplierData = multilanguageJson.body.main.subSupplier;
        this.optionsCardData = multilanguageJson.body.main.optionsCard;
        this.esgData = multilanguageJson.body.main.supplierEsgAssessment;
        this.exhibitAssessmentList.forEach(element => {
          element.esgStatusLabelt = this.esgData[element.esgStatusLabel];
          element.actiont = this.esgData[element.action];
          element.actionTwot = this.esgData[element.actionTwo];
          element.servedCountryLabelAfterTranslate = this.CountryServedTranslate(element.servedCountryLabel, multilanguageJson)
        });
      }
    }
  }

  CountryServedTranslate(CountryServedArray: Array<any>, multilanguageJson: any) {
    let allCountryList = multilanguageJson.allCountry;
    if (!allCountryList) {
      return CountryServedArray;
    }
    return CountryServedArray.map((CountryServed) => {
      if (!allCountryList.hasOwnProperty(CountryServed)) {
        return CountryServed
      }
      return allCountryList[CountryServed];
    });
  }

  subscribeEvents() {
    this.headerService.eventEmit.subscribe((event) => {
      if (event === HeaderEvents.multiLanguageChanged) {
        this.loadTranslations();
      }
    });
  }

  ngOnDestroy(): void {
    const subList = [this.withdrawSASub, this.withdrawSuccessSub, this.initTableSub];
    subList.forEach((item) => {
      if (!!item) {
        item.unsubscribe();
      }
    });
  }

  async jumpPage(taskItem, actionTwo = null) {
    if(!this.isDuplicate){
      const { servedCountryList, status } = taskItem;
      this.esgJumpParameters = {
        ...taskItem,
        tsmCode: servedCountryList[0].tsmCode,
        currentlyServedCountrySAStatus: status
      };
      if (taskItem.isHaveParentSupplier) {
        this.esgJumpParameters.parentTsmId = taskItem.supplierTsmId
        this.esgJumpParameters.childTsmId = this.supplier.supplierTsmId
        this.redirectToParentAssessment();
      } else {
        if (actionTwo) {
          this.openUpdateSAModal();
        } else {
          const { action } = this.esgJumpParameters;
          const shouldOpenModal = action === "Start Now" && await this.shouldOpenAddSupplierParentModal();
          if (shouldOpenModal) {
            await this.openAddSupplierParentModal();
          } else {
            this.redirectToEsgAssessment();
          }
        }
      }
    }
  }

  private async redirectToParentAssessment() {
    const parentSupplierInfo = await this.supplierGeneralProfileService.getSupplierById(this.esgJumpParameters.parentTsmId);
    const assignedToEmail = this.authService.getLoginUser().email;
    let section;
    let esgStatus;
    const reqDataAssess = {
      country: this.esgJumpParameters.tsmCode,
      supplierTsmId: this.supplier.supplierTsmId,
      buyerTsmId: this.buyerTsmId
    };
    const assessmentStatus = await this.supplierService.getAssessmentStatus(reqDataAssess);
    section = assessmentStatus ? assessmentStatus?.section : "";
    esgStatus = assessmentStatus ? assessmentStatus.esgStatus : "";
    let dyRoute = "/esg-questionnaire/survey-getting-started";
    if (this.canReviewAssessment(this.esgJumpParameters.esgStatus)) {
      dyRoute = "/esg-questionnaire/survey-review-summary";
    } else {

      dyRoute = this.getRedirectRoute(section, esgStatus);
    }

    this.esgQuestionnaireService.appliedFilter = {};
    this.router.navigate([dyRoute, this.esgJumpParameters.esgQuestionnaireId], {
      queryParams: {
        supplierName: parentSupplierInfo.supplierName,
        countryServed: this.esgJumpParameters.tsmCode,
        supplierTsmId: this.esgJumpParameters.parentTsmId,
        assignedToEmail: assignedToEmail,
        isMutuallyConfirmed: true,
        childTsmId: this.esgJumpParameters.childTsmId,
        childSupplierName: this.supplier.supplierName,
        esgStatus: this.esgJumpParameters.esgStatus,
        isScope3Supplier: this.isScope3Supplier(parentSupplierInfo),
        viewOnly: await this.checkQuestionnaireViewOnly(assessmentStatus)
      }
    });
    window.scroll(0, 0);
  }
  redirectToEsgAssessment = async () => {
    const assignedToEmail = this.authService.getLoginUser().email;
    const reqDataAssess = {
      country: this.esgJumpParameters.tsmCode,
      supplierTsmId: this.supplier.supplierTsmId,
      buyerTsmId: this.buyerTsmId
    };
    const assessmentStatus = await this.supplierService.getAssessmentStatus(reqDataAssess);
    this.esgJumpParameters.esgStatus = assessmentStatus ? assessmentStatus.esgStatus : "";
    let dyRoute;
    let isFromContinueOrUpdateButton = false;
    let section;
    let isSupplierStart;
    let supplierUpdateSAInfo;
    let viewOnlyFlag = false;
    if ("Validation Completed" === this.esgJumpParameters.esgStatus) {
      viewOnlyFlag = true;
    }
    section = assessmentStatus ? assessmentStatus?.section : "";
    isSupplierStart = assessmentStatus?.requestFrom;
    supplierUpdateSAInfo = assessmentStatus?.supplierUpdateSAInfo;
    if (this.esgJumpParameters.action === "Continue") {
      dyRoute = this.getRedirectRoute(section, this.esgJumpParameters.esgStatus);
      if (isSupplierStart == "supplier" && supplierUpdateSAInfo.length > 0
        && supplierUpdateSAInfo[0].updateReason == "Change Inclusion & Diversity consent") {
        isFromContinueOrUpdateButton = true;
        dyRoute = "/esg-questionnaire/survey-supplier-i-d";
      }
    } else if (this.canReviewAssessment(this.esgJumpParameters.esgStatus)) {
      dyRoute = "/esg-questionnaire/survey-review-summary";
    } else {
      dyRoute = "/esg-questionnaire/survey-getting-started";
    }
    let isUpdateButtonTrigger = await this.supplierService.getIsUpdateButtonTriggerStatus();
    if (isUpdateButtonTrigger) {
      dyRoute = "/esg-questionnaire/survey-supplier-i-d";
      isFromContinueOrUpdateButton = true;
      isSupplierStart = "supplier";
    }
    this.router.navigate([dyRoute, this.esgJumpParameters.esgQuestionnaireId], {
      queryParams: {
        supplierName: this.supplier.supplierName,
        countryServed: this.esgJumpParameters.tsmCode,
        supplierTsmId: this.supplier.supplierTsmId,
        assignedToEmail: assignedToEmail,
        esgStatus: this.esgJumpParameters.esgStatus,
        isScope3Supplier: this.isScope3Supplier(this.supplier),
        isSupplierStart: isSupplierStart,
        isFromContinueOrUpdateButton: isFromContinueOrUpdateButton,
        viewOnly: await this.checkQuestionnaireViewOnly(assessmentStatus)
      }
    }).then(() => {
      if (viewOnlyFlag) {
        this.esgQuestionnaireService.eventEmit.emit("viewOnly");
      }
    });
    window.scroll(0, 0);
  }

  async checkQuestionnaireViewOnly(assessmentStatus = null): Promise<boolean> {
    // calculate SA completion state
    let isAssessmentCompleted = false;
    const currentRole = this.authService.getRole();
    // search the newest task status
    const reqDataAssess = {
      country: this.esgJumpParameters.tsmCode,
      supplierTsmId: this.supplier.supplierTsmId,
      buyerTsmId: this.buyerTsmId
    };
    let taskSAStatus;
    if (null != assessmentStatus) {
      taskSAStatus = assessmentStatus.status;

    }
    if (currentRole === 'authority') {
      isAssessmentCompleted = taskSAStatus ? taskSAStatus === "Completed"
        || taskSAStatus === "Update Required"
        || taskSAStatus === "Validation Completed"
        || taskSAStatus === "In Progress"
        || taskSAStatus === "Awaiting update approval"
        || taskSAStatus === "Rejected" : false;
    } else {
      isAssessmentCompleted = taskSAStatus ? taskSAStatus === "Completed"
        || taskSAStatus === "Validation Completed"
        || taskSAStatus === "Awaiting update approval"
        || taskSAStatus === "Rejected" : false;
    }

    const isParentAssessment = this.esgQuestionnaireService.checkIfParentAssessment(this.supplier.supplierTsmId);

    let isAllowedToEditParentAssessment = false;
    if (isParentAssessment) {
      isAllowedToEditParentAssessment = await this.esgQuestionnaireService.checkIfAllowedToEditParentAssessment();
    }

    const isBuyerExpert = this.authService.isBuyerExpert();
    const viewOnly = isAssessmentCompleted || (isParentAssessment && !isAllowedToEditParentAssessment) || isBuyerExpert;
    return viewOnly;
  }

  protected isScope3Supplier(supplier): any {
    return supplier?.classification ? supplier?.classification.includes(this.SCOPE3_KEY_SUPPLIER) : false;
  }
  getRedirectRoute(section: string, esgStatus: string): string {
    let dyRoute: string;
    switch (section) {
      case "getting-started":
        dyRoute = "/esg-questionnaire/survey-getting-started";
        break;
      case "human-social":
        dyRoute = "/esg-questionnaire/survey-human-social";
        break;
      case "supplier-i-d":
        dyRoute = "/esg-questionnaire/survey-supplier-i-d";
        break;
      case "environment":
        dyRoute = "/esg-questionnaire/survey-environment";
        break;
      case "es-mgt":
        dyRoute = "/esg-questionnaire/survey-es-mgt";
        break;
      default:
        if (this.canReviewAssessment(esgStatus)) {
          dyRoute = "/esg-questionnaire/survey-review-summary";
        } else {
          dyRoute = "/esg-questionnaire/survey-getting-started";
        }
        break;
    }
    return dyRoute;
  }

  canReviewAssessment(esgStatus: string): boolean {
    return esgStatus === SAStatus.pendingReview
      || esgStatus === SAStatus.updateRequired
      || esgStatus === SAStatus.submittedScored;
  }


  public async openUpdateSAModal(hideNoParent: boolean = false): Promise<void> {
    // avoid opening the modal twice at the sametime
    if (this.ngbModalService.hasOpenModals()) {
      return;
    }

    this.updateSaModalConfig.supplier = this.supplier;
    this.updateSaModalConfig.supplier["currentCountry"] = this.esgJumpParameters.tsmCode;
    this.updateSaModalConfig.supplier["changeConsentFlag"] = this.esgJumpParameters['changeConsentFlag'];
    this.updateSaModalConfig.options = {
      scrollable: true,
      animation: true,
      backdrop: 'static',
      windowClass: 'update-sa-modal-window',
      backdropClass: 'modal-custom-backdrop',
    };
    this.updateSaModalConfig.redirectToEsgAssessment = this.redirectToEsgAssessment;
    const modalReference = this.ngbModalService.open(SupplierUpdateSaComponent, this.updateSaModalConfig.options);
    modalReference.componentInstance.confirmType = this.updateSaModalConfig;
    modalReference.componentInstance.confirmType.workflowId = this.esgJumpParameters.workflowId;
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
        this.initialGlobalVariable();
    await this.initialAssessmentList();
  }


  public async shouldOpenAddSupplierParentModal(mode?: string): Promise<boolean> {
    if (this.supplier.indirect) {
      return false;
    }
    if (this.servedCountryHasStartedTheAssessment() && mode !== 'specifyParent') {
      return false;
    }
    this.esgJumpParameters.confirmSupplierTsmId = "";
    const [children, relation] = await Promise.all([
      this.relationshipService.getParentChildRelationForParent(this.esgJumpParameters.supplierTsmId),
      this.relationshipService.getParentChildRelation(this.esgJumpParameters.supplierTsmId)
    ]);
    if (Array.isArray(children)) {
      const validParentStatus: GlobalSupplierRelationshipConfirmStatus[] = ['AwaitingParentConfirmation', 'MutuallyConfirmed'];
      const validChildSuppliers = children.filter(child => validParentStatus.includes(child.status));
      // active, valid subsidiary suppliers -> is a valid parent --- show no parent dialog
      if (validChildSuppliers.length) {
        return false;
      }
    }
    const validStatus = ['Open', 'NoParent'];
    if (relation && relation.status && !validStatus.includes(relation.status)) {
      return false;
    }

    //relationship status is empty/no-parent and parent found--- show parent found dialog
    if (this.supplier.possibleParentTsmId && !isEmpty(this.supplier.possibleParentTsmId)) {
      this.esgJumpParameters.confirmSupplierTsmId = this.supplier.possibleParentTsmId;
      return true;
    }

    //no parent found and relationship status empty---show no parent dialog
    if (!relation.status) {
      return true;
    }
    return false;
  }

  public async openAddSupplierParentModal(hideNoParent: boolean = false): Promise<void> {
    // avoid opening the modal twice at the sametime
    if (this.ngbModalService.hasOpenModals()) {
      return;
    }

    this.addParentModalConfig.hideNoParent = hideNoParent;
    this.addParentModalConfig.supplier = this.supplier;
    this.addParentModalConfig.taskId = this.esgJumpParameters.taskId;
    this.addParentModalConfig.confirmSupplier = {};
    if (!isEmpty(this.esgJumpParameters.confirmSupplierTsmId)) {
      let response = await this.supplierGeneralProfileService.getSupplierById(this.esgJumpParameters.confirmSupplierTsmId);
      if (response) {
        this.addParentModalConfig.confirmSupplier = {
          ...response,
          pocEmail: response.supplierAdmin ? response.supplierAdmin.userName : "",
          pocName: response.supplierAdmin ? `${response.supplierAdmin.firstName} ${response.supplierAdmin.lastName}` : ""
        };
      }
    }
    this.addParentModalConfig.options = {
      scrollable: true,
      animation: true,
      windowClass: 'add-parent-modal-window',
      backdropClass: 'modal-custom-backdrop',
    };
    this.addParentModalConfig.redirectToEsgAssessment = this.redirectToEsgAssessment;
    const modalReference = this.ngbModalService.open(ConfirmSupplierParentCreationComponent, this.addParentModalConfig.options);
    modalReference.componentInstance.confirmType = this.addParentModalConfig;
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
        this.initialGlobalVariable();
    await this.initialAssessmentList();
  }
  private servedCountryHasStartedTheAssessment(): boolean {
    return this.esgJumpParameters.currentlyServedCountrySAStatus !== 'Pending';
  }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.supplier !== undefined) {
      if (changes.supplier.currentValue !== undefined) {
        this.initialGlobalVariable();
        await this.initialAssessmentList();
      }
    }
  }

  refreshList() {
    this.eventService.eventEmit.subscribe(e => {
      if (e === "refreshList") {
        this.initialGlobalVariable();
        this.initialAssessmentList();
      }
    });
  }
}







