<div class="row">

    <div class="col-6 display-flex">
        <label class="select-label line-ht-1" for="marketUnit">Email Type</label>
        <div class="col-12 ps-0">
            <sh-multiple-dropdown [dataSource]="allEmailTypes" [selectedKeys]="searchCriteria.emailType"
                [placeholder]="'Select Email Types'" [placeholderClass]="'input-placeholder'"
                (saveSectionEvent)="saveSectionEvent($event, 'emailType')"></sh-multiple-dropdown>
        </div>
    </div>

    <div class="col-3 display-flex">
        <div class="d-flex flex-row-reverse">

            <sh-button style="margin-top: 20px" [props]="templateSearchSearchButton"></sh-button>
            <sh-button class="cancel-btn" style="margin-top: 20px; margin-right: 10px"
                [props]="templateSearchCancelButton"></sh-button>
        </div>
    </div>
</div>
<hr>

<div class="row row-cols-auto itemlist">
    <div class="col-1">
        <h6 class="subtitle">S NO</h6>
    </div>
    <div class="col-3">
        <div class="subtitle">EMAIL TYPE</div>
    </div>
    <div class="col-1">
        <h6 class="subtitle">MARKET UNIT</h6>
    </div>
    <div class="col-2">
        <div class="subtitle-requestor">LANGUAGE</div>
    </div>
    <div class="col-3">
        <h6 class="subtitle">EMAIL SUBJECT</h6>
    </div>
    <div class="col-2">
        <h6 class="subtitle-action make-center">ACTION</h6>
    </div>
</div>
<div *ngFor="let item of items; let i = index" style="border-bottom: solid 1px rgba(78, 78, 135, 0.2)">
    <email-template-manager-log-item [model]="item" [itemindex]="10*(currentPage-1)+i+1"
        (reQuery)="reQuery()"></email-template-manager-log-item>
</div>