import { ModalType } from "./types";

export const welcomeModalData: ModalType = {
    title: "Welcome to the Accenture True Supplier Marketplace",
    iconUrl: "",
    content: "",
    buttonText: "Countine",
    headerCloseButton: false
};

export const welcomeModalOptions = {
    scrollable: true,
    centered: true,
    size: "lg",
    animation: true,
    windowClass: "modal-container",
    backdropClass: "modal-custom-backdrop"
};

export const welcomeContent = `<div align="center"><img src="assets/images/welcome.svg" height="181" /></div>
<p></P><p><strong>Accenture invites you to complete a Sustainability Assessment as part of doing business with us 
or one of our subsidiaries.</strong></p>
<p>As part of our efforts to improve supply chain sustainability visibility, we require all
potential and existing suppliers to complete a Sustainability Assessment, either as 
part of supplier evaluation and selection process, supplier onboarding, or ongoing
relationship with Accenture. The Sustainability Assessment collects Environmental,
Social, and Governance (ESG) data in the areas of Environment, Human Rights &
Social Impact, Supplier Inclusion & Diversity, and Ethics & Sustainability
Management. The long-term vision is to create a platform where we can collaborate
on mutually beneficial initiatives and co-innovate across our ecosystems.</p>
<p>Sustainability is a strategic priority for us. Thank you for collaborating—let's build a
more responsible and sustainable future together!</p>`;
