<div class="modal-header">
    <h4 class="modal-title">{{consentRenewalJson.title}}</h4>
</div>
<div class="modal-body">
    <div class="modal-body-content">
        <form class="detailform">
            <div class="modal-body-content" [innerHtml]="consentRenewalJson.content">
            </div>
            <div class="d-flex btn-okay">
                <sh-button ngbAutofocus [props]="buttonProps"></sh-button>
            </div>
        </form>  
    </div>
</div>