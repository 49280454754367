import { CustomStyles } from './../../models/Styles';
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { SupplierCardType } from "../../models/SupplierCard";
import { countryList } from "../../../utilities/const/country-list";
import { getEsgStatusLabel } from "@lsl16/sustainability-shared-components";
import TaxIdLabels from '../../../assets/json/taxIdLabels.json';

@Component({
  selector: "tsm-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.sass"]
})
export class CardComponent implements OnInit {
  @Input() supplier: SupplierCardType;
  @Input() enableIndirectTag: boolean = false;

  selectedCountry: string;
  domainName: string;
  registeredLocation: string;
  dunsNumber: string;
  esgStatus: string;
  esgStatusLabel: string;
  statusLiterals: any;
  companyLegalName: string;
  showIndirectTag: boolean = false;
  indirectTagStyles: CustomStyles;
  activeTagStyles: CustomStyles;
  pendingTagStyles: CustomStyles;
  terminatedTagStyles: CustomStyles;
  sapId: any;
  sapIdToDisplay: any;
  finalTaxIdResult: any = [];

  mockData: any = [{
    "taxId": []
  }];
  taxIdLabelArray: any;


  translations = {
    Active: "",
    Pending: "",
    Terminated: "",
    Indirect: "",
    PendingTooltip: ""
  };

  constructor(
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {

    this.domainName = this.supplier.companyDomainName;
    this.registeredLocation = this.supplier.countryLocation;
    this.dunsNumber = this.supplier.dunsNumber;
    this.companyLegalName = this.supplier.companyLegalName;
    this.sapId = this.supplier.sapId;
    this.mockData[0].taxId = this.supplier.taxId;

    this.addingSpaceAfterComma();
    this.formattingTaxID();
    this.updateUiFlags();
    this.setStyles();
    this.handleEsgStatus(this.supplier);
  }

  private updateUiFlags() {
    this.showIndirectTag = this.enableIndirectTag && this.supplier.indirect;
  }

  private setStyles() {
    this.pendingTagStyles = {
      label: this.translations.Pending,
      borderStyle: { 'border-top': '2.25rem solid #4b4f51' },
      valueStyle: { 'background': '#757575' }
    }

    this.activeTagStyles = {
      label: this.translations.Active,
      borderStyle: { 'border-top': '2.25rem solid #47246a' },
      valueStyle: { 'background': '#763ab2' }
    }

    this.indirectTagStyles = {
      label: this.translations.Indirect,
      borderStyle: { 'border-top': '2.25rem solid #6A4E24' },
      valueStyle: { 'background': '#936501' }
    }

    this.terminatedTagStyles = {
      label: this.translations.Terminated,
      borderStyle: { 'border-top': '2.25rem solid #4b4f51' },
      valueStyle: { 'background': '#757575' }
    }
  }

  formattingTaxID() {
    if (this.mockData !== null && this.mockData !== undefined && this.mockData.length > 0) {
      if (this.mockData[0].taxId !== null &&
        this.mockData[0].taxId !== undefined &&
        this.mockData[0].taxId.length > 0) {
        this.taxIdLabelArray = Object.keys(this.mockData[0].taxId[0]);
        this.taxIdLabelArray.forEach(item => {
          this.finalTaxIdResult.push({ 'label': item, 'value': this.mockData[0].taxId[0][item] })
        })
      }
    }
  }

  addingSpaceAfterComma() {
    if (this.sapId !== null && this.sapId !== undefined) {
      let res = this.sapId.filter(elements => elements !== null && elements !== undefined);
      let stringResult = res.toString();
      this.sapIdToDisplay = stringResult.replace(/,/g, ', ');
    }
  }

  openSupplier() {
    this.router.navigate(["/supplier-profile", this.supplier.supplierTsmId], {
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.handleEsgStatus(changes.supplier.currentValue);
  }

  private handleEsgStatus(supplier?: SupplierCardType) {
    this.esgStatus = getEsgStatusLabel(supplier?.sushubAssessStatus)?.toLowerCase();
    this.handleEsgStatusLabel();
  }

  private handleEsgStatusLabel() {
    if (this.esgStatus && this.statusLiterals && this.statusLiterals[this.esgStatus.toLowerCase()]) {
      this.esgStatusLabel = this.statusLiterals[this.esgStatus.toLowerCase()];
    } else {
      this.esgStatusLabel = this.esgStatus;
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multilanguageJson) {
        if (multilanguageJson.questionnire && multilanguageJson.questionnire.reviewSummary && multilanguageJson.questionnire.reviewSummary.statusLiterals) {
          this.statusLiterals = multilanguageJson.questionnire.reviewSummary.statusLiterals;
          this.handleEsgStatusLabel();
        }
        if (multilanguageJson.body && multilanguageJson.body.main && multilanguageJson.body.main.card) {
          this.translations = multilanguageJson.body.main.card;
          this.setStyles();
        }
      }
    }
  }
}
