import { AfterContentChecked, Component, OnInit } from '@angular/core';
import {
  ButtonPropsType,
  SupplierRelationshipService,
  SubSupplierRelationshipService,
} from '@lsl16/sustainability-shared-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

type InvokedByTypes = 'Parent' | 'Subsidiary' | 'Super';

@Component({
  selector: 'tsm-confirm-cancel-relationship-request',
  templateUrl: './confirm-cancel-relationship-request.component.html',
  styleUrls: ['./confirm-cancel-relationship-request.component.sass']
})
export class ConfirmCancelRelationshipRequestComponent
  implements OnInit, AfterContentChecked {
  public parentTsmId: string;
  public subsidiaryTsmId: string;
  public workflowId: string;
  public invokedBy: InvokedByTypes;
  public message: string = '';

  public translations = {
    title: '',
    confirmLabel: '',
    cancelLabel: '',
    parentMessage: '',
    subsidiaryMessage: '',
    subSupplierMessage: ''
  };

  public confirmButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    disabled: false,
    onClick: () => {
      this.cancelRelationshipRequest();
    },
  };

  public cancelButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
    onClick: () => {
      this.activeModal.close();
    },
  };

  disabledCancelButtonProps: ButtonPropsType = {
    ...this.cancelButtonProps,
    color: 'white',
    backgroundColor: 'lightGrey',
    hoverBackgroundColor: 'gray',
    disabled: true
  };

  constructor(
    private activeModal: NgbActiveModal,
    private supplierRelationshipService: SupplierRelationshipService,
    private subSupplierRelationshipService: SubSupplierRelationshipService
  ) { }

  ngOnInit(): void { }

  private async cancelRelationshipRequest(): Promise<void> {
    try {
      this.confirmButtonProps = this.disabledCancelButtonProps;
      if (this.invokedBy === 'Super') {
        await this.subSupplierRelationshipService.cancelRelationshipRequest(
          this.parentTsmId,
          this.subsidiaryTsmId,
          this.workflowId
        );
      } else {
        await this.supplierRelationshipService.cancelRelationshipRequest(
          this.invokedBy,
          this.subsidiaryTsmId,
          this.parentTsmId
        );
      }
      this.activeModal.close();
    } catch (error) {
      console.error('Failed to cancel the relationship', error);
    }
  }

  ngAfterContentChecked(): void {
    if (localStorage.getItem('multiLanguage')) {
      const multiLanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      this.translations =
        multiLanguageJson.popUp.confirmCancelParentSubsidiaryRelationshipRequest;
      if (this.invokedBy === 'Parent') {
        this.message = this.translations.subsidiaryMessage;
      } else if (this.invokedBy === 'Subsidiary') {
        this.message = this.translations.parentMessage;
      } else if (this.invokedBy === 'Super') {
        this.message = this.translations.subSupplierMessage;
      }
      this.cancelButtonProps.label = this.translations.cancelLabel;
      this.confirmButtonProps.label = this.translations.confirmLabel;
    }
  }
}
