import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { HttpClient } from "@angular/common/http";
import { saveAs } from "file-saver";
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private downloadUrl = "";
  private fileName = "";
  private SAReportUrl = "";

  constructor(
    private http: HttpClient,
    private httpService: HttpService) {
        this.SAReportUrl = `${environment.tsmReportServiceURL}/report/getAssessmentReport`;
  }

  downloadFile = async (): Promise<any> => {
    try {
      const downloadFileName = this.formatDate() + '_' + environment.processAss;
      this.fileName = 'authority-monthly/' + downloadFileName;
      this.downloadUrl = `${environment.susHubESGServiceURL}/file/download/withSubDirectory/${environment.susHubContact}/${this.fileName}`;
      const file = await this.http.get(this.downloadUrl, { responseType: "blob" }).toPromise();
      if(file == undefined) {
        console.log('Exception in download')
        return;
      }
      const blob = new Blob([file], { type: 'application/vnd.oasis.opendocument.spreadsheet' });
      saveAs(blob, downloadFileName);
    } catch (e) {
      //don't do logs in the frontend, but in future this is where we could put some logic to tell the user their download failed via UI
      console.log(e);
    }
  };

  formatDate() {
    let d = new Date(),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getUTCFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('');
  };

  downloadImageFile = async (fileKey): Promise<any> => {
    try {
      const encodedKey = encodeURIComponent(fileKey)
      const bucket = environment.companyLogoBucketName
      this.downloadUrl = `${environment.susHubESGServiceURL}/file/download/companyLogo/${bucket}/${encodedKey}`;
      const file = await this.http.get(this.downloadUrl, {responseType: "blob" }).toPromise();

      if(file == undefined) {
        console.log('Exception in download')
        return;
      }

      const blob = new Blob([file], { type: "image/png" });
      return blob;
    } catch (e) {
      //don't do logs in the frontend, but in future this is where we could put some logic to tell the user their download failed via UI
      console.log(e);
    }
  };

    /**
     * Description: according to filter conditions to get sustainability assessment report data from report framework database.
     * @Param filterData: data from sustainability assessment report page
     */
   getFilterSAReportData = async (filterData): Promise<any> => {
        const url = `${this.SAReportUrl}`;
        const response = await this.httpService.PostPromise(url, filterData);
        // TO DO: Error handling
        return response;
    };

    createReport = async (searchData): Promise<any> => {
      const url = `${environment.tsmReportServiceURL}/report/createReport`;
      const response = await this.httpService.PostPromise(url, searchData);
      return response;
    };
    
    createReportBuyerExp = async (): Promise<any> => {
      const url = `${environment.tsmReportServiceURL}/report/getDuplicateProfileReport`;
      const response = await this.httpService.GetPromise(url);
      return response;
    };
    getAllMarketUnit= async (): Promise<any> => {
      const url = `${environment.tsmReportServiceURL}/report/marketUnit`;
      const response = await this.httpService.GetPromise(url);
      return response;
    };
    
    getAllCountry= async (): Promise<any> => {
      const url = `${environment.tsmBackendServiceURL}/countries/activeCountryServed`;
      const response = await this.httpService.GetPromise(url);
      return response;
    }

    downloadDataDumpFile = async (s3Url): Promise<any> => {
      try {
        this.fileName = this.formatDate() + '_' + environment.susAss;

        this.downloadUrl = `${environment.tsmReportServiceURL}/report/download`;
        const file: any = await this.http.post(this.downloadUrl, {
          s3Url
        }, { responseType: "blob" }).toPromise();

        if(file == undefined) {
          console.log('Exception in download')
          return;
        }
        // return file
        const blob = new Blob([file], { type: 'application/vnd.oasis.opendocument.spreadsheet' });
        saveAs(blob, this.fileName);
      } catch (e) {
        //don't do logs in the frontend, but in future this is where we could put some logic to tell the user their download failed via UI
        console.log(e);
      }
    };

    downloadProcessDumpFile = async (s3Url, reportType): Promise<any> => {
      try {
        this.fileName = this.formatDate() + '_' + environment.processAss;

        this.downloadUrl = `${environment.tsmReportServiceURL}/report/download`;
        const file: any = await this.http.post(this.downloadUrl, {
          s3Url: s3Url,
          reportType: reportType
        }, { responseType: "blob" }).toPromise();

        if(file == undefined) {
          console.log('Exception in download')
          return;
        }
        // return file
        const blob = new Blob([file], { type: 'application/vnd.oasis.opendocument.spreadsheet' });
        saveAs(blob, this.fileName);
      } catch (e) {
        //don't do logs in the frontend, but in future this is where we could put some logic to tell the user their download failed via UI
        console.log(e);
      }
    };

    downloadProcessDumpFileBuyerExp = async (s3Url, reportType): Promise<any> => {
      try {
        this.fileName = "DuplicateTaxIdSuppliersReport.csv";
        this.downloadUrl = `${environment.tsmReportServiceURL}/report/downloadDuplicateProfileReport`;
        const file: any = await this.http.post(this.downloadUrl, {
          s3Url: s3Url,
          reportType: reportType
        }, { responseType: "blob" }).toPromise();

        if(file == undefined) {
          console.log('Exception in download')
          return;
        }
        // return file
        const blob = new Blob([file], { type: 'application/vnd.oasis.opendocument.spreadsheet' });
        saveAs(blob, this.fileName);
      } catch (e) {
        //don't do logs in the frontend, but in future this is where we could put some logic to tell the user their download failed via UI
        console.log(e);
      }
    };
    downloadValidationLogs = async (buid, downloadReport): Promise<any> => {
      try {
        this.fileName = buid + '.xlsx' ;
        this.downloadUrl = `${environment.tsmBackendServiceURL}/supplier-mass-upload/bu/getData`;
        const payload = {
          buId: buid,
          downloadReport: downloadReport
        };
        const file: any = await this.http.post(this.downloadUrl, {
          buId: buid,
          downloadReport: downloadReport
        }, { responseType: "blob" }).toPromise();
        if(file == undefined) {
          console.log('Exception in download')
          return;
        }
        const blob = new Blob([file], { type: 'application/vnd.oasis.opendocument.spreadsheet' });
        saveAs(blob, this.fileName);
      } catch (e) {
        //don't do logs in the frontend, but in future this is where we could put some logic to tell the user their download failed via UI
        console.log(e);
      }
    };

}
