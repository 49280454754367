import { Component, OnInit } from '@angular/core';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SupplierInviteService } from 'src/app/services/inviteSupplier/supplier-invite.service';

@Component({
  selector: 'tsm-pop-up-confirm-reject-invite-supplier',
  templateUrl: './pop-up-confirm-reject-invite-supplier.component.html',
  styleUrls: ['./pop-up-confirm-reject-invite-supplier.component.sass']
})
export class PopUpConfirmRejectInviteSupplierComponent implements OnInit {

  childTsmId: string;
  comment: string;
  workflowId: string;
  inviteMode: string;

  translations = {
    cancel: '',
    confirm: '',
    confirmRejectionHeader: '',
    confirmRejectionInfoMsg: ''
  };

  confirmButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    disabled: false,
    onClick: () => {
      this.rejectSupplierInvite();
    },
  };

  cancelButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
    onClick: () => {
      this.activeModal.close();
    },
  };

  disabledCancelButtonProps: ButtonPropsType = {
    ...this.cancelButtonProps,
    color: 'white',
    backgroundColor: 'lightGrey',
    hoverBackgroundColor: 'gray',
    disabled: true
  };

  constructor(
    private activeModal: NgbActiveModal,
    private supplierInviteService: SupplierInviteService
  ) { }

  ngOnInit(): void { }

  private async rejectSupplierInvite(): Promise<void> {
    try {
      this.confirmButtonProps = this.disabledCancelButtonProps;
      const payload = {
        supplierTsmId: this.childTsmId,
        comment: this.comment,
        workflowId: this.workflowId
      }
      await this.supplierInviteService.rejectInvitedSupplier(this.inviteMode, payload);
      this.activeModal.close();
    } catch (error) {
      console.error('Failed to reject supplier invitation', error);
    }
  }

  ngAfterContentChecked(): void {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      this.translations = multiLanguageJson.body.main.inviteSupplier;

      this.cancelButtonProps.label = this.translations.cancel;
      this.confirmButtonProps.label = this.translations.confirm;
    }
  }

}
