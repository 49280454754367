<div  class="modal-header" >
    <h4 class="modal-title">{{modalData.title}}</h4>
</div>
<div class="modal-body">
    <div class="modal-body-content" style="min-height: 0!important;">
        <div *ngIf="notifyType == 'send-sa'">
            <div *ngIf="propStatus == 'A'">
                <p>There is an existing sustainability assessment that can be used for {{existingCountries}}, therefore a new assessment request is not required. Visit the Sustainability Assessment section in 'Actions' tab to check the countries listed in the request include {{existingCountries}}.</p>
                <p>To check the sustainability assessment progress for the active request, click the 'View Details' button.</p>
            </div>
            <div *ngIf="propStatus == 'B'">
                <p>Initiating a sustainability assessment request will be valid for {{notExistingCountries}}.</p>
                <p>Meanwhile, there is an existing sustainability assessment that can be used for {{existingCountries}}, therefore a new assessment request is not required for {{existingCountries}}. Visit the Sustainability Assessment section in 'Actions' tab to check the countries listed in the request entry includes {{existingCountries}}.</p>
            </div>
        </div>
        <div *ngIf="notifyType == 'withdraw-sa'">
            <div>{{modalData.content}} <span>{{countryNotCreated}}</span>.</div>
        </div>
        <div *ngIf="notifyType == 'pc-mapping'">
            <div *ngIf="propStatus == 'A'">
                <p>There is an existing Parent company Sustainability Assessment that can be used for {{existingCountries}}, therefore a new assessment request is not required for {{existingCountries}}. Click on 'View Details' to view the status of Parent Assessment.</p>
                <p>Visit the Sustainability Assessment section in 'Actions' tab to check the updated countries listed in the request entry includes {{existingCountries}}.</p>
            </div>
            <div *ngIf="propStatus == 'B'">
                <p>Initiating a sustainability assessment request will be valid for {{notExistingCountries}}.</p>
                <p>Meanwhile, there is an existing Parent company sustainability assessment that can be used for {{existingCountries}}, therefore a new assessment request is not required for {{existingCountries}}.</p>
                <p>Visit the Sustainability Assessment section in 'Actions' tab to check the countries listed in the request entry includes {{existingCountries}}.</p>
            </div>
        </div>
    </div>
    <div class="modal-body-button">
        <sh-button ngbAutofocus [props]="cancelButtonProps"> </sh-button>
        <sh-button *ngIf="propStatus == 'B'" ngbAutofocus [props]="confirmButtonProps" class="float-r"> </sh-button>
        <sh-button *ngIf="propStatus == 'A'" ngbAutofocus [props]="viewDetailsButtonProps" class="float-r"> </sh-button>
    </div>
</div>