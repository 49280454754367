import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { AuthService } from "./services/auth/auth.service";

@Directive({
  selector: "[tsmPermission]"
})
export class PermissionDirective implements OnInit {
  constructor(private el: ElementRef, private authService: AuthService) { }

  @Input("tsmPermission") buttonPermissionId: string;
  authData: any;

  async ngOnInit(): Promise<void> {
    if (!this.authService.getToken()) {
      await this.sleep(5000);
    }
    const btnPer = localStorage.getItem("buttonPermission");
    if (!btnPer || btnPer === "{}") {
      await this.refreshAuth();
    } else {
      this.authData = JSON.parse(btnPer);
    }

    if (this.buttonPermissionId == "supplierAdminManageUser=sph") {
      return;
    }

    const perArr = this.buttonPermissionId.split("=");
    const perId = perArr[0];
    const perPlatform = perArr[1];

    const platFormArr = perPlatform.split("&");
    let authFailedFlag;
    for (let i = 0; i < platFormArr.length; i++) {
      const buttonList = this.authData[platFormArr[i]];
      if (!(buttonList && perId in buttonList && buttonList[perId] > 0)) {
        authFailedFlag = true;
      }
    }
    if (authFailedFlag) {
      // remove html element
      this.el.nativeElement.remove();
    }
  }

  async refreshAuth() {
    localStorage.removeItem("buttonPermission");
    const authResponse = await this.authService.authentication();
    this.authData = authResponse.button;
    // no profile
    if (Object.keys(this.authData).length === 0) {
      return;
    }
    // no result from API
    if (Object.keys(this.authData.tsm).length === 0
    && Object.keys(this.authData.sup).length === 0
    && Object.keys(this.authData.sph).length === 0) {
      return;
    }
    localStorage.setItem("buttonPermission", JSON.stringify(this.authData));
  }

  async sleep(time) {
    return new Promise(resolve => {
      setTimeout(() => resolve("sleep some times"), time);
    });
  }

}
