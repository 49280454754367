<div class="form me-5 mb-5 ms-5 p-5">
    <span [ngClass]="{'error': displayError}">
        {{ translations.text }}
    </span>
  <sh-question-explanations [props]="tooltipProps"></sh-question-explanations>
  <tsm-multiple-sa-uploader
    [attachDocumentation]="translations.attachDocumentation"
    (supplierListFileEvent)="handleSupplierListFileEvent($event)"
  ></tsm-multiple-sa-uploader>
</div>

<div
  *ngIf="showEmptyMessage; else validationList"
  class="form me-5 mb-5 ms-5 p-5 empty-message"
>
  <img
    id="searchImage"
    src="assets/images/EmptyImage_Search.svg"
    alt="emptySearch"
    class="image"
  />
  <span class="highlight-text">
    {{ translations.emptyMessage }}
  </span>
</div>
<ng-template #validationList >
    <div class="form me-5 mb-5 ms-5">
        <tsm-supplier-validation-container
            [supplierListFile]="currentSupplierListFile"
        ></tsm-supplier-validation-container>
    </div>
</ng-template>
