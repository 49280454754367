import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ButtonPropsType, HttpService } from "@lsl16/sustainability-shared-components";
import { Popup } from "../../models/Popup";
import { ModalService } from "src/app/services/modal/modal.service";
import { UpdateApproveRequestConfig } from "./updateApproveRequest";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "tsm-pop-up-sa-status-approve",
  templateUrl: "./pop-up-sa-update-approve.component.html",
  styleUrls: ["./pop-up-sa-update-approve.component.sass"]
})

export class PopUpSaUpdateApproveComponent implements OnInit {
  public redirectUrl = "/termination-request-board";
  @Input() confirmType: UpdateApproveRequestConfig;
  @Input() opened: boolean = true;
  modalData: Popup;
  terminationParams: any;
  yesButton: ButtonPropsType = {
    label: "Yes",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 102,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 24px",
    hoverBackgroundColor: "darkBlue",
    hoverColor: "white",
    onClick: async () => {
      await this.approveRequest();
    }
  };
  cancelButton: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 102,
    height: 48,
    padding: "16px 22px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "purple",
    hoverColor: "white",
    borderColor: "grey"
  };

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    public modalService: ModalService,
    public ngbModalService: NgbModal,
    public httpService: HttpService
  ) { }

  ngOnInit() {
    this.modalData = this.modalService.getSAUpdateApproveData();
    this.cancelButton.onClick = this.activeModal.close;
  }

  async approveRequest() {
    this.terminationParams = {
      ...this.confirmType.terminationParams
    };
    const getTerminateUrl = `${environment.tsmBackendServiceURL}/supplierUpdateSA/approved`;
    const response = await this.httpService.PostPromise(getTerminateUrl, this.terminationParams);
    if (response) {
      this.activeModal.close(response);
    }
  }
}