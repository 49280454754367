import { Component, OnInit } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";
import { manageSARequestApproveModel } from "src/app/models/manageSArequestApprove";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";
import { CountryServedService } from "../../services/countryServedData/country-served.service";
import { countryList } from "src/utilities/const/country-list";
@Component({
  selector: 'tsm-manage-sa-updates-approvals',
  templateUrl: './manage-sa-updates-approvals.component.html',
  styleUrls: ['./manage-sa-updates-approvals.component.sass']
})
export class ManageSAUpdatesApprovalsComponent implements OnInit {
  public termination: manageSARequestApproveModel[];
  pageNumber: number = 1;
  totalNumberOfPages: number = 0;
  noDataFound: boolean = false;
  pageParams: any = {
      page: 1,
      pageSize: 10
  };

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private router: Router,
    private countryServedService: CountryServedService) {
      
    }

  async ngOnInit(): Promise<void> {
    if (this.authService.getRole() !== "authority") {
      document.getElementsByTagName('body')[0].style.display = 'none'
      if (this.checkRole()) {
          this.setCurrentView()
      } else {
        window.alert("You don't have permission to view this page");
      }
    } else {
      await this.checkCountryList()
      document.getElementsByTagName('body')[0].style.display = ''
      await this.showTable();
    }
  }
  async checkCountryList() {
    if (!JSON.parse(localStorage.getItem('countryList'))) {
      countryList['Data'] = await this.countryServedService.countryList()
      countryList['Data'].servedCountryList = this.sortSelectResource(countryList['Data'].servedCountryList)
      localStorage.setItem("countryList", JSON.stringify(countryList['Data']))
    }
  }
  sortSelectResource = (resource: any): any => {
    return resource?.sort((obj1: any, obj2: any) => {
        const val1 = obj1.Text;
        const val2 = obj2.Text;
        if (val1 > val2) {
            return 1;
        } else if (val1 < val2) {
            return -1;
        } else {
            return 0;
        }
    });
  }
  checkRole() {
    return this.getAllRole().includes('authority')
  }
  getAllRole() {
    let platform = this.authService.getCurrentPlatform();
    return this.authService.getAllowedRoles(platform)
  }
  setCurrentView = async (): Promise<void> => {
    this.authService.setCurrentRole('authority');
    await this.authService.handleButtonPermission()
    await this.router.navigateByUrl("/manage-updates-approval-board");
    window.location.reload()
};
  async showTable() {
      if (this.pageNumber) {
          this.pageParams.page = this.pageNumber
      }
      const getRecordsUrl = `${environment.tsmBackendServiceURL}/supplierUpdateSA/getRecords?page=${this.pageParams.page}&pageSize=${this.pageParams.pageSize}`;
      const response: any = await this.httpService.GetPromise(getRecordsUrl);
      const { numberOfPages, data } = response;
      if (data.length === 0) {
          this.noDataFound = true;
      } else {
          this.termination = data;
          this.totalNumberOfPages = numberOfPages;
      }
  }

  handleCurrentPage = (currentPage: number): void => {
      this.pageNumber = currentPage;
      window.scrollTo(0, 270);
      this.showTable();
  };
  
}
