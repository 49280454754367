import { RoleResolutionService } from '../services/roleResolution/role-resolution.service';
import _ from 'lodash';

export class InternalUser {
  public userId: string;
  public userName: string;
  public firstName: string;
  public lastName: string;

  public displayName: string;
  public email: string;
  public displayRole: string;
  public roles: Array<any>;
  
  public isSystemAdmin: boolean;

  constructor(
    roleResolutionService: RoleResolutionService,
    {
      userId,
      userName,
      firstName,
      lastName,
      extension_tsm_TSM,
      extension_tsm_SPH,
      extension_tsm_systemAdmin,
    }
  ) {
    this.userId = userId;
    this.userName = userName;
    this.firstName = firstName;
    this.lastName = lastName;

    this.displayName = `${firstName} ${lastName}`;
    this.email = userName;

    if(!_.isEqual(extension_tsm_TSM, extension_tsm_SPH)) {
      console.log('B2C Extension TSM and SPH do not match. Using TSM one.')
    }
    
    this.roles  = JSON.parse(extension_tsm_TSM);
    this.isSystemAdmin = extension_tsm_systemAdmin;

    // filter supplier roles and concatenate all role names
    roleResolutionService.getRoleDetailsForExtensionString(
      extension_tsm_TSM,
      this.isSystemAdmin,
    ).then(roles => this.displayRole = roles.filter(role => role.profileId !== 2)
                                            .map(role => role.roleName)
                                            .join(", "));
  }
}
