<tsm-secondary-banner></tsm-secondary-banner>
<div class="body_container">
  <div class="main_bg page-padding">
    <div class="container">
      <div class="form shadow-sm">
        <div class="d-flex title">
          <div class="me-auto ms-2 align-self-center cursor-pointer" id="invite-supplier-header">
            {{ translations.header }}
          </div>
        </div>
        <form class="detailform">
          <qnr-dynamic-form
            [configs]="configs"
            [model]="model"
            [viewOnly]="true"
            #form
          ></qnr-dynamic-form>
          <div class="extra-input-container">
            <div class="form-input">
              <label class="form-label" for="supplierPOCFirstName"
                >{{ translations.pocFirstName
                }}<span style="color: #d30e0e">*</span></label
              >
              <input
                type="text"
                class="form-control"
                id="supplierPOCFirstName"
                [value]="model.supplierPOCFirstName"
                disabled
              />
            </div>

            <div class="form-input">
              <label class="form-label" for="supplierPOCLastName"
                >{{ translations.pocLastName
                }}<span style="color: #d30e0e">*</span></label
              >
              <input
                type="text"
                class="form-control"
                id="supplierPOCLastName"
                [value]="model.supplierPOCLastName"
                disabled
              />
            </div>
            <div class="form-input">
              <label class="form-label" for="supplierPOC"
                >{{ translations.pocEmail
                }}<span style="color: #d30e0e">*</span></label
              >
              <input
                type="text"
                class="form-control"
                id="supplierPOC"
                [value]="model.supplierPOCEmail"
                disabled
              />
            </div>
            <label
              for="invite-parent-comment"
              class="form-label"
              [ngStyle]="
                noCommentError || invalidCommentError
                  ? { color: '#d63059' }
                  : ''
              "
              >{{ translations.comment }}</label
            >
            <textarea
              class="comment-text"
              ngbAutofocus
              name="comment"
              [(ngModel)]="model.comment"
              (keyup)="onKeyUp()"
              rows="4"
              cols="90"
              maxlength="200"
              [disabled]="commentDisabled"
              id="invite-parent-comment"
            ></textarea>
            <div class="invalid-msg" id="no-comment">
              <div *ngIf="noCommentError">
                {{ translations.noCommentErrorMsg }}
              </div>
              <div *ngIf="invalidCommentError">
                {{ translations.invalidCommentErrorMsg }}
              </div>
            </div>
            <div class="inline-block">
              <img
                class="aboutico"
                src="assets/icons/icon-infomation.svg"
                alt="icon-information"
              />
              <div class="aboutto">
                {{ translations.inviteSupplierInfoMsg }}
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="flex-start">
                <sh-button [props]="cancelButton"></sh-button>
              </div>
              <div class="d-flex">
                <sh-button
                  [props]="rejectButton"
                  style="margin-right: 24px"
                ></sh-button>
                <sh-button [props]="confirmButton"></sh-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
