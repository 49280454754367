<label class="control-label" for="requestDate"
    [ngStyle]="fromDateInvalid ? {'color': '#d63059'}:''">Request Date Prior To</label>
<div class="row">
    <div class="col-6">
        <div class="date-from">
            <div class="input-area date-picker">
                <input class="form-control date-input" id="request-date-from" [placeholder]="labelTranslations.inputPlaceholder" name="dp"
                    [(ngModel)]="dateFrom" ngbDatepicker #d="ngbDatepicker" (dateSelect)="inputFromChanged()"
                    (input)="inputFromChanged()">

                <span class="calendar" type="button" (click)="d.toggle()"><img class="ico"
                    src="../../../../assets/icons/calendar.svg" alt="calendarIcon"></span>

            </div>
        </div>
        <div class="invalid-msg" id="invalid-fromdate" *ngIf="fromDateInvalid">{{labelTranslations.invalidFromDateMsg}}
        </div>
    </div>
</div>