import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FileUploader } from "file-upload-bfp";
import { isSupplierListResponse, MassInviteService } from 'src/app/services/massInvite/mass-invite.service';
import {
  SupplierListFileEvent,
  ParsedSupplierListFile,
  MassInviteEventTypes as MIEventTypes,
  MassInviteEvents
} from "src/app/models/SupplierListFile";

@Component({
  selector: 'tsm-multiple-sa-uploader',
  templateUrl: './multiple-sa-uploader.component.html',
  styleUrls: ['./multiple-sa-uploader.component.sass']
})
export class MultipleSaUploaderComponent implements OnInit {
  @Input() attachDocumentation: string;
  @Output() supplierListFileEvent = new EventEmitter<SupplierListFileEvent>(true);

  fileToUpload: File | null = null;

  // Unused, but necessary for ng2FileDrop
  uploader!: FileUploader;

  /** Is the current file being read, validated or sent to the backend? */
  isUploading = false;

  // Separate variable from fileLevelErrors because we need to still show the generic
  // error message, even if there are no user-showable details
  hasErrors = false;
  fileLevelErrors: string[] = [];

  labels = {
    genericError: "There is an issue with the file you are trying to upload. Please try again.",
    multiFile: "Sorry, you cannot upload more than one file at the same time.",
    onlyXml: "Only xml files are supported."
  };

  constructor(
    private massInviteService: MassInviteService
  ) { }

  ngOnInit(): void {
    this.initializeUploader();
    this.massInviteService.eventEmit.subscribe((event) => this.handleMassInviteEvent(event));
  }

  private initializeUploader() {
    this.uploader = new FileUploader({
      allowedMimeType: [
        "text/xml",
        ""
      ],
      allowedFileType: [
        "xml"
      ],
      autoUpload: false
    });
  }

  public async handleFile(files: FileList) {
    this.hasErrors = false;
    this.notifyFileDeleted();
    this.fileToUpload = this.getSingleFile(files);
    if (!this.fileToUpload) {
      this.notifyFileDeleted();
      return;
    }
    this.isUploading = true;
    await this.readAndValidateFile();
    this.isUploading = false;
    this.massInviteService.eventEmit.emit({ name: MIEventTypes.NewFile });
  }

  public deleteFile() {
    this.fileToUpload = null;
    this.fileLevelErrors = [];
    this.hasErrors = false;
    this.notifyFileDeleted();
  }

  private notifyFileDeleted() {
    this.supplierListFileEvent.emit({
      deleted: true,
      hasErrors: this.hasErrors
    });
  }

  private getSingleFile(files: FileList): File | null {
    this.hasErrors = false;
    this.fileLevelErrors = [];
    if (!files.length) {
      return null;
    }
    if (files.length > 1) {
      this.hasErrors = true;
      this.fileLevelErrors.push(this.labels.multiFile);
      return null;
    }
    return files.item(0);
  }

  private async readAndValidateFile() {
    let fileContents: ParsedSupplierListFile;
    // Even though the input[file] has accept=".xml", to add a file extension
    // restriction it only applies to the input (not the droptarget). Even then,
    // accept=.xml is optional and can be removed by the user.
    //
    // While the file extension is also validated by the backend, we need to
    // validate it here to prevent the XML->JSON conversion from failing due to
    // trying to read a PNG, for example (which would only show an unhelpful
    // generic error message)
    // 
    if (!this.fileToUpload.name.toLowerCase().endsWith(".xml")) {
      this.hasErrors = true;
      this.fileLevelErrors.push(this.labels.onlyXml);
      this.notifyFileDeleted();
      return;
    }
    try {
      fileContents = await this.massInviteService.readSupplierListFile(this.fileToUpload);
    } catch (err) {
      console.error(`Error reading supplier list file ${JSON.stringify(err)}`);
      this.hasErrors = true;
      this.notifyFileDeleted();
      return;
    }
    const res = await this.massInviteService.validateSupplierListFile(this.fileToUpload.name, fileContents);

    if (isSupplierListResponse(res)) {
      if (res.fileValidations?.length) {
        this.hasErrors = true;
        this.fileLevelErrors.push(...res.fileValidations);
      }
      this.supplierListFileEvent.emit({
        filename: this.fileToUpload.name,
        contents: fileContents,
        validations: res,
        hasErrors: this.hasErrors
      });
    } else {
      console.error(`Error validating supplier list file ${JSON.stringify(res)}`);
      this.hasErrors = true;
      this.notifyFileDeleted();
    }
  }

  private handleMassInviteEvent(event: MassInviteEvents) {
    if (event.name === MIEventTypes.MIProcess_Start) {
      this.isUploading = true;
    } else if (event.name === MIEventTypes.MIProcess_Done || event.name === MIEventTypes.MIProcess_Fail) {
      this.isUploading = false;
    }
  }
}
