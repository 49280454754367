<div class="item">
  <div class="row hover_pointer align-items-center">
    <div class="id-width">
      <div class="subtitle ellipsis" [title]="model.id">{{ model.timeId }}</div>
    </div>
    <div class="col-1-5">
      <h6 class="subtitle ellipsis ml-8" [title]="model.supplierName">
        {{ model.supplierName }}
      </h6>
    </div>
    <div class="col-1-5">
      <div class="subtitle ellipsis ml-8" [title]="model.countryServed">
        {{ model.countryServed }}
      </div>
    </div>
    <div class="col-1-5">
      <h6 class="subtitle ellipsis" [title]="model.updateRequestor">
        {{ model.updateRequestor }}
      </h6>
    </div>
    <div class="col-comment">
      <h6 class="subtitle ellipsis" [title]="model.comments">
        {{ model.comments }}
      </h6>
    </div>
    <div class="date-width">
      <div class="subtitle">{{ model.createTime | date : "MMMM dd yyyy" }}</div>
    </div>
    <div class="col-status">
      <h6 class="subtitle">
        {{ model.status }}
        <img
          *ngIf="model.status === 'Request Rejected'"
          class="icon-size icon-ml"
          placement="bottom-left"
          tooltipClass="manage-user-tooltip-class"
          triggers="click:blur"
          src="assets/icons/info-blue.svg"
          alt="icon-info"
          [ngbTooltip]="rejectReason"
        />
        <ng-template #rejectReason>
          <div class="tip-inner-content">
            {{ model.rejectReason }}
          </div>
        </ng-template>
      </h6>
    </div>
    <div class="subtitle-action" style="display: flex">
      <div>
        <sh-button [props]="rejectButton"></sh-button>
      </div>
      <div style="padding-left: 16px">
        <sh-button [props]="terminateButton"></sh-button>
      </div>
    </div>
  </div>
</div>
