<div *ngIf="opened" class="modal-header">
    <img class="modal-icon" [src]="modalData.iconUrl" alt="icon-modalData" />
    <h4 class="modal-title">{{ modalData.title }}</h4>
</div>
<div *ngIf="opened" class="modal-body">
    <div class="modal-body-content">
        <span class="modal-body-content-text">{{modalData.content}}</span>
    </div>
    <div class="d-flex flex-row-reverse">
        <sh-button ngbAutofocus [props]="cancelButton"> </sh-button>
    </div>
</div>