import { EventEmitter, Injectable } from '@angular/core';
import { HttpService } from '@lsl16/sustainability-shared-components';
import { environment } from '../../../environments/environment';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SystemAdminService {
  baseUrl: string;
  public eventEmit: any;

  constructor(private httpService: HttpService) {
    this.baseUrl = `${environment.susHubB2CUserMgtServiceURL}/users`;
    this.eventEmit = new EventEmitter();
  }

  async searchInternalUsers(criteria: any): Promise<any> {
    let serviceUrl = environment.tsmBackendServiceURL;
    const url = `${serviceUrl}/search/user/internal?criteria=${criteria}`;
    return await this.httpService.GetPromise(url);
  }

  /**
   * Gets a specific user's details from their userID
   * @param userId user id
   */
  async getUsersById(userId: string): Promise<any> {
    const getUserUrl = `${this.baseUrl}/${userId}`;
    return await this.httpService.GetPromise(getUserUrl);
  }

  /**
   * update user role
   * @param userId user id
   * @param roleInfo user roles
   */
  async updateUserRole(userId: string, roleInfo: any): Promise<any> {
    const updateUserRoleUrl = `${this.baseUrl}/internal/${userId}`;
    return await this.httpService.PatchPromise(updateUserRoleUrl, roleInfo);
  }

  public async getUserRoles(): Promise<any> {
    const getUserRoles = `${this.baseUrl}/userRole`;
    return await this.httpService.GetPromise(getUserRoles);
  }

  public mergeProfiles(primaryProfileId, secondaryProfileId) {
    const url=`${environment.tsmBackendServiceURL}/merge-profiles/${primaryProfileId}/${secondaryProfileId}`;
    const reqBody = {
      host: window.location.origin
    }
    const response = this.httpService.PostPromiseHttpResponse<HttpResponse<any> | HttpErrorResponse>(url,reqBody);

    return response;
  }
}
