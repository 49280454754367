import { MassInviteEvents, MassInviteEventTypes as MIEventTypes } from 'src/app/models/SupplierListFile';
import { Component, OnInit, Input } from '@angular/core';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PopUpMassInviteComponent } from 'src/app/components/pop-up-mass-invite/pop-up-mass-invite.component';
import { SelectedSupplierListFileEvent } from 'src/app/models/SupplierListFile';
import { MassInviteService } from 'src/app/services/massInvite/mass-invite.service';
import { SupplierListResponseReportRow } from 'src/app/models/SupplierListFile';

@Component({
  selector: 'tsm-supplier-validation-container',
  templateUrl: './supplier-validation-container.component.html',
  styleUrls: ['./supplier-validation-container.component.sass']
})
export class SupplierValidationContainerComponent implements OnInit {
  currentSupplierListFile: SelectedSupplierListFileEvent;
  @Input() set supplierListFile(file: SelectedSupplierListFileEvent) {
    this.handleSupplierValidationsUpdate(file);
  }

  validatedSuppliersCount: number = 0;
  errorCount: number = 0;
  displaySendAssessmentButton: boolean = false;
  massInviteProcessed: boolean = false;

  //only english labels, because page is not visible in supplier view 
  labels = {
    validatedSuppliers: "Validated Suppliers",
    errors: "Errors",
    sendAssessments: "Send Assessments"
  };

  sendAssessmentButton: ButtonPropsType = {
    label: '',
  };

  enabledSendAssessmentButton: ButtonPropsType = {
    label: this.labels.sendAssessments,
    backgroundColor: 'blue',
    color: 'white',
    borderColor: "blue",
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    width: 205,
    iconPosition: "right",
    onClick: () => {
      this.openMassInviteModal();
    }
  };

  disabledSendAssessmentButton: ButtonPropsType = {
    ...this.enabledSendAssessmentButton,
    backgroundColor: 'lightGrey',
    hoverBackgroundColor: 'gray',
    borderColor: 'mediumGrey',
    disabled: true
  };

  constructor(
    private ngbModalService: NgbModal,
    public massInviteService: MassInviteService
  ) { }

  ngOnInit(): void {
    this.enableSendAssessmentButton();
    this.updateUiFlags();
    this.subscribeToEvents();
  }

  handleSupplierValidationsUpdate(file: SelectedSupplierListFileEvent) {
    this.currentSupplierListFile = file;
    this.updateCountLabels(file?.validations.report);
  }

  updateCountLabels(supplierValidations: SupplierListResponseReportRow[]) {
    this.errorCount = this.calculateErrorCount(supplierValidations);
    this.validatedSuppliersCount = supplierValidations?.length || 0;
  }

  calculateErrorCount(supplierValidations: SupplierListResponseReportRow[]): number {
    let errorCount: number = 0;

    supplierValidations?.forEach(row => {
      if (!row.success) errorCount++;
    });

    return errorCount;
  }

  private enableSendAssessmentButton() {
    this.sendAssessmentButton = this.enabledSendAssessmentButton;
  }

  private updateUiFlags() {
    this.displaySendAssessmentButton = this.currentSupplierListFile ? true : false;
  }

  private openMassInviteModal(): void {
    const massInviteModalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      windowClass: 'add-parent-modal-window',
      backdropClass: 'modal-custom-backdrop',
    };

    const modalReference = this.ngbModalService.open(PopUpMassInviteComponent, massInviteModalOptions);
    modalReference.componentInstance.fileName = this.currentSupplierListFile.filename;
    modalReference.componentInstance.contents = this.currentSupplierListFile.contents;
    let confirmModal = <HTMLElement>document.getElementsByTagName("ngb-modal-window")[0];
    if (confirmModal) confirmModal.setAttribute("aria-hidden", "false");
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  private subscribeToEvents(): void {
    this.massInviteService.eventEmit.subscribe((event) => {
      this.handleMassInviteServiceEvents(event);
    });
  }

  private handleMassInviteServiceEvents(event: MassInviteEvents) {
    switch (event.name) {
      case MIEventTypes.MIProcess_Start:
        this.sendAssessmentButton = this.disabledSendAssessmentButton;
        this.sendAssessmentButton.isProcessing = true;
        break;
      case MIEventTypes.MIProcess_Done:
        this.sendAssessmentButton = this.disabledSendAssessmentButton;
        this.sendAssessmentButton.isProcessing = false;
        this.massInviteProcessed = true;
        break;
      case MIEventTypes.NewFile:
        this.enableSendAssessmentButton();
        break;
    }
  }
}
