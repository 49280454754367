import { PowerBiDashboardComponent } from './views/power-bi-dashboard/power-bi-dashboard.component';
import { UserProfileEditSaAccessComponent } from './views/user-profile-edit-sa-access/user-profile-edit-sa-access.component';
import { Component, NgModule, Type } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthService } from "../app/services/auth/auth.service";
import { AuthGuard } from "../app/services/auth/auth.guard";
import { SupplierActionSelectionComponent } from "./views/supplier-action-selection/supplier-action-selection.component";
import {
    EsgQuestionnaireHeaderComponent,
    SurveyEnvironmentComponent,
    SurveyEsMgtComponent,
    SurveyGettingStartedComponent,
    SurveyHumanSocialComponent,
    SurveySupplierIDComponent,
    SurveyReviewSummaryComponent
} from "@lsl16/sushub-frontend";
import { CallbackComponent } from "./views/callback/callback.component";
import { LayoutComponent } from "./views/layout/layout.component";
import { AddSupplierComponent } from "./views/add-supplier/add-supplier.component";
import { AddGeographyServedComponent } from "./views/add-geography-served/add-geography-served.component";
import { SustainabilityAssessmentComponent } from "./views/sustainability-assessment/sustainability-assessment.component";
import { SupplierProfileContainerComponent } from "./views/supplier-profile-containter/supplier-profile-container.component";
import { MsalGuard } from "@azure/msal-angular";
import { SupplierUserProfileComponent } from "./views/supplier-user-profile/supplier-user-profile.component";
import { CreateNewUserComponent } from "./views/create-new-user/create-new-user.component";
import { LandingContainerComponent } from "./views/landing-container/landing-container.component";
import { supllierHelpAndSupportComponent } from "./views/supplier-help-and-support/supplier-help-and-support.component";
import { GdprDashboardComponent } from "./views/gdpr-dashboard/gdpr-dashboard.component";
import { SystemAdminGuard } from "./services/auth/system-admin.guard";
import { DashboardSearchComponent } from "./views/dashboard-search/dashboard-search.component";
import { SustainabilityAssessmentReportComponent } from './views/sustainability-assessment-report/sustainability-assessment-report.component';
import { SustainabilityAssessmentDownloadComponent } from './views/sustainability-process-report/sustainability-process-report.component';
import { SystemAdminBoardComponent } from "./views/system-admin-board/system-admin-board.component";
import { SystemAdminUserProfileComponent } from "./views/system-admin-user-profile/system-admin-user-profile.component";
import { Supplierhub2esgComponent } from "./views/supplierhub2esg/supplierhub2esg.component";
import { ParentConfirmSupplierProfileComponent } from './views/supplier-profile-containter/parent-confirm-supplier-profile/parent-confirm-supplier-profile.component';
import { BvNewSupplierProfileComponent } from "./views/supplier-profile-containter/bv-new-supplier-profile/bv-new-supplier-profile.component"
import { ParentConfirmSupplierGuard } from "./services/auth/parent-confirm-supplier.guard";
import { SupplierAdminBoardContainerComponent } from "./views/supplier-admin-board-container/supplier-admin-board-container.component";
import { TerminationRequestBoardComponent } from './views/termination-request-board/termination-request-board.component';
import { CountryServedViewComponent } from "./views/country-served-view/country-served-view.component";
import { SAScoringReportComponent } from './views/sascoring-report/sascoring-report.component';
import { SuppplierGuard } from "./services/auth/supplier.guard";
import { LeaveDashboardSearch } from "./services/auth/leave-dashboard-search.service";
import { AddSupplierGuard } from './services/auth/add-supplier.guard';
import { InviteParentSupplierComponent } from './views/invite-parent-supplier/invite-parent-supplier.component';
import { SustainabilityAssessmentNewComponent } from "./views/sustainability-assessment-new/sustainability-assessment-new.component";
import { RequestMultipleSaContainerComponent } from './views/request-multiple-sa-container/request-multiple-sa-container.component';
import { SubSupplierGuard } from './services/auth/sub-supplier.guard';
import { BvHelpSupportComponent } from "./views/bv-help-support/bv-help-support.component";
import { ManageSAUpdatesApprovalsComponent } from './views/manage-SA-updates-approvals/manage-sa-updates-approvals.component';
import { DocumentManagementComponent } from './views/document-management/document-management.component';
import { HelpAndSupportDynamicComponent } from './views/help-and-support-dynamic/help-and-support-dynamic.component';
import { AuthorityUserGuard } from './services/auth/authority-user.guard';
import { EmailTemplateManagerComponent } from './views/email-template-manager/email-template-manager.component';
import { RiskAssessmentQuestionnaireComponent } from './components/risk-assessment-questionnaire/risk-assessment-questionnaire.component';
import { RiskassessmentResolverService } from '../app/services/riskassessment/riskassessment.resolver.service';
import { NewDashboardSearchComponent } from './views/new-dashboard-search/new-dashboard-search.component';
import { CreateBulkTsmProfilesComponent } from './views/create-bulk-tsm-profile-container/create-bulk-tsm-profiles/create-bulk-tsm-profiles.component';
import { environment } from '../environments/environment';
import { RaReportComponent } from './views/ra-report/ra-report.component';
import { SaRetriggeringContainerComponent } from './views/sa-retriggering-container/sa-retriggering-container.component';
import { CreateMergeProfileComponent } from './views/create-merge-profile/create-merge-profile.component';
import { SaRetriggerStatusContainerComponent } from './views/sa-retrigger-status-container/sa-retrigger-status-container.component';

function getDashboardComponent(): Type<Component> {
    if (['prod', 'stage', 'dev'].includes(environment.env)) {
      return <Type<Component>>NewDashboardSearchComponent;
    }else{
      return <Type<Component>>DashboardSearchComponent;
    }
}


const routes: Routes = [
    {
        path: "callback",
        component: CallbackComponent
    },
    //code to make msal=angular current version compatible
    {
        component: LayoutComponent,
        matcher: (url) => {
          return url.length === 1 && (url[0].path.startsWith('code') || url[0].path.startsWith('state'))
            ? { consumed: url }
            : null;
        },
    },
    {
        path: "",
        component: LayoutComponent,
        canActivate: [MsalGuard],
        children: [
            {
                path: "powerbi-dashboard",
                component: PowerBiDashboardComponent,
                canActivate: [AuthGuard],
                data: {
                    title:"Power BI Dashboard"
                }
            },
            {
                path: "risk-assessment-questionnaire/:mode/:code/:cid/:scid/:ic",
                    component: RiskAssessmentQuestionnaireComponent,
                        resolve: {
                    SupplierModel: RiskassessmentResolverService
                }
            },
            {
                path: "risk-assessment-questionnaire/:mode/:code",
                    component: RiskAssessmentQuestionnaireComponent,
                        resolve: {
                    SupplierModel: RiskassessmentResolverService
                }
            },
           
            {
                path: "",
                component: LandingContainerComponent,
                data: {
                    title:"Home"
                }
            },
            {
                path: "supplier-action/:id/:country",
                component: SupplierActionSelectionComponent
            },
            {
                path: "supplierhub2esg",
                component: Supplierhub2esgComponent
            },
            {
                path: "add-supplier",
                component: AddSupplierComponent,
                canActivate: [AuthGuard],
                data: {
                    title:"Add Supplier"
                }
            },
            {
                path: "add-supplier/:workflowId",
                component: InviteParentSupplierComponent,
                canActivate: [AddSupplierGuard]
            },
            {
                path: "add-sub-supplier/:workflowId",
                component: InviteParentSupplierComponent,
                canActivate: [AddSupplierGuard]
            },
            {
                path: "gdpr-dashboard",
                component: GdprDashboardComponent,
                canActivate: [SystemAdminGuard]
            },
            {
                path: "add-geography-served",
                component: AddGeographyServedComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "esg-questionnaire",
                component: EsgQuestionnaireHeaderComponent,
                children: [
                    {
                        path: "",
                        redirectTo: "survey-getting-started",
                        pathMatch: "full",
                        data: {
                            title:"Sustainability Assessment"
                        }   
                    },
                    {
                        path: "survey-getting-started/:id",
                        component: SurveyGettingStartedComponent,
                        canActivate: [AuthGuard],
                        data: {
                            title:"Sustainability Assessment"
                        }   
                    },
                    {
                        path: "survey-environment/:id",
                        component: SurveyEnvironmentComponent,
                        canActivate: [AuthGuard],
                        data: {
                            title:"Sustainability Assessment"
                        }   
                    },
                    {
                        path: "survey-human-social/:id",
                        component: SurveyHumanSocialComponent,
                        canActivate: [AuthGuard],
                        data: {
                            title:"Sustainability Assessment"
                        }   
                    },
                    {
                        path: "survey-supplier-i-d/:id",
                        component: SurveySupplierIDComponent,
                        canActivate: [AuthGuard],
                        data: {
                            title:"Sustainability Assessment"
                        }   
                    },
                    {
                        path: "survey-es-mgt/:id",
                        component: SurveyEsMgtComponent,
                        canActivate: [AuthGuard],
                        data: {
                            title:"Sustainability Assessment"
                        }   
                    },
                    {
                        path: "survey-review-summary/:id",
                        component: SurveyReviewSummaryComponent,
                        canActivate: [AuthGuard],
                        data: {
                            title:"Sustainability Assessment"
                        }   
                    }
                ]
            },
            {
                path: "sustainability-assessment/:id/:country",
                component: SustainabilityAssessmentComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "sustainability-assessment-new/:id/:country",
                component: SustainabilityAssessmentNewComponent,
                canActivate: [AuthGuard],
                data: {
                    title:"New SA Request"
                }
            },
            {
                path: "supplier-profile/:id",
                component: SupplierProfileContainerComponent,
                canActivate: [AuthGuard],
                data: {
                    title:"Supplier Profile"
                }
            },
            {
                path: "supplier-new-profile/:id",
                component: BvNewSupplierProfileComponent,
            },
            {
                path: "parent-supplier-profile/:id",
                component: ParentConfirmSupplierProfileComponent,
                canActivate: [ParentConfirmSupplierGuard]
            },
            {
                path: "parental-supplier-response/:id",
                component: ParentConfirmSupplierProfileComponent,
                canActivate: [ParentConfirmSupplierGuard]
            },
            {
                path: "sub-supplier-profile/:id",
                component: ParentConfirmSupplierProfileComponent,
                canActivate: [SubSupplierGuard]
            },
            {
                path: "supplier-admin-board",
                component: SupplierAdminBoardContainerComponent,
                canActivate: [AuthGuard],
                data: {
                    title:"Supplier Admin Board"
                }
            },
            {
                path: "supplier-admin-board/:id",
                component: SupplierUserProfileComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "supplier-admin-board/:subsidiaryId/:userId",
                component: UserProfileEditSaAccessComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "system-supplier-admin-board",
                component: SupplierAdminBoardContainerComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "system-supplier-admin-board/:id",
                component: SupplierUserProfileComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "system-admin-board",
                component: SystemAdminBoardComponent,
            },
            {
                path: "country-served-view",
                component: CountryServedViewComponent,
                canActivate: [SystemAdminGuard]
            },
            {
                path: "system-admin-board/:id",
                component: SystemAdminUserProfileComponent,
            },
            {
                path: "create-new-user",
                component: CreateNewUserComponent,
                canActivate: [AuthGuard],
                data: {
                    title:"Create New User"
                }
            },
            {
                path: "supplier-support",
                component: supllierHelpAndSupportComponent,
                data: {
                    title:"Supplier Support"
                }
            },
            {
                path: "buyer-help",
                component: BvHelpSupportComponent,
                data: {
                    title:"Help & Support"
                }
            },
            {
                path: "dashboard-search",
                component: getDashboardComponent(),
                canActivate: [SuppplierGuard],
                canDeactivate: [LeaveDashboardSearch],
                data: {
                    keep: true,
                    title:"Dashboard"
                }
            },
            {
                path: "dashboard-search",
                component: getDashboardComponent(),
                canActivate: [SuppplierGuard],
                canDeactivate: [LeaveDashboardSearch],
                data: {
                    keep: true,
                    title:"Dashboard"
                }
            },
            {
                path: "email-template-manager",
                component: EmailTemplateManagerComponent
            },
            {
                path: "sa-scoring-report",
                component: SAScoringReportComponent,
                canActivate: [SuppplierGuard],
                data: {
                    title:"SA Scoring Report"
                }
            },
            {
                path: "request-multiple-sa",
                component: RequestMultipleSaContainerComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "ra-report",
                component: RaReportComponent,
                canActivate: [SuppplierGuard]
            },
            {
                path: "create-bulk-tsm-profiles/:buid",
                component: CreateBulkTsmProfilesComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "create-merge-profile",
                component: CreateMergeProfileComponent,
                canActivate: [SystemAdminGuard]
            },
            {
                path: "sustainability-assessment-report",
                component: SustainabilityAssessmentReportComponent,
                canActivate: [AuthGuard],
                data: {
                    title:"SA Report"
                }
            },
            {
                path: "sustainability-process-report",
                component: SustainabilityAssessmentDownloadComponent,
                canActivate: [AuthGuard],
                data: {
                    title:"SA Process Report"
                }
            },
            {
                path: "termination-request-board",
                component: TerminationRequestBoardComponent,
                data: {
                    title:"Termination Request Board"
                }
            },
            {
                path: "manage-updates-approval-board",
                component: ManageSAUpdatesApprovalsComponent,
                data: {
                    title:"Manage SA Approvals"
                }
            },
            {
                path: "document-management",
                component: DocumentManagementComponent,
                data: {
                    title:"Document Management"
                }
            },
            {
                path: "help-support-new",
                component: HelpAndSupportDynamicComponent,
                canActivate: [AuthorityUserGuard],
                data: {
                    title:"Help & Support"
                }
            },
            {
                path: "sa-retriggering",
                component: SaRetriggeringContainerComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "sa-retrigger-status/:reTriggerId",
                component: SaRetriggerStatusContainerComponent,
                canActivate: [SuppplierGuard]
            },
            
        ]
    },
    {
        path: "access_token",
        component: LayoutComponent,
        canActivate: [MsalGuard],
        children: [{ path: "", component: LandingContainerComponent }]
    },
    {
        matcher: (url) => {
          return url.length === 1 && url[0].path.startsWith('access_token')
            ? { consumed: url }
            : null;
        },
        component: LayoutComponent,
        canActivate: [MsalGuard],
        children: [{ path: "", component: LandingContainerComponent }]
    },
    {
        // Needed for hash routing
        path: "state",
        component: LayoutComponent,
        children: [{ path: "", component: LandingContainerComponent }]
    }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            // Don't perform initial navigation in iframes
            initialNavigation: !isIframe ? "enabledBlocking" : "disabled"
        })
    ],
    exports: [RouterModule],
    providers: [AuthService]
})
export class AppRoutingModule { }
