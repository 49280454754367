import { Injectable } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { HelpAndSupportResponse} from "src/app/models/HelpAndSupport";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class HelpAndSupportService {
    private baseUrl = `${environment.tsmBackendServiceURL}/help-and-support`;

    constructor(private httpService: HttpService) {}

    getData = async (): Promise<HelpAndSupportResponse> => {
        try {
            return await this.httpService.GetPromise(this.baseUrl);
        } catch (error) {
            //TODO error handling
        }
    }

    addData = async (result): Promise<any> => {
        let url = `${this.baseUrl}/addFAQAndHelpContentData`
        try {
            return await this.httpService.PostPromise(url, result);
        } catch (error) {
            console.error('Failed to add FAQ and help content data', error);
        }
    }


    getFAQData = async (): Promise<any> => {
        let url = `${this.baseUrl}/getFAQAndHelpContentData`
        try {
            return await this.httpService.GetPromise(url);
        } catch (error) {
            console.error('Failed to fetch FAQ and help content data', error);
        }
    }
}
