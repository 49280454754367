import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { SupplierRelationshipService } from "@lsl16/sustainability-shared-components";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root"
})
export class SubSupplierGuard  {
    constructor(
        private router: Router,
        private authService: AuthService,
        private supplierRelationshipService: SupplierRelationshipService,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        try {
            if (!this.authService.isValidToken()) {
                this.authService.logout();
                return false;
            }

            const paramId = route.params.id;

            if (this.authService.getRole() !== "supplier") {
                this.router.navigateByUrl(`/supplier-profile/${paramId}`);
                return false;
            }
            else{
                // parent accessing sub-supplier confirmation
                const response = await this.supplierRelationshipService.hasAccessToParentSupplier(paramId);
                if (response?.superSupplier) { return true };
            }

            window.alert("You don't have permission to view this page");
            return false;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}
