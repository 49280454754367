import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from "./auth.service";
import { WorkflowService } from '@lsl16/sustainability-shared-components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopUpNotificationRedirectErrorComponent } from 'src/app/components/pop-up-notification-redirect-error/pop-up-notification-redirect-error.component';

@Injectable({
  providedIn: 'root'
})
export class AddSupplierGuard  {
  constructor(
    private authService: AuthService,
    private workflowService: WorkflowService,
    private ngbModalService: NgbModal,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot) {
    try {
      if (!this.authService.isValidToken()) {
        this.authService.logout();
        return false;
      }

      // Authenticate user on page re-load
      await this.authService.authentication();
      const isGeneralBuyerUser = this.authService.isGeneralBuyerUser();
      const isSustainabilityExpert = this.authService.isSustainabilityExpert();
      const isDelegatedRepresentative = this.authService.isDelegatedRepresentative();
      const isUserAllowedToAccessLink = isGeneralBuyerUser || isSustainabilityExpert || isDelegatedRepresentative;
      if (!isUserAllowedToAccessLink) {
        this.displayErrorMessage('noPermissionError');
        return false;
      }

      const workflowId = route.params.workflowId;
      const response = await this.workflowService.getWorkflowById(workflowId);

      // Potentially an error occurred
      const hasError = !response.state && response.msg;
      if (hasError) {
        console.error(`Error reported from API:`, response.msg);
        return false;
      }

      const isInvitationRequestOpen = response.state && response.state === "AwaitingInviteApproval";
      if (isInvitationRequestOpen) {
        return true;
      }
        
      // If workflow state is not AwaitingInviteApproval, invitation is either approved or rejected
      this.displayErrorMessage('requestClosedInternalUser');
      return false;
    }
    catch (error) {
      console.error(`Error in fetching invited supplier's data:`, error);
      return false;
    }
  }

  private displayErrorMessage(msgLabel) {
    const confirmType = {
      options: {},
      centered: true,
      msgLabel: msgLabel
    };
    const modalRef= this.ngbModalService.open(PopUpNotificationRedirectErrorComponent, confirmType);
    modalRef.componentInstance.confirmType = confirmType;
  }
}
