<div class="container-fluid mb-5">
    <div>
        <div class="search-content ms-5">
            <div class="search-container mt-3">
                <div
                    class="search-textbox d-flex rounded-pill bg-white ps-3 pe-1 py-1 search-error-textbox"
                >
                    <input
                        type="text"
                        id="searchInput"
                        class="pb-1 flex-grow-1"
                        size="30"
                        placeholder="Search for Specific Country Served"
                        [(ngModel)]="inputText"
                        aria-label="input-search"
                        (input)="blockNonPrintableChar()"
                        maxlength="5000"
                    />
                    <div class="sh-btn flex-row-reverse">
                        <sh-button [props]="advSearchSearchButton"> </sh-button>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <sh-button [props]="allCountryServed"></sh-button>
                <sh-button class="ms-2" [props]="activeCountryServed"> </sh-button>
                <sh-button class="ms-2" [props]="inactiveCountryServed"> </sh-button>
            </div>
        </div>
    </div>
    <div class="main_bg">
        <div class="page-content mx-5">
            <sh-country-served-control  (loadingMethod) ="loadingMethod($event)" [parentCountryList] = "parentCountryList"></sh-country-served-control>
        </div>
    </div>
</div>

<div class="loadEffect" style="position: fixed;left: 50%;top:30%;z-index: 1000;" *ngIf="loading">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
