<div class="modal-header">
  <h4 class="modal-title">
    {{ modalTranslation.title }}
  </h4>
</div>
<div class="modal-body">
  <div class="modal-body-content">
    <div class="reason-container">
        <div>
            <label class="reason-label" for="reason_sua">{{ modalTranslation.updateReason }}</label>
            <span *ngIf="reasonRequired" style="color: #d30e0e">*</span>
            <tsm-select-input id="reason_sua"
                [height]="inputHeight"
                [fontSize]="fontSize"
                [color]="color"
                [drapDownData]="updateReasonArr" 
                [multiple]="false"
                [dropDownViewOnly]="buttonDisabled"
                (SingleDateSend)="getSingleDate($event)">
            </tsm-select-input>
        </div>
    </div>

    <div *ngIf="displayPage; else emptyPage">
        <div *ngIf="isChangeConsent; else others">
            <div class="changeConsent-msg-container">
                <div class="infor-icon">
                    <img class="info-title-icon" src="assets/icons/info-blue.svg" alt="icon-update-sa" />
                </div>            
                <div class="infor-message" id="changeConsentInfoMsg">
                    {{ modalTranslation.changeConsentDesc }}
                </div>
            </div>       
        </div>
        <ng-template #others>
            <div class="others-container">
                <div class="comments-container">
                    <label class="comments-label" for="comments_sua">{{ modalTranslation.comments }}</label>
                    <span *ngIf="commentRequired" style="color: #d30e0e">*</span>
                    <div class="coments-content">
                        <textarea class="comments-input" id="comments_sua" (input)="changeComments()" [readonly]="buttonDisabled" [(ngModel)]="comments" rows="5" cols="90" maxlength="500"></textarea>
                    </div>
                </div>
                <div class="others-msg-container">
                    <div class="infor-icon">
                        <img class="info-title-icon" src="assets/icons/info-blue.svg" alt="icon-update-sa" />
                    </div>
                    <div class="infor-message" id="othersInfoMsg">
                        {{ modalTranslation.othersDesc }}
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <ng-template #emptyPage>
        <div class="changeConsent-msg-container">
        </div>
    </ng-template>
    
  </div>
  <div class="modal-buttons">
    <sh-button [props]="cancelButtonProps"></sh-button>
    <sh-button ngbAutofocus [props]="submitButtonProps"></sh-button>
  </div>
</div>


<div class="loadEffect" style="position: fixed;left: 50%;top:30%;z-index: 1000;" *ngIf="loading">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
</div>
