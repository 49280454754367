<div class="container-fluid mb-5">
    <div class="main_bg">
        <sh-alert style="position: absolute;width: 100%;" *ngIf= "saveError" [props]="limitUserAlertProps"></sh-alert>
        <div class="container container-fluid first-container d-flex">
            <div class="search-and-catagory mx-5 px-1 row row-cols-auto justify-content-between">
                <div class="search-container">
                    <div class="search-textbox d-flex rounded-pill bg-white ps-3 pe-3 py-1">
                        <input id="searchUserInput" type="text" class="pb-1" size="30" placeholder="Search for User Account"  [(ngModel)]="userName" (keydown)="searchKeyDown($event)" (input)="blockNonPrintableChar()" maxlength="5000" aria-label="input-text" />
                    </div>
                </div>
                <sh-button *ngIf="isCreateUser_Button_Visible" [props]="props"></sh-button>
            </div> 
            <div class="mx-4">
                <div class="note d-flex flex-row me-auto mb-3 ms-4 ps-1 mx-5">
                    {{'supplier.body.main.supplierAdminBoard.noteLabel'|translate}}
                </div>
            </div>  
        </div>
        <div class="page-content mx-5">
            <sh-admin-board-log [supplierTsmId]="supplierTsmId" [items]="users" [currentLoginEmail]="loggedInUser" [noUsersAssigned]="noUsersAssigned"
                [subsidiaryAccessingParent]="isSubsidiaryAccessingParent">
            </sh-admin-board-log>
        </div>
    </div>
</div>
<tsm-pop-up-disabled hidden=true></tsm-pop-up-disabled>