<tsm-subheader [props]="subheaderProps"></tsm-subheader>
<div class="container-fluid mb-5">
  <div class="main_bg">
    <div class="container container-fluid first-container d-flex">
      <div class="form m-5 p-5">
        <div class="advanced-search advanced-search-content">
            <div class="search-panel bg-white mb-3 mx-0">
                <div class="filters-area row g-3">
                    <div class="col-4 px-16">
                        <tsm-creation-date-filter
                        [clearFilter]="clearFilter"
                        [invalidValue]="invalidValue"
                        (dateFromChanged)="
                            filterCriteriaChanged($event, 'creationDateFrom')
                        "
                        (dateToChanged)="
                            filterCriteriaChanged($event, 'creationDateTo')
                        "
                        >
                        </tsm-creation-date-filter>
                    </div>
                    <div class="col-4 px-16">
                        <tsm-country-served-filter
                          [clearFilter]="clearFilter"
                          (valueChanged)="
                            filterCriteriaChanged($event, 'countryServed')
                          "
                        >
                        </tsm-country-served-filter>
                    </div>
                    <div class="col-4 px-16" id="RAStatus">
                        <label class="filter-control-label form-label" for="RAStatus">RA Status</label> 
                        <tsm-ra-report-filter
                        [dropDownData]="tempRAStatus"
                        [placeholder] = "placeholders.raStatus"
                        [clearFilter]="clearFilter"
                        (valueChanged)="filterCriteriaChanged($event, 'raStatus')"
                        >
                        </tsm-ra-report-filter>
                    </div>
                    <div class="col-4 px-16" id="category">
                        <label class="filter-control-label form-label" for="category">Categories</label> 
                        <tsm-ra-report-filter
                        [dropDownData]="allCategory"
                        [placeholder] = "placeholders.category"
                        [clearFilter]="clearFilter"
                        (valueChanged)="filterCriteriaChanged($event, 'categories')"
                        >
                        </tsm-ra-report-filter>
                    </div>
                    <div class="col-4 px-16" id="subcategory">
                        <label class="filter-control-label form-label" for="subcategory">Subcategories</label> 
                        <tsm-ra-report-filter
                        [dropDownData]="allSubCategory"
                        [placeholder] = "placeholders.subCategory"
                        [clearFilter]="clearFilter"
                        (valueChanged)="filterCriteriaChanged($event, 'subcategories')"
                        >
                        </tsm-ra-report-filter>
                    </div>
                    <div class="col-3 px-16" id="BIBDAStatus">
                        <label class="filter-control-label form-label" for="BIBDAStatus">BI/BDA Status</label> 
                        <tsm-ra-report-filter
                        [dropDownData]="BIBDAStatus"
                        [placeholder] = "placeholders.biBdaStatus"
                        [clearFilter]="clearFilter"
                        (valueChanged)="filterCriteriaChanged($event, 'biBdaStatus')"
                        >
                        </tsm-ra-report-filter>
                    </div>
                    <div class="col-4 px-16">
                        <tsm-supplier-name
                        [clearFilter]="clearFilter"
                        (valueChanged)="filterCriteriaChanged($event, 'supplierName')"
                        ></tsm-supplier-name>
                    </div>
                    <div class="col-4 px-16" id="infoSecScore">
                        <label class="filter-control-label form-label" for="infoSecScore">Info Sec Score</label> 
                        <tsm-ra-report-filter
                        [dropDownData]="infoSecScore"
                        [placeholder] = "placeholders.infoSecScore"
                        [clearFilter]="clearFilter"
                        (valueChanged)="filterCriteriaChanged($event, 'infoSecScore')"
                        >
                        </tsm-ra-report-filter>
                    </div>
                    <div class="col-4 px-16" id="dataPrivacyScore">
                        <label class="filter-control-label form-label" for="dataPrivacyScore">Data Privacy Score</label> 
                        <tsm-ra-report-filter
                        [dropDownData]="dataPrivacyScore"
                        [placeholder] = "placeholders.dataPrivacyScore"
                        [clearFilter]="clearFilter"
                        (valueChanged)="filterCriteriaChanged($event, 'dataPrivacyScore')"
                        >
                        </tsm-ra-report-filter>
                    </div>
                    <div class="col-4 px-16">
                        <div class="checkbox-wrap">
                            <br />
                            <input
                              class="checkbox"
                              type="checkbox"
                              id="includeRaQuesAnsCheckbox"
                              [(ngModel)]="includeRaQuesAns"
                              (change)="inputChanged()"
                            />
                            <label class="label-margin form-label" for="includeRaQuesAnsCheckbox">{{ checkboxLabel }}</label>
                          </div>
                    </div>
                    
                    
                    <div class="inline-block"></div>
                </div>
                <div class="d-flex justify-content-end">
                    <div class="align-self-end me-3">
                        <sh-button [props]="clearFilterButton"></sh-button>
                    </div>
                    <div class="align-self-end">
                        <sh-button [props]="applyFilterButton"></sh-button>
                    </div>
                </div>
                <div 
                    id="waitingMessage"
                    class="d-flex justify-content-end waitingMessage"
                    *ngIf="showWaitingMessage && !filterRequestCompleted">
                        {{waitingMessageLabel}}
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="mx-5">
      <tsm-ra-report-preview
        [applyFilterEvent]="applyFilterEventEmitter"
        (requestStatusEventEmitter)="handleRequestStatusEvent($event)"></tsm-ra-report-preview>
    </div>
  </div>
</div>