<div style="padding-bottom: 30px;">
    <div>
        <div class="file-title">
            Notification
        </div>
        <div style="margin: 0 44px">
            <div class="content">
                {{lable}}
            </div>
            <div style="display: flex;flex-direction: row-reverse;;margin-top: 33px;">
                <sh-button *ngIf="confirmType.title !== 'Published'" [props]="cancel"></sh-button>
                <div>
                    <sh-button [props]="confirm"></sh-button>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="width: 100%;height: 100%;position:absolute;top: 0;background-color: gray;opacity: 0.5;z-index: 1000;"
    *ngIf="loading">
    <div class="loadEffect" style="position: fixed;left: 45%;top:30%;z-index: 1000;">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>