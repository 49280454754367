import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: 'tsm-add-new-supplier',
  templateUrl: './add-new-supplier.component.html',
  styleUrls: ['./add-new-supplier.component.sass']
})
export class AddNewSupplierComponent implements OnInit {
  @Output()
  addNewSupplierEmitter: EventEmitter<any> = new EventEmitter<any>();
  addNewSupplier: ButtonPropsType = {
    label: "Add New Supplier",
    color: "white",
    fontSize: 14,
    backgroundColor: "blue",
    borderColor: "blue",
    hoverColor: "white",
    hoverBackgroundColor: "blue",
    hoverBorderColor: "white",
    height: 42,
    width: 260,
    padding: "10px",
    icon: "assets/icons/person_add-white.svg",
    clickIcon: "assets/icons/person_add-white.svg",
    iconPosition: "left",
    iconAlt: "icon-add-new-supplier",
    onClick: () => this.addNewSupplierEmitter.emit()
  };

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void { }

  getRole(): string {
    return this.authService.getRole();
  }
}
