import { Component, Input, OnInit } from '@angular/core';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileConfig } from 'src/app/components/profile-details/profile';


type messagePopUpModalLiterals = {
  title: string;
  msg: string;
  closeBtn: string;
}
@Component({
  selector: 'tsm-message-pop-up',
  templateUrl: './message-pop-up.component.html',
  styleUrls: ['./message-pop-up.component.sass'],
})
export class MessagePopUpComponent implements OnInit {
  @Input() confirmType: ProfileConfig;
  public modalTranslation: messagePopUpModalLiterals;
  buttonAvailable: boolean = false;
  cancelButtonProps: ButtonPropsType;

  constructor(
    private activeModal: NgbActiveModal
  ) {
  }

  async ngOnInit(): Promise<void> {
    setTimeout(() => {
        this.buttonAvailable = true;
    }, 10000);
  }

  checkButtonAvailable(): void {
      if (this.buttonAvailable) {
        this.cancelButtonAvailable();
      } else {
        this.cancelButtonDisable();
      }
  }

  cancelButtonAvailable(): void {
    this.cancelButtonProps = {
        label: '',
        backgroundColor: 'blue',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Graphik-Medium',
        height: 48,
        disabled: false,
        padding: '16px 24px',
        margin: '0 0 0 2px',
        hoverBackgroundColor: 'darkBlue',
        hoverColor: 'white',
        onClick: () => {
          this.activeModal.close();
        },
    };
  }

  closeButtonAvailable(): void {
    this.cancelButtonProps = {
        label: 'Close',
        backgroundColor: 'blue',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Graphik-Medium',
        height: 48,
        disabled: false,
        padding: '16px 24px',
        margin: '0 0 0 2px',
        onClick: () => {
          this.modalTranslation.closeBtn = "";
          this.activeModal.close();
        },
    };
  }

  cancelButtonDisable(): void {
    this.cancelButtonProps = {
        label: '',
        backgroundColor: 'gray',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Graphik-Medium',
        width: 115,
        height: 48,
        disabled: true,
        padding: '16px 24px',
        margin: '0 0 0 2px',
        hoverBackgroundColor: 'darkBlue',
        class: 'update-pop-up-button',
        hoverColor: 'white'
    };
  }

  private setButtonLabels(): void {
    if(this.modalTranslation.closeBtn =='LATAM'){
    this.closeButtonAvailable();
    }
    else{
      this.cancelButtonProps.label = this.modalTranslation.closeBtn;
    }
  }

  getModalTranslation(){
    this.modalTranslation = this.confirmType.supplier.modalTranslation;         
  }
  
  ngAfterContentChecked() {
    this.getModalTranslation();
    this.checkButtonAvailable();
    this.setButtonLabels();
  }
}
