import { Component, OnInit, Input } from '@angular/core';
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EmailTemplateService } from '../../../app/services/emailTemplateManager/email-template-manager';

@Component({
  selector: 'pop-up-template-delete',
  templateUrl: './pop-up-template-delete.component.html',
  styleUrls: ['./pop-up-template-delete.component.sass']
})

export class PopUpTemplateDeleteComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private emailTemplateService: EmailTemplateService
  ) { }

  @Input() confirmType: any;
  @Input() templateMessage: any;
  showSaveBtn: boolean = true;
  emailLanguage: string = "English";
  EditorTitle: string;
  tip: string = "*";
  emailTypeLabel: string = "Email Type";
  questionLabel: string = "Subject";
  answerLabel: string = "Template";
  emailTypeInfo: string = ""
  publishTip: string = "";
  subjectInfo: string = "";
  templateInfo: string = "";
  helpAndSupportId: string
  isCreate: boolean = true;
  isEdit: boolean = true;
  isDelete: boolean = true;
  isPublish: boolean = true;
  constants: any = {};
  deleteProps: ButtonPropsType = {
    label: "Yes",
    color: "white",
    class: "faqGray",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    padding: "14px 10px",
    margin: "0 0 0 2px",
    iconHeight: 14,
    iconWidth: 14,
    iconPosition: "right",
    disabled: true,
    onClick: async () => {
      await this.deleteTemplate();
    }
  };

  closeProps: ButtonPropsType = {
    label: "Close",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    borderColor: 'gray',
    padding: "14px 5px",
    margin: "0 0 0 2px",
    onClick: () => {
      this.closeClick();
    }
  };

  cancelProps: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    borderColor: 'gray',
    padding: "14px 5px",
    margin: "0 0 0 2px",
    onClick: async () => {
      await this.cancel();
    }
  };

  async ngOnInit(): Promise<void> {
    await this.config();
    this.accessbility();
  }

  async config() {
    const res = this.confirmType;
    this.EditorTitle = res.title;
    this.emailTypeInfo = res.emailType; //emailtype value
    this.subjectInfo = res.emailSubject; //email subject value
    this.templateInfo = res.emailTemplate; //  email template value
    this.emailLanguage = res.emailLanguage;
    this.deleteProps.disabled = false;
    this.deleteProps.class = "faqBlue";
    this.constants = {
      templateDeleteSuccess: `Deleted template for ${this.emailTypeInfo} Successfully !`,
      errormessage: `Something went wrong !`,
      deletePublishMsg: `Are you sure you want to delete the template for ${this.emailTypeInfo} type for ${this.emailLanguage} Language?`
    };
    this.publishTip = this.constants.deletePublishMsg;
  }

  async deleteTemplate() {
    const response: any = await this.emailTemplateService.deleteData({
      templateId: this.confirmType.emailTemplateId,
      templateData: {
        emailtemplate: {
          subject: this.confirmType.emailSubject,
          template: this.confirmType.emailTemplate
        }
      }
    });

    if (response?.msg === 'success') {
      this.publishTip = this.constants.templateDeleteSuccess;
      this.showSaveBtn = false;
    } else {
      this.publishTip = this.constants.errormessage;
      this.showSaveBtn = false;
    }
  }

  async cancel(): Promise<any> {
    this.activeModal.close();
  }
  closeClick(): void {
    this.activeModal.close();
    window.location.reload();
  }

  accessbility() {
    const tsmRoot = document.getElementsByTagName("tsm-root")[0] as HTMLElement;
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }
}
