import { Component, OnInit } from '@angular/core';
import { ButtonPropsType, HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";
import _ from "lodash";

@Component({
  selector: 'tsm-country-served-view',
  templateUrl: './country-served-view.component.html',
  styleUrls: ['./country-served-view.component.sass']
})
export class CountryServedViewComponent implements OnInit {
  allCountryServed: ButtonPropsType;
  activeCountryServed: ButtonPropsType;
  inactiveCountryServed: ButtonPropsType;
  advSearchSearchButton: ButtonPropsType;
  baseUrl: any
  countryList: any;
  inputText: any;
  parentCountryList: any;
  unChangeList: any;
  btnState: string;
  loading: boolean;

  constructor(private httpService: HttpService) {
    this.baseUrl = `${environment.tsmBackendServiceURL}`;
  }

  async ngOnInit(): Promise<void> {
    this.btnState = 'all';
    this.initButton();
    await this.getCountryViewList();
  }

  initButton() {
    this.advSearchSearchButton = {
      label: "Search",
      color: "white",
      fontSize: 16,
      backgroundColor: "purple",
      borderColor: "lightPurple",
      hoverColor: "purple",
      hoverBackgroundColor: "lightPurple",
      hoverBorderColor: "lightPurple",
      height: 40,
      width: 120,
      padding: "10px",
      icon: "assets/icons/icon-search.svg",
      clickIcon: "assets/icons/icon-search.svg",
      iconPosition: "left",
      iconAlt: "icon-search",
      onClick: () => this.search(),
    };
    this.allCountryServed = {
      label: 'All',
      color: 'blue',
      backgroundColor: 'white',
      borderColor: 'blue',
      class: 'active',
      height: 40,
      onClick: () => this.filterQuestions('all'),
    }
    this.activeCountryServed = {
      label: 'Active',
      color: 'greyDark',
      backgroundColor: 'white',
      borderColor: 'greyDark',
      class: 'inactive',
      height: 40,
      onClick: () => this.filterQuestions('active'),
    };
    this.inactiveCountryServed = {
      label: 'Inactive',
      color: 'greyDark',
      backgroundColor: 'white',
      borderColor: 'greyDark',
      class: 'inactive',
      height: 40,
      onClick: () => this.filterQuestions('inactive'),
    };
  }

  async filterQuestions(selectedFilter) {
    this.updateStyles(selectedFilter);
    if(selectedFilter === 'all') {
      this.btnState = 'all';
      await this.search()
    } else if(selectedFilter === 'active') {
      this.btnState = 'active';
      await this.search()
    } else {
      this.btnState = 'inactive';
      await this.search()
    }
  }

  updateStyles(selectedFilter: any) {
    this.allCountryServed.class = 'inactive';
    this.activeCountryServed.class = 'inactive';
    this.inactiveCountryServed.class = 'inactive';

    if (selectedFilter === 'all') {
      this.allCountryServed.class = 'active';
    } else if (selectedFilter === 'active') {
      this.activeCountryServed.class = 'active';
    } else if (selectedFilter === 'inactive') {
      this.inactiveCountryServed.class = 'active';
    }
  }

  async search() {
      if(this.countryList){
        if(this.btnState ==='all'){
          if(!this.inputText){
            this.parentCountryList = this.countryList
          } else{
            this.parentCountryList = this.countryList.filter(item => {
              return item.countryServedName.toLowerCase().indexOf(this.inputText.toLowerCase()) >= 0
            })
          }
        } else if(this.btnState === 'active'){
          if(!this.inputText){
            this.parentCountryList = this.countryList.filter(item => item.countryServedEnable === true)
          }else{
            this.parentCountryList = this.countryList.filter(item => {
              return item.countryServedName.toLowerCase().indexOf(this.inputText.toLowerCase()) >= 0 && item.countryServedEnable === true
            })
          }
        } else if(this.btnState ==='inactive'){
          if(!this.inputText){
            this.parentCountryList = this.countryList.filter(item => item.countryServedEnable === false)
          }else {
            this.parentCountryList = this.countryList.filter(item => {
              return item.countryServedName.toLowerCase().indexOf(this.inputText.toLowerCase()) >= 0 && item.countryServedEnable === false
            })
          }
        }
      }
  }

  async getCountryViewList() {
    const servedDataUrl = `${this.baseUrl}/countries/activeCountryServed`;
    this.countryList = await this.httpService.GetPromise(servedDataUrl);
    this.countryList.sort(function (obj1, obj2) {
      var val1 = obj1.countryServedName;
      var val2 = obj2.countryServedName;
      if (val1 > val2) {
        return 1;
      } else if (val1 < val2) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  async loadingMethod(param:number){
    if(param == 1){
      this.loading =true;
    }else if(param == 2){
      this.loading = false;
      await this.getCountryViewList();
    }
  }
  blockNonPrintableChar() {
    let printablevalue= this.inputText;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.inputText = printablevalue; 
  }
}
