<div class="modal-header">
    <h4 class="modal-title">BI/BDA - Risk Assessment Result</h4>
  </div>
  <div class="modal-body">
    <div class="body-content" *ngIf="showComponent">
      <div class="modal-body-content">
        <qnr-dynamic-form [configs]="configs" [model]="model" [context]="context" [callingComponent]="callingComponent" #form></qnr-dynamic-form>
      </div>
      <div class="button-content">
        <sh-button ngbAutofocus [props]="cancelProps"></sh-button>
        <sh-button class="continueButton" ngbAutofocus [props]="continueProps" [userType]="userType"></sh-button>
      </div>
    </div>
  </div>
  <div style="width: 100%;height: 100%;position:absolute;top: 0;"
    *ngIf="loading">
    <div class="loadEffect" style="position: fixed;left: 45%;top:30%;z-index: 1000;">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>