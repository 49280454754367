import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tsm-bulk-profiles-process-flow',
  templateUrl: './bulk-profiles-process-flow.component.html',
  styleUrls: ['./bulk-profiles-process-flow.component.sass']
})

export class BulkProfilesProcessFlowComponent{

  labelTranslations = {
    stepByStep: '',
    guidelines: ''
  }
  steps: { id: string; name: string; }[] = [
    {
      id: 'downloadTemplate',
      name: 'Download Template'
    },
    {
      id: 'uploadTsmProfileList',
      name: 'Upload TSM Profile List'
    },
    {
      id: 'validationCheckList',
      name: 'Validation Check List'
    },
    {
      id: 'createSupplierProfiles',
      name: 'Create Supplier Profiles'
    },
    {
      id: 'sendAssessments',
      name: 'Send Assessment'
    }
  ]

  constructor() { }

    ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.bulkProfilesProcessFlow;
      }
    }
  }
}
