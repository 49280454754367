<sh-alert [ngStyle]="{'display': successAlertVisible ? 'block' : 'none'}" [props]="successAlert"></sh-alert>
<sh-alert [ngStyle]="{'display': failureAlertVisible ? 'block' : 'none'}" [props]="failureAlert"></sh-alert>
<tsm-subheader [props]= "reportProps"></tsm-subheader>

<div class="container-fluid mb-5">
    <div class="main_bg">
        <div class="container container-fluid first-container d-flex">
            <div class="form shadow-sm m-5 p-5">
                <form>
                    <qnr-dynamic-form [configs]="configs" [model]="model" [context]="context" #form></qnr-dynamic-form>
                    <div class="inline-block"></div>
                    <div class="d-flex justify-content-end">
                      <div class="align-self-end me-3">
                        <sh-button [props]="clearFilterButton"></sh-button>
                      </div>
                      <div class="align-self-end">
                        <sh-button [props]="applyFilterButton"></sh-button>
                      </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="d-flex justify-content-between mx-5" *ngIf="initFlag">
            <div class="align-self-center"> &nbsp; </div>
            <div class="align-self-center">
                <sh-button [props]="downloadButton"></sh-button>
            </div>
        </div>
        <div class="m-5" [ngClass]="{'data-pagination-layout': !emptyResult}" *ngIf="initFlag">
            <div class="report-data-layout">
                <sh-assessment-report [items]="currentPageReport" *ngIf="!emptyResult; else noResults"></sh-assessment-report>
            </div>
            <div class="pagination-layout">
                <tsm-pagination-bar
                *ngIf="initFlag && !emptyResult"
                class="tsm-pagination-bar"
                [pages]="totalNumberOfPages"
                (currentPageChange)="handleCurrentPage($event)"
                [currentPage]="pageNumber"
                ></tsm-pagination-bar>
            </div>
        </div>
        <ng-template #noResults>
            <p class="no-results px-5">
                No records found.<br/><br/>
                Suggestions:<br/>
                - Try a different search scope<br/>
                - Try different filters<br/>
                - Try fewer filters
            </p>
        </ng-template>
    </div>
    
</div>