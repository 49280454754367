import { EventEmitter, Injectable } from "@angular/core";
import { AdminBoardLogItem, HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class AdminBoardService {
  baseUrl: string;
  baseUrlStore: string;
  eventEmit: any;

  constructor(private httpService: HttpService) {
    this.baseUrl = `${environment.susHubB2CUserMgtServiceURL}/users`;
    this.baseUrlStore = `${environment.tsmBackendServiceURL}/userprofile/operationUserProfile`;
    this.eventEmit = new EventEmitter();
  }

  adminBoardLog: AdminBoardLogItem[] = [];

  async getAdminBoardLog(tsmId): Promise<any> {
    return await this.httpService.GetPromise(`${this.baseUrl}/organisation/${tsmId}`);
  }

  /**
   * Gets a specific user's details from their userID
   * @returns: User: AdminBoardLogItem
   * @param userId user id
   */
  async getAdminBoardLogItem( userId: string): Promise<any> {
    const getUserUrl = `${this.baseUrl}/${userId}`;
    return await this.httpService.GetPromise(getUserUrl);
  }

  /**
   * update user role
   * @param userId user id
   * @param roleMapping role id and schema id
   */
  async updateUserRole(userId: string, roleMapping: any): Promise<any> {
    const updateUserRoleUrl = `${this.baseUrl}/userRole/${userId}`;
    return await this.httpService.PatchPromise(updateUserRoleUrl, roleMapping);
  }

  /**
   * delete user by id
   * @param userId user id
   */
  async deleteUser(userId: string, deleteReq: any): Promise<any> {
    const deleteUserUrl = `${this.baseUrl}/deleteUser/${userId}`;
    return await this.httpService.PostPromise(deleteUserUrl, deleteReq);
  }

  /**
   * create user
   * @param createUserData parameters for creating a new user
   */
  async createUser(createUserData: any): Promise<any> {
    return await this.httpService.PostPromise(this.baseUrl, createUserData);
  }
  
  /**
   * create user store
   * @param createUserData parameters for creating a new user
   */
   async createUserStore(createUserData: any): Promise<any> {
    return await this.httpService.PostPromise(this.baseUrlStore, createUserData);
  }

  /**
   * grant supplier user admin access
   * @param userId user id
   */
  async grantSupplierAdminUser(userId: string): Promise<any> {
    const grantUrl = `${this.baseUrl}/supplier/admin/access/${userId}`;
    return await this.httpService.PostPromise(grantUrl, null);
  }
}
