import { Component, OnInit, Input } from '@angular/core';
import { Section } from './section';

@Component({
    selector: 'tsm-section-performance-grid',
    templateUrl: './section-performance-grid.component.html',
    styleUrls: ["./section-performance-grid.component.sass"]
})
export class SectionPerformanceComponent implements OnInit {
    @Input() sections: Section[] = [];

    constructor() { }

    ngOnInit() {}
}
