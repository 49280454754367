<div class="modal-header">
  <h4 class="modal-title">Dear Supplier,</h4>
  <!-- <div *ngIf="duplicateFlag == true">
      <a tabindex="0" (keydown.enter)="activeModal.close()"><img 
          class="modal-header-close-button"
          src="assets/icons/x.svg"
          alt="icon-x"
          (click)="activeModal.close()" 
      /></a>
  </div> -->
</div>

<div class="modal-body">
  <div class="body-content">
      <div class="modal-body-content">
          <p>To improve data quality and your experience in our platform, we’ve made some changes.</p>
          <p>We’ve spotted some duplicates and separate profiles of the same companies in our system. We grouped and merged relevant profiles, so you can see the clear overview of all already gathered data in one place.</p>
          <p>To view all the changes made to your profile, visit the <b>Activity Log</b> on this page or check the <b>Transactions</b> tab on the onboarding page.</p>
          <p>If you believe that the changes should not happen, and would like to roll back the previous version – please contact: <a href="mailto:{{procurementSupport}}">{{procurementSupport}}</a></p>
          <p>Find out more in Supplier Guide …. : <a href="#" (click)="onClicked()">
           Single Supplier Profile Guide
         </a></p>
      </div>
      <div class="d-flex justify-content-left" (keydown.enter)="buttonProps.onClick()">
          <button class="close-btn" (click)="buttonProps.onClick()">
              <div class="close-label" tabindex="0">{{buttonProps.label}}</div>
          </button>
      </div>
  </div>
</div>