<div class="modal-header">
    <h4 class="modal-title">
        {{ translations.title }}
    </h4>
</div>
<div class="modal-body">
    <div class="modal-body-content">
        {{message}}
    </div>
</div>
<div class="modal-buttons">
    <sh-button [props]="cancelButtonProps"></sh-button>
    <sh-button ngbAutofocus [props]="confirmButtonProps"></sh-button>
</div>