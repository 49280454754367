import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonPropsType, SubsidiarySupplier, SupplierRelationshipService } from '@lsl16/sustainability-shared-components';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfirmCancelRelationshipRequestComponent } from '../../confirm-cancel-relationship-request/confirm-cancel-relationship-request.component';

@Component({
  selector: 'tsm-parent-subsidiary-supplier-item',
  templateUrl: './parent-subsidiary-supplier-item.component.html',
  styleUrls: ['./parent-subsidiary-supplier-item.component.sass']
})
export class ParentSubsidiarySupplierItemComponent implements OnInit {
  @Input() model: SubsidiarySupplier;
  @Input() isSupplier: boolean;
  @Input() isSubsidiaryUser: boolean;
  @Input() parentSupplierTsmId: string;
  @Input() subSupplierMode: boolean = false;
  @Input() displayButtons: boolean = false;
  @Input() isDuplicate = false;

  public showCancelRelationshipButton: boolean = false;

  public cancelRelationshipButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 14,
    disabled: false,
    fontFamily: 'Graphik-Medium',
    padding: '0px 0px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
    icon: 'assets/icons/x-grey.svg',
    iconHeight: 14,
    iconWidth: 14,
    onClick: () => {
      this.openConfirmCancelRelationshipModal();
    },
  };

  constructor(
    private router: Router,
    private ngbModalService: NgbModal,
    private authService: AuthService,
    private supplierRelationshipService: SupplierRelationshipService,
  ) { }

  ngOnInit(): void { }

  private openConfirmCancelRelationshipModal(): void {
    const cancelRelationshipModalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      windowClass: 'add-parent-modal-window',
      backdropClass: 'modal-custom-backdrop',
    };

    const modalReference = this.ngbModalService.open(ConfirmCancelRelationshipRequestComponent, cancelRelationshipModalOptions);
    modalReference.componentInstance.parentTsmId = this.parentSupplierTsmId;
    modalReference.componentInstance.subsidiaryTsmId = this.model.supplierTsmId;
    modalReference.componentInstance.invokedBy = 'Parent';
  }

  private async handleCancelRelationshipButtonVisibility(): Promise<void> {
    const hasValidRole = this.authService.getRole() === "supplier" && (this.authService.isGeneralSupplier() || this.authService.isSupplierAdmin());
    if (!hasValidRole) {
      this.showCancelRelationshipButton = false;
      return;
    }

    if (this.subSupplierMode) {
      this.showCancelRelationshipButton = this.displayButtons;
      return;
    }

    const parentAccess = await this.supplierRelationshipService.hasAccessToParentSupplier(this.model.supplierTsmId);
    if (!parentAccess.parent) {
      this.showCancelRelationshipButton = false;
      return;
    }

    this.showCancelRelationshipButton = true;
    
    //Button will be disabled for duplicate supplier profiles
    this.cancelRelationshipButtonProps.disabled = this.isDuplicate;
  }

  navigateToSupplierProfile() {
    let redirectUrl = this.isSupplier ? `parent-supplier-profile/${this.model.supplierTsmId}` : `supplier-profile/${this.model.supplierTsmId}`;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([redirectUrl]);
  }

  async ngOnChanges(): Promise<void> {
    await this.handleCancelRelationshipButtonVisibility();
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multiLanguageJson) {
        this.cancelRelationshipButtonProps.label = multiLanguageJson.body.main.supplierParentDetail.cancelRelationshipButtonLabel;
      }
    }
  }
}
