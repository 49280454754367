<div class="card mb-3 shadow rounded-0 position-relative" style="margin-left: 15px; margin-right: 15px"
  (click)="openSupplier()" tabindex="0" (keydown.enter)="openSupplier()">
  <div class="status d-flex justify-content-end">
    <div *ngIf="supplier.status === 'Pending'" title="{{ this.translations.PendingTooltip }}">
      <tsm-tag [customStyles]="pendingTagStyles"></tsm-tag>
    </div>

    <div *ngIf="supplier.status === 'Active'">
      <tsm-tag [customStyles]="activeTagStyles"></tsm-tag>
    </div>
    <div *ngIf="supplier.status === 'Terminated'">
      <tsm-tag [customStyles]="terminatedTagStyles"></tsm-tag>
    </div>
  </div>

  <div class="row">
    <div class="col-8">
      <div class="title" [title]="supplier.supplierName">
        {{ supplier.supplierName }}
      </div>
    </div>
    <div class="col-4 d-flex justify-content-end">
      <div *ngIf="showIndirectTag" class="status" style="margin-top: 8px !important">
        <tsm-tag [customStyles]="indirectTagStyles"></tsm-tag>
      </div>
    </div>
  </div>

  <div class="breakline mt-2"></div>
  <div class="info-list mb-4">
    <div>
      <ul class="card-lists">
        <li>
          <label class="domainNameLabel">Domain Name</label>
          <span [title]="domainName">{{ domainName }}</span>
        </li>
        <li>
          <label class="registeredLocationLabel">Registered Company Location</label><span>{{ registeredLocation
            }}</span>
        </li>

        <li>
          <label class="dunsNumberLabel">D-U-N-S Number</label><span>{{ dunsNumber }}</span>
        </li>


        <!-- For Demo Purpose -->
        <!-- <li *ngIf="mockData.length">
          <label class="taxIdLabel">BRN/VAT No.</label>
          <span *ngIf="mockData[0].taxId.length" class="taxIdLabel">{{ finalTaxIdResult[0].value | uppercase }}
            <strong>({{ finalTaxIdResult[0].label }})</strong></span>
          <span class="toolTipText taxIdtoolTipText">
            <p *ngFor="let i of finalTaxIdResult"><strong>{{ i.value |uppercase }} ({{ i.label }})</strong></p>
          </span>
        </li> -->
        <!-- For Demo Purpose -->

        <!-- <li>
          <label class="sapIdLabel">SAP ID</label>
          <span class="taxIdLabel">{{ sapIdToDisplay }}</span>
          <span class="toolTipText">{{ sapIdToDisplay }}</span>
        </li> -->

      </ul>
    </div>

  </div>
</div>