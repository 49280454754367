import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ButtonPropsType, Popup } from "@lsl16/sustainability-shared-components";
import { EventService } from 'src/app/services/EventService.service';

@Component({
  selector: 'tsm-pop-up-country-served-notify',
  templateUrl: './pop-up-country-served-notify.component.html',
  styleUrls: ['./pop-up-country-served-notify.component.sass']
})
export class PopUpCountryServedNotifyComponent implements OnInit {

  constructor(
    public eventService: EventService,
    private activeModal: NgbActiveModal,
    private modalService: ModalService
  ) { }
  
  @Input() confirmType;
  modalData: Popup;
  countryNotify: any;
  status: string;
  countryCreated: any;
  popStatus: string;
  contentData = {
    contentFirstPopA: 'There is an existing sustainability assessment that can be used for',
    contentFirstPopB: `need to send a new assessment.`,
    contentFirstPopC: `To view sustainability assessment progress, click the "View Details" button.`,
    contentSecondPopA: `You are about to initiate a sustainability assessment request which will be valid for`,
    contentSecondPopB: `An existing sustainability assessment/s exists for`,
    contentSecondPopC: `need to send a new assessment.`,
    contentThirdPop: `You are about to initiate a sustainability assessment request which will be valid for`,
  }
  cancelButtonProps: ButtonPropsType = {
      label: "Cancel",
      backgroundColor: "white",
      color: "purple",
      fontSize: 16,
      fontFamily: "Graphik-Medium",
      borderColor:"purple",
      height: 48,
      onClick: () => {
        this.activeModal.close();
      }
  };

  cancelFirstButtonProps: ButtonPropsType = {
    label: "Close",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    borderColor:"purple",
    height: 48,
    onClick: () => {
      this.activeModal.close();
    }
};
  
  confirmButtonProps: ButtonPropsType = {
    label: "Confirm",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    onClick: async () => {
        this.confirm();
    }
  };

  confirmFistButtonProps: ButtonPropsType = {
    label: "View details",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    onClick: async () => {
        this.confirm();
    }
  };

  ngOnInit(): void {
    this.countryNotify = '';
    this.popStatus = this.confirmType.notifyType
    this.status = this.confirmType.status;
    this.modalData = this.modalService.getCountryNodifyData(this.confirmType.notifyType);
    this.countryNotify = this.confirmType.countryNotify;
    this.countryCreated = this.confirmType.countryCreated;
  };

  confirm () {
    this.eventService.eventEmit.emit(this.confirmType.notifyType);
    this.activeModal.close();
  };

}
