import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { factories, IEmbedConfiguration, Report, service } from "powerbi-client";
import { environment } from "../../environments/environment";

type DashboardIds = {
    reportId: string,
    workspaceId: string,
}

type PowerBiEmbeddedResponse = {
    embedUrl?: string,
}

function instanceOfDashboardIds(obj: any): obj is DashboardIds {
    let ids = obj as DashboardIds;
    return !!ids.reportId && !!ids.workspaceId;
}


export class HttpClientFactory {
    public static create(backend: HttpBackend): HttpClient {
        return new HttpClient(backend);
    }
}

export class PowerbiServiceFactory {
    public static create(): service.Service {
        return  new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
    }
}

@Injectable({
    providedIn: "root"
})
export class DashboardService {
    private serviceUrl: string;
    private httpClient: HttpClient

    constructor(private httpService: HttpService, httpBackend: HttpBackend) {
        this.serviceUrl = environment.tsmBackendServiceURL;
        this.httpClient = HttpClientFactory.create(httpBackend);
    }

    async getReportLinks(): Promise<DashboardIds> {
        const url = `${this.serviceUrl}/dashboard/links`;
        const response = await this.httpService.GetPromise(url);

        if (instanceOfDashboardIds(response)) {
            return response;
        }

        throw new Error(`Failed to fetch powerbi ids: ${response}`);
    }

    async getPowerBiEmbedLink(accessToken: string): Promise<string> {
        const reportLinks = await this.getReportLinks();
        const url = `https://api.powerbi.com/v1.0/myorg/reports/${reportLinks.reportId}`;

        // create new http client to avoid http interceptors
        const headers = {Authorization: `Bearer ${accessToken}` }; 
        const response =  await this.httpClient.get<PowerBiEmbeddedResponse>(url, {headers: headers}).toPromise();

        if (response?.embedUrl && typeof response.embedUrl === "string") {
            return response.embedUrl;
        }

        throw new Error(`Request for fetching embedded link failed: ${response}`);
    }

    public embedReport(reportContainer: HTMLElement, config: IEmbedConfiguration): Report {
        const powerbi = PowerbiServiceFactory.create();
        const report = powerbi.embed(reportContainer, config) as Report;

        report.iframe.title = 'dashboard';

        return report;
    }
}
