import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';

const powerbiConfig = environment.powerBi;
const msalConfig: Configuration = {
      cache: {
        cacheLocation: "sessionStorage",
      },
      auth: {
        redirectUri: powerbiConfig.redirectUri,
        clientId: powerbiConfig.clientId,
        authority: powerbiConfig.authority,
        navigateToLoginRequestUrl: false,
      },
      system: {
        iframeHashTimeout: 9000,
        loggerOptions: {
            loggerCallback: (
                level: LogLevel,
                message: string,
                containsPii: boolean
            ): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
       
    },
   
  }

  const msalInstance:PublicClientApplication = new PublicClientApplication(msalConfig);

type MsalHandleResponseResult = "NoHandling" | "Success" | "Failed";

export class WindowService{
  public static href(): string {
    return window.location.href;
  }

  public static replace(url: string) {
    window.location.replace(url);
  }

  public static hash(): string {
    return window.location.hash; 
  }
}

@Injectable({
  providedIn: 'root'
})
export class PowerBiAuthService {
  private tokenHelper: JwtHelperService;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { 
    this.tokenHelper= new JwtHelperService();
    
  }

  // public getMsalInsance():msal.PublicClientApplication{
  //   return msalInstance;
  // }

  // public getMsalConfig():Configuration{
  //   return msalConfig;
  // }

  public tokenIsValid(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }

    if (this.tokenHelper.isTokenExpired(token)) {
      return false;
    }

    return true;
  }

  public getToken(): string {
    return sessionStorage.getItem("powerbi-token");
  }

  public static storeToken(token: string){ 
    if (!token) {
      throw new Error("Invalid token");
    }

    sessionStorage.setItem("powerbi-token", token);
  }

  public static getMsalInsance(){
    return msalInstance;
  }

  public static getCurrentConfiguration(){
    return msalConfig;
  }

  public static handleMaslResponse(){
    if (!WindowService.href().includes("#code") && !WindowService.href().includes("#id_token")) {
        return "NoHandling";
      }
    msalInstance.handleRedirectPromise().then((tokenResponse) => {
    if (tokenResponse.tokenType === "id_token") {
      const powerbiConfig = environment.powerBi;
      const request = {
        scopes: powerbiConfig.scopes,
        account: tokenResponse.account,
      };

      // execute another redirect to fetch auth token
      msalInstance.acquireTokenRedirect(request);
      return "Success";
    }

    if (tokenResponse.tokenType === "Bearer") {
      PowerBiAuthService.storeToken(tokenResponse.accessToken);

      // reload current page again to execute the angular app
      WindowService.replace(window.origin);

      return;
    }

    console.warn("auth response is neither id_token nor access_token or Bearer");
   
}).catch((error) => {
    return "Failed"
});
  }
  

  
   public async login() {

    const loginRequest = {
      scopes: powerbiConfig.scopes,
      loginHint: this.getUsername(),
    }

    // store url of the current page for redirecting later
    const currentUrl = WindowService.hash().replace("#", "");
    sessionStorage.setItem("powerbiLoginRedirect", currentUrl);
    msalInstance
    .handleRedirectPromise()
    .then(async (tokenResponse) => {
        if (!tokenResponse) {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                // No user signed in
                msalInstance.loginRedirect(loginRequest);
                // this.router.navigateByUrl(currentUrl);
            }
        } else {
            // Do something with the tokenResponse
        }
    })
    .catch((err) => {
        // Handle error
        console.error(err);
    });

    return "Success";
  }

  private getUsername(): string {
    const username = this.authService.getLoginUser().email;

    if (username.endsWith("@ds.dev.accenture.com")) {
      return username.replace("@ds.dev.accenture.com", "@accenture.com");
    }

    return username;
  }

  // Redirect to the page from where powerbi authentication was called
  // Only callable once per login, subsequent calls have no effect
  public tryRedirectToLoginPage() {
    let powerbiUrl = sessionStorage.getItem("powerbiLoginRedirect");
    sessionStorage.removeItem("powerbiLoginRedirect");

    if (powerbiUrl && this.tokenIsValid()) {
      this.router.navigateByUrl(powerbiUrl);
    }
  }
}
