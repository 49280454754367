import { Component, Input } from '@angular/core';
import { ToolNotification } from 'src/app/models/ToolNotification';
import { NotificationMode } from 'src/app/services/notification/notification.service'

@Component({
  selector: 'tsm-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.sass']
})
export class NotificationListComponent {
  @Input() notifications: ToolNotification[];
  @Input() mode: NotificationMode;
}
