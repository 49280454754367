import { Injectable } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { EsgQuestionnaireService } from "@lsl16/sushub-frontend";
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { QuestionFilters } from "src/app/models/Filters";
import { DropdownItemPropsType, SubheaderMultipleDropdownPropsType } from "src/app/models/SubheaderMultipleDropdown";
import { AuthService } from "src/app/services/auth/auth.service";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { SupplierService } from "src/app/services/supplier/supplier.service";
import { SubheaderPropsType } from "../../../models/SubheaderProps";
import { countryServedList } from "../../../../utilities/const/country-served";
import { countryList } from 'src/utilities/const/country-list';
import _ from "lodash";

@Injectable()
export class EsgQuestionnaireSubheaderViewOnly {

    countries: DropdownItemPropsType[];
    defaultCountryServed: string;
    initStatus: string = "not yet initiated";
    countryServed: string[];
    supplierTsmId: string;
    selectedCountries: string[];
    prevDefaultCountryServed: string;
    prevSupplierTsmId: string;
    parentSupplierTsmId: string;
    props: SubheaderPropsType;

    constructor(
        private supplierEsgQuestionnaireService: EsgQuestionnaireService,
        private router: Router,
        private supplierProfileService: SupplierGeneralProfileService,
        private route: ActivatedRoute,
        private supplierService: SupplierService,
        private authService: AuthService
    ) {
        this.collectQueryParams();
        this.subscribeEsgQuestionnaireEvents();
    }

    sharedButtonProps: ButtonPropsType = {
        label: '',
        fontSize: 16,
        height: 45,
        padding: '10px',
    };

    esgExitButton: ButtonPropsType = {
        ...this.sharedButtonProps,
        label: "Exit",
        color: "purple",
        backgroundColor: "lightPurple",
        borderColor: "lightPurple",
        hoverColor: "purple",
        hoverBackgroundColor: "lightPurple",
        hoverBorderColor: "lightPurple",
        width: 96,
        onClick: () => this.exit()
    };

    esgSaveCloseButton: ButtonPropsType = {
        ...this.sharedButtonProps,
        label: "Save & Close",
        color: "white",
        backgroundColor: "blue",
        borderColor: "blue",
        hoverColor: "white",
        hoverBackgroundColor: "darkBlue",
        hoverBorderColor: "darkBlue",
        icon: "assets/icons/chevron-down-purple.svg",
        clickIcon: "assets/icons/chevron-down-purple.svg",
        iconPosition: "left",
        iconAlt: "icon-chevron-down",
        onClick: () => this.saveAndClose()
    }

    esgAllQuestionseButton: ButtonPropsType = {
        ...this.sharedButtonProps,
        label: 'All Questions',
        color: 'blue',
        backgroundColor: 'white',
        borderColor: 'blue',
        class: 'active',
        onClick: () => this.filterQuestions(QuestionFilters.All)
    };

    esgUnansweredButton: ButtonPropsType = {
        ...this.sharedButtonProps,
        label: 'Unanswered',
        color: 'greyDark',
        backgroundColor: 'white',
        borderColor: 'greyDark',
        class: 'inactive',
        onClick: () => this.filterQuestions(QuestionFilters.UnAnswered)
    };

    esgAnsweredButton: ButtonPropsType = {
        ...this.sharedButtonProps,
        label: 'Answered',
        color: 'greyDark',
        backgroundColor: 'white',
        borderColor: 'greyDark',
        class: 'inactive',
        onClick: () => this.filterQuestions(QuestionFilters.Answered)
    };

    countryDropdown: SubheaderMultipleDropdownPropsType =
        {
            checkedChanged: (event, kv: any) => this.onCountrySelected(event, kv)
        };

    manageUsersButton: ButtonPropsType = {
        ...this.sharedButtonProps,
        label: 'Manage Users',
        disabled: false,
        color: "purple",
        backgroundColor: "lightPurple",
        borderColor: "lightPurple",
        hoverColor: "purple",
        hoverBackgroundColor: "lightPurple",
        hoverBorderColor: "lightPurple",
        tooltipText: "manageUsersTooltip",
        onClick: () => this.redirectToAdminBoard()
    };

    /**
     * Description: Provides props for subheader component in supplier landing page
     * Params: none
     * Output: Subheader props object of type SubheaderPropsType
     */
    getProps = async (): Promise<SubheaderPropsType> => {
        this.updateManageUsersTooltip();
        this.resetFilter();
        this.props = {
            isWelcomeMessage: false,
            headingPrefixColor: "purple",
            heading: "Sustainability Assessment",
            subheaderButtonProps: await this.getSubheaderButtonProps(),
            manageUsersButtonProps: this.manageUsersButton,
            allQuestionsButtonProps: this.esgAllQuestionseButton,
            unansweredButtonProps: this.esgUnansweredButton,
            answeredButtonProps: this.esgAnsweredButton,
        };
        return this.props;
    };

    private updateManageUsersTooltip() {
        const isOnlyGeneralSupplier = this.authService.isGeneralSupplier() && !this.authService.isSupplierAdmin();
        if (isOnlyGeneralSupplier) this.manageUsersButton.tooltipText = "generalSupplierManageUsersTooltip";
    }

    exit() {
        this.supplierEsgQuestionnaireService.eventEmit.emit("exit");
    }

    redirectToAdminBoard = async () => {
        this.router.navigateByUrl(`/supplier-admin-board?supplierTsmId=${this.supplierTsmId}`);
    }

    filterQuestions(selectedFilter) {
        this.updateStyles(selectedFilter);
        this.supplierEsgQuestionnaireService.appliedFilter = {
            questions: selectedFilter,
        };
        this.supplierEsgQuestionnaireService.eventEmit.emit('filter');
    }

    updateStyles(selectedFilter: any) {
        this.esgAllQuestionseButton.class = 'inactive';
        this.esgUnansweredButton.class = 'inactive';
        this.esgAnsweredButton.class = 'inactive';

        if (selectedFilter === QuestionFilters.All) {
            this.esgAllQuestionseButton.class = 'active';
        } else if (selectedFilter === QuestionFilters.UnAnswered) {
            this.esgUnansweredButton.class = 'active';
        } else if (selectedFilter === QuestionFilters.Answered) {
            this.esgAnsweredButton.class = 'active';
        }
    }

    initialiseSupplier = async (): Promise<void> => {
        const supplier = await this.supplierProfileService.getSupplierById(this.supplierTsmId);
        this.countryServed = supplier.countryServed;
    }

    loadCountries = async (): Promise<void> => {
        if (_.isEmpty(this.countries)) {
            await this.initialiseSupplier();
            this.countries = [];
            if (this.countryServed) {
                let result = this.countryServed.reduce((promise, country) => {
                    return promise.then(async last => {
                        const esgStatus = await this.getAssessmentStatus(this.supplierTsmId, country);
                        const isValidEsgStatus = esgStatus != this.initStatus;
                        const countryExists = this.findIfExists(country);
                        if (isValidEsgStatus && countryExists < 0) {
                            this.countries.push({ 'Code': country, 'Text': countryList['Data'].mapping[country] })
                        }
                    });
                }, Promise.resolve());
                result.then(e => {
                    const globalExists = this.findIfExists("global");
                    if (globalExists < 0) {
                        let sortedCountries = _.orderBy(this.countries, ['Text'], ['asc']);
                        this.countries = [{ Code: "global", Text: "Global" }, ...sortedCountries]
                    }
                    this.countryDropdown.itemsToDisplay = this.countries;
                    this.prepareFilterValues();
                });
            }
        }
        else if (this.defaultCountryServed != this.prevDefaultCountryServed) {
            this.prepareFilterValues();
        }
    }

    findIfExists(searchAttribute) {
        return _.findIndex(this.countries, (e) => {
            return e['Code'] === searchAttribute;
        });
    }

    prepareFilterValues() {
        if (!_.isEmpty(this.countries)) {
            this.selectedCountries = ['global', this.defaultCountryServed];
            this.countryDropdown.selectedKeys = this.selectedCountries;
            this.prevDefaultCountryServed = this.defaultCountryServed;
            this.prevSupplierTsmId = this.supplierTsmId;
            this.supplierEsgQuestionnaireService.appliedFilter.countries = this.selectedCountries;
        }
    }

    onCountrySelected(event, kv: any): void {
        this.countryDropdown.selectedKeys = event;
        this.selectedCountries = event;
        this.supplierEsgQuestionnaireService.appliedFilter.countries = this.selectedCountries;
        this.supplierEsgQuestionnaireService.eventEmit.emit('filter');
    }

    setDefaultCountryServed(): void {
        this.route.queryParams.subscribe((params: Params) => {
            this.defaultCountryServed = params["countryServed"];
            this.supplierTsmId = params["supplierTsmId"];
            this.resetCountryFilter();
        });
    }

    getAssessmentStatus = async (supplierTsmId: string, countryCode: string): Promise<string> => {
        if (countryCode === "") {
            return this.initStatus;
        }
        const esgstatus = await this.supplierService.getESGStatus(supplierTsmId, countryCode);
        if (!esgstatus || Object.keys(esgstatus.assessment).length == 0) {
            return this.initStatus;
        } else {
            return esgstatus?.assessment;
        }
    }

    resetCountryFilter() {
        if (this.defaultCountryServed != this.prevDefaultCountryServed || this.supplierTsmId != this.prevSupplierTsmId) {
            this.countryDropdown.selectedKeys = null;
            this.selectedCountries = [];
            this.countries = [];
        }
    }

    resetFilter() {
        if (_.isEmpty(this.supplierEsgQuestionnaireService.appliedFilter)) {
            this.updateStyles(QuestionFilters.All);
        }
    }

    collectQueryParams() {
        this.route.queryParams.subscribe((params: Params) => {
            this.supplierTsmId = params["supplierTsmId"];
            this.defaultCountryServed = params["countryServed"];
            this.parentSupplierTsmId = params.parentSupplierTsmId;
        });
    }

    async getSubheaderButtonProps(): Promise<ButtonPropsType> {
        const isSaveCloseButtonEnable = await this.isSaveCloseButtonEnable();
        return isSaveCloseButtonEnable ? this.esgSaveCloseButton : this.esgExitButton;
    }

    isSaveCloseButtonEnable = async (): Promise<boolean> => {
        if (this.authService.getRole() !== 'authority') {
            return false;
        }

        let supplierTsmId = this.supplierTsmId;
        if (!_.isEmpty(this.parentSupplierTsmId)) {
            supplierTsmId = this.parentSupplierTsmId;
        }

        const esgStatus = await this.getAssessmentStatus(supplierTsmId, this.defaultCountryServed);
        if (esgStatus && esgStatus === "pending review") {
            return true;
        }
        return false;
    }

    saveAndClose() {
        this.supplierEsgQuestionnaireService.eventEmit.emit("reviewDataSaveAndClose");
    }

    subscribeEsgQuestionnaireEvents() {
        this.supplierEsgQuestionnaireService.eventEmit.subscribe(async (event: any) => {
            if (event === "reworkRequestedFromSupplier" && this.props) {
                this.props.subheaderButtonProps = await this.getSubheaderButtonProps();
            }
        });
        this.supplierEsgQuestionnaireService.getValidationCompletedEvent().subscribe(async () => {
            if (this.props) {
                this.props.subheaderButtonProps = await this.getSubheaderButtonProps();
            }
        })
    }
}
