import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { isNgbDateStruct } from 'src/app/models/Search';
@Component({
  selector: 'tsm-creation-date-filter',
  templateUrl: './creation-date-filter.component.html',
  styleUrl: './creation-date-filter.component.sass'
})
export class CreationDateFilterComponent implements OnInit {
  @Output() dateFromChanged = new EventEmitter<Date>();
  @Output() dateToChanged = new EventEmitter<Date>();
  @Input() clearFilter: Subject<boolean>;
  @Input() invalidValue: Subject<string>;
  public dateFrom?: NgbDateStruct = null;
  public dateTo?: NgbDateStruct = null;
  fromDateInvalid = false;
  toDateInvalid = false;
  toDateLessThanFrom = false;
  labelTranslations = {
    controlLabel: '',
    from: '',
    to: '',
    inputPlaceholder: '',
    invalidFromDateMsg: '',
    invalidToDateMsg: '',
    invalidToDateLessMsg: ''
  }
  constructor() { }
  ngOnInit(): void {
    this.clearFilter.subscribe(() => this.clear());
    this.invalidValue.subscribe((event) => this.handleInvalid(event));
  }
  inputFromChanged() {
    this.handleDateChanged(this.dateFromChanged, this.dateFrom);
  }
  inputToChanged() {
    this.handleDateChanged(this.dateToChanged, this.dateTo);
  }
  private handleDateChanged(eventEmitter: EventEmitter<Date|unknown>, value: NgbDateStruct|unknown) { 
    this.resetInvalidFlags();
    if (value && isNgbDateStruct(value)) {
      eventEmitter.emit(new Date(value.year, value.month-1, value.day));
    } else {
      eventEmitter.emit(value);
    }
  }
  clear() {
    this.resetInvalidFlags();
    this.dateFrom = null;
    this.dateTo = null;
  }
  resetInvalidFlags() {
    this.fromDateInvalid = false;
    this.toDateInvalid = false;
    this.toDateLessThanFrom = false;
  }
  handleInvalid(event) {
    if (event === 'creationDateFrom')
      this.fromDateInvalid = true;
    if (event === 'creationDateTo')
      this.toDateInvalid = true;
    if (event === 'creationDateFrom#creationDateTo')
      this.toDateLessThanFrom = true;
  }
  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.requestDate;
      }
    }
  }
}