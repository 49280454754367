import { Injectable } from "@angular/core";
import { GdprLogItem, HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class GdprService {
    tsmgetb2cuser = `${environment.tsmBackendServiceURL}/b2c/getUser`;
    gdprLog: GdprLogItem[] = [];

    constructor(private httpService: HttpService) {}

    /**
     * Search for users based on username
     * @param username
     * @returns
     */
    getUsers = async (username: string): Promise<GdprLogItem> => {
        return await this.httpService.PostPromise<GdprLogItem>(this.tsmgetb2cuser, {id: username});
    };
}
