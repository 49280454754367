import { environment } from "../environments/environment";

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: environment.auth.policies.signUpSignIn.name,
        forgotPassword: environment.auth.policies.forgotPassword.name,
        editProfile: environment.auth.policies.editProfile.name,
        updatePassword: environment.auth.policies.updatePassword.name,
        updatePhoneNumber: environment.auth.policies.updatePhoneNumber.name
    },
    authorities: {
        signUpSignIn: {
            authority: environment.auth.policies.signUpSignIn.authority
        },
        forgotPassword: {
            authority: environment.auth.policies.forgotPassword.authority
        },
        editProfile: {
            authority: environment.auth.policies.editProfile.authority
        },
        updatePassword: {
            authority: environment.auth.policies.updatePassword.authority
        },
        updatePhoneNumber: {
            authority: environment.auth.policies.updatePhoneNumber.authority
        }
    },
    authorityDomain: environment.auth.authorityDomain
};

/**
 * Enter here the coordinates of your Web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig: { scopes: string[]; uri: string } = {
    scopes: environment.auth.apiConfig.scopes,
    uri: environment.auth.apiConfig.uri
};
