<div *ngIf="opened" class="modal-header">
    <img class="modal-icon" [src]="modalData.iconUrl" alt="icon-modalData" />
    <h4 class="modal-title">{{ modalData.title }}</h4>
</div>
<div *ngIf="opened" class="modal-body">
    <div class="modal-body-content">
        <textarea class="rejectReason" (keyup)="onKeyUp()" ngbAutofocus [(ngModel)]="rejectReason" maxlength="400"
            placeholder="Please provide a rejection reason." cols="30" rows="10">
            </textarea>
    </div>
    <div class="d-flex flex-row-reverse">
        <sh-button ngbAutofocus [props]="rejectButton"> </sh-button>
        <sh-button ngbAutofocus [props]="cancelButton"> </sh-button>
    </div>
</div>