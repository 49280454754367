import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { EsgReportService, ReportType, TimeoutError } from 'src/app/services/esgReport/esg-report.service';
import { RequestStatusEvent } from 'src/app/views/report-preview/report-preview.component';
import { FilterData } from '../../services/esgReport/esg-report.service';

@Component({
  selector: 'tsm-sa-and-scoring-report-download-button',
  templateUrl: './sa-and-scoring-report-download-button.component.html',
  styleUrls: ['./sa-and-scoring-report-download-button.component.sass']
})
export class SaAndScoringReportDownloadButtonComponent implements OnInit {
  @Input() filters: FilterData[];
  @Input() totalReportsCount?: number = null;
  @Input() reportType: ReportType;
  @Input() filterRequestCompletedEvent: EventEmitter<RequestStatusEvent> 

  public showTimeoutError = false;
  public timeoutErrorLabel = "";

  downloadButton: ButtonPropsType = {
    label: "Download Full Report",
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    width: 370,
    icon: "assets/icons/file_download-24px.svg",
    iconPosition: "left",
    onClick: () => {
      this.clickDownload();
    }
  };

  enableDownloadButton: ButtonPropsType = {
    ...this.downloadButton,
    disabled: false,
    backgroundColor: 'blue',
    isProcessing: false
  };

  disableDownloadButton: ButtonPropsType = {
    ...this.downloadButton,
    disabled: true,
    backgroundColor: 'mediumGrey',
    isProcessing: true
  };

  constructor(
    private esgReportService: EsgReportService
  ) { }

  ngOnInit(): void { 
    this.filterRequestCompletedEvent.subscribe(() => {
      this.cleanupTimeoutError();
    });
  }

  async clickDownload() {
    try {
      this.disableButton();
      await this.esgReportService.generateReport(this.reportType, this.filters);
      this.enableButton();
    }
    catch (error) {
      if (error instanceof TimeoutError) {
        this.handleTimeoutError();
        return;
      }

      console.error(error);
      this.enableButton();
    }
  }

  private handleTimeoutError() {
    this.showTimeoutError = true;
    this.disableButton(false);
  }

  private cleanupTimeoutError() {
    this.showTimeoutError = false;
    this.enableButton();
  }

  enableButton() {
    this.downloadButton = this.enableDownloadButton;
  }

  disableButton(processing = true) {
    this.downloadButton = this.disableDownloadButton;
    this.downloadButton.isProcessing = processing;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));

      if (multiLanguageJson.body != undefined) {
        const buttonLabel = (this.reportType === "supplier_records") ?
          multiLanguageJson.body.main.saReportPreview.supplierDownloadButtonLabel : multiLanguageJson.body.main.saReportPreview.downloadButtonLabel;

        this.downloadButton.label = buttonLabel.replace("${totalReports}", this.totalReportsCount?.toString());
        this.timeoutErrorLabel = multiLanguageJson.body.main.saReportPreview.donwloadTimeoutErrorMessage;
      }
    }
  }
}
