import { Component, OnInit } from '@angular/core';
import {
  ButtonPropsType,
  HttpService
} from "@lsl16/sustainability-shared-components"
import _ from "lodash";
import { PopUpUploadFileComponent } from "src/app/components/pop-up-upload-file/pop-up-upload-file.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { ConfirmationBoxComponent } from "src/app/components/confirmation-box/confirmation-box.component";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'tsm-document-management',
  templateUrl: './document-management.component.html',
  styleUrls: ['./document-management.component.sass']
})
export class DocumentManagementComponent implements OnInit {

  constructor(private http: HttpClient, public ngbModalService: NgbModal, private httpService: HttpService, public supplierGeneralProfileService: SupplierGeneralProfileService,) { }
  uploadFile: ButtonPropsType = {
    label: "Upload File",
    backgroundColor: "blue",
    color: "white",
    fontSize: 14,
    fontFamily: "Graphik-Medium",
    height: 40,
    borderRadius: 25,
    padding: "14px 10px",
    icon: "assets/images/Download_Icon.svg",
    clickIcon: "assets/images/Download_Icon.svg",
    iconPosition: "left",
    iconAlt: "icon-document-management",
    onClick: async () => {
      this.open(this.formDate, "upload")
    }
  }
  withdrawSuccessSub: any
  formDate: any
  loading: boolean = false
  ngOnInit(): void {
    this.getDocumentdata()
    this.withdrawSuccessSub = this.supplierGeneralProfileService.eventEmit.subscribe(async (value: any) => {
      if (value === "renovateDate") {
        this.getDocumentdata()
      }
    });
  }
  deleted(item) {
    const confirmType = {
      windowClass: "modal-faq",
      title: "delete",
      centered: true,
      size: "lg",
      formDate: item,
    }
    const modalReference = this.ngbModalService.open(ConfirmationBoxComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
  }
  published(item) {
    let add = true
    if (item.userType === "Authority User") {
      for (let i = 0; i < this.formDate.length; i++) {
        if (this.formDate[i].status === "Published" && this.formDate[i].userType === "Authority User") {
          const confirmType = {
            windowClass: "modal-faq",
            title: "Published",
            centered: true,
            size: "lg",
          }
          const modalReference = this.ngbModalService.open(ConfirmationBoxComponent, confirmType);
          modalReference.componentInstance.confirmType = confirmType;
          add = false
          return false
        }
      }
    }

    if (add) {
      const confirmType = {
        windowClass: "modal-faq",
        title: "Publish",
        centered: true,
        size: "lg",
        formDate: item,
      }
      const modalReference = this.ngbModalService.open(ConfirmationBoxComponent, confirmType);
      modalReference.componentInstance.confirmType = confirmType;
    }
  }
  async getDocumentdata() {
    const baseUrl = `${environment.tsmBackendServiceURL}/document/query/authority?isAll=true`;
    const faqData: any = await this.httpService.GetPromise(baseUrl)
    let authorityArr = [];
    let buyerArr = [];
    let supplierArr = [];
    this.formDate = faqData.data
    faqData.data.forEach((item, index) => {
      if (item.status === "1") {
        this.formDate[index].status = "Draft"
      }
      if (item.status === "2") {
        this.formDate[index].status = "Published"
      }
      if (item.userType === "Authority User") authorityArr.push(item);
      if (item.userType === "Buyer") buyerArr.push(item);
      if (item.userType === "Supplier") supplierArr.push(item);
    })
    this.formDate = [...authorityArr, ...buyerArr, ...supplierArr];
  }
  open(item, title) {
    const confirmType = {
      windowClass: "modal-faq",
      title: title,
      centered: true,
      size: "lg",
      formDate: item,
    }
    const modalReference = this.ngbModalService.open(PopUpUploadFileComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
  }
  async downLoad(item) {
    this.loading = true
    const baseUrl = `${environment.tsmBackendServiceURL}/document/download/${item.documentId}`;
    const file = await this.http.get(baseUrl, { responseType: "blob" }).toPromise();
    const link = document.createElement('a')
    let blob = new Blob([file], { type: 'application/pdf' });
    link.style.display = 'none'
    link.href = URL.createObjectURL(blob);
    link.target = '_blank';
    link.setAttribute('download', item.fileName)
    document.body.appendChild(link)
    link.click()
    this.loading = false
    document.body.removeChild(link)
  }
}
