import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { SupplierService } from 'src/app/services/supplier/supplier.service';

@Component({
  selector: 'tsm-supplier-duplicate-check',
  templateUrl: './supplier-duplicate-check.component.html',
  styleUrls: ['./supplier-duplicate-check.component.sass']
})
export class SupplierDuplicateCheckComponent implements OnInit {
  constructor(
    private router: Router,
    private supplierService: SupplierService,
  ) { }
  @Input() formValues: any;
  @Input() matchDataList: any;
  reason:string="";
  showReason:boolean=false;
  showError:boolean=false

  submitButton: ButtonPropsType = {
    label: "Submit",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    width: 127,
    borderRadius: 25,
    backgroundColor: "blue",
    borderColor: "blue",
    padding: "14px 10px",
    margin: "0 0 0 2px",
    iconHeight: 14,
    iconWidth: 14,
    iconPosition: "right",
    onClick: async () => {
      this.save()
    }
  };

  cancelButton: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    width: 95,
    borderRadius: 25,
    borderColor: 'gray',
    padding: "14px 5px",
    margin: "0 0 0 2px",
    onClick: async () => {
      this.cancel()
    }
  };

  ngOnInit(): void {
    this.hasSwFlagTrue();
    window.scrollTo(0, 0);
  }

  hasSwFlagTrue() {
    this.showReason=this.matchDataList.some(item => item.sw_flag === true);
  }

  submitForm(values){
    this.submitButton.isProcessing = true;
    this.supplierService.save(values).then((response) => {
      if (response && response.supplierTsmId) {
        this.goNext(response.supplierTsmId, values);
      } else {
        // handle exception
        this.submitButton.isProcessing = false
      }
    }).catch((error) => {
      // handle exception
      this.submitButton.isProcessing = false;
    });
  }

  save() {
    const values = this.formValues;
    if(this.showReason){
      if(this.reason!=""){
        this.showError=false
        values.reason=this.reason
        this.submitForm(values)
      }else{
        this.showError=true
      }
    }else{
      values.reason=""
      this.submitForm(values)
    }
    
   
  }

  goNext = (supplierTsmId, values): void => {

    this.router.navigate(["supplier-profile", supplierTsmId], {
      queryParams: {
        supplierName: values.supplierName
      }
    });
  }

  cancel() {
    this.router.navigateByUrl("/dashboard-search");
  }
  blockNonPrintableChar() {
    let printablevalue= this.reason;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.reason = printablevalue; 
  }
}
