<header>
    <nav class="py-3 px-5">
        <div class="d-flex">
            <div class="me-auto align-self-center cursor-pointer">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    (click)="backtoHome()"
                    viewBox="0 0 27 27"
                    fill="black"
                    width="27px"
                    height="27px"
                    fill="#4e4e87"
                >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
                    />
                </svg>
                <a
                    class="site-title text-decoration-none cursor-pointer"
                    (click)="backtoHome()"
                    tabindex="0"
                    >{{ labelReturn }}</a
                >
            </div>
        </div>
    </nav>
</header>
