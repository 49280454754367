import {Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges, ChangeDetectorRef} from "@angular/core";
import { ElementRef, HostListener, ViewChild } from '@angular/core';
import { environment } from "src/environments/environment";
import {HttpService } from "@lsl16/sustainability-shared-components";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: 'tsm-select-drop-down-box',
  templateUrl: './select-drop-down-box.component.html',
  styleUrls: ['./select-drop-down-box.component.sass']
})

export class SelectDropDownBoxComponent implements OnInit, OnChanges {
  @Output() private reaload = new EventEmitter();
  @Input() profileData: any;
  @Input() disabled: boolean;

  defaultProfile:string="";
  gradeOptions: any = [];
  dropDownOpen: boolean = false;
  data: any = [];
  baseUrl:string="";
  @ViewChild("inputPanel") inputPanel: ElementRef;
  supplierSelectDropDownBox = {label:''};

  constructor(
    private httpService: HttpService,
    public authService: AuthService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.baseUrl = `${environment.tsmBackendServiceURL}/userprofile`;
  }

  ngOnChanges(changes:{[propKey:string]: SimpleChange }) {
    if(this.profileData && changes.profileData){
      this.profileData = changes['profileData']['currentValue'];
      if (this.profileData && this.profileData.profileData !== undefined) {
        this.gradeOptions = []
          this.defaultProfile = this.profileData.profileData[this.profileData.extension_tsm_defaultProfile].profileName
          this.setOptions();
      }
    }
  }

  setmodel(item) {
    if(this.profileData.profileData[this.profileData.extension_tsm_defaultProfile].profileName !== item){
      localStorage.removeItem(this.profileData.extension_tsm_defaultProfile)
    }
    this.defaultProfile = item
    this.dropDownOpen = false
    for(let key  in  this.profileData.profileData){
        if(this.profileData.profileData[key].profileName === item){
            this.getprofile(key)
        }
    }
  }

  async getprofile(extension_tsm_defaultProfile): Promise<any> {
      const b2cUser = this.authService.decodeToken();
      const data = {
        pocEmail:b2cUser.email,
        extension_tsm_defaultProfile:extension_tsm_defaultProfile,
      }
      this.disabled = true;
      this.reaload.emit(data)
  }

  async dropdownopen() {
    if (this.disabled) {
      return;
    }
    await this.reloadProfile();
    this.dropDownOpen = !this.dropDownOpen
  }

  async reloadProfile(): Promise<any> {
    const b2cUser = this.authService.decodeToken();
    const queryUserProfileUrl = `${this.baseUrl}/getUserprofile/${b2cUser.email}`;
    this.profileData = await this.httpService.GetPromise(queryUserProfileUrl);
    this.setOptions();
  }

  setOptions() {
    this.gradeOptions = [];
    for(let key  in  this.profileData.profileData){
      this.gradeOptions.push(this.profileData.profileData[key].profileName)
    }
  }

  mouseleave(){
  }

  @HostListener('document:click', ['$event'])
  documentClick(event) {
    if (this.inputPanel &&this.inputPanel.nativeElement.contains(event.target)) {
        return
    }
    this.dropDownOpen = false;
  }

  ngAfterContentChecked(): void {
    if (localStorage.getItem("multiLanguage")) {
        const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
        if (multilanguageJson && multilanguageJson.body.main.selectDropDownBox) {
            this.supplierSelectDropDownBox = multilanguageJson.body.main.selectDropDownBox;
        }
    }
    this.cdRef?.detectChanges();
  }
}
