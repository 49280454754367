import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { environment } from "src/environments/environment";
import {HttpService } from "@lsl16/sustainability-shared-components";

@Injectable({
    providedIn: "root"
})
export class AuthGuard  {
    constructor(private authService: AuthService,
        private httpService: HttpService,
        private router: Router,) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authService.isValidToken()) {
            this.authService.logout();
            return false;
        }
        const authMap = await this.authService.authentication();
        const loginUser =await this.authService.getLoginUser();
        const email = loginUser.email
        if (authMap && authMap.page[route.routeConfig.path] !== undefined && authMap.page[route.routeConfig.path] > 0) {
            const tsmId = route.params.id
            if(route.routeConfig.path === 'supplier-profile/:id'&&loginUser.defaultProfile === 'supplier'){
                const baseUrl = `${environment.tsmBackendServiceURL}/userprofile`;
                const queryUserProfileUrl = `${baseUrl}/update`;
                const data = {
                    pocEmail:email,
                    extension_tsm_defaultProfile:tsmId
                }

                await this.httpService.PostPromise(queryUserProfileUrl,data);

                const url = `${baseUrl}/getUserprofile/${data.pocEmail}`;
                const userprofileData:any = await this.httpService.GetPromise(url);
                const user = userprofileData.profileData[userprofileData.extension_tsm_defaultProfile];
                const userInfo = {
                    tsmId: userprofileData.extension_tsm_defaultProfile,
                    pocEmail: data.pocEmail,
                    extension_tsm_privacyStatement: userprofileData.extension_tsm_privacyStatement,
                    extension_tsm_defaultRole: userprofileData.extension_tsm_defaultRole,
                    extension_tsm_systemAdmin: userprofileData.extension_tsm_systemAdmin,
                    extension_tsm_TSM : user.extension_tsm_TSM,
                    extension_tsm_SUP : user.extension_tsm_SUP,
                    extension_tsm_SPH : user.extension_tsm_SPH,
                  };
                  localStorage.setItem("userInfo", JSON.stringify(userInfo));
            }
            return true;
        }
        window.alert("You don't have permission to view this page");
        return false;
    }
}
