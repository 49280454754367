import { Injectable } from '@angular/core';
import { CountryService, HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CountryServedService {
  public baseUrl: string;
  public countryServedConst = {
    transferToSupplierHub: {},
    mapping: {}
  };
  public allCountryList = {
    transferToSupplierHub: {},
    mapping: {},
    valueToKey_nameToTsmCode: {},
    valueToKey_tsmCodeToGroupSa: {},
    valueToKey_nameToGroupSa: {},
    countryGroupSa: {},
    countryGroupSaArr: [],
    countryGroupOnboardArr: [],
    servedCountryList:[]
  };

  constructor(private httpService: HttpService,
              private countryService: CountryService) {
    this.baseUrl = `${environment.tsmBackendServiceURL}`;
  }

  countryServedFilter = async (): Promise<any> => {
    let countryData = await this.getCountryData();
    countryData.forEach(element => {
      if(element.countryServedEnable != undefined && element.countryServedEnable == true) {
        this.countryServedConst.transferToSupplierHub[element.tsmCode] = element.supplierHubCode;
        this.countryServedConst.mapping[element.tsmCode] = element.countryServedName;
      }
    });
    return this.countryServedConst;
  };

  countryList = async (): Promise<any> => {
    let countryData = await this.getCountryData();
    // init countryGroupSaArr, in case data duplicate
    this.allCountryList.countryGroupSaArr = []
    this.allCountryList.servedCountryList = []
    countryData.forEach(element => {
      this.allCountryList.transferToSupplierHub[element.tsmCode] = element.supplierHubCode;
      this.allCountryList.mapping[element.tsmCode] = element.countryServedName;
      this.allCountryList.countryGroupSa[element.tsmCode] = element.countryGroupSa ? element.countryGroupSa : "";
      this.allCountryList.valueToKey_nameToTsmCode[element.countryServedName] = element.tsmCode;
      this.allCountryList.valueToKey_tsmCodeToGroupSa[element.tsmCode] = element.countryGroupSa;
      this.allCountryList.valueToKey_nameToGroupSa[element.countryServedName] = element.countryGroupSa;
      this.allCountryList.countryGroupSaArr.push({
        tsmCode: element.tsmCode,
        countryGroupSa: element.countryGroupSa ? element.countryGroupSa : "",
        countryName: element.countryServedName
      });
      this.allCountryList.countryGroupOnboardArr.push({
        tsmCode: element.tsmCode,
        countryGroupOnboard: element.countryGroupOnboard ? element.countryGroupOnboard : "",
        countryName: element.countryServedName
      });
      if (element.countryServedEnable === true) {
        this.allCountryList.servedCountryList.push(element);
      }
    });
    return this.allCountryList;
  };

  getCountryData = async (): Promise<any> => {
    let countryData = [];
    try {
      let result = await this.countryService.getActiveCountryData();
      if (Array.isArray(result)) {
        countryData = result
      }
    } catch (error) {
      console.log(`getCountryData: ${error}`)
    } finally {
      return countryData;
    }
  }

  getCountryServedWithSameGroupSa = (selectedCountry = '', countryServed = []) => {
      let countryServedNotify = '';
      // get the country details which related to all countries that current profile has
      let countryArr = this.allCountryList.countryGroupSaArr.filter(c => countryServed.includes(c.tsmCode));
      // get the country details of selected country
      let countryDetail = countryArr.filter(c=> c.tsmCode === selectedCountry)[0];
      if (countryDetail) {
          let groupSa = countryDetail.countryGroupSa || '';
          if (groupSa) {
              // if selected country has groupSa, get all country names of this groupSa which current profile has
              countryServedNotify = countryArr.filter(c => countryServed.some(g => c.tsmCode === g && c.countryGroupSa === groupSa)).map(i=>{
                  return i.countryName;
              }).join(', ');
          } else {
              // if selected country doesn't have groupSa, simply return country name
              countryServedNotify = countryDetail.countryName;
          }
      }
      return countryServedNotify;
  }
}
