import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter
} from "@angular/core";
import { Subscription } from "rxjs";
import { ButtonPropsType, SAStatus, UserType } from "@lsl16/sustainability-shared-components";
import { Options } from "../../models/Options";
import { EsgQuestionnaireService, GeneralSupplierType } from "@lsl16/sushub-frontend";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { AuthService } from "src/app/services/auth/auth.service";
import { BvPopUpTerminationComponent } from "../bv-pop-up-termination/bv-pop-up-termination.component";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { PopUpCountryServedNotifyComponent } from 'src/app/components/pop-up-country-served-notify/pop-up-country-served-notify.component';
import { CountryServedService } from "src/app/services/countryServedData/country-served.service";
import { EventService } from 'src/app/services/EventService.service';

@Component({
  selector: "tsm-options-card",
  templateUrl: "./options-card.component.html",
  styleUrls: ["./options-card.component.sass"]
})
export class OptionsCardComponent implements OnInit, OnChanges {
  @ViewChild('tsmBuyerPopUp', { static: true }) tsmBuyerPopUp;
  @Output() private refreshData = new EventEmitter();
  @Input() options: Options;
  @Input() userType: UserType;
  @Input() esgStatus: String;
  @Input() supplier: GeneralSupplierType;
  requestButton: ButtonPropsType;
  requestButton2: ButtonPropsType;
  saveSubmission: Subscription;
  changeStatusEventEmitSubscription: any;
  optionsCardJson: any;
  manageUsersButton: ButtonPropsType;  
  updateSAButton: ButtonPropsType;  
  reviewAssessmentButton: ButtonPropsType;
  sharedButtonProps: ButtonPropsType;
  updateSAButtonProps:ButtonPropsType;
  reasonButton: boolean = false;
  cardShow: boolean;
  tsmId: string;
  country: string;
  hasValidRole: boolean = false;
  isSystemSupplierAdmin: boolean;
  isBuyerExpert;
  showSendAssessmentButton: boolean;
  buttonJson: any;
  saActionSubscription: Subscription;
  ProfileDetailStrictData = {
    options: {},
    supplier: {},
    centered: true,
    size: "lg",
    windowClass: "countdown-termination",
  };

  constructor(
    private esgQuestionnaireService: EsgQuestionnaireService,
    public eventService: EventService,
    public ngbModalService: NgbModal,
    public authService: AuthService,
    private httpService: HttpService,
    public supplierGeneralProfileService: SupplierGeneralProfileService,
    private countryServedService: CountryServedService
  ) { }

  ngOnInit(): void {
    this.refresh();
    const userInfo = this.authService.getLoginUser();
    this.isSystemSupplierAdmin = this.authService.isSystemSupplierAdmin();
    this.isBuyerExpert = this.authService.isBuyerExpert();
    this.handleManageUsersButtonVisibility();
    this.handleEsgButtonVisibility();
    this.sharedButtonProps = {
      label: "",
      fontSize: 16,
      height: 48,
      width: 220,
      padding: '10px'
    };
    this.setRequestButtonProps();
    this.setManageUsersButtonProps();    
    this.setReviewAssessmentButtonProps();
    this.refreshButtonVisibility();
    this.setUpdateSAButtonProps();
    this.requestButton2 = this.requestButton;
    this.requestButton2 = {
      fontSize: 16,
      height: 48,
      width: 220,
      padding: "10px",
      label: "Withdraw Request",
      color: "white",
      backgroundColor: "blue",
      borderColor: "blue",
      hoverColor: "white",
      hoverBackgroundColor: "blue",
      hoverBorderColor: "white",
      onClick: () => {
        this.open();
      }
    };
    if (window.location.href.indexOf("supplier-profile") > -1 || window.location.href.indexOf("supplier-action") > -1  && userInfo.defaultProfile === "buyer") {
      this.cardShow = true
    } else {
      this.cardShow = false;
    }
    this.saActionSubscription = this.eventService.eventEmit.subscribe(e => {
      // esgStatus has a value means this is SA card, in case that onboarding card subscribes the same event and opens pop up twice
      if (e === 'withdraw-sa' && this.esgStatus) {
          this.withdraw();
      };
    });
  }

  showTooltip(): void {
    this.reasonButton = true;
  }

  hideTooltip(): void {
    this.reasonButton = false;
  }

  async open() {
    // get country name(s) that currently taking action with
    let countryServedNotify = this.countryServedService.getCountryServedWithSameGroupSa(this.options.countryServed, this.supplier.countryServed);
    const confirmType = {
        options: {},
        notifyType: 'withdraw-sa',
        countryNotify: countryServedNotify,
        centered: true,
        size: "lg",
        windowClass: "modal-country-notify",
    };

    // pop up country notify
    const modalReference = this.ngbModalService.open(PopUpCountryServedNotifyComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
    let tsmRoot  =  <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden","false");
    }
  }

  async withdraw() {
    const service = this.ngbModalService;
    const countryServed = this.options.countryServed
    const authUrl = `${environment.susHubESGServiceURL}/invite-event/records?supplierTsmId=${this.options.tsmId}&countryServed=${countryServed}`;
    const items = await this.httpService.GetPromise(authUrl);
    const confirmType = this.ProfileDetailStrictData;
    confirmType.supplier = items;
    confirmType.options = this.options;
    const modalReference = this.ngbModalService.open(BvPopUpTerminationComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
    let tsmRoot  =  <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden","false");
    }
  }

  setRequestButtonProps = (): void => {
    this.requestButton = {
      ...this.sharedButtonProps,
      ...this.options.buttonProps,
      onClick: () => {
        this.options.onClick();
      }
    };
    this.changeButtonStatusSubscription();
  };

  handleManageUsersButtonVisibility(): void {
    const userRole = this.authService.getRole();
    const isValidSupplier = userRole === 'supplier' &&
      (this.authService.isSupplierAdmin() || this.authService.isGeneralSupplier());
    const isValidBuyer = userRole === 'buyer' && this.isSystemSupplierAdmin;
    this.hasValidRole = isValidSupplier || isValidBuyer;
  }

  handleEsgButtonVisibility() {
    if (this.options.showButton === undefined)
      this.options.showButton = true;
  }

  setManageUsersButtonProps = (): void => {
    this.manageUsersButton = {
      ...this.sharedButtonProps,
      ...this.options.manageUsersButtonProps,
      onClick: () => {
        this.options.onManageUsersButtonClick();
      },
    };
  };

  /**
   * supplier User could request to update SA function
   */
  setUpdateSAButtonProps = (): void => {
    this.updateSAButton = {
      ...this.updateSAButton,
      ...this.options.updateSAButtonProps,
      onClick: () => {
        this.options.onUpdateSAButtonClick();
      },
    };
  };

  setReviewAssessmentButtonProps = (): void => {
    this.reviewAssessmentButton = {
      ...this.sharedButtonProps,
      ...this.options.reviewAssessmentButtonProps,
      onClick: () => {
        this.options.onReviewAssessmentButtonClick();
      },
    };
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.setRequestButtonProps();
      this.setManageUsersButtonProps();      
      this.setReviewAssessmentButtonProps();
      this.setUpdateSAButtonProps();
    }
    if (changes.esgStatus) {
      this.refreshButtonVisibility();
    }
  }

  private refreshButtonVisibility() {
    this.showSendAssessmentButton = !this.esgStatus ||
      this.esgStatus === SAStatus.notYetInitiated ||
      this.esgStatus === SAStatus.submittedApproved ||
      this.esgStatus === SAStatus.submittedScored ||
      this.esgStatus === SAStatus.withdrew;
  }

  changeButtonStatusSubscription = (): void => {
    this.changeStatusEventEmitSubscription = this.esgQuestionnaireService.eventEmit.subscribe(
      (event) => {
        if (typeof event === "string") {
          const arr = event.split("#");
          if (arr[0] === "buttonStatusChanged") {
            if (!arr[1]) {
              return;
            }
            const changePros = JSON.parse(arr[1]);
            this.requestButton = {
              fontSize: 16,
              height: 48,
              width: 220,
              padding: "10px",
              ...changePros,
              onClick: () => {
                this.options.onClick();
              }
            };
          } else if(arr[0] === "updateSAButtonChanged" && this.options.updateSAButtonProps){
            if (!arr[1]) {
              return;
            }
            const changePros = JSON.parse(arr[1]);
            this.updateSAButton = {
              ...changePros,
              onClick: () => {
                this.options.onUpdateSAButtonClick();
              },
            };
          }else if (arr[0] === "manageUsersButtonStatusChanged" && this.options.manageUsersButtonProps) {
            if (!arr[1]) {
              return;
            }
            const changePros = JSON.parse(arr[1]);
            this.manageUsersButton = {
              ...changePros,
              onClick: () => {
                this.options.onManageUsersButtonClick();
              },
            };
          }
        }
      }
    );
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multiLanguageJson.body != undefined && this.options.title != undefined) {
        this.optionsCardJson = multiLanguageJson.body.main.optionsCard;
        if (this.optionsCardJson.hasOwnProperty(this.options.title)) {
          this.optionsCardJson.title = this.optionsCardJson[this.options.title];
        }
        if (this.optionsCardJson.hasOwnProperty(this.options.description)) {
          this.optionsCardJson.description = this.optionsCardJson[this.options.description];
        }
        if (this.optionsCardJson.hasOwnProperty(this.options.relationshipInfoText)) {
          this.optionsCardJson.relationshipInfoText = this.optionsCardJson[this.options.relationshipInfoText];
        }
        if (this.optionsCardJson.hasOwnProperty(this.options.showParentHandlingSAInfo)) {
          this.optionsCardJson.showParentHandlingSAInfo = this.optionsCardJson[this.options.showParentHandlingSAInfo];
        }
        this.buttonJson = multiLanguageJson.body.sharedComponent.buttonLable;
        if (this.manageUsersButton && this.buttonJson.hasOwnProperty(this.manageUsersButton.tooltipText)) {
          this.buttonJson.manageUsersTooltip = this.buttonJson[this.manageUsersButton.tooltipText];
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (!!this.saveSubmission) {
      this.saveSubmission.unsubscribe();
      }
      if (!!this.saActionSubscription) {
          this.saActionSubscription.unsubscribe();
        }
  }

  public refresh() {
    this.saveSubmission = this.supplierGeneralProfileService.eventEmit.subscribe(async (value: any) => {
      if (value === "Withdraw") {
        this.refreshData.emit();
      }
    })
  }
}
