import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'tsm-manage-SA-request-approve-log',
  templateUrl: './manage-SA-request-approve-log.component.html',
  styleUrls: ['./manage-SA-request-approve-log.component.sass']
})
export class ManageSARequestApproveLogComponent implements OnInit {
  @Input() items: Array<any>;
  @Input() currentPage: number;
  @Output() requery = new EventEmitter()
  constructor() { }

  ngOnInit(): void { }
  reQuery(): void{
    this.requery.emit()
  }
}
