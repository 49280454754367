import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubheaderPropsType } from 'src/app/models/SubheaderProps';
import { EsgReportService } from 'src/app/services/esgReport/esg-report.service';

@Component({
  selector: 'tsm-sa-retrigger-status-container',
  templateUrl: './sa-retrigger-status-container.component.html',
  styleUrls: ['./sa-retrigger-status-container.component.sass']
})
export class SaRetriggerStatusContainerComponent implements OnInit {
  subheaderProps: SubheaderPropsType = {
    isWelcomeMessage: false,
    heading: ""
  };
  reTriggerSArecords :any = []
  loading = true;
  constructor(private route: ActivatedRoute,
    private esgReportService: EsgReportService
  ) { }

  async ngOnInit(): Promise<void> {
    const routeParam = this.route.snapshot.paramMap.get("reTriggerId");
    this.reTriggerSArecords = await this.esgReportService.getReTriggeringSA(routeParam);
    console.log('reTriggerSArecords', this.reTriggerSArecords)
    if(this.reTriggerSArecords === 'Not Found') {
      this.reTriggerSArecords = []
    }

    this.loading = false;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.subheaderProps['heading'] = multilanguageJson.body.main.SaRetriggerFilters.saRetriggerStatusHeading;
      }
    }
  }

}
