<div  class="modal-header" >
    <h4 class="modal-title">{{ modalData.title }}</h4>
</div>
<div class="modal-body">
    <div class="modal-body-content" style="min-height: 0!important;">
        <div *ngIf="popStatus != 'withdraw-sa'">
            <div *ngIf="status == 'A'">
                <p  style="margin-bottom: 0 !important;">{{contentData.contentFirstPopA}}&nbsp;<a>{{countryCreated}}</a>,&nbsp;you&nbsp;<a style='font-weight: bold'>do not</a>&nbsp;<a>{{contentData.contentFirstPopB}}</a></p>
                <p>{{contentData.contentFirstPopC}}</p>
            </div>
            <div *ngIf="status == 'B'">
                <div>{{contentData.contentSecondPopA}}
                    <span>{{countryNotify}}</span>.</div>
                    <br>
                <div>{{contentData.contentSecondPopB}}
                    <span>{{countryCreated}}</span>,&nbsp;therefore you&nbsp;<a style='font-weight: bold'>do not</a>&nbsp;<a>{{contentData.contentSecondPopC}}</a></div>
            </div>
            <div *ngIf="status == 'C'">
                <div>{{contentData.contentThirdPop}}
                    <span>{{countryNotify}}</span>.</div>
            </div>
        </div>
        <div *ngIf="popStatus == 'withdraw-sa'">
            <div>{{modalData.content}}
                <span>{{countryNotify}}</span>.</div>
        </div>
    </div>
    <div style="margin: 30px;">
        <div *ngIf="status == 'A'">
            <sh-button ngbAutofocus [props]="cancelFirstButtonProps"> </sh-button>
            <sh-button ngbAutofocus [props]="confirmFistButtonProps" style="float: right;"> </sh-button>
        </div>
        <div *ngIf="status != 'A'">
            <sh-button ngbAutofocus [props]="cancelButtonProps"> </sh-button>
            <sh-button ngbAutofocus [props]="confirmButtonProps" style="float: right;"> </sh-button>
        </div>
    </div>
</div>