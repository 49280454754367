import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if("/assets/i18n/en.json" === request.url){
        return next.handle(request);
      }
        if (!this.authService.isValidToken()) {
            this.authService.logout();
        } else {
            if (this.authService.getToken()) {
                const accessToken = `Bearer ${this.authService.getToken()}`;
                const currentRole = this.authService.getRole();
                const cuRoleKey = this.authService.convertSchemaKey(currentRole);

                request = request.clone({
                    headers: request.headers.set("Authorization", accessToken)
                      .set("role", cuRoleKey.toString())
                });

                //If an auth refresh is needed in the backend, send the additional header
                //This is needed for the automatic sustainability request trigger
                const authRefresh = this.authService.getAuthRefresh();

                if (authRefresh) {
                    request = request.clone({
                        headers: request.headers.set("authRefresh", authRefresh)
                    });
                }

                return next.handle(request);
            }
        }
        return next.handle(request);
    }
}
