import { Component, Input, SimpleChange, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertPropsType, ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { ReportService } from "src/app/services/authority/report.service";
import { MassInviteService } from 'src/app/services/massInvite/mass-invite.service';
import { Subject, Subscription, switchMap, timer } from 'rxjs';


@Component({
  selector: 'tsm-bulk-profiles-validation-list',
  templateUrl: './bulk-profiles-validation-list.component.html',
  styleUrls: ['./bulk-profiles-validation-list.component.sass']
})
export class BulkProfilesValidationListComponent {
  @Input() initiatesa;
  @Input() logList: any[];
  @Input() buid;
  @Input() refreshed;
  @Input() refresheddate = (new Date()).toLocaleString();
  @Input() currentStatusMsg;
  @Output() dismissEvent = new EventEmitter<any>();
  @Output() dataEvent = new EventEmitter<boolean>();
  loading = false
  labels = {
    validatedSuppliers: "Validated Suppliers",
    validatedSupplier: "Validated Supplier",
    recordsProcessed: "Records Processed",
    recordProcessed: "Record Processed",
    createAndSendSA: "Create TSM Profile and Send Sustainability Assessments",
    errors: "Errors",
    error: "Error",
    createSupplier: "Create TSM Profile",
    profileCreationProgress: "Profile creation in-progress...."
  };
  recordsProcessedCount = 0;
  errorsCount = 0;
  validatedSupplierCount = 0;
  createSupplierButton: ButtonPropsType = {
    label: '',
  };
  enabledcreateSupplierButton: ButtonPropsType = {
    label: this.labels.createSupplier,
    backgroundColor: 'blue',
    color: 'white',
    borderColor: "blue",
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    width: 214,
    onClick: () => {
      this.createSupplier();
    }
  };
  downloadLogsButton: ButtonPropsType = {
    label: "Download Logs",
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    width: 205,
    margin: '0 30px 0 0',
    onClick: () => {
      this.downloadLogs();
    }
  };
  disabledcreateSupplierButton: ButtonPropsType = {
    ...this.enabledcreateSupplierButton,
    backgroundColor: 'lightGrey',
    hoverBackgroundColor: 'gray',
    borderColor: 'mediumGrey',
    isProcessing: false,
    disabled: true
  };
  time: any;
  initiatesaParam;
  showProgressAlert: boolean = true;
  showAlert: boolean;
  showAlert_fail: boolean;
  alertProps: AlertPropsType;
  alertProps_fail: AlertPropsType;
  subscription !: Subscription;

  constructor(
    private reportService: ReportService,
    private massInviteService: MassInviteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ){}

  ngOnInit(): void {
    if(this.errorsCount === this.recordsProcessedCount){
      this.disableCreateSupplierButton();
    } else {
      this.enableCreateSupplierButton();
    }
  }
  private enableCreateSupplierButton() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.initiatesaParam = params['initSa'];
    });
    if(this.initiatesa === 'Yes' || this.initiatesaParam === 'Yes'){
      this.enabledcreateSupplierButton.label = this.labels.createAndSendSA
      this.enabledcreateSupplierButton.height = 50;
      this.enabledcreateSupplierButton.width = 290;
    }
    this.createSupplierButton = this.enabledcreateSupplierButton;
  }
  private disableCreateSupplierButton(){
    if(this.initiatesa === 'Yes' || this.initiatesaParam === 'Yes'){
      this.disabledcreateSupplierButton.label = this.labels.createAndSendSA
      this.disabledcreateSupplierButton.height = 50;
      this.disabledcreateSupplierButton.width = 290;
    }
    this.createSupplierButton = this.disabledcreateSupplierButton
  }
  private async downloadLogs(){
    let response = await this.reportService.downloadValidationLogs(this.buid, true); /// api to download
  }
  private async createSupplier(){
    this.disableCreateSupplierButton();
    this.disabledcreateSupplierButton.isProcessing = true;
    this.showProgressAlert = false;
    const createResponse: any = await this.massInviteService.createBulkTsmProfile(this.buid);
    if(createResponse.text === "OK"){
      if(this.validatedSupplierCount > 0){
        if(this.validatedSupplierCount === 1){
          this.labels.validatedSuppliers = this.labels.recordProcessed;
        } else{
          this.labels.validatedSuppliers = this.labels.recordsProcessed;
        }
      }
      this.subscription = timer(0, 10000).pipe(
      switchMap(() => this.massInviteService.getStatus(this.buid))
      ).subscribe(async (result:any) => {
        this.logList = [];
        this.refreshed = true;
        this.refresheddate = (new Date()).toLocaleString();
        this.showProgressAlert = true;
        this.currentStatusMsg = this.labels.profileCreationProgress;
        if(result.status === "COMPLETED"){
          this.subscription.unsubscribe();
          // setTimeout(async () => {
            this.logList = await this.massInviteService.getValidationLogs(this.buid, false);
            if(this.logList){
              this.showProgressAlert = false;
              this.disabledcreateSupplierButton.isProcessing = false;
              this.dataEvent.emit(true);
              this.setAlertSuccessMessage('Records processed succesfully.');
            } else {
              this.disabledcreateSupplierButton.isProcessing = false;
            }
          // }, 10000);
        } 
      } );
    } else {
      this.enableCreateSupplierButton();
      this.disabledcreateSupplierButton.isProcessing = false;
      this.setAlertErrorMessage('Some Error Occured, Please try again.');
    }
  }
  ngOnChanges(changes: SimpleChange) {
    if(this.logList){
      this.logList.sort((a,b) => a["Row No"] - b["Row No"]);
      this.recordsProcessedCount = this.logList.length;
      if(this.errorsCount > 0){
        this.errorsCount = 0;
      }
      this.logList.forEach(element => {
        if (element["isFailed"] === true){
          this.errorsCount++;
        }
      });
      if(this.errorsCount === 1){
        this.labels.errors = this.labels.error;
      }
      this.validatedSupplierCount = this.recordsProcessedCount - this.errorsCount ;
      if(this.validatedSupplierCount === 1) {
        this.labels.validatedSuppliers = this.labels.validatedSupplier;
      }
      if(this.errorsCount === this.recordsProcessedCount){
        this.disableCreateSupplierButton();
      } else {
        this.enableCreateSupplierButton();
      }
    }
  }
  setAlertSuccessMessage = (message): void => {
    this.showAlert = true;
    this.alertProps = {
      type: 'alert-requestVerifiedSuccess',
      message: message,
      icon: 'assets/images/icon-tick.svg',
      iconFill: '#376803',
      iconClose: 'assets/images/icon-cross-green.svg',
      onDismiss: this.onAlertDismiss,
    };
    // window.scrollTo(0, 0);
  };

  onAlertDismiss = (): void => {
    this.showAlert = false;
    this.dismissEvent.emit("dismiss");

  };
  setAlertErrorMessage = (message): void => {
    this.showAlert_fail = true;
    this.alertProps_fail = {
      type: 'alert-requestVerifiedFaild',
      message: message,
      icon: 'assets/images/icon-error.svg',
      iconFill: '#cc1e32',
      iconClose: 'assets/images/icon-cross-blue.svg',
      onDismiss: this.onAlertDismiss_1,
    };
    // window.scrollTo(0, 0);
  };
  onAlertDismiss_1 = (): void => {
    this.showAlert_fail = false;
  };

}
