import { Injectable, inject } from "@angular/core";
import {
  ResolveFn,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";

import { RiskassessmentService } from "./riskassessment.service";
import { RiskAssessmentModel, createRaModel } from "../../models/riskAssessment";



export const RiskassessmentResolverService: ResolveFn<RiskAssessmentModel> =
    async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      // return inject(AddEditClientService).getClientById();
       const raService = inject(RiskassessmentService);
    let raCode = route.paramMap.get("code");
    let cid = route.paramMap.get("cid");
    let scid = route.paramMap.get("scid");
    let mode = route.paramMap.get("mode");
    raService.raCode = raCode;
    raService.raModel = createRaModel(raCode);

    if (mode === "0") {
      // new create supplier requestor form
      raService.mode = "create";
      raService.raQuestion= await raService.initilizeRiskAssessmentForm(cid,scid);
      await raService.sortSelectResource(raService.raQuestion);
    } else if(mode==="1"){
      raService.mode = "view";
      await raService.loadSupplierRequestForm();
    }
     else {
      // retrieve this supplier requestor form
      raService.mode = "edit";
      await raService.loadSupplierRequestFormEdit();
    }
    return raService.raModel;
  }

