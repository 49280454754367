import { EventEmitter, Injectable } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class SupplierService {
    private baseUrl = "";
    public eventEmit: any;
    private esgUrl = "";
    private esgUrlNew = "";
    private supplierOnboardingStatusUrl = "";
    private assessmentStatusUrl = "";
    private questionnaireUrl = "";
    private esgStatusUrl = "";
    private createSupplierUrl = "";
    private saListUrl = "";
    private SAStatusUrl = "";
    private updateSaUrl = "";
    private updateWorkflowToInProgressUrl = "";
    private verifyUrl = "";
    private isUpdateButtonTrigger = false;
    private terminateSAUrl = "";
    private checkActiveRequestUrl = "";
    private supplierSaListUrl = "";
    public esgQuestionnaire: any;

    constructor(private httpService: HttpService, private http: HttpClient) {
        this.eventEmit = new EventEmitter();
        this.baseUrl = `${environment.tsmBackendServiceURL}/supplier`;
        this.esgUrl = `${environment.susHubESGServiceURL}/invite-event`;
        this.esgUrlNew = `${environment.susHubESGServiceURL}/invite-event/resend`;
        this.supplierOnboardingStatusUrl = `${environment.tsmBackendServiceURL}/supplier-hub/onboarding-status`;
        this.assessmentStatusUrl = `${environment.tsmBackendServiceURL}/tasks/status`;
        this.questionnaireUrl = `${environment.susHubESGServiceURL}/esg-questionnaire`;
        this.esgStatusUrl = `${environment.susHubESGServiceURL}/assessment-status`;
        this.createSupplierUrl = `${environment.tsmBackendServiceURL}/supplier-hub/create-supplier-profile`;
        this.saListUrl = `${environment.tsmBackendServiceURL}/tasks/saList`;
        this.SAStatusUrl = `${environment.susHubESGServiceURL}/assessment-status/getAuthorityAssessmentStatus`;
        this.updateSaUrl = `${environment.susHubESGServiceURL}/change-consent`;
        this.updateWorkflowToInProgressUrl = `${environment.susHubESGServiceURL}/change-consent/updateWorkflowToInProgress`;
        this.verifyUrl = `${environment.tsmBackendServiceURL}/supplier/repeat/verify`;
        this.terminateSAUrl = `${environment.tsmBackendServiceURL}/terminate/terminate-supplier`;
        this.checkActiveRequestUrl = `${environment.tsmBackendServiceURL}/tasks/getActiveUpdateSaRecord`;
        this.supplierSaListUrl = `${environment.tsmBackendServiceURL}/supplier-action/supplierSaList`;
    }

    /**
     * Description: saves esg survey data of specific supplier to database.
     * @Param data: data from survey
     */
    save = async (newSupplierData): Promise<any> => {
        const url = `${this.baseUrl}`;
        const response = await this.httpService.PostPromise(url, newSupplierData);
        // TO DO: Error handling
        return response;
    };

    /**
     * Description: saves new supplier information to database when a buyer sends a requests for a new supplier.
     * @Param data: supplier general data
     */
    add = async (newSupplierData): Promise<any> => {
        const url = `${this.esgUrl}`;
        const response = await this.httpService.PostPromise(url, newSupplierData);
        return response;
    };
    addNew = async (newSupplierData): Promise<any> => {
        const url = `${this.esgUrlNew}`;
        const response = await this.httpService.PostPromise(url, newSupplierData);
        return response;
    };
    getOnboardingStatus = async (reqData): Promise<any> => {
        const url = this.supplierOnboardingStatusUrl;

        const accessToken = `Bearer ${localStorage.getItem("sustain_access_token")}`;

        return await this.http
            .post<any>(url, reqData, {
                headers: {
                    Authorization: accessToken
                }
            })
            .toPromise();
    };

    getAssessmentStatus = async (assessData): Promise<any> => {
        const url = `${this.assessmentStatusUrl}/${assessData.country}/${assessData.supplierTsmId}/${assessData.buyerTsmId}`;
        return await this.httpService.GetPromise(url);
    };

    /**
     * Gets the assessment status of a supplier
     * @param supplierTsmId - TSM ID of the supplier you want to access
     * @param countryServed - Country Served of the suppier you want to query
     * @returns {Object} The assessment status of the supplier
     */
    getESGStatus = async (supplierTsmId: string, countryServed: string): Promise<any> => {
        const url = `${this.esgStatusUrl}/${supplierTsmId}/${countryServed}`;
        return await this.httpService.GetPromise(url);
    };
    getSAStatus = async (supplierTsmId: string, countryServed: string): Promise<any> => {
        const url = `${this.SAStatusUrl}/${supplierTsmId}/${countryServed}`;
        return await this.httpService.GetPromise(url);
    };
    /**
     * Description: saves esg survey status
     * @Param data: data from survey
     */
    changeQuestionnaireStatus = async (esgQuestionnaireData): Promise<any> => {
        const url = `${this.questionnaireUrl}`;
        const response = await this.httpService.PutPromise(url, esgQuestionnaireData);
        // TO DO: Error handling
        return response;
    };

    /**
     * Description: create supplier and get supplier code
     * @Param data: data from survey
     */
    createSupplier = async (createSupplierData): Promise<any> => {
        const url = `${this.createSupplierUrl}`;
        const response = await this.httpService.PostPromise(url, createSupplierData);
        // TO DO: Error handling
        return response;
    };

    getAssessmentList = async (assessData): Promise<any> => {
        const url = `${this.saListUrl}/${assessData.supplierTsmId}/${assessData.buyerTsmId}`;
        return await this.httpService.GetPromise(url);
    };

    /**
     * Description: supplier update SA
     * @param supplierTsmId - TSM ID of update person
     * @param countryServed - current Country Served
     * @param userId - the userId of update person
     * @param email - the email of update person
     * @param reason - why you want to update SA
     * @returns {Object} The questionnaireId of what you want to update
     */
    updateSa = async (supplierData): Promise<any> => {
        const url = `${this.updateSaUrl}`;
        const response = await this.httpService.PostPromise(url, supplierData);
        return response;
    };

    updateSAChooseOther = async (supplierData): Promise<any> => {
        const url = `${this.updateSaUrl}/other`;
        const response = await this.httpService.PostPromise(url, supplierData);
        return response;
    };


    setIsUpdateButtonTriggerStatus = async (flag): Promise<any> => {
        this.isUpdateButtonTrigger = flag;
    };

    getIsUpdateButtonTriggerStatus = async (): Promise<any> => {
        return this.isUpdateButtonTrigger;
    };

    updateWorkflowToInProgress = async (workflowId, data): Promise<any> => {
        const url = `${this.updateWorkflowToInProgressUrl}/${workflowId}`;
        const response = await this.httpService.PostPromise(url, data);
        return response;
    };

    verify = async (checkSupplierData): Promise<any> => {
        const url = this.verifyUrl;
        const response = await this.httpService.PostPromise(url, checkSupplierData);
        // TO DO: Error handling
        return response;
    };

    /**
     * Description: termination of SA
     * @param supplierTsmId - TSM ID of the supplier you want to access
     * @param terminateReason - The reason for termination
     * @returns {Object} Whether terminate success or fail
     */
    terminateSA = async (supplierData): Promise<any> => {
        const url = `${this.terminateSAUrl}`;
        const response = await this.httpService.PostPromise(url, supplierData);
        return response;
    };

    /**
     * Gets the assessment status of a supplier
     * @param supplierTsmId - TSM ID of the supplier you want to access
     * @param countryServed - Country Served of the suppier you want to query
     * @param supplierName - supplierName of the suppier you want to query
     * @returns {Object} whether exist active request
     */
     getActiveUpdateSaRecord = async (supplierData): Promise<any> => {
        const url = `${this.checkActiveRequestUrl}/${supplierData.supplierTsmId}/${supplierData.countryServed}/${supplierData.supplierName}`;
        return await this.httpService.GetPromise(url);
    };

    getSupplierAssessmentList = async (supBuyIdData): Promise<any> => {
        const url = `${this.supplierSaListUrl}/${supBuyIdData.supplierTsmId}/${supBuyIdData.buyerTsmId}`;
        return await this.httpService.GetPromise(url);
    };

    /**
     * Description: fetches esg survey data of specific supplier.
     * Param: none
     * Output: Esg survey data.
     */
    getByquestionnaireId = async (questionnaireId: string, countryServed: string, assignedToEmail?: string): Promise<any> => {
        let url = `${this.questionnaireUrl}/${questionnaireId}?countryServed=${countryServed}&assignedToEmail=${assignedToEmail}`;
        this.esgQuestionnaire = await this.httpService.GetPromise(url);
        if (this.esgQuestionnaire.concurrentSustainabilityAssessments) {
            this.eventEmit.emit("concurrentSustainabilityAssessments");
        }
        // TO DO: Error handling
        return this.esgQuestionnaire;
    };
    /**
     * Get Task
     *
     * @param {*} buyerTsmId
     * @param {*} supplierTsmId
     * @param {*} esgQuestionnaireId
     * @param {*} countryServed
     * @memberof EsgQuestionnaireService
     */
    getQuestionnaireTask = async (buyerTsmId, supplierTsmId, esgQuestionnaireId, countryServed): Promise<any> => {
        const url = `${this.questionnaireUrl}/getTaskRecord`;
        const param = {
            buyerTsmId: buyerTsmId,
            supplierTsmId: supplierTsmId,
            esgQuestionnaireId: esgQuestionnaireId,
            countryServed: countryServed
        }
        const result = await this.httpService.PostPromise(url, param);
        if (result) return result;
        return [];
    }
}
