import { Component, Input, OnInit, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { environment } from "src/environments/environment";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import _ from "lodash";
import { PopUpRiskAssessmentComponent } from "src/app/components/pop-up-risk-assessment/pop-up-risk-assessment.component";
import { ActivatedRoute, Router } from "@angular/router";
import { RiskassessmentService } from 'src/app/services/riskassessment/riskassessment.service';
import { serchCriteria } from './search-criteria.model';
import { RAStatusCode, RAStatusText } from './risk-assessment-status.enum';
import { AuthService } from 'src/app/services/auth/auth.service';


@Component({
  selector: 'tsm-bv-risk-assessment',
  templateUrl: './bv-risk-assessment.component.html',
  styleUrls: ['./bv-risk-assessment.component.sass']
})
export class BvRiskAssessmentComponent implements OnInit, OnChanges {
  @Output() refershTerminateCallBack = new EventEmitter();
  @Input() allMu: any;
  @Input() allCountry: any;
  @Input() allCategory: any;
  @Input() allSubCategory: any;
  @Input() supplier: any;
  @Input() isDuplicate = false;
  statusFlag: boolean = true;

  tempMarketUnit: any = [];
  allCountryServedCodeList: any = [];
  tempLocation: any = [];
  tempCategory: any = [];
  tempSubCategory: any = [];
  tempRAStatus: any = [
    {
      Code: RAStatusCode.pendingReview,
      Text: RAStatusText.pendingReview
    }, {
      Code: RAStatusCode.materialityDetermined,
      Text: RAStatusText.materialityDetermined
    }, {
      Code: RAStatusCode.dueDiligenceCompleted,
      Text: RAStatusText.dueDiligenceCompleted
    }
  ];
  allAssessmentList: any = [];
  searchCriteria: serchCriteria = {
    countryLocation: [],
    marketUnit: [],
    category: [],
    subCategory: [],
    raStatus: []
  };
  oldSearchCriteria: any = _.cloneDeep(this.searchCriteria);

  exhibitAssessmentList: any = [];
  mu: string = "";
  country: any = [];
  riskAssessmentData = [];
  // supplierPoc: string = "";
  raAdminFlag: boolean = false;
  selectedSupplierTsmId: any;
  isBiAccess: boolean;
  raPriorityRoles = {
    scm: 'scm',
    srdp: 'srdp',
    prisk: 'prisk',
    srsa: 'srsa',
    srra: 'srra'
  }

  constructor(
    config: NgbModalConfig,
    public ngbModalService: NgbModal,
    private HttpService: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private riskAssessmentService: RiskassessmentService,
    private authService: AuthService
  ) {
    config.backdrop = "static";
  }
  async ngOnInit(): Promise<void> {
    // To get the supplier TSM ID 
    const routeParam = this.route.snapshot.paramMap.get("id");
    this.selectedSupplierTsmId = routeParam;
    await this.triggerRiskAssessmentMetaData(this.selectedSupplierTsmId);
    this.filterAssessmentList(this.searchCriteria);
  }

  saveSectionEvent(e, dropdown): void {
    this.searchCriteria[dropdown] = e;
    this.filterAssessmentList(this.searchCriteria);
  }


  // show data according to selected box
  private filterAssessmentList(searchCriteria: serchCriteria): void {
    let tempLoc = this.allCountry;
    this.exhibitAssessmentList = this.riskAssessmentData;
    if (searchCriteria.marketUnit.length > 0) {
      const searchMarketUnit = searchCriteria.marketUnit.map((ent: any) => {
        return ent.Code;
      });
      this.exhibitAssessmentList = this.exhibitAssessmentList.filter((item) => {
        const intersection = new Set(searchMarketUnit).has(item?.marketUnit);
        if (intersection) {
          return true;
        }
        return false;
      });
      tempLoc = this.allCountry.filter((item) => {
        return searchMarketUnit.includes(item?.marketUnit);
      });
    }
    if (!_.isEqual(searchCriteria.marketUnit, this.oldSearchCriteria.marketUnit)) {
      searchCriteria.countryLocation = [];
      this.tempLocation = this.getServedCountryList(tempLoc);
    }
    if (searchCriteria.countryLocation.length > 0) {
      const searchCountryLocation = searchCriteria.countryLocation.map((ent: any) => {
        return ent.Text;
      });
      this.exhibitAssessmentList = this.riskAssessmentData.filter((item) => {
        const intersection = item?.countryServed.filter(country => new Set(searchCountryLocation).has(country));
        if (intersection.length > 0) {
          return true;
        }
        return false;
      });
    }
    if (searchCriteria.category.length > 0) {
      const searchCategory = searchCriteria.category.map((ent: any) => {
        return ent.Text;
      });
      this.exhibitAssessmentList = this.exhibitAssessmentList.filter((item) => {
        const intersection = new Set(searchCategory).has(item?.category);
        if (intersection) {
          return true;
        }
        return false;
      });
    }
    if (searchCriteria.subCategory.length > 0) {
      const searchSubCategory = searchCriteria.subCategory.map((ent: any) => {
        return ent.Text;
      });
      this.exhibitAssessmentList = this.exhibitAssessmentList.filter((item) => {
        const intersection = new Set(searchSubCategory).has(item?.subCategory);
        if (intersection) {
          return true;
        }
        return false;
      });
    }
    if (searchCriteria.raStatus.length > 0) {
      const searchEsgStatus = searchCriteria.raStatus.map((ent: any) => {
        return ent.Text;
      });
      this.exhibitAssessmentList = this.exhibitAssessmentList.filter((item) => {
        let status;
        if (item?.infoseclifecyclestatus === null) {
          status = "Pending Review"
        }
        else {
          status = item?.infoseclifecyclestatus
        }
        if (searchEsgStatus.includes(status)) {
          return true;
        }
        return false;
      });
    }
    this.oldSearchCriteria = _.cloneDeep(searchCriteria);
  }

  getServedCountryList(servedData): any {
    const tmpLoc = [];
    servedData?.forEach(element => {
      const state = element.countryServedEnable;
      if (state === true) {
        const country = {
          Code: element.tsmCode,
          Text: element.countryServedName
        };
        tmpLoc.push(country);
      }
    });
    return this.sortSelectResource(tmpLoc);
  }

  sortSelectResource = (resource: any): any => {
    return resource?.sort((obj1: any, obj2: any) => {
      const val1 = obj1.Text;
      const val2 = obj2.Text;
      if (val1 > val2) {
        return 1;
      } else if (val1 < val2) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  async triggerRiskAssessmentMetaData(supplierTsmId): Promise<void> {
    // To get the risk assessment metadata details
    const riskAssessmentDetails = `${environment.tsmBackendServiceURL}/riskAssessmentData/getRiskAssessmentMetaData`;
    this.riskAssessmentData = this.getAllRiskAssessmentList(await this.HttpService.PostPromise(riskAssessmentDetails, { supplier_TsmId: supplierTsmId }));
    // this.supplierPoc = (this.supplier.supplierAdmin ? this.supplier.supplierAdmin.userName : this.supplier.pointOfContactEmail)
  }

  private async initialAssessmentList(): Promise<void> {
    const raList = this.riskAssessmentData;
    raList.forEach((item: any) => {
      item.arrayServedCountry = item.servedCountryList.map((country: any) => {
        return country.name;
      }).sort();
      item.arrayServedCountryCode = item.servedCountryList.map((country: any) => {
        return country.tsmCode;
      });
      this.allCountryServedCodeList = this.allCountryServedCodeList.concat(item.arrayServedCountryCode);
      const tempStrMarketUnit = item.servedCountryList.map((country: any) => {
        return country.marketUnit;
      }).sort();
      item.arrayMarketUnit = Array.from(new Set(tempStrMarketUnit));

    });
    this.allAssessmentList = raList;
    this.exhibitAssessmentList = this.allAssessmentList.sort((a, b) => a.creationDate.localeCompare(b.creationDate));
  }

  isRequestor(assessment): boolean {
    let userId = JSON.parse(localStorage.getItem("userInfo")).pocEmail;
    if (assessment.requestorName === userId) {
      return true
    }
    return false
  }

  hasViewAccess(assessment): boolean {
    let RAFlag = localStorage.getItem("RA_flag");
    this.isBiAccess = this.authService.isBI();
    if (RAFlag === this.raPriorityRoles.scm || RAFlag === this.raPriorityRoles.srdp || RAFlag === this.raPriorityRoles.prisk || RAFlag === this.raPriorityRoles.srsa || RAFlag === this.raPriorityRoles.srra || this.isBiAccess) {
      return true
    }
    return false
  }

  hasWriteAccess(assessment): boolean {
    let RAFlag = localStorage.getItem("RA_flag");
    if (RAFlag === this.raPriorityRoles.prisk || RAFlag === this.raPriorityRoles.srsa) {
      return true
    }
    return false
  }

  getAllRiskAssessmentList(assessmentList) {
    let riskAssessmentArray = []
    assessmentList.riskAssessmentMetaData.forEach((assessment) => {
      riskAssessmentArray.push(assessment);
    })
    return riskAssessmentArray;
  }

  sharedButtonProps: ButtonPropsType = {
    label: "",
    fontSize: 16,
    height: 48,
    padding: "10px 0px 10px 0px",
    borderColor: "black-12",
    fontFamily: "Graphik"
  };

  requestRiskAssButton: ButtonPropsType = {
    ...this.sharedButtonProps,
    width: 319,
    color: "white",
    backgroundColor: "blue",
    disabled: false,
    label: "Request New Risk Assessment",
    onClick: () => {
      this.onRequestRiskAssButtonClick();
    },
  };

  onRequestRiskAssButtonClick = async () => {
    const confirmType = {
      options: {},
      centered: true,
      size: "lg",
      supplier: this.supplier,
      windowClass: "modal-country-notify",
      supplierTsmId: this.selectedSupplierTsmId
    };
    const modalReference = this.ngbModalService.open(PopUpRiskAssessmentComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.requestRiskAssButton.disabled = this.isDuplicate;
    if (changes.supplier !== undefined && this.supplier && this.supplier.status === "Terminated") {//Terminated
      this.statusFlag = false;
    }
    if (changes.allMu && changes.allMu.currentValue) {
      this.tempMarketUnit = this.sortSelectResource(changes.allMu.currentValue);
    }
    if (changes.allCategory && changes.allCategory.currentValue) {
      this.tempCategory = this.sortSelectResource(changes.allCategory.currentValue);
    }
    if (changes.allSubCategory && changes.allSubCategory.currentValue) {
      this.tempSubCategory = this.sortSelectResource(changes.allSubCategory.currentValue);
    }
    if (changes.allCountry && changes.allCountry.currentValue) {
      const dataCountry = changes.allCountry.currentValue;
      this.tempLocation = this.getServedCountryList(dataCountry);
    }

    if (changes.supplier.currentValue !== undefined) {
      await this.initialAssessmentList();
    }
  }
  async triggerViewDetails(selectedRA) {
    let bibdaReview;
    if(selectedRA.bibdaStatus) {
      if(selectedRA.bibdaStatus === "Not Start/On-going") {
        bibdaReview = "1";
      } else {
        bibdaReview = "2";
      }
    }
    const queryParams = {
      TBR: bibdaReview,
      supplierTsmId: this.selectedSupplierTsmId
    };
    this.router.navigate([`/risk-assessment-questionnaire/1/`, `${selectedRA.raId}`], { queryParams });
  }

  async triggerDraft(selectedRA) {
    this.riskAssessmentService.raMetadata = {
      "tsmId": selectedRA.tsmId,
    }
    this.router.navigateByUrl(`/risk-assessment-questionnaire/2/${selectedRA.raId}`);
  }
  // download report
  async downloadReport(selectedRA): Promise<void> {
    await this.riskAssessmentService.downloadReportOfRA(selectedRA.raId);
  }
}
