<label class="control-label" for="creationDate"
    [ngStyle]="fromDateInvalid || toDateInvalid || toDateLessThanFrom? {'color': '#d63059'}:''">Creation Date</label>
<div class="row">
    <div class="col-6">
        <div class="date-from">
            <label class="date-input-label form-label"
                [ngStyle]="fromDateInvalid? {'color': '#d63059'}:''">{{labelTranslations.from}} </label>
            <div class="input-area date-picker">
                <input class="form-control date-input" id="request-date-from" [placeholder]="labelTranslations.inputPlaceholder" name="dp"
                    [(ngModel)]="dateFrom" ngbDatepicker #d="ngbDatepicker" (dateSelect)="inputFromChanged()"
                    (input)="inputFromChanged()">
                <span class="calendar" type="button" (click)="d.toggle()"><img class="ico"
                    src="../../../../assets/icons/calendar.svg" alt="calendarIcon"></span>
            </div>
        </div>
        <div class="invalid-msg" id="invalid-fromdate" *ngIf="fromDateInvalid">{{labelTranslations.invalidFromDateMsg}}
        </div>
    </div>

    <div class="col-6">
        <div class="date-from">
            <label class="date-input-label form-label"
                [ngStyle]="toDateInvalid || toDateLessThanFrom ? {'color': '#d63059'}:''">{{labelTranslations.to}}
            </label>
            <div class="input-area date-picker">
                <input class="form-control date-input" [placeholder]="labelTranslations.inputPlaceholder" name="dpTo"
                    [(ngModel)]="dateTo" ngbDatepicker #d1="ngbDatepicker" (dateSelect)="inputToChanged()"
                    (input)="inputToChanged()">

                <span class="calendar" type="button" (click)="d1.toggle()"><img class="ico"
                        src="../../../../assets/icons/calendar.svg" alt="calendarIcon"></span>

            </div>
        </div>
        <div class="invalid-msg" id="invalid-fromdateless" *ngIf="toDateLessThanFrom">
            {{labelTranslations.invalidToDateLessMsg}}</div>
        <div class="invalid-msg" id="invalid-todate" *ngIf="toDateInvalid">{{labelTranslations.invalidToDateMsg}}</div>
    </div>
</div>