<div class="modal-header" >
    <h4  class="modal-title">Request Sustainability Assessment</h4>
</div>
<div class="modal-body" #focusContainer  tabindex="-1">
  <div class="body-content" *ngIf="showComponent">
    <div class="modal-body-content">
      <qnr-dynamic-form [configs]="configs" [model]="model" [context]="context" [callingComponent]="callingComponent" (closeBtnEvent)="closeBtnEvent($event)" (openLATAMPopUp)="openLATAMPopUp($event)" [closeLatamPopUp]="closeLatamPopUp" #form></qnr-dynamic-form>
      <p *ngIf="hasParent"><img src="assets/icons/info-blue.svg" alt="info-blue logo"/>{{selectForParent}}</p>
    </div>
    <div class="button-content">
      <sh-button ngbAutofocus [props]="cancelProps"></sh-button>
      <sh-button *ngIf="isSelected!='LATAM' || (isSelected=='LATAM' && this.isBuyerExpert)" class="continueButton" ngbAutofocus [props]="continueProps"></sh-button>
      <sh-button *ngIf="isSelected=='LATAM' && !this.isBuyerExpert" class="continueButton" ngbAutofocus [props]="latamContinueProps"></sh-button>
    </div>
  </div>
</div>
