<div class="assesment mb-12 shadow rounded-0 position-relative border ra-dashboard">
    <div class="row ms-3">
        <div class="col-12 col-md-8">
            <div class="title-container mt-3">
                <div class="title">Risk Assessment</div>
                <div class="sub_title_name">Identify the risks related to a new engagement with this supplier.</div>
            </div>
        </div>
        <div class="col-4">
            <sh-button tabindex="0" class="fl_right me-3 mt-3" *ngIf="statusFlag" [props]="requestRiskAssButton">Request New Risk Assessment</sh-button>
        </div>
    </div>
    <div class="ra-table" *ngIf="riskAssessmentData.length > 0">
        <div class="row mt-3  ms-3 ">
            <div class="col">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="marketUnit_bvRisk">Select MU</label>
                        <div class="col-7 ps-0">
                            <tsm-select-input id="marketUnit_bvRisk"
                                [drapDownData]="tempMarketUnit" 
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'marketUnit')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="countryLocation">Select Country/ies</label>
                        <div class="col-7 ps-0">
                            <tsm-select-input
                                id="tempLocationRisk"
                                [drapDownData]="tempLocation" 
                                [refresh]="true"
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'countryLocation')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="categoryRisk">Category</label>
                        <div class="col-8 ps-0">
                            <tsm-select-input id="categoryRisk"
                                [drapDownData]="tempCategory" 
                                [refresh]="true"
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'category')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="subCategoryRisk">Sub category</label>
                        <div class="col-8 ps-0">
                            <tsm-select-input id="subCategoryRisk"
                                [drapDownData]="tempSubCategory" 
                                [refresh]="true"
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'subCategory')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="raStatus">Status</label>
                        <div class="col-8 ps-0">
                            <tsm-select-input id="raStatus"
                                [drapDownData]="tempRAStatus" 
                                [refresh]="true"
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'raStatus')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="ra-table-scrollable" *ngIf="riskAssessmentData.length > 0">
            <div class="row mt-3  ms-3 table-header">
            <div class="col-marketunit col-common table-overflow">MARKET<br>UNIT</div>
            <div class="col-countryserved col-common table-overflow">COUNTRIES<br>SERVED</div>
            <div class="col-category col-common table-overflow">CATEGORY</div>
            <div class="col-category col-common table-overflow">SUB-CATEGORY</div>
            <div class="col-category col-common table-overflow">BI/BDA STATUS</div>
            <div class="col-relnumber col-common table-overflow">RELATIONSHIP<br>NUMBER</div>
            <div class="col-isriskscore col-common table-overflow">INFORMATION SECURITY<br>RISK SCORE</div>
            <div class="col-datariskscore col-common table-overflow">DATA PRIVACY<br>RISK SCORE</div>
            <div class="col-requestor col-common table-overflow">REQUESTOR<br>NAME</div>
            <!-- <div class="col-supplier col-common">SUPPLIER<br>MANAGER</div> -->
            <div class="col-status col-common table-overflow">ASSESSMENT<br>STATUS</div>
            <div class="col-description col-common table-overflow">SHORT<br>DESCRIPTION</div>
            <div class="col-ra-id col-common table-overflow">RA ID</div>
            <div class="col-date col-common table-overflow">COMPLETION<br>DATE</div>
            <div class="col-action col-common table-overflow">ACTION</div>
        </div>
        <div class="table-overflow">
            <div>
                <div tabindex="0" class="row mt-3  ms-3" *ngFor="let assessment of exhibitAssessmentList">
                    
                    <div class="col-marketunit col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="marketUnit">
                            {{assessment.marketUnit}}
                        </div>
                        <ng-template #marketUnit>
                            <div class="tip-inner-content">
                                {{assessment.marketUnit}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-countryserved col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="countryServed">
                            {{assessment.countryServed}}
                        </div>
                        <ng-template #countryServed>
                            <div class="tip-inner-content">
                                {{assessment.countryServed}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-category col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="category">
                            {{assessment.category}}
                        </div>
                        <ng-template #category>
                            <div class="tip-inner-content">
                                {{assessment.category}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-category col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="subCategory">
                            {{assessment.subCategory}}
                        </div>
                        <ng-template #subCategory>
                            <div class="tip-inner-content">
                                {{assessment.subCategory}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-category col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="bibdaStatus">
                            {{assessment.bibdaStatus}}
                        </div>
                        <ng-template #bibdaStatus>
                            <div class="tip-inner-content">
                                {{assessment.bibdaStatus}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-relnumber col-common col-font-common">
                        <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="relationshipNumber">
                            {{assessment.relationshipnumber === null ? 'Pending Review' : assessment.relationshipnumber}}
                        </div>
                        <ng-template #relationshipNumber>
                            <div class="tip-inner-content">
                                {{assessment.relationshipnumber === null ? 'Pending Review' : assessment.relationshipnumber}}
                            </div>
                        </ng-template>
                    </div>

                    <div class="col-isriskscore col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="infoSecRiskScore">
                            {{assessment.infosecrisk === null ? 'Pending Review' : assessment.infosecrisk}}
                        </div>
                        <ng-template #infoSecRiskScore>
                            <div class="tip-inner-content">
                                {{assessment.infosecrisk === null ? 'Pending Review' : assessment.infosecrisk}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-datariskscore col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="dataPrivacyRiskScore">
                            {{assessment.dataprivacyrisk === null ? 'Pending Review' : assessment.dataprivacyrisk}}
                        </div>
                        <ng-template #dataPrivacyRiskScore>
                            <div class="tip-inner-content">
                                {{assessment.dataprivacyrisk === null ? 'Pending Review' : assessment.dataprivacyrisk}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-requestor col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="requestorName">
                            {{assessment.requestorName}}
                        </div>
                        <ng-template #requestorName>
                            <div class="tip-inner-content">
                                {{assessment.requestorName}}
                            </div>
                        </ng-template>
                    </div>
                    <!-- <div class="col-supplier col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="supplierManager">
                            {{supplierPoc}}
                        </div>
                        <ng-template #supplierManager>
                            <div class="tip-inner-content">
                                {{supplierPoc}}
                            </div>
                        </ng-template>
                    </div> -->
                    <div class="col-status col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="assessmentStatus">
                            {{assessment.infoseclifecyclestatus === null ? 'Pending Review' : assessment.infoseclifecyclestatus}}
                        </div>
                        <ng-template #assessmentStatus>
                            <div class="tip-inner-content">
                                {{assessment.infoseclifecyclestatus === null ? 'Pending Review' : assessment.infoseclifecyclestatus}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-description col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="shortDesc">
                            {{assessment.shortDesc}}
                        </div>
                        <ng-template #shortDesc>
                            <div class="tip-inner-content">
                                {{assessment.shortDesc}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-ra-id col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="raID">
                            {{assessment.raId}}
                        </div>
                        <ng-template #raID>
                            <div class="tip-inner-content">
                                {{assessment.raId}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-date col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body" [ngbTooltip]="completionDate">
                            {{assessment.completionDate | date:'dd/MM/yyyy'}}
                        </div>
                        <ng-template #completionDate>
                            <div class="tip-inner-content">
                                {{assessment.completionDate}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-action col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                            triggers="hover" container="body">
                            <!------Action Start-------->
                            <img src="assets/icons/Options_Icon.svg" alt="icon-info"
                                class="icon-mt fl_right icon-option rotate_h" tooltipClass="manage-user-tooltip-class"
                                triggers="click:blur" [ngbTooltip]="tipActions" placement="bottom-right"
                                container="body" />
                            <ng-template #tipActions>
                                <div class="actions-tooltip">
                                    <p *ngIf="((assessment.isDraft == 'false' || assessment.isDraft == 'notapplicable' || (assessment.isDraft == 'true' && (isRequestor(assessment) || hasWriteAccess(assessment)))) && (hasViewAccess(assessment) || isRequestor(assessment) || hasWriteAccess(assessment))); else elseBlock">Available actions: </p>
                                    <ng-template #elseBlock>
                                        <p>No available action</p>
                                    </ng-template>
                                    <!-- <a class="">Continue</a> -->
                                    <a *ngIf="(assessment.isDraft == 'false' || assessment.isDraft == 'notapplicable') && (hasViewAccess(assessment) || isRequestor(assessment) || hasWriteAccess(assessment)) " (click)="triggerViewDetails(assessment)" class="">View details </a>
                                    <a *ngIf="assessment.isDraft == 'true' && (isRequestor(assessment) || hasWriteAccess(assessment))" (click)="triggerDraft(assessment)" class="">Continue </a>
                                    <a *ngIf="assessment.isDraft == 'false' && (hasViewAccess(assessment) || isRequestor(assessment) || hasWriteAccess(assessment))" (click)="downloadReport(assessment)" class="">Export data</a>
                                </div>
                            </ng-template>
                            <!------Action End-------->
                        </div>
                    </div>
                </div>
                            </div>
        </div>
    </div>
    <div class="row mb-4"></div>
</div>