export let countryList = {
  Data: {
    transferToSupplierHub: {},
    mapping: {},
    valueToKey_nameToTsmCode: {},
    valueToKey_tsmCodeToGroupSa: {},
    valueToKey_nameToGroupSa: {},
    countryGroupSa: {},
    countryGroupSaArr: [],
    countryGroupOnboardArr: [],
    servedCountryList:[]
  }
};
