import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { Popup } from "../../models/Popup";
import { ModalService } from "src/app/services/modal/modal.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "tsm-pop-up-inactive",
  templateUrl: "./pop-up-inactive.component.html",
  styleUrls: ["./pop-up-inactive.component.sass"]
})

export class PopUpInactiveComponent implements OnInit {
  @Input() opened: boolean = true;
  modalData: Popup;
  buttonProps2: ButtonPropsType = {
    label: "Close",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 115,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "darkBlue",
    hoverColor: "white"
  };

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: ModalService,
    public ngbModalService: NgbModal,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.modalData = this.modalService.getInactiveData();
    let translateData = {
      title: "Notice",
      iconUrl: "assets/icons/icon-profile.svg",
      content: 'User limit reached under this profile.',
      headerCloseButton: false,
      buttonText: "Close"
    };
    this.translate.get('supplier.body.main.createUserPopup').subscribe({
      next: (res: string) => {
          let data = JSON.parse(JSON.stringify(res)); 
          translateData.content = data.content,
          translateData.title = data.title
          translateData.buttonText = data.buttonText
      }
  });
    this.modalData = translateData
    this.buttonProps2.onClick = this.activeModal.close;
  }
}