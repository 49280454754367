<div class="row itemlist">
  <div class="col-3">
    <div class="subtitle">{{ labelTranslation.company }}</div>
  </div>
  <div class="col-2">
    <div class="subtitle">{{ labelTranslation.location }}</div>
  </div>
  <div class="col-2">
    <div class="subtitle">{{ labelTranslation.userName }}</div>
  </div>
  <div class="col-2">
    <div class="subtitle">{{ labelTranslation.email }}</div>
  </div>
  <div class="col-1">
    <div class="subtitle">{{ labelTranslation.editRights }}</div>
  </div>
  <div class="col-2"></div>
</div>

<div *ngIf="!searchExecuted" class="centered-content">
  <img class="empty-form-image" id="emptyImage" src="assets/images/EmptyImage_Users.svg" alt="image-empty-users"/>
  <div class="highlight-text" id="emptyMessage">{{ labelTranslation.emptyMessage }}</div>
</div>

<div *ngIf="searchExecuted && !errorOccurred && users.length === 0" class="centered-content">
  <img class="empty-form-image" id="noResultImage" src="assets/images/EmptyImage_Search.svg" alt="image-empty-search" style="height: 128px" />
  <div class="highlight-text" id="noResultMessage">{{ labelTranslation.noResultMessage }}</div>
</div>

<div *ngIf="errorOccurred" class="centered-content">
  <img class="empty-form-image" id="errorImage" src="assets/images/EmptyImage_Users.svg" alt="image-empty-users" style="height: 128px" />
  <div style="margin-top: 20px" class="highlight-text" id="errorMessage">{{ labelTranslation.errorMessage }}</div>
</div>

<div *ngFor="let user of users" style="border-bottom: solid 1px rgba(78, 78, 135, 0.2)">
  <tsm-subsidiary-supplier-admin-board-user-item [model]="user"></tsm-subsidiary-supplier-admin-board-user-item>
</div>