import { Output } from "@angular/core";
import { Component, EventEmitter, Input, OnInit, OnDestroy } from "@angular/core";
import {
  ButtonPropsType, Modal,
} from "@lsl16/sustainability-shared-components";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "../../services/auth/auth.service";
import { HeaderEvents, HeaderService } from "src/app/services/header/header-service";

@Component({
  selector: 'tsm-pop-up-privacy',
  templateUrl: './pop-up-privacy.component.html',
  styleUrls: ['./pop-up-privacy.component.sass']
})
export class PopUpPrivacyComponent implements OnInit, OnDestroy {

  privacyJson = {
    title: "",
    content: ""
  }
  currentPrivacyLanguage = '';
  privacyLanguageJson = {};
  languageButton: string;
  @Input() country: string;
  @Input() modalType: string;
  @Input() modalData: Modal;
  @Input() privacyFlag: Boolean;
  buttonProps: ButtonPropsType = {
    label: "I Accept",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    onClick: async () => {
      await this.continue();
    }
  };
  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }
  privacyIconUrl = "assets/icons/info.svg";
  languageList = [];
  isSupplier = false;
  constructor(
    private authService: AuthService,
    public activeModal: NgbActiveModal,
    private supplierGeneralProfileService: SupplierGeneralProfileService,
    private translateServiceL: TranslateService,
    public ngbModalService: NgbModal
  ) { }
  ngOnDestroy(): void {
    const currentLanguage = localStorage.getItem('current language')
    this.translateServiceL.use(currentLanguage);
  }

  ngOnInit(): void {
    this.languageButton = '';
    this.initLanguage();
    if (this.privacyFlag === true) {
      this.buttonProps.label= 'Close'
    }
    this.isSupplier = this.authService.getRole() == "supplier";
  }

  async continue(): Promise<any> {
      if(this.privacyFlag !== true){
        localStorage.setItem("multiLanguageButtonShow", JSON.stringify(true));
      }
      this.activeModal.close();
  }

  initLanguage() {
    let role = this.authService.getRole()
    if (role === 'supplier') {
      this.useTransLanguage('english',true);
    }else{
      this.useTransLanguage('english',false);
    }
    debugger;
  }

  setLanguageButton(language) {
    switch (language) {
      case 'english':
        this.languageButton = 'English'
        break;
      case 'spainish':
        this.languageButton = 'Español'
        break;
      case 'portuguese':
        this.languageButton = 'Português'
        break;
      case 'french':
        this.languageButton = 'Français'
        break;
      case 'vietnam':
        this.languageButton = 'Vietnamese'
        break;
      case 'Thai':
        this.languageButton = 'Thai'
        break;
      case 'bahasa':
        this.languageButton = 'Bahasa'
        break;
      case 'chinese':
        this.languageButton = '中文'
        break;
      case 'italiano':
        this.languageButton = 'Italiano'
        break;
      case 'german':
        this.languageButton = 'Deutsch'
        break;
      case 'japanese':
        this.languageButton = '日本語'
        break;
      case 'brazil':
        this.languageButton = 'Português (Brasil)'
        break;
      case 'latamspanish':
        this.languageButton = 'LATAM Español'
        break;
      default:
        break;
    }
  }

  switchLanguage(language: string) {
    this.useTransLanguage(language,true);

  }

  useTransLanguage(language: string, isSetLanguageButton: boolean){
    if(isSetLanguageButton) this.setLanguageButton(language);
    this.translateServiceL.use(language);
    this.translateServiceL.get('supplier').subscribe({next: (res: string) => {
      console.log('res', res);
      this.privacyLanguageJson = res;
      const multiLanguageJson =  JSON.parse(JSON.stringify(this.privacyLanguageJson));
      if (this.privacyFlag === true) {
        this.buttonProps.label= JSON.parse(JSON.stringify(multiLanguageJson.body.sharedComponent.buttonLable['Close']));
      }else{
        this.buttonProps.label = JSON.parse(JSON.stringify(multiLanguageJson.body.sharedComponent.buttonLable['I Accept']));
      }
    }
    });


  }

  ngAfterContentChecked() {
    const multiLanguageJson =  JSON.parse(JSON.stringify(this.privacyLanguageJson));
    if (multiLanguageJson.body != undefined) {
      if(!!this.country){
        if(this.country == "China/Mainland"){
          this.languageList = [
            {value: "english",name: "English"},
            {value: "chinese",name: "中文"}
          ];
          this.privacyJson = JSON.parse(JSON.stringify(multiLanguageJson.body.main.popUpChinaPrivacy));
        } else {
          this.languageList = [
            {value: "english", name: "English"},
            {value: "spainish", name: "Español"},
            {value: "french", name: "Français"},
            {value: "portuguese", name: "Português"},
            {value: "vietnam", name: "Vietnamese"},
            {value: "Thai", name: "Thai"},
            {value: "bahasa", name: "Bahasa Indonisia"},
            {value: "chinese", name: "中文"},
            {value: "italiano", name: "Italiano"},
            {value: "german", name: "Deutsch"},
            {value: "japanese", name: "日本語"},
            {value: "brazil", name: "Português (Brasil)"},
            {value: "latamspanish", name: "LATAM Español"}
          ];
          let role = this.authService.getRole()
          if (role === 'supplier'){
            this.privacyJson = JSON.parse(JSON.stringify(multiLanguageJson.body.main.popUpSupplierPrivacy));
          }else{
            this.privacyJson = JSON.parse(JSON.stringify(multiLanguageJson.body.main.popUpPrivacy));
          }

        }

      } else {
        const prvacyObj = JSON.parse(JSON.stringify(multiLanguageJson.body.sharedComponent.shModel));
        this.privacyJson.content = prvacyObj.content["privacyStatement"];
        this.privacyJson.title = prvacyObj.title["Privacy Statement"]
      }
    }
  }
}
