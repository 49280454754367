import { Injectable } from "@angular/core";
import { SubheaderPropsType } from "../../../models/SubheaderProps";

@Injectable()
export class CountryServedView {
    constructor() {}

    /**
     * Description: Provides props for subheader component in gdpr page
     * Params: none
     * Output: Subheader props object of type SubheaderPropsType
     */
    getProps = (): SubheaderPropsType => {
        return {
            isWelcomeMessage: false,
            headingPrefix: "System Admin",
            headingPrefixColor: "grey",
            heading: "Country Served Management"
        };
    };
}
