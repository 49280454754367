import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { AlertPropsType, HttpService, SAStatus, SupplierInformationBoxComponent, SupplierRelationshipService } from "@lsl16/sustainability-shared-components";
import { SupplierGeneralProfileService } from "../../../services/supplier-general-profile.service";
import { EventService } from 'src/app/services/EventService.service';
import { BaseSupplierProfileComponent } from "../base-supplier-profile/base-supplier-profile.component";
import { AuthService } from "src/app/services/auth/auth.service";
import { BuyerService } from "src/app/services/buyer/buyer.service";
import { SupplierHubService } from "src/app/services/supplierHub/supplier-hub.service";
import { environment } from "../../../../environments/environment";
import { ReportService } from "src/app/services/authority/report.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { EsgQuestionnaireService } from "@lsl16/sushub-frontend";
import { ProfileDetailsComponent } from "src/app/components/profile-details/profile-details.component";
import { ModalService } from "src/app/services/modal/modal.service";
import { PopUpBuyerTerminateReasonComponent } from "src/app/components/pop-up-buyer-terminate-reason/pop-up-buyer-terminate-reason.component";
import { ProfileConfig } from "src/app/components/profile-details/profile";

@Component({
  selector: 'tsm-bv-new-supplier-profile',
  templateUrl: './bv-new-supplier-profile.component.html',
  styleUrls: ['./bv-new-supplier-profile.component.sass']
})
export class BvNewSupplierProfileComponent extends BaseSupplierProfileComponent implements OnInit {
  @ViewChild('supplierInformationBox') supplierInformationBox: SupplierInformationBoxComponent
  requestSubmission: Subscription;
  showAlert: boolean;
  showAlert_fail: boolean;
  isScope3KeySup: boolean = false;
  alertProps: AlertPropsType;
  alertProps_fail: AlertPropsType;
  supplierGeneralProfileEventEmitSubscription: Subscription;
  profileData: any;
  isDuplicate = false;
  private baseUrl?: string;
  editProfileDetailData: any = {
    type: "edit-profile-detail",
    options: {},
    supplier: {}
  };
  terminateReasonPopUpModalConfig: ProfileConfig = {
    type: "pop-up-buyer-terminate-reason",
    options: {},
    supplier: {}
  };

  constructor(
    public supplierGeneralProfileService: SupplierGeneralProfileService,
    public eventService: EventService,
    public authService: AuthService,
    private httpService: HttpService,
    public BuyerService: BuyerService,
    supplierHubService: SupplierHubService,
    public reportService: ReportService,
    relationshipService: SupplierRelationshipService,
    public ngbModalService: NgbModal,
    private route: ActivatedRoute,
    private esgQuestionnaireService: EsgQuestionnaireService,
    private router: Router,
    private modalService: ModalService
  ) {
    super(supplierGeneralProfileService, authService, supplierHubService, reportService, relationshipService);
  }

  async ngOnInit(): Promise<void> {
    await this.getAll()
    this.editProfileDetailData.options = this.modalService.getOptions(this.editProfileDetailData.type);
    this.baseUrl = `${environment.tsmBackendServiceURL}/userprofile`;
    let routeParam = this.route.snapshot.paramMap.get("id");
    this.selectedSupplierTsmId = routeParam;
    this.supplierTsmId = routeParam;
    this.userType = this.authService.getRole();
    this.successfulRequestSubscription();
    await this.initialiseSupplier();
    await this.fetchParentData();
    this.collectReviewData();
    this.isScope3KeySupplier();
    this.subscribeEvent();
    this.addActivityLogTab(this.userType)
  }

  private isScope3KeySupplier() {
    if (this.supplierTsmId != this.selectedSupplierTsmId) {
      this.isScope3KeySup = this.isScope3Supplier(this.parentSupplier);
    } else {
      this.isScope3KeySup = this.isScope3Supplier(this.supplier);
    }
  }

  private subscribeEvent() {
    this.supplierGeneralProfileEventEmitSubscription =
      this.supplierGeneralProfileService.eventEmit.subscribe(async (event) => {
        let emitArry = event.split('#');
        if (emitArry[0] === 'verifySuccess') {
          this.showAlert = true;
          this.countryServed = emitArry[1];
          await this.initialiseSupplierWithCountryServed(this.countryServed);
          this.getprofile();
          this.alertProps = {
            type: 'alert-profileVerifiedSuccess',
            message: 'Profile updated successfully.',
            icon: 'assets/images/icon-tick.svg',
            iconFill: '#d5eed9',
            iconClose: 'assets/images/icon-cross-green.svg',
            onDismiss: this.onAlertDismiss,
          };
        }
      });
  }

  async getprofile(): Promise<any> {
    const b2cUser = this.authService.decodeToken();
    const queryUserProfileUrl = `${this.baseUrl}/getUserprofile/${b2cUser.email}`;
    this.profileData = await this.httpService.GetPromise(queryUserProfileUrl);
  }

  setAlertProps = (props): void => {
    this.showAlert = true;
    this.alertProps = {
      ...props,
      onDismiss: () => {
        this.showAlert = false;
      }
    };
    window.scrollTo(0, 0);
  }

  successfulRequestSubscription = () => {
    this.requestSubmission = this.esgQuestionnaireService.eventEmit.subscribe((value: any) => {
      if (value === "addSuccess") {
        this.showAlert = true;
        this.alertProps = {
          type: "alert-requestVerifiedSuccess",
          message: "You have successfully sent the Sustainability Assessment request to the Supplier.",
          icon: "assets/images/icon-tick.svg",
          iconFill: "#376803",
          iconClose: "assets/images/icon-cross-green.svg",
          onDismiss: this.onAlertDismiss
        };
        this.initialiseSupplier();
        window.scrollTo(0, 0);
      }
      if (value['text'] === "bothCase") {
        this.showAlert = true;
        this.showAlert_fail = true;
        this.alertProps = {
          type: "alert-requestVerifiedSuccess",
          message: (value['message_success']),
          icon: "assets/images/icon-tick.svg",
          iconFill: "#376803",
          iconClose: "assets/images/icon-cross-green.svg",
          onDismiss: this.onAlertDismiss
        };
        this.alertProps_fail = {
          type: "alert-requestVerifiedFaild",
          message: (value['message_fail']),
          icon: "assets/images/icon-error.svg",
          iconFill: "#cc1e32",
          iconClose: "assets/images/icon-cross-blue.svg",
          onDismiss: this.onAlertDismiss_1
        };
        window.scrollTo(0, 0);
      }
    });
  };

  onAlertDismiss = (): void => {
    this.showAlert = false;
  };

  onAlertDismiss_1 = (): void => {
    this.showAlert_fail = false;
  };

  async openProfilePopup(modalChoice: string): Promise<void> {
    if (!this.supplier) {
      await this.initialiseSupplier();
    }

    const confirmType = this.editProfileDetailData;
    let confirmTypeSupplier = {
      ...this.supplier,
      currentcountryServed: this.countryServed
    };
    confirmType.supplier = confirmTypeSupplier;
    const modalReference = this.ngbModalService.open(ProfileDetailsComponent, confirmType.options);
    modalReference.componentInstance.confirmType = confirmType;
  }

  ngOnDestroy(): void {
    const subList = [this.supplierGeneralProfileEventEmitSubscription];
    subList.forEach((item) => {
      if (!!item) {
        item.unsubscribe();
      }
    });
  }

  async openTerminatePopup(modalChoice: string): Promise<void> {
    if (!this.isDuplicate) {
      if (modalChoice == "terminate-modal") {
        this.openTerminateReasonModal();
      }
    }
  }

  async refershTerminate(value: boolean): Promise<void> {
    this.supplierInformationBox.terminateButtonDisplay("buyer");
  }

  public async openTerminateReasonModal(): Promise<void> {
    // avoid opening the modal twice at the sametime
    if (this.ngbModalService.hasOpenModals()) {
      return;
    }
    this.terminateReasonPopUpModalConfig.supplier = this.supplier
    this.terminateReasonPopUpModalConfig.options = {
      scrollable: true,
      animation: true,
      backdrop: 'static',
      windowClass: 'message-pop-up-modal-window',
      backdropClass: 'modal-custom-backdrop',
    };
    const modalReference = this.ngbModalService.open(PopUpBuyerTerminateReasonComponent, this.terminateReasonPopUpModalConfig.options);
    modalReference.componentInstance.confirmType = this.terminateReasonPopUpModalConfig;
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

}
