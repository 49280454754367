import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AdminBoardLogItem,
  ButtonPropsType, AlertPropsType,
} from '@lsl16/sustainability-shared-components';
import { LoginUser } from 'src/app/models/LoginUser';
import { AdminBoardService } from 'src/app/services/adminboard/admin-board.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopUpDisabledComponent } from 'src/app/components/pop-up-disabled/pop-up-disabled.component';
import { PopUpInactiveComponent } from 'src/app/components/pop-up-inactive/pop-up-inactive.component';
import { GeneralSupplierType } from '@lsl16/sushub-frontend';
 
@Component({
  selector: 'tsm-supplier-admin-board',
  templateUrl: './supplier-admin-board.component.html',
  styleUrls: ['./supplier-admin-board.component.sass']
})
export class SupplierAdminBoardComponent implements OnInit {
 
  @Input() supplierTsmId: string;
  @Input() isSubsidiaryAccessingParent: boolean;
  @Input() isDuplicate:boolean = false;
  supplier: GeneralSupplierType;
  public users: AdminBoardLogItem[];
  public b2cUsers: AdminBoardLogItem[];
  loggedInUser: LoginUser;
  userName: string;
  props: ButtonPropsType;
  limitUserAlertProps: AlertPropsType;
  saveError: boolean;
  supplierAdminBoardJson: any;
  isSystemSupplierAdmin: boolean;
  noUsersAssigned: boolean = false;
  isCreateUser_Button_Visible: boolean = false;
 
  constructor(
    private adminBoardService: AdminBoardService,
    private router: Router,
    private authService: AuthService,
    public ngbModalService: NgbModal,
    public supplierGeneralProfileService: SupplierGeneralProfileService
  ) { }
 
  async ngOnInit() {
    this.isSystemSupplierAdmin = this.authService.isSystemSupplierAdmin();
    this.setCreateUserBtnVisibility();
    this.loggedInUser = this.authService.getLoginUser();
    this.supplier = await this.supplierGeneralProfileService.getSupplierById(this.supplierTsmId);
    this.isDuplicate =
    this.supplier?.supplierName.substring(0, 10).toLowerCase() ===
    'duplicate-';
    this.search();
  }
 
  async searchKeyDown(event) {
    if (event.keyCode === 13) { //enter:13
      if (this.userName) {
        this.users = this.b2cUsers.filter(
          (s) => s.displayName.toLowerCase().indexOf(this.userName.toLowerCase()) >= 0
        );
      } else {
        this.users = this.b2cUsers;
      }
    }
  }
 
  async search() {
    this.b2cUsers = await this.adminBoardService.getAdminBoardLog(this.supplierTsmId);
    const usersObj = this.b2cUsers;
    for (let i = usersObj.length - 1; i >= 0; i--) {
      if (usersObj[i].email === this.loggedInUser.email) {
        var firstObj = usersObj[i];
        usersObj.splice(i, 1);
      } else if (!usersObj[i].userId) {
        // do not display not registered users
        usersObj.splice(i, 1);
      }
    }
    if (firstObj) {
      usersObj.unshift(firstObj);
    }
    this.noUsersAssigned = (this.b2cUsers.length === 0 && this.isSystemSupplierAdmin);
    this.users = this.b2cUsers;
    this.limitUser();
    this.limitActiveUser();
  }
 
  limitUser() {
    if (this.b2cUsers.length >= 30) {
      this.saveError = true
      this.limitUserAlertProps = {
        type: "alert-limitUserFaild",
        message: "You have reached the limit of 30 active users. To create a new user,please deactivate an active user first.",
        icon: "assets/images/icon-error.svg",
        iconFill: "#ffe8e6",
        iconClose: "assets/images/icon-cross-blue.svg",
        onDismiss: () => {
          this.saveError = false;
        }
      };
    }
  }
 
  limitActiveUser() {
    let countIsActive = 0;
    const usersObj = this.b2cUsers;
    Object.getOwnPropertyNames(usersObj).forEach(function (key) {
      if (key && key != "length") {
        const isActive = usersObj[key].currentRole.includes(3);
        if (isActive === false) {
          countIsActive++;
        }
      }
    });
    if (countIsActive >= 20) {
      this.saveError = true
      this.props = {
        label: "Create New User",
        color: "white",
        fontSize: 16,
        backgroundColor: "blue",
        borderColor: "blue",
        hoverColor: "white",
        hoverBackgroundColor: "blue",
        hoverBorderColor: "white",
        height: 48,
        disabled: this.isDuplicate,
        width: 223,
        padding: "10px",
        icon: "assets/icons/icon-add-supplier.svg",
        clickIcon: "assets/icons/icon-add-supplier.svg",
        iconPosition: "left",
        iconAlt: "icon-add-supplier",
        onClick: () => this.openInactivePopUp()
      };
    }
    else {
      this.props = {
        label: "Create New User",
        color: "white",
        fontSize: 16,
        backgroundColor: "blue",
        borderColor: "blue",
        hoverColor: "white",
        hoverBackgroundColor: "blue",
        hoverBorderColor: "white",
        disabled: this.isDuplicate,
        height: 48,
        width: 223,
        padding: "10px",
        icon: "assets/icons/icon-add-supplier.svg",
        clickIcon: "assets/icons/icon-add-supplier.svg",
        iconPosition: "left",
        iconAlt: "icon-add-supplier",
        onClick: () => {
          this.redirectToCreateNewUser();
        }
      };
    }
  }
 
  openDisabledPopUp(): void {
    const service = this.ngbModalService;
    if (!service.hasOpenModals()) {
      const modalReference = service.open(PopUpDisabledComponent, {
        centered: true,
        size: "lg",
        scrollable: true,
        windowClass: "countdown-modal",
        backdropClass: "modal-custom-backdrop",
        backdrop: "static",
        keyboard: false
      });
    }
  }
 
  openInactivePopUp(): void {
    const service = this.ngbModalService;
    if (!service.hasOpenModals()) {
      const modalReference = service.open(PopUpInactiveComponent, {
        centered: true,
        size: "lg",
        scrollable: true,
        windowClass: "countdown-modal",
        backdropClass: "modal-custom-backdrop",
        backdrop: "static",
        keyboard: false
      });
    }
  }
 
  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multiLanguageJson.body.main.supplierAdminBoard != undefined) {
        this.supplierAdminBoardJson = multiLanguageJson.body.main.supplierAdminBoard;
        document.getElementById('searchUserInput').setAttribute("placeholder", this.supplierAdminBoardJson.placeholder);
      }
    }
  }
 
  redirectToCreateNewUser() {
    if (this.isSystemSupplierAdmin) {
      this.router.navigate(["/create-new-user"], {
        queryParams: {
          supplierTsmId: this.supplierTsmId
        }
      });
    }
    else {
      this.router.navigateByUrl("create-new-user")
    }
  }
 
  setCreateUserBtnVisibility() {
    const userRole = this.authService.getRole();
    if (userRole === 'buyer') {
      this.isCreateUser_Button_Visible = this.isSystemSupplierAdmin;
    }
    else if (userRole === 'supplier') {
      this.isCreateUser_Button_Visible = this.authService.isSupplierAdmin() && !this.isSubsidiaryAccessingParent;
    }
  }
  blockNonPrintableChar() {
    let printablevalue= this.userName;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.userName = printablevalue;
  }
}
