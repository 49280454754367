import { Component, OnInit } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";
import { emailTemplateManagerModel } from "src/app/models/emailTemplateManager";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";

@Component({
  selector: 'email-template-manager',
  templateUrl: './email-template-manager.component.html',
  styleUrls: ['./email-template-manager.component.sass']
})
export class EmailTemplateManagerComponent implements OnInit {
  public templateSearchData: emailTemplateManagerModel[];
  searchCriteria: any;
  pageNumber: number = 1;
  totalNumberOfPages: number = 0;
  noDataFound: boolean = false;
  pageParams: any = {
    page: 1,
    pageSize: 10
  };

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private router: Router) {
  }

  resetFilter(): void {
    this.searchCriteria = { emailtype: [], language: [], marketeunit: [] };
  };

  async ngOnInit(): Promise<void> {
    this.resetFilter();

    if (this.authService.getRole() !== "authority") {
      document.getElementsByTagName('body')[0].style.display = 'none'
      if (this.checkRole()) {
        this.setCurrentView()
      } else {
        window.alert("You don't have permission to view this page");
      }
    } else {
      document.getElementsByTagName('body')[0].style.display = ''
      await this.showTable();
    }
  };

  setCurrentView = async (): Promise<void> => {
    this.authService.setCurrentRole('authority');
    await this.authService.handleButtonPermission();
    await this.router.navigateByUrl("/email-template-manager");
    window.location.reload();
  };

  checkRole() {
    return this.getAllRole().includes('authority')
  };

  getAllRole() {
    let platform = this.authService.getCurrentPlatform();
    return this.authService.getAllowedRoles(platform)
  };

  async showTable(searchFilter?: any) {
    if (this.pageNumber) {
      this.pageParams.page = this.pageNumber
    }
    if (searchFilter) {
      this.resetFilter();
      this.searchCriteria = searchFilter;
    }
    let emailTypeList = this.searchCriteria?.emailtype?.length ? [...this.searchCriteria?.emailtype] : [];
    const getRecordsUrl = `${environment.SUSHUBEMAILServiceURL}/email-templates/search?page=${this.pageParams.page}&pageSize=${this.pageParams.pageSize}`;
    const response: any = await this.httpService.PostPromise(getRecordsUrl,
      { emailTypes: emailTypeList });

    const { numberOfPages, data } = response;
    if (data?.length === 0) {
      this.noDataFound = true;
    } else {
      this.templateSearchData = [];
      this.templateSearchData = data;
      this.totalNumberOfPages = numberOfPages;
    }
  };

  handleCurrentPage = (currentPage: number): void => {
    this.pageNumber = currentPage;
    window.scrollTo(0, 270);
    this.showTable();
  };

  handleSearchResults = (templateSearchResults): void => {
    if (templateSearchResults) {
      document.getElementsByTagName('body')[0].style.display = '';
      this.pageNumber = 1;
      this.showTable(templateSearchResults)
    }
  };
}

