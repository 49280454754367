import { Output } from "@angular/core";
import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import {
    ButtonPropsType, Modal,
} from "@lsl16/sustainability-shared-components";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "../../services/auth/auth.service";

@Component({
    selector: "tsm-pop-up-welcome",
    templateUrl: "./pop-up-welcome.component.html",
    styleUrls: ["./pop-up-welcome.component.sass"]
})
export class PopUpWelcomeComponent implements OnInit {
    @Input() modalType: string;
    @Input() modalData: Modal;
    buttonProps: ButtonPropsType = {
        label: "Start working together",
        backgroundColor: "grayPurple",
        color: "white",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        height: 48,
        borderRadius: 12,
        padding: "16px 24px",
        margin: "0 0 0 2px",
        onClick: async () => {
            await this.continue();
        }
    };
    welcomeJson: any;
    languageButton: string;
    languageText: string = '';
    languageList = [];
    languageTextList = [];
    languageButtonList = [];
    constructor(
        public activeModal: NgbActiveModal,
        private supplierGeneralProfileService: SupplierGeneralProfileService,
        private translateService: TranslateService,
        private authService: AuthService
    ) {}
    async ngOnInit() {
        this.welcomeJson = {
            title: ''
        };
        this.languageButton = '';
        this.initLanguage();
    }

    initLanguage() {
        const language = localStorage.getItem("current language");
        let role = this.authService.getRole()
        // current CountryServed language
        let currentCSLang = localStorage.getItem('currentCountryServed');
        let saCountryServed = localStorage.getItem('saCountryServed')
        if (null != language && role === 'supplier') {
            this.translateService.use(language);
            if(saCountryServed) {
                this.setLanguageListByCountryServed(JSON.parse(saCountryServed));
            } else {
                this.setLanguageByCountryServed(currentCSLang);
            }
        } else {
            this.setLanguageByCountryServed("");
        }

        let title;
        let publicMassage;
        this.translateService.get('supplier.questionnire.title').subscribe({
            next: (res: any) => {
            title = res
              localStorage.setItem(
                 "title",JSON.stringify(res)
             )
            }
        });
        this.translateService.get('supplier.questionnire.publicMassage').subscribe({
            next: (res: any) => {
             publicMassage = res
              localStorage.setItem(
                 "publicMassage",JSON.stringify(res)
             )
            }
        });
        this.translateService.get('supplier.questionnire.Getting Started').subscribe({
            next: (res: string) => {
              localStorage.setItem(
                 "Getting Started",JSON.stringify(Object.assign(res,publicMassage,title))
             )
            }
        });
        this.translateService.get('supplier.questionnire.Environment').subscribe({
            next: (res: any) => {
              localStorage.setItem(
                "Environment",JSON.stringify(Object.assign(res,publicMassage,title))
                )
            }
        });
        this.translateService.get('supplier.questionnire.Human Rights & Social Impact').subscribe({
            next: (res: any) => {
              localStorage.setItem(
                 "Human Rights & Social Impact",JSON.stringify(Object.assign(res,publicMassage,title))
             )
            }
        });
        this.translateService.get('supplier.questionnire.Supplier Inclusion & Diversity').subscribe({
            next: (res: any) => {
              localStorage.setItem(
                 "Supplier Inclusion & Diversity",JSON.stringify(Object.assign(res,publicMassage,title))
             )
            }
        });
        this.translateService.get('supplier.questionnire.Ethics & Sustainability Management').subscribe({
            next: (res: any) => {
              localStorage.setItem(
                 "Ethics & Sustainability Management",JSON.stringify(Object.assign(res,publicMassage,title))
             )
            }
        });
    }

    async continue(): Promise<any> {
        this.supplierGeneralProfileService.eventEmit.emit("welcome");
        this.activeModal.close();
    }
    switchLanguage(language: string) {
        this.translateService.use(language);
        localStorage.setItem("current language",language);
        this.setLanguageButton(this.languageText);
        this.translateService.get('supplier').subscribe({
            next: (res: string) => {
              localStorage.setItem(
                 "multiLanguage",JSON.stringify(res)
              )
            }
        });
        let title;
        let publicMassage;
        this.translateService.get('supplier.questionnire.title').subscribe({
            next: (res: any) => {
             title = res
              localStorage.setItem(
                 "title",JSON.stringify(res)
             )
            }
        });
        this.translateService.get('supplier.questionnire.publicMassage').subscribe({
            next: (res: any) => {
             publicMassage = res
              localStorage.setItem(
                 "publicMassage",JSON.stringify(res)
             )
            }
        });
        this.translateService.get('supplier.questionnire.Getting Started').subscribe({
            next: (res: string) => {
              localStorage.setItem(
                 "Getting Started",JSON.stringify(Object.assign(res,publicMassage,title))
             )
            }
        });
        this.translateService.get('supplier.questionnire.Environment').subscribe({
            next: (res: any) => {
              localStorage.setItem(
                 "Environment",JSON.stringify(Object.assign(res,publicMassage,title))
             )
            }
        });
        this.translateService.get('supplier.questionnire.Human Rights & Social Impact').subscribe({
            next: (res: any) => {
              localStorage.setItem(
                 "Human Rights & Social Impact",JSON.stringify(Object.assign(res,publicMassage,title))
             )
            }
        });
        this.translateService.get('supplier.questionnire.Supplier Inclusion & Diversity').subscribe({
            next: (res: any) => {
              localStorage.setItem(
                 "Supplier Inclusion & Diversity",JSON.stringify(Object.assign(res,publicMassage,title))
             )
            }
        });
        this.translateService.get('supplier.questionnire.Ethics & Sustainability Management').subscribe({
            next: (res: any) => {
              localStorage.setItem(
                 "Ethics & Sustainability Management",JSON.stringify(Object.assign(res,publicMassage,title))
             )
            }
        });
    }

    setLanguageByCountryServed(currentCSLang) {
        let currentLanguage = localStorage.getItem("current language");
        switch (currentCSLang) {
            case 'ES':
            case 'AD':
                this.languageText = 'spainish';
                break;
            case 'PT':
                this.languageText = 'portuguese';
                break;
            case 'FR':
            case 'BE':
            case 'LU':
            case 'MA':
            case 'VN':
                this.languageText = 'french';
                break;
            case 'IT':
                this.languageText = 'italiano';
                break;
            case 'HK':
            case 'TW':
            case 'CN':
                this.languageText = 'chinese';
                break;
            case 'CH':
            case 'AT':
            case 'DE':
            case 'RS':
                this.languageText = 'german';
                break;
            case 'JP':
                this.languageText = 'japanese';
                break;
            case 'AR':
            case 'EC':
            case 'CR':
            case 'PE':
            case 'CO':
            case 'MX':
            case 'CL':
            case 'BR':
                this.languageTextList = ['brazil', 'latamspanish'];
                this.languageText ='latam'
                break;
            default:
                this.languageText = '';
                break;
        }
        if (currentLanguage != this.languageText  || currentCSLang == '') {
            this.switchLanguage('english')
        }
    }
    setLanguageButton(language) {
        switch (language) {
            case 'english':
                this.languageButton = 'English'
                break;
            case 'spainish':
                this.languageButton = 'Español'
                break;
            case 'portuguese':
                this.languageButton = 'Português'
                break;
            case 'french':
                this.languageButton = 'Français'
                break;
            case 'chinese':
                this.languageButton = '中文'
                break;
            case 'italiano':
                this.languageButton = 'Italiano'
                break;
            case 'german':
                this.languageButton = 'Deutsch'
                break;
            case 'japanese':
                this.languageButton = '日本語'
                break;
            case 'latam':
                this.languageButtonList.push('Português (Brasil)')
                this.languageButtonList.push('LATAM Español')
            default:
                break;
        }
    }

    ngAfterContentChecked() {
        if (localStorage.getItem("multiLanguage")) {
            const multiLanguageJson =  JSON.parse(localStorage.getItem("multiLanguage"));
            if (multiLanguageJson.body != undefined) {
                this.welcomeJson = JSON.parse(JSON.stringify(multiLanguageJson.body.main.poUpWelcome));
            }
        }
        this.languageList = [
            {value: "english", name: "English"}
          ];
        const  languageListTmp = this.languageTextList.map((value, index) => ({ value, name: this.languageButtonList[index] }));
        this.languageList = this.languageList.concat(languageListTmp);
    }
    setLanguageListByCountryServed(inSaCountryServed) {
        inSaCountryServed.forEach(item => {
           this.setLanguageByCountryServed(item);
            if(this.languageText && this.languageTextList.indexOf(this.languageText) < 0) {
                //Ignoring this push as for latam we are adding two contries in the above logic
                if(this.languageText!=='latam'){
                this.languageTextList.push(this.languageText);
                this.languageButtonList.push(this.languageButton);
                }
            }
        })
    }
}
