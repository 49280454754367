<tsm-user-secondary-banner
  [jumpUrl]="systemAdminBoard"
></tsm-user-secondary-banner>
<sh-alert
  [props]="saveErrorAlertProps"
  [ngStyle]="{ display: saveError ? 'block' : 'none' }"
></sh-alert>

<div class="user-profile-container mt-5">
  <tsm-system-admin-user-profile-detail
    *ngIf="user"
    [user]="user"
    [roles]="roles"
    (outerEvent)="outerEvent($event)"
  ></tsm-system-admin-user-profile-detail>
</div>
