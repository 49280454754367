import { CustomStyles } from './../../models/Styles';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tsm-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.sass']
})
export class TagComponent implements OnInit {
  @Input() customStyles: CustomStyles;
  
  label: string = "";
  borderStyle: any;
  valueStyle: any;

  constructor() {
  }

  ngOnInit(): void {
    this.setStyles();
  }

  private setStyles() {
    this.label = this.customStyles.label;
    this.borderStyle = this.customStyles.borderStyle;
    this.valueStyle = this.customStyles.valueStyle;
  }
}
