import { Component, OnInit } from '@angular/core';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParsedSupplierListFile } from 'src/app/models/SupplierListFile';
import { MassInviteService } from 'src/app/services/massInvite/mass-invite.service';

@Component({
  selector: 'tsm-pop-up-mass-invite',
  templateUrl: './pop-up-mass-invite.component.html',
  styleUrls: ['./pop-up-mass-invite.component.sass']
})
export class PopUpMassInviteComponent implements OnInit {
  public fileName: string;
  public contents: ParsedSupplierListFile;

  translations = {
    title: '',
    message: '',
    cancelLabel: '',
    confirmLabel: ''
  };

  cancelButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
    onClick: () => {
      this.activeModal.close();
    },
  };

  confirmButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    disabled: false,
    onClick: () => {
      this.processSupplierListFile();
    },
  };

  disabledConfirmButtonProps: ButtonPropsType = {
    ...this.confirmButtonProps,
    color: 'white',
    backgroundColor: 'lightGrey',
    hoverBackgroundColor: 'gray',
    disabled: true
  };

  constructor(
    private activeModal: NgbActiveModal,
    public massInviteService: MassInviteService
  ) { }

  ngOnInit(): void {
  }

  private processSupplierListFile(): void {
    try {
      this.confirmButtonProps = this.disabledConfirmButtonProps;
      this.massInviteService.processSupplierListFile(this.fileName, this.contents);
      this.activeModal.close();
    } catch (error) {
      console.error('Failed to process supplier list file', error);
    }
  }

  ngAfterContentChecked(): void {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      this.translations = multiLanguageJson.popUp.massInvite;
      this.confirmButtonProps.label = this.translations.confirmLabel;
      this.cancelButtonProps.label = this.translations.cancelLabel;
    }
  }
}
