import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { GeneralSupplierType } from '@lsl16/sushub-frontend';
import { SupplierGeneralProfileService } from 'src/app/services/supplier-general-profile.service';
import { InviteMode } from 'src/app/models/SubSupplier';


type specifyParentLiterals = {
  parentCompanyDunsNumber: string;
  nineDigitsNumberErrorMessage: string;
  searchBtn: string;
  dunsNumberTooltipMessage: string;
  supplierDunsNumber: string;
}
@Component({
  selector: 'tsm-specify-parent',
  templateUrl: './specify-parent.component.html',
  styleUrls: ['./specify-parent.component.sass']
})

export class SpecifyParentComponent implements OnInit {
  @Input() supplier: GeneralSupplierType;
  @Input() mode?: InviteMode;
  @Output() onSearchParent = new EventEmitter<GeneralSupplierType>();
  @Output() onParentDunsNumberChanged = new EventEmitter<string>();
  @Input() parentDunsNumber: string;
  public translation: specifyParentLiterals;
  private nineDigitsNumberPattern = /^[0-9]{9}$/;

  explanationProps: any = {
    tooltip: true,
    tooltipMessage: "The D-U-N-S Numbering (<b>D</b>ata <b>U</b>niversal <b>N</b>umbering <b>S</b>ystem) managed by Dun & Bradstreet assigns a unique, nine-digit number to a single business entity."
  }

  searchButtonProps: ButtonPropsType = {
    label: 'Search',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    width: 115,
    height: 32,
    padding: '16px 20px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
  };

  enabledSearchButtonProps: ButtonPropsType = {
    ...this.searchButtonProps,
    borderColor: 'lightBlue',
    disabled: false,
    onClick: () => this.search(),
  };

  disabledSearchButtonProps: ButtonPropsType = {
    ...this.searchButtonProps,
    borderColor: 'white',
    color: 'mediumGrey',
    hoverColor: 'mediumGrey',
    disabled: true
  };

  constructor(public supplierGeneralProfileService: SupplierGeneralProfileService) { }

  ngOnInit(): void {
    this.setSearchButtonPops();
  }

  public isValidDunsNumber(): boolean {
    if (!this.parentDunsNumber) {
      return false
    }
    const result = this.nineDigitsNumberPattern.test(this.parentDunsNumber)
    return result;
  }

  async search() {
    let supplierInfo;
    if(this.mode === "subsupplier"){
      supplierInfo = await this.supplierGeneralProfileService.getSubSupplierByDuns(this.parentDunsNumber);
    } else {
      supplierInfo = await this.supplierGeneralProfileService.getParentSupplierByDuns(this.parentDunsNumber);
    }
    if (supplierInfo.supplierTsmId) {
      this.onSearchParent.emit(supplierInfo);
    }
    else {
      this.onSearchParent.emit(null);
    }
  }

  onChange() {
    this.setSearchButtonPops();
    this.onParentDunsNumberChanged.emit(this.parentDunsNumber);
  }

  setSearchButtonPops() {
    if (this.parentDunsNumber && this.parentDunsNumber !== this.supplier?.dunsNumber && this.isValidDunsNumber()) {
      this.searchButtonProps = this.enabledSearchButtonProps;
    }
    else {
      this.searchButtonProps = this.disabledSearchButtonProps;
    }
  }
 
  setMessages() {
    this.explanationProps.tooltipMessage = this.translation.dunsNumberTooltipMessage;
  }

  setButtonLabel() {
    this.searchButtonProps.label = this.translation.searchBtn;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.translation = multilanguageJson.body.main.specifyParentLiterals;
        this.setButtonLabel();
        this.setMessages();
      }
    }
  }
  blockNonPrintableChar() {
    let printablevalue= this.parentDunsNumber;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.parentDunsNumber = printablevalue; 
  }
}
