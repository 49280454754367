import { Component, OnInit } from "@angular/core";
import { Roles } from "../../models/Roles";
import { AuthService } from "../../services/auth/auth.service";

@Component({
    template: ""
})
export class BaseContainerComponent implements OnInit {
    constructor(public authService: AuthService) {}

    get role(): Roles {
        return this.authService.getRole();
    }

    ngOnInit(): void {}
}
