<div>
    <div>
        <div class="file-title">
            Upload File
        </div>
        <div style="margin:30px 44px 30px 44px">
            <div class="table-title">
                <div>Upload a PDF file witch you'd like to viside to end user.
                    <img tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="docuemntComments"
                        style="cursor: pointer;" src="assets/icons/icon-help-enabled.svg" alt="">
                    <ng-template #docuemntComments class="match" style="width: 50%;">
                        <div class="tip-inner-content">
                            <div>Please note the following before uploading documents:</div>
                            <div class="text-content">
                                <div class="drop">·</div>
                                <div>The file name can only contain letters, numbers and special characters visible on
                                    the keyboard.</div>
                            </div>
                            <div class="text-content">
                                <div class="drop">·</div>
                                <div>Maximum length of the file name: 200 Characters.</div>
                            </div>
                            <div class="text-content">
                                <div class="drop">·</div>
                                <div>Accepted file format: PDF.</div>
                            </div>
                            <div class="text-content">
                                <div class="drop">·</div>
                                <div>Maximum number of files: 1</div>
                            </div>
                            <div class="text-content">
                                <div class="drop">·</div>
                                <div>Maximum size: 50mb</div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div>
                <tsm-upload-file style="width: 100%;" [confirmType]="confirmType"
                    (supplierListFileEvent)="handleSupplierListFileEvent($event)"></tsm-upload-file>
            </div>
            <div class="file-content">
                <div>
                    <div class="table-title">End User <span style="color:red">*</span> </div>
                    <tsm-single-drop-select (SingleDateSend)="getSingleDate($event)" [placeholder]="'Select One'"
                        [drapDownData]="user" [readonly]="true" [choiceDate]="userData">
                    </tsm-single-drop-select>
                </div>
                <div>
                    <div class="table-title">Category<span style="color:red">*</span></div>
                    <tsm-single-drop-select (SingleDateSend)="getCategoryDate($event)" [placeholder]="'Select One'"
                        [drapDownData]="Category" [readonly]="true" [disable]="creatbydisable"
                        [background]="creatbybackground" [choiceDate]="CategoryData">
                    </tsm-single-drop-select>
                </div>
                <div>
                    <div class="table-title">Display Text<span style="color:red">*</span></div>
                    <input (change)="change()" [ngClass]="{'no-pop':Displaydisable}" [(ngModel)]="displayText"
                        [disabled]="Displaydisable" type="text" maxlength="5000" (input)="blockNonPrintableChar()"
                        placeholder="All language supported" class="DisplayText input-placeholder">
                    <div style="font-size: 14px;">&nbsp;&nbsp;The text will be displayed in the Help page to the end
                        user</div>
                </div>
                <div>
                    <div class="table-title">Language<span style="color:red">*</span></div>
                    <tsm-single-drop-select (SingleDateSend)="getLanguageDate($event)" [placeholder]="'Select One'"
                        [drapDownData]="language" [readonly]="true" [disable]="languledisable"
                        [background]="Languagebackground" [choiceDate]="languageData">
                    </tsm-single-drop-select>
                </div>
                <div>
                    <div class="table-title">Comments</div>
                    <textarea [(ngModel)]="comments" (ngModelChange)="textChange()"
                        placeholder="The comments are only for internal notes, will not be visible to the end users."
                        class="DisplayText" name="" id="" cols="77" rows="3"></textarea>
                </div>
            </div>
            <div clas="file-button" style="display: flex;position:relative;margin-top: 33px;">
                <div>
                    <sh-button [props]="cancel"></sh-button>
                </div>
                <div style="position: absolute;right: 0">
                    <sh-button *ngIf="confirmType.title !== 'edit'" [props]="draft"></sh-button>
                    <sh-button *ngIf="confirmType.title !== 'edit'" [props]="publish"></sh-button>
                    <sh-button *ngIf="confirmType.title === 'edit'" [props]="save"></sh-button>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="width: 100%;height: 100%;position:absolute;top: 0;background-color: gray;opacity: 0.5;z-index: 1000;"
    *ngIf="loading">
    <div class="loadEffect" style="position: fixed;left: 45%;top:30%;z-index: 1000;">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>