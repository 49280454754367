<div class="dropdown-select" [ngStyle]="{ 'width': width }" #inputPanel>
    <div class="dropdown-select-tittle" [ngStyle]="{'pointer-events': dropDownViewOnly == true ? 'none' : 'auto'}" (click)="dropdownopen()">
        <input style="display: inline-block;"  [ngStyle]="{'height': height, 'font-size': fontSize, 'color': color, 'background-color': background}" [(ngModel)]="choiceDate" [title] = "choiceDate" [ngClass]="{'no-pop':disable}" class="dropdown-input input-placeholder" [readonly]="readonly"  [placeholder]="placeholder" (ngModelChange)="textChange()">
        <img class="icon-dropdown" src="./assets/icons/chevron-down-dropdown.svg" alt="icon-chevron-down-dropdown"  [ngClass]="{'no-pop':disable}"/>
    </div>
    <div *ngIf="dropDownOpen">
        <div class="dropdown-option" 
            >
             <div  class="line-drop" *ngFor="let item  of drapDownData">
                <div class="dropdown-option-content" (click)="slectTarget(item)" [title]="item.Text">{{item.Text}}</div>
            </div>
        </div> 
    </div>
</div>