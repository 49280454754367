<div class="container-fluid mb-5">
    <div class="main_bg">
        <div class="page-content mx-5">
            <email-template-manager-log [items]="templateSearchData" [currentPage]="pageNumber"
                (templateSearchResultsEventEmitter)="handleSearchResults($event)"
                (requery)="showTable()"></email-template-manager-log>
            <div *ngIf="noDataFound" class="centered-content">
                <img class="empty-form-image" src="assets/images/EmptyImage_Search.svg" style="height: 128px"
                    alt="image-empty-search" />
                <div style="margin-top: 20px" class="highlight-text">no items</div>
            </div>
        </div>
    </div>
    <tsm-pagination-bar *ngIf="totalNumberOfPages !== 0" class=" tsm-pagination-bar" [pages]="totalNumberOfPages"
        (currentPageChange)="handleCurrentPage($event)" [currentPage]="pageNumber">
    </tsm-pagination-bar>
</div>