<div [ngClass]="
    { buyer: 'buyer', supplier: 'supplier', authority: 'authority' }[userType]
  ">
  <div class="card mb-3 shadow rounded-0 position-relative border">
    <div class="img-header">
      <img [src]="options?.imageUrl" style="width: 100%" alt="icon-options-header" />
      <div class="img-header-text-overlay" *ngIf="options?.isAssessmentCreatedFromSubsidiary">
        <div class="img-header-text-overlay-triangle"></div>
        <div class="img-header-text-overlay-content">
          <div>
            {{ "supplier.body.main.optionsCard.subsidiary" | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="card-container">
      <div class="card-title mt-5 ms-3">
        {{ !!optionsCardJson?.title ? optionsCardJson?.title : options.title }}
      </div>
      <div class="status-card mx-3">
        <sh-sa-status *ngIf="userType === 'buyer' || userType === 'authority'" [userType]="userType"
          [esgStatus]="esgStatus"></sh-sa-status>
      </div>

      <div *ngIf="options.description && !options?.showParentHandlingSAInfo" class="card-body my-3 mx-3">
        <p>
          {{
          !!optionsCardJson?.description
          ? optionsCardJson?.description
          : options.description
          }}
        </p>
      </div>

      <div class="card-body my-3 mx-3" *ngIf="options?.showParentHandlingSAInfo">
        {{ optionsCardJson?.showParentHandlingSAInfo }}
      </div>

      <div *ngIf="options.displayRelationStatus" class="relationshipStatus">
        <sh-parent-relationship-status [supplierTsmId]="options?.supplierTsmId">
        </sh-parent-relationship-status>
      </div>
      <div *ngIf="options.relationshipInfoText" class="card-body my-4 mx-3">
        <p class="card-text">
          {{ options.relationshipInfoText }}
        </p>
      </div>
      <div class="card-body my-3 mx-3 explainContent" *ngIf="options?.explain" [ngStyle]="
          isSystemSupplierAdmin && options?.manageUsersButtonProps
            ? { bottom: '175px' }
            : ''
        ">
        <div style="margin-left: 16px">
          <div style="margin-top: 20px; margin-left: -16px">
            <img class="modal-icon" src="assets/icons/info-blue.svg" alt="icon-info" />
          </div>
          <div style="
              float: left;
              width: 2px;
              height: 40px;
              background: rgb(83, 107, 129);
              margin-top: -25px;
              margin-left: 16px;
            "></div>
          <div style="margin-top: -20px; margin-left: 22px">
            <p style="word-wrap: break-word; white-space: pre-line">
              {{ options?.explain }}
            </p>
          </div>
        </div>
      </div>

      <div class="d-flex mx-3 mb-4 card-status" id="requestorContent" *ngIf="options?.isLockedStatus" [ngStyle]="
          isSystemSupplierAdmin && options?.manageUsersButtonProps
            ? { bottom: '70px' }
            : ''
        ">
        <tsm-options-card-status [sentDate]="options?.requestdate"></tsm-options-card-status>
      </div>

      <div class="text my-3 mx-3" style="margin-bottom: 1.5rem !important"
        *ngIf="options?.isAssessmentCreatedFromSubsidiary">
        <div class="d-flex">
          <div>
            {{ "supplier.body.main.optionsCard.subsidiary" | translate }}
          </div>
          <div class="text-bold subsidiary-sa-info-value">
            {{ options?.subsidiaryAssessmentData?.subsidiaryName }}
          </div>
        </div>

        <div class="d-flex">
          <div>
            {{ "supplier.body.main.optionsCard.sentTo" | translate }}
          </div>
          <div class="text-bold subsidiary-sa-info-value">
            {{ options?.subsidiaryAssessmentData?.initialPointOfContact }}
          </div>
        </div>

        <div class="d-flex">
          <div>
            {{ "supplier.body.main.optionsCard.sentOn" | translate }}
          </div>
          <div class="text-bold subsidiary-sa-info-value">
            {{ options?.subsidiaryAssessmentData?.creationDate }}
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-center mb-4" *ngIf="
            !options?.isLockedStatus &&
            options?.buttonProps?.label !== 'Send Assessment' &&
            options?.showButton
          ">
          <sh-button [props]="requestButton" [ngStyle]="
          options?.updateSAButtonProps && options?.showUpdateSAButton 
            ? { width: '150px' }
            : ''
        "></sh-button>
          <sh-button [props]="updateSAButton" [ngStyle]="
          options?.updateSAButtonProps && options?.showUpdateSAButton 
            ? { width: '150px' }
            : ''
          " *ngIf="
        options?.updateSAButtonProps && options?.showUpdateSAButton && options?.updateSAButtonProps?.label !== 'Send Assessment'
        "></sh-button>
        </div>
      </div>

      <div id="sendAssessmentButton" class="d-flex justify-content-center mb-4" *ngIf="
          options?.buttonProps?.label === 'Send Assessment' &&
          !options?.isLockedStatus &&
          showSendAssessmentButton
        " [ngStyle]="
          isSystemSupplierAdmin && options?.manageUsersButtonProps
            ? { bottom: '70px' }
            : ''
        ">
        <sh-button [props]="requestButton" [ngStyle]="
        options?.updateSAButtonProps && options?.showUpdateSAButton 
          ? { width: '150px' }
          : ''
        "></sh-button>
        <sh-button [props]="updateSAButton" [ngStyle]="
        options?.updateSAButtonProps && options?.showUpdateSAButton 
          ? { width: '150px' }
          : ''
        " *ngIf="
        options?.updateSAButtonProps && options?.showUpdateSAButton && options?.updateSAButtonProps?.label !== 'Send Assessment'
        "></sh-button>
      </div>

      <!-- *ngIf="options?.buttonProps.label === 'Send Assessment'&&cardShow&&options?.isLockedStatus" -->
      <div id="requestButtonTwo" style="display: flex; position: relative"
        class="justify-content-center mb-4 requestButtonTwo" *ngIf="
          options?.buttonProps?.label === 'Send Assessment' &&
          cardShow &&
          options?.isTerminateStatus
        ">
        <sh-button [props]="requestButton2"></sh-button>
        <img class="modal-icon" src="assets/icons/info-blue.svg" alt="icon-info" (mouseenter)="showTooltip()"
          (mouseleave)="hideTooltip()" style="
            margin-top: 13px;
            margin-left: 259px;
            transform: rotate(180deg);
            cursor: pointer;
            position: absolute;
          " *ngIf="options.isReject" />
        <div class="tooltip-reason" *ngIf="reasonButton">
          <div class="tooltip-inner-reason">
            <div class="tip-Content">
              <div style="
                  font-weight: bold;
                  font-size: 14px;
                  color: #4e4e87;
                  line-height: 1.43;
                  margin-bottom: 20px;
                ">
                Rejected Reason
              </div>
              {{ options.reason }}
            </div>
          </div>
        </div>
      </div>
      <!-- withdrow -->
      <div id="requestButtonThree" style="display: flex; position: relative"
        class="justify-content-center mb-4 requestButtonThree" *ngIf="isBuyerExpert && options?.showReviewButton">
        <sh-button [props]="reviewAssessmentButton"></sh-button>
      </div>


      <div class="d-flex justify-content-center mb-4" [ngStyle]="
          isSystemSupplierAdmin && options?.manageUsersButtonProps
            ? { bottom: '5px' }
            : ''
        " *ngIf="manageUsersButton?.label === 'Manage Users' && hasValidRole">
        <sh-button [props]="manageUsersButton" [ngbTooltip]="manageUsersButton?.tooltipText ? tipOption : null"
          placement="bottom-left" tooltipClass="manage-user-tooltip-class"></sh-button>
      </div>
      <ng-template #tipOption>
        <div class="tip-inner-content">
          <div class="tip-header">
            {{ "supplier.body.main.optionsCard.Tip" | translate }}
          </div>
          {{ buttonJson.manageUsersTooltip }}
        </div>
      </ng-template>
    </div>
  </div>
</div>