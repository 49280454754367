import { Component, Input, OnInit } from '@angular/core';
import { GeneralSupplierType } from '@lsl16/sushub-frontend';
import { obfuscateName, obfuscateEmail } from "@lsl16/sustainability-shared-components";
import { InviteMode } from 'src/app/models/SubSupplier';

type confirmModalLiterals = {
  parentCompanyNameLabel: string;
  locationLabel: string;
  websiteLabel: string;
  domainNameLabel: string;
  dunsNumberLabel: string;
  pocNameLabel: string;
  pocEmailLabel: string;
}
@Component({
  selector: 'tsm-confirm-parent',
  templateUrl: './confirm-parent.component.html',
  styleUrls: ['./confirm-parent.component.sass']
})
export class ConfirmParentComponent implements OnInit {
  @Input() confirmSupplier?: GeneralSupplierType;
  @Input() mode?: InviteMode;
  modalTranslation: confirmModalLiterals;
  constructor() { }

  getObfuscatedPocName(): string {
    let pocFirstName: string;
    let pocLastName: string;
    if (this.confirmSupplier.supplierAdmin) {
      pocFirstName = this.confirmSupplier.supplierAdmin.firstName ? obfuscateName(this.confirmSupplier.supplierAdmin.firstName) : "";
      pocLastName = this.confirmSupplier.supplierAdmin.lastName ? obfuscateName(this.confirmSupplier.supplierAdmin.lastName) : "";
    }
    else {
      pocFirstName = this.confirmSupplier.pocFirstName ? obfuscateName(this.confirmSupplier.pocFirstName) : "";
      pocLastName = this.confirmSupplier.pocLastName ? obfuscateName(this.confirmSupplier.pocLastName) : "";
    }
    return (pocFirstName + " " + pocLastName).trim();
  }

  getObfuscatedPocEmail(): string {
    return this.confirmSupplier.pocEmail ? obfuscateEmail(this.confirmSupplier.pocEmail) : "";
  }

  ngOnInit(): void {
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multilanguageJson.body != undefined) {
        this.modalTranslation = multilanguageJson.body.main.addSupplierParentModal;
      }
    }
  }
}
