<div class="onbarding shadow rounded-0 position-relative border">
    <div class="row ml-3 tittle-heard">
        <div class="col-12">
            <div class="title-container mt-3">
                <div class="title">{{ translatedLabels.Onboarding }}</div>
                <div class="sub_title_name">{{ translatedLabels.OnboardingMessage }}</div>
            </div>
        </div>
    </div>
    <div *ngIf="!onbordingDataIf">
        <div class="row mt-3 ml-3 table-header">
            <div class="col-2">{{ translatedLabels['ONBOARDING PROFILE NAME'] }}</div>
            <div class="col-2">{{ translatedLabels['COUNTRIES SERVED'] }}</div>
            <div class="col-2">{{ translatedLabels['REQUESTOR'] }}</div>
            <div class="col-2">{{ translatedLabels['REQUEST DATE'] }}</div>
            <div class="col-2">{{ translatedLabels['STATUS'] }}</div>
            <div class="col-2">{{ translatedLabels['ACTIONS'] }}</div>
        </div>
        <div class="row ml-3 header-border"></div>
        <div class="content-max">
            <div *ngFor="let item of onboaredDataList">
                <div class="row mt-3  ml-3 fs-12">
                    <div class="col-2 font-style onboarding-content" placement="bottom-right"
                        tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="tipfilesname">
                        {{item.onboardingprofilesname}}
                        <ng-template #tipfilesname>
                            <div class="tip-inner-content">
                                {{item.onboardingprofilesname}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-2 onboarding-content" placement="bottom-right"
                        tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="tiponboardedcountry">
                        {{item.onboardedcountryt}}
                        <ng-template #tiponboardedcountry>
                            <div class="tip-inner-content">
                                {{item.onboardedcountrt}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-2 onboarding-content" placement="bottom-right"
                        tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="tipRequestor">
                        {{item.requestor}}
                        <ng-template #tipRequestor>
                            <div class="tip-inner-content">
                                {{item.requestor}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-2 onboarding-content" placement="bottom-right"
                        tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="tipRequsteddate">
                        {{item.requesteddate | date: 'MM/dd/yyyy'}}
                        <ng-template #tipRequsteddate>
                            <div class="tip-inner-content">
                                {{item.requesteddate}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-2">
                        <div class="width-100">
                            <div class="colstatus colstatus-common status-color-orange" style="margin-bottom: 2px;"
                                [ngClass]="{'status-color-green':item.level==4,'status-color-blue':item.level==1,'status-color-red':item.level=='cancel'}">
                                {{item.statust}}
                            </div>
                            <div class="display-flex">
                                <div class="status-bar-common status-bar-ready"
                                    [ngClass]="{'status-bar-process':item.level&lt;4,'status-bar-process':item.level>1,'status-bar-complete':item.level == 4,'status-bar-empty':item.level==1}">
                                </div>
                                <div class="status-bar-common status-bar-ready"
                                    [ngClass]="{'status-bar-process':item.level&lt;4,'status-bar-process':item.level>=2,'status-bar-complete':item.level == 4}">
                                </div>
                                <div class="status-bar-common status-bar-ready"
                                    [ngClass]="{'status-bar-process':item.level&lt;4,'status-bar-process':item.level>=3,'status-bar-complete':item.level == 4}">
                                </div>
                                <div class="status-bar-common status-bar-ready"
                                    [ngClass]="{'status-bar-process':item.level&lt;4,'status-bar-process':item.level>=4,'status-bar-complete':item.level == 4}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="statusFlag" class="col-2" (click)="redirectToOnboarding(item)">
                        <a class="a-button text-overflow onboarding-content">{{item.actiont}}
                        </a>
                    </div>
                </div>
                <div class="row ml-3 header-border" style="padding-top: 1rem"></div>
            </div>
        </div>
    </div>
    <div *ngIf="!onbordingDataIf" class="row mb-4"></div>
</div>