import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ButtonPropsType, Popup,
} from "@lsl16/sustainability-shared-components";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminBoardService } from 'src/app/services/adminboard/admin-board.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { DeleteUserConfig } from './deleteUser';

@Component({
  selector: 'tsm-pop-up-delete-user',
  templateUrl: './pop-up-delete-user.component.html',
  styleUrls: ['./pop-up-delete-user.component.sass']
})
export class PopUpDeleteUserComponent implements OnInit {

  public redirectUrl = "/supplier-admin-board";
  @Input() confirmType: DeleteUserConfig;
  popData: Popup;
  user: any;
  deleteReq: any;
  backButtonProps: ButtonPropsType = {
      label: "Back",
      backgroundColor: "white",
      color: "purple",
      fontSize: 16,
      fontFamily: "Graphik-Medium",
      width: 96,
      height: 48,
      padding: "16px 10px",
      margin: "0 0 0 2px",
      borderColor: "black-12",
      onClick: () => {
        this.activeModal.close();
      }
  };

  confirmButtonProps: ButtonPropsType = {
    label: "Confirm",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 121,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    borderColor: "black-12",
    onClick: async () => {
      await this.delete();
    }
  };
  constructor(private router: Router,private activeModal: NgbActiveModal,private modalService: ModalService,private adminBoardService: AdminBoardService) { }

  ngOnInit(): void {
    this.user = this.confirmType.user;
    this.deleteReq = this.confirmType.deleteReq;
    this.popData = this.modalService.getDeleteUserData();
  }

  async delete() {
    const response = await this.adminBoardService.deleteUser(this.user.userId, this.deleteReq);
    if (response){
      await this.router.navigateByUrl(this.redirectUrl);
      this.activeModal.close();
    }
  }  

}
