import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'tsm-user-secondary-banner',
  templateUrl: './user-secondary-banner.component.html',
  styleUrls: ['./user-secondary-banner.component.sass']
})
export class UserSecondaryBannerComponent implements OnInit {

  @Input() jumpUrl: string;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  backtoUser() {
    this.router.navigateByUrl(this.jumpUrl);
  }
}
