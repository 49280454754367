import { Component, OnInit } from "@angular/core";
import { FAQ, HelpAndSupportResponse, Link } from "src/app/models/HelpAndSupport";
import { HelpAndSupportService } from "src/app/services/helpAndSupport/help-and-support.service";
import { TranslateService } from '@ngx-translate/core';
import { EsgQuestionnaireService } from "@lsl16/sushub-frontend";
import { HeaderEvents, HeaderService } from "src/app/services/header/header-service";

@Component({
    selector: "supplier-help-and-support",
    templateUrl: "./supplier-help-and-support.component.html",
    styleUrls: ["./supplier-help-and-support.component.sass"]
})
export class supllierHelpAndSupportComponent implements OnInit {
    faqData: HelpAndSupportResponse;
    faqs: FAQ[];
    current = null;
    preCurrent = null;
    links: Link[];
    helpSupportJson: any;
    describe: null;
    localLanguage: string;
    supplierHelpSupport: any;
    helpContentData: any[];
    supplierhelp: any[];
    additionalSupportHeader: any;

    constructor(private helpAndSupportService: HelpAndSupportService,
        private translate: TranslateService,
        private esgQuestionnaireService: EsgQuestionnaireService,
        private headerService: HeaderService
        ) {}

    async ngOnInit() {
        this.faqData = await this.helpAndSupportService.getData();
        let saCountryServed = localStorage.getItem('saCountryServed');
        if(saCountryServed) {
            saCountryServed = JSON.parse(saCountryServed);
          } else {
            saCountryServed = "";
          }
        this.esgQuestionnaireService.eventEmitToHeaderCS.emit(saCountryServed);
        this.faqs = this.faqData.FAQs;
        this.links = this.faqData.links;
        this.translate.get('supplier.helpSupport').subscribe((res)=>{
            this.supplierHelpSupport = res;
           
        })
        this.handleFAQData();
        this.subscribeEvents();
    }
    
    subscribeEvents() {
        this.headerService.eventEmit.subscribe((event) => {
          if (event === HeaderEvents.multiLanguageChanged) {
           this.handleFAQData();
          }
        });
      }
      
    handleFAQData = async (): Promise<any> => {
        const helpUrl = await this.helpAndSupportService.getFAQData();
        this.helpContentData = helpUrl.data;
        const multiLanguageJson =  localStorage.getItem("current language");
        this.supplierhelp = this.helpContentData['FAQRecord'].filter(elements => elements.buyerSupplierFaqQuestion === "Supplier FAQ" && elements.language.toLowerCase() === multiLanguageJson);
        this.supplierhelp = this.sortSelectResource(this.supplierhelp);
        this.additionalSupportHeader = this.helpContentData['updateHelpPageContent'].filter(elements => elements.language.toLowerCase() === multiLanguageJson);
    }

  sortSelectResource = (resource: any): any => {
    return resource?.sort((obj1: any, obj2: any) => {
        const val1 = obj1.lastModifiedOn;
        const val2 = obj2.lastModifiedOn;
        if (new Date(val1).getTime() > new Date(val2).getTime()) {
            return 1;
        } else if (new Date(val1).getTime() < new Date(val2).getTime()) {
            return -1;
        } else {
            return 0;
        }
    }).sort((a: any, b: any) => {
      if (a.index === null || a.index === undefined) return 1;
      if (b.index === null || b.index === undefined) return -1;
      if (a.index < b.index) return -1;
      if (a.index > b.index) return 1;
      return 0;
    });;
}

    handleClick = (i) => {
        if (this.preCurrent == i) {
            this.current = null;
            this.preCurrent = this.current;
            return;
        }
        this.current = i;
        this.preCurrent = this.current;
    };
}
