<sh-alert [ngStyle]="{'display': successAlertVisible ? 'block' : 'none'}" [props]="successAlert"></sh-alert>
<sh-alert [ngStyle]="{'display': failureAlertVisible ? 'block' : 'none'}" [props]="failureAlert"></sh-alert>
<div class="container-fluid mb-5">
    <div class="main_bg">
        <div class="container container-fluid first-container d-flex">
            
            <div class="">
                <div class="d-flex flex-row mx-5 my-3 note">
                    To delete or manage a user or supplier's data relating to GDPR, please search for the related user using their email.
                </div>
            </div>  
            
            <div class="search-and-catagory mx-5 px-1 row justify-content-between">
                <div class="search-container">
                    <div class="search-textbox d-flex rounded-pill bg-white ps-3 pe-3 py-1">
                        <input type="text" class="pb-1" size="30" placeholder="Search for User Account"  [(ngModel)]="userName" (keydown)="searchKeyDown($event)" (input)="blockNonPrintableChar()" maxlength="5000" aria-label="input-text" />
                    </div>
                </div><sh-button [props]="searchButton"></sh-button>
            </div>

        </div>

        <div class="page-content mx-5">
            <sh-gdpr-log [items]="users"></sh-gdpr-log>
        </div>
    </div>
</div>