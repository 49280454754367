<div class="row mt-30">
  <div class="col">
    <label
      class="col-label"
      [ngClass]="{
        'invalid-display': parentDunsNumber && !isValidDunsNumber()
      }"
      *ngIf="mode === 'subsupplier'; else parentLabel"
    >
      {{ translation.supplierDunsNumber }}
      <span class="required">*</span>
      <sh-question-explanations
        [props]="explanationProps"
      ></sh-question-explanations>
    </label>

    <ng-template #parentLabel>
      <label
        class="col-label"
        [ngClass]="{
          'invalid-display': parentDunsNumber && !isValidDunsNumber()
        }"
      >
        {{ translation.parentCompanyDunsNumber }}
        <span class="required">*</span>
        <sh-question-explanations
          [props]="explanationProps"
        ></sh-question-explanations>
      </label>
    </ng-template>
  </div>
</div>

<div class="row">
  <div class="col-7">
    <input
      id="parentDunsNumberId"
      type="text"
      autocomplete="off"
      class="form-control"
      [(ngModel)]="parentDunsNumber"
      (ngModelChange)="onChange()"
      maxlength="9"
      aria-label="input-text"
      (input)="blockNonPrintableChar()"
    />
    <div class="input-invalid">
      <div *ngIf="parentDunsNumber && !isValidDunsNumber()">
        <span class="input-invalid-text">{{
          translation.nineDigitsNumberErrorMessage
        }}</span>
      </div>
    </div>
  </div>
  <div class="col-3 ps-0 btn-align">
    <sh-button [props]="searchButtonProps" id="searchBtn"></sh-button>
  </div>
</div>
