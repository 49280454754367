import { Component, OnInit } from '@angular/core';
import { IEmbedConfiguration, models, Report } from 'powerbi-client';
import { PowerBiAuthService } from 'src/app/services/auth/power-bi-auth.service';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'tsm-power-bi-dashboard',
  templateUrl: './power-bi-dashboard.component.html',
  styleUrls: ['./power-bi-dashboard.component.sass']
})
export class PowerBiDashboardComponent implements OnInit {

  private report?: Report;

  constructor(
    private powerbiAuthService: PowerBiAuthService,
    private dashboardService: DashboardService,
  ) {}

  async ngOnInit(): Promise<void> {

    if (!this.powerbiAuthService.tokenIsValid()) {
      this.powerbiAuthService.login();
      return;
    }

    try {
      const token = this.powerbiAuthService.getToken(); 
      const embedUrl = await this.dashboardService.getPowerBiEmbedLink(token);

      const embedConfiguration: IEmbedConfiguration = {
        type: "report",
        tokenType: models.TokenType.Aad,
        accessToken: token,
        embedUrl: embedUrl,
        viewMode: models.ViewMode.View,
        settings: {
          persistentFiltersEnabled: true,
          hideErrors: true,
          customLayout: {
            pageSize: {
              type: models.PageSizeType.Widescreen,
            },
            displayOption: models.DisplayOption.FitToPage,
            reportAlignment: models.ReportAlignment.Center,
          },
          layoutType: models.LayoutType.Custom,
          localeSettings: {
            language: "en",
            formatLocale: "en",
          },
          bars: {
            actionBar: {
              visible: true,
            },
          },
          panes: {
            filters: {
              expanded: false,
              visible: true,
            },
            pageNavigation: {
              visible: false,
            }
          },
        },
      };

      const reportContainer = document.getElementById('reportContainer');
      this.report = this.dashboardService.embedReport(reportContainer, embedConfiguration);
      this.report.iframe.title = 'dashboard';

    } catch (error) {
      console.log("Failed to initialize powerbi dashboard", error);
      return;
    }
  }
}