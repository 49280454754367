import { Subscription } from 'rxjs';
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { ToolNotification } from 'src/app/models/ToolNotification';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService, NotificationMode } from 'src/app/services/notification/notification.service';


@Component({
  selector: 'tsm-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.sass']
})
export class NotificationContainerComponent {
  @Input() notifications: ToolNotification[];
  private notificationEventSubscription: Subscription;
  translations = {
    headerTitle: '',
    headerTitleArchived: '',
    emptyNotificationMsg: '',
    emptyArchiveMsg: '',
    archivedMovedHint: '',
    archivedDeletedHint: '',
    archiveLink: ''
  };
  mode: NotificationMode;
  archivedNotifications: ToolNotification[] = [];
  archivedNotificationsCount: number;

  constructor(private notificationService: NotificationService, private authService: AuthService, private ref: ChangeDetectorRef) { }

  async ngOnInit(): Promise<void> {
    this.mode = 'unread';
    await this.loadArchivedNotifications();
    this.subscribeEvents();
  }

  private subscribeEvents() {
    this.notificationEventSubscription = this.notificationService.getRefreshNotificationsEvent().subscribe(async (e) => {
      switch (e.name) {
        case "delete":
          await this.loadArchivedNotifications();
          this.ref.detectChanges();
          break;
        case "resetMode":
          this.mode = 'unread';
          break;
      }
    });
  }

  private async loadArchivedNotifications() {
    this.archivedNotifications = await this.notificationService.getArchivedToolNotifications();
    this.archivedNotificationsCount = this.archivedNotifications.length;
  }

  closeNotificationPanel() {
    this.mode = 'unread';
    this.notificationService.emitNotificationEvent('closeNotification');
  }

  async goToArchive() {
    this.mode = "archive";
  }

  back() {
    this.mode = 'unread';
  }

  getRole() {
    return this.authService.getRole();
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.translations = multilanguageJson.body.main.notifications;
      }
    }
  }

  ngOnDestroy() {
    if (this.notificationEventSubscription) {
      this.notificationEventSubscription.unsubscribe();
    }
  }

  getContainerStyling(): string {
    let className: string;
    const role = this.getRole();
    const lang = localStorage.getItem('current language');
    switch (role) {
      case 'authority': {
        className = 'notification-container-auth';
        break;
      }
      case 'buyer': {
        className = 'notification-container-buyer';
        break;
      }
      default: {
        className = '';
        break;
      }
    }
    switch (lang) {
      case 'spainish':
        className = className.concat(' spainish');
        break;
      case 'portuguese':
        className = className.concat(' portuguese');
        break;
      case 'french':
        className = className.concat(' french');
        break;
      case 'italiano':
        className = className.concat(' italiano');
        break;
      case 'chinese':
        className = className.concat(' chinese');
        break;
    }

    return className;
  }
}
