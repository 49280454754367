import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AppComponent } from 'src/app/app.component';

export class Bar {
    public static readonly BACKGROUND_COLOR = "#C9C9C9";
    public static readonly HIGHLIGHTED_BACKGROUND_COLOR = "#A100FF";

    public label: string;
    public fillColor: string;
    public height: number;
    public position: number;
    public highlighted: boolean = false;
    public tooltip: string;
    
    constructor(label: string, highlighted: boolean, height: number, position: number, tooltip: string) {
        this.label = label;
        this.fillColor = this.determineColor(highlighted);
        this.height = height;
        this.position = position;
        this.highlighted = highlighted;
        this.tooltip = tooltip;
    }

    determineColor(highlighted: boolean) {
        return highlighted ? Bar.HIGHLIGHTED_BACKGROUND_COLOR : Bar.BACKGROUND_COLOR;
    }
}

@Component({
    selector: '[chart-bar]',
    templateUrl: './chart-bar.component.svg',
    styleUrls: ['./chart-bar.component.sass']
})
export class ChartBarComponent implements OnInit {
    @Input() fillColor: string = "rgb(200,200,200)";
    @Input() label: string = "Example";
    @Input() height: string = "0";
    @Input() position: string = "0";
    @Input() highlighted: string = 'false';
    @Input() markerLabel1: string = "Your";
    @Input() markerLabel2: string = "Score";
    @Input() tooltip: string = "tooltip";

    @ViewChild("barRect", { static: false }) controlBar: ElementRef;
    @ViewChild("barLabel", { static: false }) controlBarLabel: ElementRef;
    @ViewChild("markerLabel", { static: false }) controlMarkerLabel: ElementRef;

    x: number = 0;
    y: number = 0;
    size: number = 0;
    positionX: number = 20;

    xMarker: number = 0;
    xMarkerLabelOffset: number = 0;
    markerVisibility: string = "invisible";
    labelColor: string = "#333333";

    doHideTooltip: boolean = false;
    
    constructor(private cdr: ChangeDetectorRef, private appComponent: AppComponent) { }

    ngOnInit(): void {
        this.y = parseInt(this.position);
        this.height = `${this.height}%`

        if (this.highlighted == 'true') {
            this.markerVisibility = "visible";
            this.labelColor = "#ffffff";
        }
    }

    ngAfterViewInit() {
        // adjust "bar label" X-position based on its content.
        this.positionX = -this.controlBarLabel.nativeElement.getBBox().width - 20;
        // set marker x-position
        this.xMarker = this.x + this.controlBar.nativeElement.getBBox().width + 5;
        this.xMarkerLabelOffset = this.controlMarkerLabel.nativeElement.getBBox().width + 13; // 13 -> 8(marker width) + 5(space beween marker & it's text)
        this.cdr.detectChanges();
    }

    showTooltip(evt: MouseEvent): void {
        if (this.highlighted == 'true') {
            this.doHideTooltip = false;
            this.appComponent.showGlobalTooltip(null, this.tooltip, evt.pageX - 200, evt.pageY + 20);
        }
    }

    hideTooltip(): void {
        if (this.highlighted == 'true') {
            this.doHideTooltip = true;
            setTimeout(() => {
                if (this.doHideTooltip) {
                    this.appComponent.hideGlobalTooltip();
                }
            }, 10);
        }
    }
}
