import { Injectable } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { B2cRequestDataType } from "./types";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class B2cService {
    baseUrl: string = `${environment.tsmBackendServiceURL}/b2c`;

    constructor(private httpService: HttpService) {}

    updateUsersPrivacyStatement = async (id: string, data: B2cRequestDataType): Promise<any> => {
        const updateUsersPrivacyStatementUrl = `${this.baseUrl}/users/${id}/privacyStatement`;
        return await this.httpService.PostPromise(updateUsersPrivacyStatementUrl, data);
    };

    getUser = async (id: string): Promise<B2cRequestDataType> => {
        const getUserUrl = `${this.baseUrl}/getUser`;
        return await this.httpService.PostPromise(getUserUrl, {id});
    };

    insertNames = async (): Promise<void> => {
        await this.httpService.PostPromise(`${environment.tsmBackendServiceURL}/user-name`, {});
    }
}
