import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root"
})
export class SuppplierGuard  {
    constructor(private authService: AuthService) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (! window.location.href.includes('/supplier-profile/')) {
            sessionStorage.setItem('dashBoardSearchScrollTop', "0")
            sessionStorage.setItem('useCache', 'false')
            sessionStorage.removeItem('searchParams')
        }

        let flag = this.authService.getLoginUser()?.defaultProfile;

        if ("supplier"!=flag) {
             return true;
        }else{
            window.alert("You don't have permission to view this page");
            return false;
        }
    }
}