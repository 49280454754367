<header>
  <!-- Fixed navbar -->
  <nav class="navbar-bg-gradients py-3 px-5">
    <div class="d-flex">
      <!-- Accenture Sustainability hub Logo and Title -->
      <div class="text-center me-auto align-self-center cursor-pointer">
        <a tabindex="0" (keydown.enter)="backToLanding()"><img class="greater-than-symbol me-3 pb-1" src="assets/images/logo_banner.svg" (click)="backToLanding()"
          alt="icon-banner" /></a>
        <a class="site-title text-white text-decoration-none" (click)="backToLanding()" tabindex="0" (keydown.enter)="backToLanding()">
          {{ supplierHeader.logo }}
        </a>
      </div>

      <!-- Dashboard  -->
      <div class="text-center me-5 align-self-center" *ngIf="isReportLinksAvailable" (click)="goDashboard()">
        <div style="display: flex; justify-content: center; align-items: center">
          <a tabindex="0" (keydown.enter)="goDashboard()"><img src="assets/images/Dashboard_Icon.svg" class="nav-btn-img" alt="icon-dashboard" /></a>
        </div>
        <div class="explation">
          {{ supplierHeader.dashboard }}
        </div>
      </div>

      <!-- Report-->
      <div id="reportMenu" class="text-center me-5 align-self-center report" *ngIf="showSAReport || showSAandScoringReport"
      tooltipClass="report-tooltip-class" triggers="click:blur" [open]="isReportTooltipOpen" [ngbTooltip]="tipOption"
      #pop="ngbTooltip" >
      <a href="#" tabindex="0" (click)="toggleDropdown(pop, $event)" (keydown.tab)="toggleDropdown(pop, $event)"><img src="assets/images/icon-report.png"
          class="nav-btn-img" alt="icon-report" /></a>
      <div class="explation" >{{ supplierHeader.report }}</div>
    
      <ng-template #tipOption>
        <button  id="saAndScoringReport" class="dropdownOption" *ngIf="showSAandScoringReport"
          (click)="toSAandScoringReport()" tabindex="0" 
          (keydown.tab)="toggleDropdown(pop , $event)"
          (blur)="closeDropdownSA(pop)"
          (click)="toggleDropdown(pop, $event)"
          (keyup.enter)="closeDropdownOnEnter($event, pop)">
          {{ supplierHeader.saAndScoringReport }}
        </button>
        <button class="border-1" *ngIf="showDuplicateTaxIdSR" tabindex="-1"></button>
        <button class="border-1" *ngIf="ra_flag === 'scm' || ra_flag==='srdp' || ra_flag==='prisk' || getBIRole()==='BI'" tabindex="-1"></button>
        <button  id="raReport" class="dropdownOption" *ngIf="ra_flag === 'scm' || ra_flag==='srdp' || ra_flag==='prisk' || getBIRole()==='BI'"
          (click)="toRAReport()" tabindex="0" 
          (keydown.tab)="toggleDropdown(pop , $event)"
          (blur)="closeDropdownRA(pop)"
          (click)="toggleDropdown(pop, $event)"
          (keyup.enter)="closeDropdownOnEnter($event, pop)">
          Risk Assessment Report
        </button>

        <button class="dropdownOption" *ngIf="showDuplicateTaxIdSR" tabindex="0"
          (click)="toDownloadReportBuyerExp()" (click)="toggleDropdown(pop, $event)" (keydown.tab)="toggleDropdown(pop, $event)" (blur)="closeDropdownBuyerExpert(pop)" (keyup.enter)="closeDropdownOnEnter($event, pop)">
          Duplicate Tax Id Suppliers Report
        </button>

        <button class="border-1" *ngIf="showSAReport" tabindex="-1"></button>
        <button class="dropdownOption" *ngIf="showSAReport && !roleIsDR" tabindex="0" (click)="toSusAssessmentReport()" (click)="toggleDropdown(pop, $event)" (keydown.tab)="toggleDropdown(pop, $event)" (keyup.enter)="closeDropdownOnEnter($event, pop)">
          {{ supplierHeader.saReport }}
        </button>
        <button class="border-1" *ngIf="showSAReport && !roleIsDR" tabindex="-1"></button>
        <button class="dropdownOption" *ngIf="showSAReport" tabindex="0" (click)="toDownloadReport()" (click)="toggleDropdown(pop, $event)" (keydown.tab)="toggleDropdown(pop, $event)" (blur)="closeDropdownAuthority(pop)" (keyup.enter)="closeDropdownOnEnter($event, pop)">
          <img class="report-style" src="assets/icons/download-purple.svg" alt="icon-download" />{{
          supplierHeader.downloadProcessReport }}
        </button>
      </ng-template>
    </div>

      <!-- Home -->
      <div class="text-center me-5 align-self-center" *ngIf="headerRole() === 'supplier'" (click)="goHome()">
        <a href="#" tabindex="0" (keydown.enter)="goHome()"><img src="assets/images/icon-home-white.png" class="nav-btn-img" alt="icon-home" /></a>
        <div class="explation">
          {{ supplierHeader.home }}
        </div>
      </div>

      <!-- Help & Support -->
      <div class="text-center me-5 align-self-center header-button-width" *ngIf="showHelp || isDelegatedRepresentative"
        (click)="goByerHelp()">
        <div>
          <a tabindex="0" (keydown.enter)="goByerHelp()"><img src="assets/images/icon-faq.png" class="nav-btn-img" alt="FAQ icon - click for help" /></a>
          <span class="explation" style="display: block">{{
            supplierHeader.help
            }}</span>
        </div>
      </div>

      <div class="text-center me-5 align-self-center"
        *ngIf="headerRole() === 'authority' || headerRole() === 'supplier'">
        <a class="icon-focus text-decoration-none"   (click)="downloadGuideFile()" *ngIf="headerRole() === 'authority'">
          <a tabindex="0" (keydown.enter)="downloadGuideFile()"><img src="assets/images/Download_Icon.svg"  class="nav-btn-img" [ngClass]="{'no-pop':!download}" alt="icon-faq" /></a>
          <span  class="explation" [ngClass]="{'no-pop':!download}" style="display: block">{{
            supplierHeader.guide
            }}</span>
        </a>
        <div *ngIf="headerRole() === 'supplier'" (click)="goSupport()">
          <a tabindex="0" (keydown.enter)="goSupport()"><img src="assets/images/icon-faq.png" class="nav-btn-img" alt="icon-faq" /></a>
          <span class="explation" style="display: block">{{
            supplierHeader.help
            }}</span>
        </div>
      </div>

      <!-- P + chat bot -->
      <div class="text-center me-5 align-self-center header-button-width" *ngIf="headerRole() === 'buyer'"
        (click)="goChatbot()">
        <a href="#" tabindex="0" (keydown.enter)="goChatbot()"><img src="assets/images/Chatbot-Icon.svg" class="nav-btn-img" alt="icon-chatbot" /></a>
        <div class="explation">
          {{ supplierHeader.chatbot }}
        </div>
      </div>

      <!-- Notifications -->
      <div #notificationDropdownRef class="text-center align-self-center me-5 notifications"
        *ngIf="isNotificationsRequired()" ngbDropdown placement="bottom" [open]="isDropdownOpen" (click)="toggleNotifications($event)">
        <a href="#" tabindex="0"><img class="notification-img cursor-pointer" src="assets/icons/notification.svg" id="notification-dropdown"
          ngbDropdownToggle alt="icon-notification-plain"/></a>
        <div style="overflow: auto" class="cursor-pointer" role="button" ngbDropdownToggle id="notification-menu">
          {{ supplierHeader.notifications }}
        </div>
        <div class="notification_count" *ngIf="notificationCount > 0">
          {{ notificationCount }}
        </div>
        <div class="dropdown-menu notification dropdown-menu-right" [ngClass]="getNotificationStyling()" ngbDropdownMenu style="margin-top: 4px;
        margin-left: 46px;">
          <tsm-notification-container [notifications]="notifications"></tsm-notification-container>
        </div>
      </div>

      <!-- Profile -->
      <div [ngClass]="
          headerRole() === 'buyer'
            ? 'text-center align-self-center cursor-pointer'
            : 'text-center me-5 align-self-center'
        " style="margin: 0;" ngbDropdown class="profileaction" placement="bottom" (openChange)="openChange()" [open]="isProfileDropdownOpen">
        <a href="#" tabindex="0" (click)="toggleProfileDropdown($event)"><img style="
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
          " src="assets/images/icon-profile-plain.svg" id="dropdownBasicOne" ngbDropdownToggle
          alt="icon-profile-plain" /></a>
        <div class="explation" style="overflow: auto" role="button" ngbDropdownToggle>
          {{ supplierHeader.profile }}
        </div>

        <div ngbDropdownMenu [ngClass]="
            headerRole() === 'supplier'
              ? 'dropdown-menu-supplier'
              : 'dropdown-menu-buyer'
          "style="margin: 0;">
          <button ngbDropdownItem>{{ loginUser?.userName }}</button>
          <button ngbDropdownItem (click)="toAdminBoard()" tsmPermission="supplierAdminManageUser=sph"
            *ngIf="reloadManageUser">
            {{ supplierHeader.profileDetails.manageUsers }}
          </button>
          <button ngbDropdownItem *ngIf="headerRole() === 'authority'" (click)="toTerminationRequestBoard()">
            Manage SA Withdrawal
          </button>
          <button ngbDropdownItem *ngIf="headerRole() === 'authority'" (click)="toManageRequestSA()">
            Manage SA Updates approvals
          </button>
          <button ngbDropdownItem *ngIf="headerRole() === 'authority'" (click)="toManageEmailTemplates()">
            Manage Email Templates
          </button>
          <button ngbDropdownItem *ngIf="isSystemAdmin() && headerRole() === 'buyer'" (click)="toSystemAdminBoard()">
            {{ supplierHeader.profileDetails.manageUsers }}
          </button>
          <button ngbDropdownItem *ngIf="
              (isSystemAdmin() && headerRole() === 'buyer') ||
              (isSystemAdmin() && headerRole() === 'authority')
            " (click)="toSystemAdminCountry()">
            Manage Country Served
          </button>
          <button ngbDropdownItem *ngIf="
              isDelegatedRepresentative
            " (click)="toDocumentManagement()">
            Document Management
          </button>
          <button ngbDropdownItem tsmPermission="requestMultipleSa=tsm" (click)="toRequestMultipleSa()"
            id="sendMultiSa">
            Send Multiple SA
          </button>
          <button ngbDropdownItem tsmPermission="requestMultipleSa=tsm" (click)="toCreateBulkTsmProfiles()"
            id="createBulkTsmProfiles">
            Create Bulk TSM Profiles
          </button>
          <button ngbDropdownItem tsmPermission="requestMultipleSa=tsm" (click)="toRetriggerSa()"
            id="retriggerSa">
            Retrigger SA
          </button>
          <button ngbDropdownItem *ngIf="isDelegatedRepresentative" (click)="toBulkUpload()"
            id="bulkUpload">
            {{ supplierHeader.profileDetails.bulkUploadTemplate }}
          </button>
          <button ngbDropdownItem *ngIf="getAllowedRoles().includes('buyer')" (click)="setCurrentView('buyer','buyer')">
            {{ supplierHeader.profileDetails.viewAsBuyer }}
          </button>
          <button ngbDropdownItem *ngIf="
              (isSystemAdmin() && headerRole() === 'buyer') ||
              (isSystemAdmin() && headerRole() === 'authority')
            " (click)="toMergeProfile()">
            Merge Profiles
          </button>
          <button ngbDropdownItem *ngIf="getAllowedRoles().includes('supplier')" (click)="setCurrentView('supplier','supplier')">
            {{ supplierHeader.profileDetails.viewAsSupplier }}
          </button>
          <button ngbDropdownItem *ngIf="getAllowedRoles().includes('authority')" (click)="setCurrentView('authority','authority')">
            {{ supplierHeader.profileDetails.viewAsAuthority }}
          </button>
          <button ngbDropdownItem *ngIf="getRiskAssessmentRoles().includes('scm')" (click)="setCurrentView('buyer', 'scm')">
            {{ supplierHeader.profileDetails.viewAsScm }}
          </button>
          <button [innerHTML]="supplierHeader.profileDetails.viewAsSrdp" ngbDropdownItem
            *ngIf="getRiskAssessmentRoles().includes('srdp')" (click)="setCurrentView('buyer', 'srdp')"></button>
          <button ngbDropdownItem *ngIf="getRiskAssessmentRoles().includes('prisk')" (click)="setCurrentView('buyer', 'prisk')">
            {{ supplierHeader.profileDetails.viewAsPRisk }}
          </button>
          <button [innerHTML]="supplierHeader.profileDetails.viewAsSrsa" ngbDropdownItem
            *ngIf="getRiskAssessmentRoles().includes('srsa')" (click)="setCurrentView('buyer', 'srsa')"></button>
          <button [innerHTML]="supplierHeader.profileDetails.viewAsSrra" ngbDropdownItem
            *ngIf="getRiskAssessmentRoles().includes('srra')" (click)="setCurrentView('buyer', 'srra')"></button>
          <button ngbDropdownItem *ngIf="defaultProfile === 'supplier' && loginUser"
          (click)="updatePassword('supplier')">
          {{ supplierHeader.profileDetails.updatePassword }}
          </button>
          <button ngbDropdownItem *ngIf="defaultProfile === 'supplier' && loginUser" (click)="editProfile()">
            {{ supplierHeader.profileDetails.updatePhoneNumber }}
          </button>
          <button ngbDropdownItem (click)="logout()">
            {{ supplierHeader.logout }}
          </button>
        </div>
      </div>
      <!-- Language -->
      <div class="text-center align-self-center cursor-pointer" *ngIf="headerRole() === 'supplier'" [open]="isLanguageDropdownOpen" ngbDropdown>
        <a href="#" tabindex="0" (click)="toggleLanguageDropdown($event)"><img style="
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
          " src="assets/icons/website-white.svg" id="dropdownBasicTwo" ngbDropdownToggle alt="Language selection icon" /></a>
        <div class="explation" style="overflow: auto" ngbDropdownToggle>
          {{ supplierHeader.language }}
        </div>
        <div ngbDropdownMenu [ngClass]="
            headerRole() === 'supplier'
              ? 'dropdown-menu-supplier supplier-language'
              : 'dropdown-menu-buyer'
          ">
          <button ngbDropdownItem  (click)="switchLanguage('english')">
            English
          </button>
          <button ngbDropdownItem *ngIf="spainish === true"  (click)="switchLanguage('spainish')">
            Español
          </button>
          <button ngbDropdownItem *ngIf="portuguese === true" (click)="switchLanguage('portuguese')">
            Português
          </button>
          <button ngbDropdownItem  *ngIf="french === true" (click)="switchLanguage('french')">
            Français
          </button>
          <button ngbDropdownItem *ngIf="italiano === true" (click)="switchLanguage('italiano')">
            Italiano
          </button>
          <button ngbDropdownItem *ngIf="chinese === true" (click)="switchLanguage('chinese')">
            中文
          </button>
          <button ngbDropdownItem *ngIf="german === true" (click)="switchLanguage('german')">
            Deutsch
          </button>
          <button ngbDropdownItem *ngIf="japanese === true" (click)="switchLanguage('japanese')">
            日本語
          </button>
          <button ngbDropdownItem *ngIf="brazil === true" (click)="switchLanguage('brazil')">
            Português (Brasil)
          </button>
          <button ngbDropdownItem *ngIf="latamspanish === true" (click)="switchLanguage('latamspanish')">
            LATAM Español
          </button>
        </div>
      </div>
    </div>
  </nav>
</header>
<div class="loadEffect" style="position: fixed;left: 50%;top:30%;z-index: 1000;" *ngIf="loading">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
</div>
