import { Component, Output } from '@angular/core';
import {  EventEmitter, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';


@Component({
  selector: 'tsm-tsm-id',
  templateUrl: './tsm-id.component.html',
  styleUrls: ['./tsm-id.component.sass']
})
export class TsmIDComponent {
  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;
TsmID= '';

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }
  constructor() { }

  ngOnInit(): void {
    this.clearFilter.subscribe(() => this.clear());
  }
  inputChanged() {
    this.valueChanged.emit(this.TsmID);
    let printablevalue= this.TsmID;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.TsmID = printablevalue; 
  }
  clear() {
    this.TsmID = '';
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.TsmID;
      }
    }
  }
  

}
