import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'tsm-requestor-email',
  templateUrl: './requestor-email.component.html',
  styleUrls: ['./requestor-email.component.sass']
})
export class RequestorEmailComponent implements OnInit {
  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;
  @Input() invalidValue: Subject<string>;

  requestorEmail = '';
  invalid = false;

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: '',
    invalidMsg: ''
  }

  constructor() { }

  ngOnInit(): void {
    this.clearFilter.subscribe(() => this.clear());
    this.invalidValue.subscribe((event) => this.handleInvalid(event));
  }
  inputChanged() {
    this.invalid = false;
    this.valueChanged.emit(this.requestorEmail);
    let printablevalue= this.requestorEmail;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.requestorEmail = printablevalue; 
  }
  clear() {
    this.invalid = false;
    this.requestorEmail = '';
  }

  handleInvalid(event) {
    if (event === 'requestorEmail')
      this.invalid = true;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.requestorEmail;
      }
    }
  }
}
