import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SAStatus } from '@lsl16/sustainability-shared-components';
import { Subject } from 'rxjs';
import { filterModes } from 'src/app/services/esgReport/esg-report.service';

@Component({
  selector: 'tsm-esg-status-filter',
  templateUrl: './esg-status-filter.component.html',
  styleUrls: ['./esg-status-filter.component.sass']
})
export class EsgStatusFilterComponent implements OnInit {

  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;
  @Input() filterMode: filterModes;
  isDisabled: boolean = false;
  esgStatuses: any[];
  values: any[];

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }

  constructor() { }

  async ngOnInit(): Promise<void> {
    this.initStatuses();
    if (this.clearFilter) {
      this.clearFilter.subscribe(() => this.clear());
    }
  }

  initStatuses() {
    if (this.filterMode === 'multiSa') {
      this.esgStatuses = [
        {
          Code: SAStatus.notYetInitiated,
          Text: "Not yet initiated"
        },
        {
          Code: SAStatus.submittedScored,
          Text: "Validation completed"
        }
      ];
      return;
    }

    if (this.filterMode === 'saRetrigger') {
      this.isDisabled = true
      this.values = [SAStatus.submittedScored];
      this.dropdownChanged(this.values)
    }

    this.esgStatuses = [
      {
        Code: SAStatus.awaitingAcceptance,
        Text: "Awaiting acceptance"
      }, {
        Code: SAStatus.awaitingSubmission,
        Text: "Awaiting submission"
      }, {
        Code: SAStatus.pendingReview,
        Text: "Pending review"
      }, {
        Code: SAStatus.updateRequired,
        Text: "Update required"
      }, {
        Code: SAStatus.submittedScored,
        Text: "Validation completed"
      }
    ];
  }

  dropdownChanged(selectedValues) {
    this.values = selectedValues;
    this.valueChanged.emit(this.values);
  }

  clear() {
    this.values = [];
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.esgStatus;
      }
    }
  }
}
