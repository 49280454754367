<div class="upload">
    <div>
        <div class="title-magnager">
            Document Management
        </div>
        <div style="position: relative;height: 60px;margin: 16px 0 32px 0;">
            <button style="width: 141px;background: #3F72D2;color: white;position: absolute;right: 2%;" class="button"
                (click)="open(formDate, 'upload')">
                <img src="assets/images/Download_Icon.svg" alt="Download_Icon"
                    style="transform: rotate(180deg); width: 14px;height: 17px;">
                Upload File
            </button>
        </div>
        <div>
            <div class="files-list">
                <div class="row mt-3 table-header">
                    <div class="col-3 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="DisplayText">DISPLAY TEXT
                        <ng-template #DisplayText>
                            <div class="tip-inner-content">
                                DISPLAY TEXT
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-3 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="ENDUSER">END USER
                        <ng-template #ENDUSER>
                            <div class="tip-inner-content">
                                END USER
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-3 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="Status">STATUS
                        <ng-template #Status>
                            <div class="tip-inner-content">
                                STATUS
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-3 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="Category">CATEGORY
                        <ng-template #Category>
                            <div class="tip-inner-content">
                                CATEGORY
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-3 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="Language">LANGUAGE
                        <ng-template #Language>
                            <div class="tip-inner-content">
                                LANGUAGE
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-3 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="Createdby">CREATED BY
                        <ng-template #Createdby>
                            <div class="tip-inner-content">
                                CREATED BY
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-3 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="MODIFIEdby">MODIFIED BY
                        <ng-template #MODIFIEdby>
                            <div class="tip-inner-content">
                                MODIFIED BY
                            </div>
                        </ng-template>
                    </div>
                    <!-- <div class="col-3 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                    triggers="hover" [ngbTooltip]="Created">Created
                    <ng-template #Created>
                        <div class="tip-inner-content">
                            Created
                        </div>
                    </ng-template>
                    </div> -->
                    <div class="col-3 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="Comments">COMMENTS
                        <ng-template #Comments>
                            <div class="tip-inner-content">
                                COMMENTS
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-4 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        style="flex:2.3 !important" triggers="hover" [ngbTooltip]="Actions">ACTIONS
                        <ng-template #Actions>
                            <div class="tip-inner-content">
                                ACTIONS
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="row header-border"></div>
                <div class="content-max">
                    <!-- *ngFor="let item of matchDataList" -->
                    <div *ngFor="let item of formDate">
                        <div class="row mt-3 fs-12">
                            <div class="col-3 match-content" style="flex: 1 !important;" placement="bottom-left"
                                tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="displayText">
                                <img style="height: 15px;" src="assets/icons/attachment.svg" class="icon"
                                    alt="icon-attachment" (click)="downLoad(item)">
                                {{item.displayText}}
                                <ng-template #displayText class="match">
                                    <div class="tip-inner-content">
                                        {{item.displayText}}
                                    </div>
                                </ng-template>
                            </div>
                            <div class="col-3 match-content" style="flex: 1 !important;" placement="bottom-left"
                                tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="userType">
                                {{item.userType}}
                                <ng-template #userType class="match">
                                    <div class="tip-inner-content">
                                        {{item.userType}}
                                    </div>
                                </ng-template>
                            </div>
                            <div class="col-3 match-content" style="flex: 1 !important;" placement="bottom-left"
                                tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="status">
                                {{item.status}}
                                <ng-template #status class="match">
                                    <div class="tip-inner-content">
                                        {{item.status}}
                                    </div>
                                </ng-template>
                            </div>
                            <div class="col-3 match-content" style="flex: 1 !important;" placement="bottom-left"
                                tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="createRy">
                                {{item.category}}
                                <ng-template #createRy class="match">
                                    <div class="tip-inner-content">
                                        {{item.category}}
                                    </div>
                                </ng-template>
                            </div>
                            <div class="col-3 match-content" style="flex: 1 !important;" placement="bottom-left"
                                tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="language">
                                {{item.language}}
                                <ng-template #language class="match">
                                    <div class="tip-inner-content">
                                        {{item.language}}
                                    </div>
                                </ng-template>
                            </div>
                            <div class="col-3 match-content" style="flex: 1 !important;" placement="bottom-left"
                                tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="createBy">
                                {{item.createBy}}
                                <ng-template #createBy class="match">
                                    <div class="tip-inner-content">
                                        {{item.createBy}}
                                    </div>
                                </ng-template>
                            </div>
                            <div class="col-3 match-content" style="flex: 1 !important;" placement="bottom-left"
                                tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="updateBy">
                                {{item.updateBy}}
                                <ng-template #updateBy class="match">
                                    <div class="tip-inner-content">
                                        {{item.updateBy}}
                                    </div>
                                </ng-template>
                            </div>
                            <div class="col-3 match-content" style="flex: 1 !important;" placement="bottom-left"
                                tooltipClass="manage-user-tooltip-class" triggers="hover"
                                [ngbTooltip]="item.documentComments?docuemntComments:''">
                                {{item.documentComments}}
                                <ng-template #docuemntComments class="match">
                                    <div class="tip-inner-content">
                                        {{item.documentComments}}
                                    </div>
                                </ng-template>
                            </div>
                            <div class="col-4 match-content" style="flex:2.3!important;display: flex;"
                                placement="bottom-left" tooltipClass="manage-user-tooltip-class" triggers="hover">
                                <button (click)="deleted(item)" class="button"
                                    style="width: 94px;margin-right:15px;color: #4E4E87;" margin-right:><img
                                        class="icon-delete" src="./assets/icons/delete.svg"
                                        alt="icon-chevron-down-dropdown" />
                                    Delete</button>
                                <button (click)="open(item,'edit')"
                                    style="width: 59px;margin-right:15px;color: #4E4E87;" class="button">Edit</button>
                                <button (click)="published(item)"
                                    [ngClass]="item.status !== 'Published' ? '' : 'update-published'"
                                    style="width: 89px;background: #3F72D2;color: white;"
                                    class="button">Publish</button>
                            </div>
                        </div>
                        <div class="row header-border" style="padding-top: 1rem"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- *ngIf="loading" -->
<div style="width: 100%;height: 100%;position:absolute;top: 0;background-color: gray;opacity: 0.5;z-index: 1000;"
    *ngIf="loading">
    <div class="loadEffect" style="position: fixed;left: 45%;top:30%;z-index: 1000;">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>