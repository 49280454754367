<tsm-secondary-banner [isPopUp]="isPopUp" (outerEvent)="openPopUp()"></tsm-secondary-banner>
<div [ngClass]="supplierCheckFlag ? '' : 'body_container'">
  <div class="main_bg page-padding">
    <div *ngIf="!supplierCheckFlag" class="container">
      <div class="form shadow-sm">
        <div class="d-flex title">
          <div class="me-auto ms-2 align-self-center cursor-pointer" tabindex="0">
            First create a new supplier record
          </div>
        </div>
        <form class="detailform">
          <qnr-dynamic-form [configs]="configs" [model]="model" [context]="context" [callingComponent]="callingComponent" #form></qnr-dynamic-form>
          <div class="inline-block">
            <img class="aboutico" src="assets/icons/icon-infomation.svg" alt="icon-information" />
            <div class="aboutto" tabindex="0">You are about to add a new supplier, please ensure this information is correct before
              submitting. Only the supplier will have the ability to change these details.</div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="align-self-center">
              &nbsp;
            </div>
            <div class="align-self-center">
              <sh-button [props]="nextButton"></sh-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <tsm-supplier-duplicate-check *ngIf="supplierCheckFlag" [formValues]="formValues"
      [matchDataList]="matchDataList"></tsm-supplier-duplicate-check>
  </div>
</div>