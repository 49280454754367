import { SubSupplierEvents } from '@lsl16/sustainability-shared-components';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SubSupplier, SubSupplierRelationshipService } from '@lsl16/sustainability-shared-components';
import { HeaderEvents, HeaderService } from 'src/app/services/header/header-service';
import { orderBy } from "lodash";

type historicalSubSupplierLiterals = {
  name: string;
  location: string;
  pointOfContact: string;
  countriesServed: string;
  relationStatus: string;
  rejectionReason: string;
  title: string;
};

@Component({
  selector: 'tsm-historical-sub-suppliers',
  templateUrl: './historical-sub-suppliers.component.html',
  styleUrls: ['./historical-sub-suppliers.component.sass']
})
export class HistoricalSubSuppliersComponent implements OnInit {
  @Input() supplierTsmId: string;

  labelTranslations: historicalSubSupplierLiterals;
  historicalSubSupplieritems: SubSupplier[] = [];
  noRecordsFound: boolean = true;

  constructor(private subSupplierRelationshipService: SubSupplierRelationshipService,
    private headerService: HeaderService) { }

  async ngOnInit(): Promise<void> {
    this.loadTranslations();
    this.subscribeEvents();
    await this.fetchHistoricalSubSuppliers();
  }

  async fetchHistoricalSubSuppliers(): Promise<void> {
    try {
      const response =
        await this.subSupplierRelationshipService.getRejectedAndCancelledSubSuppliers(
          this.supplierTsmId
        );
      if (response && response.length > 0) {
        this.sortSuppliers(response);
        this.noRecordsFound = false;
      }
      else {
        this.noRecordsFound = true;
      }
    } catch (error) {
      console.error(`Failed to get historical sub suppliers for supplier ${this.supplierTsmId}.`, error);
    }
  }

  loadTranslations() {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.subSupplier;
      }
    }
  }

  subscribeEvents() {
    this.headerService.eventEmit.subscribe((event) => {
      if (event === HeaderEvents.multiLanguageChanged) {
        this.loadTranslations();
      }
    });
    this.subSupplierRelationshipService.eventEmit.subscribe((event) => {
      this.handleSubSupplierServiceEvents(event);
    });
  }

  async handleSubSupplierServiceEvents(eventType: SubSupplierEvents) {
    const eventArray = ['ConfirmedNewSupplier', 'RelationshipCancelled'];
    if (eventArray.includes(eventType)) {
      await this.fetchHistoricalSubSuppliers();
    }
  }

  sortSuppliers(response: SubSupplier[]) {
    response = orderBy(response, ["status", "name"], ['asc', "asc"]);
    this.historicalSubSupplieritems = response;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.supplierTsmId['previousValue'] != undefined && changes.supplierTsmId['currentValue'] !== changes.supplierTsmId['previousValue']) {
      await this.fetchHistoricalSubSuppliers();
    }
  }
}
