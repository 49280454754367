import { Component, Input, OnInit } from '@angular/core';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddParentConfig } from 'src/app/components/profile-details/profile';
import { SupplierService } from "src/app/services/supplier/supplier.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { EventService } from "src/app/services/EventService.service";

type updateSAModalLiterals = {
  title: string;
  updateReason: string;
  comments: string;
  changeConsentDesc: string;
  othersDesc: string;
  cancelBtn: string;
  submitBtn: string;
  ChangeInclusion: string;
  OtherReasons: string;
}
@Component({
  selector: 'tsm-supplier-update-sa',
  templateUrl: './supplier-update-sa.component.html',
  styleUrls: ['./supplier-update-sa.component.sass'],
})
export class SupplierUpdateSaComponent implements OnInit {
  @Input() confirmType: AddParentConfig;
  public modalTranslation: updateSAModalLiterals;
  isChangeConsent: boolean;
  displayPage: boolean;
  reason: string;
  reasonRequired: boolean = true;
  comments: string;
  commentRequired: boolean = true;
  supplierInfo: any;
  submitData: any;
  inputHeight: string;
  fontSize: string;
  color: string;
  workflowId: string;
  updateReasonArr: any;
  consentReason: string;
  otherReason: string;
  submitButtonProps: ButtonPropsType;
  buttonDisabled: boolean = false;
  loading: boolean = false;

  public cancelButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    width: 115,
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
    class: 'update-pop-up-button',
    onClick: () => {
      this.activeModal.close();
    },
  };

  constructor(
    private activeModal: NgbActiveModal,
    private supplierService: SupplierService,
    private authService: AuthService,
    public eventService: EventService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.getModalTranslation();
    this.updateReasonArr = []
    this.consentReason = 'Change Inclusion & Diversity consent'
    this.otherReason = 'Other reasons'
    if (this.confirmType.supplier.changeConsentFlag) {
      this.updateReasonArr.push(
        {
          Code: this.consentReason,
          Text: this.modalTranslation.ChangeInclusion
        }
      )
    }
    this.updateReasonArr.push(
      {
        Code: this.otherReason,
        Text: this.modalTranslation.OtherReasons
      }
    )
    this.displayPage = false;
    this.reasonRequired = true;
    this.commentRequired = true;
    this.inputHeight = "38px";
    this.fontSize = "15px";
    this.color = "#6964A7";
    this.checkSubmitButton();
    this.supplierInfo = this.confirmType.supplier;
    this.workflowId = (this.confirmType as any).workflowId;
    this.submitData = {
      "supplierTsmId": this.supplierInfo.supplierTsmId,
      "supplierName": this.supplierInfo.supplierName,
      "userId": this.supplierInfo.supplierAdmin.userId,
      "email": this.authService.getLoginUser().email,
      "countryServed": this.supplierInfo.currentCountry
    };
  }

  private setButtonLabels(): void {
    this.cancelButtonProps.label = this.modalTranslation.cancelBtn;
    this.submitButtonProps.label = this.modalTranslation.submitBtn;
  }

  getModalTranslation() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.modalTranslation =
          multilanguageJson.body.main.supplierUpdateSaModal;
      }
    }
  }

  ngAfterContentChecked() {
    this.getModalTranslation();
    this.checkSubmitButton();
    this.setButtonLabels();
  }

  submitButtonAvailable(): void {
    this.submitButtonProps = {
        label: '',
        backgroundColor: 'blue',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Graphik-Medium',
        width: 115,
        height: 48,
        disabled: false,
        padding: '16px 24px',
        margin: '0 0 0 2px',
        hoverBackgroundColor: 'darkBlue',
        class: 'update-pop-up-button',
        hoverColor: 'white',
        onClick: () => {
            this.buttonDisabled = true;
            this.submit();
        },
      };
  }

  submitButtonDisable(): void {
    this.submitButtonProps = {
        label: '',
        backgroundColor: 'gray',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Graphik-Medium',
        width: 115,
        height: 48,
        disabled: true,
        padding: '16px 24px',
        margin: '0 0 0 2px',
        hoverBackgroundColor: 'gray',
        class: 'update-pop-up-button',
        hoverColor: 'white'
      };
  }

  cancelButtonDisable(): void{
    this.cancelButtonProps = {
        label: '',
        backgroundColor: 'gray',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Graphik-Medium',
        width: 115,
        height: 48,
        disabled: true,
        padding: '16px 24px',
        margin: '0 0 0 2px',
        hoverBackgroundColor: 'gray',
        class: 'update-pop-up-button',
        hoverColor: 'white'
    };
  }

  changeComments(): void{
    if (this.comments && this.comments.trim()) {
      this.submitButtonAvailable();
    } else {
      this.submitButtonDisable();
    }
  }

  checkSubmitButton(): void {
    if (this.reason && !this.buttonDisabled) {
      if (this.isChangeConsent) {
        this.submitButtonAvailable();
      } else {
        this.changeComments();
      }
    } else {
        if (this.buttonDisabled) {
            this.cancelButtonDisable();
        }
        this.submitButtonDisable();
    }
  }

  getSingleDate(e: any) {
    this.displayPage = true;
    this.reason = e.Code;
    if (this.reason == this.consentReason) {
      this.isChangeConsent = true;
    } else {
      this.comments = '';
      this.isChangeConsent = false;
    }
    this.checkSubmitButton();
  }

  async submit() {
      this.loading = true;
      if (this.isChangeConsent) {
        // choose -> Change Inclusion & Diversity consent
        this.submitData["reason"] = this.reason;
        const response = await this.supplierService.updateSa(this.submitData);
        const updateWorkflow = await this.supplierService.updateWorkflowToInProgress(this.workflowId,this.submitData);
        this.loading = false;
        if (response && response.code === 200) {            
            await this.supplierService.setIsUpdateButtonTriggerStatus(true);
            this.eventService.eventEmit.emit('refreshList');
            this.activeModal.close();
            this.confirmType.redirectToEsgAssessment();
        } else if (response && response.code === 5025) {
            this.activeModal.close("task status has changed");
        } else {
            console.error("create new task failed!");
            return;
        }
    } else {
        // choose -> Other reasons
        this.submitData["comments"] = this.comments;
        this.submitData["reason"] = this.reason;
        const response = await this.supplierService.updateSAChooseOther(this.submitData);
        this.loading = false;
        if (response && response.code === 200) {
            this.eventService.eventEmit.emit('refreshList');
            this.activeModal.close('other Reason submit');
        } else if (response && response.code === 5025) {
            this.activeModal.close("task status has changed"); 
        } else {
            console.error("create new task failed!");
            return;
        }
      }
    }
}
