import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { ButtonPropsType, SupplierRelationshipService } from '@lsl16/sustainability-shared-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tsm-confirm-withdraw-relationship-request',
  templateUrl: './confirm-withdraw-relationship-request.component.html',
  styleUrls: ['./confirm-withdraw-relationship-request.component.sass']
})
export class ConfirmWithdrawRelationshipRequestComponent implements OnInit, AfterContentChecked {
  public parentTsmId: string;
  public subsidiaryTsmId: string;

  public translations = {
    title: '',
    message: '',
    confirmLabel: '',
    cancelLabel: '',
  };

  public confirmButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    onClick: () => {
      this.withdrawRelationshipRequest();
    }
  };

  public cancelButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
    onClick: () => {
      this.activeModal.close();
    },
  };


  constructor(
    private activeModal: NgbActiveModal,
    private supplierRelationshipService: SupplierRelationshipService,
  ) { }

  public ngAfterContentChecked(): void {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson =  JSON.parse(localStorage.getItem("multiLanguage"));
      this.translations = multiLanguageJson.popUp.confirmWithdrawRelationshipRequest;
      this.cancelButtonProps.label = this.translations.cancelLabel;
      this.confirmButtonProps.label = this.translations.confirmLabel;
    }
  }

  public async withdrawRelationshipRequest() {
    try {
      await this.supplierRelationshipService.withdrawRelationshipRequest(this.subsidiaryTsmId, this.parentTsmId);
      this.activeModal.close();
    } catch (error) {
      console.log('Failed to withdraw the relationship', error);
    }
  }

  public ngOnInit(): void {
    const tsmRoot  =  document.getElementsByTagName("tsm-root")[0] as HTMLElement;
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }
}
