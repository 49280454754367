<div class="add-new-supplier">
    <div class="row row-cols-auto" *ngIf="getRole() === 'buyer'">
        <div class="purple"></div>
        <div class="text">
            <div class="text-one">
                Didn't find the supplier?
            </div>
            <div class="text-two">
                Please create a new supplier profile if you don't see suppliers you are looking for in the search results.
            </div>
        </div>
        <sh-button [props]="addNewSupplier" tsmPermission="addSupplier=tsm" class="pe-4" style="margin-top: 30px;"></sh-button>
    </div>
</div>