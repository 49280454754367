<tsm-subheader [props]="subheaderProps"></tsm-subheader>

<div class="container-fluid mb-5 mt-5">
  <div class="merge-profile-content ms-5 me-5">
    <div class="row">
      <div class="col-4">
        <div class="profile-container secondary-profile-container">
          <h6 class="subtitle">Secondary Profile TSM ID</h6>
          <div class="input-box-container d-flex rounded-pill bg-white">
            <input
              type="text"
              [(ngModel)]="secondaryProfileId"
              (input)="onProfileIdChange('updatingSecondaryProfileId')"
              class="input-box pb-1 flex-grow-1 border-0"
              size="30"
              placeholder="Enter Secondary Profile TSM ID"
              maxlength="1000"
            />
          </div>
          <p class="profile-name">{{ secondaryProfileName }}</p>
          <div *ngIf="saList2 && saList2.length > 0">
            <div class="row mt-3 ms-3">
              <table class="table">
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>SA Group</th>
                    <th>SA Status</th>
                    <th>Last Updated</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <ng-container *ngFor="let item of saList2; let i = index">
                      <tr id="row mt-3 ms-3">
                            <td>{{ i+1 }}</td>
                            <td>
                              <span class="ellipsis" title="{{ getCountryServed(item.servedCountryList) }}">
                              {{ item.servedCountryList[0]?.countryGroupSa? item.servedCountryList[0]?.countryGroupSa : item.servedCountryList[0]?.tsmCode }}
                              </span>
                            </td>
                            <td>
                              <span class="ellipsis" title="{{ item.esgStatus }}">
                              {{ item.esgStatus }}
                              </span></td>
                            <td>{{ item.lastUpdatedDate.slice(0, 10) | date: 'd.MM.yyyy' }}</td>
                          </tr>
                      </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <p class="errorMessage" *ngIf="secondaryProfileErrorMessage" style="color: red;">
            {{ secondaryProfileErrorMessage }}
          </p>
        </div>
      </div>
      <div class="col-4 text-center">
        <div class="merge-arrow-container">
          <div class="arrow-text">You will be merging</div>
          <div class="arrow" style="color: rgb(63, 114, 210);"></div>
          <div class="arrow-text">secondary profile into primary profile</div>
        </div>
      </div>
      <div class="col-4">
        <div class="profile-container primary-profile-container">
          <h6 class="subtitle">Primary Profile TSM ID</h6>
          <div class="input-box-container d-flex rounded-pill bg-white">
            <input
              type="text"
              [(ngModel)]="primaryProfileId"
              (input)="onProfileIdChange('updatingPrimaryProfileId')"
              class="input-box pb-1 flex-grow-1 border-0"
              size="30"
              placeholder="Enter Primary Profile TSM ID"
              maxlength="1000"
            />
          </div>
          <p class="profile-name">{{ primaryProfileName }}</p>
            <div *ngIf="saList && saList.length > 0"> 
            <div class="row mt-3 ms-3">
              <table class="table">
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>SA Group</th>
                    <th>SA Status</th>
                    <th>Last Updated</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <ng-container *ngFor="let item of saList; let i = index">
                      <tr id="row">
                            <td>{{ i+1 }}</td>
                            <td>
                              <span class="ellipsis" title="{{ getCountryServed(item.servedCountryList) }}">
                              {{ item.servedCountryList[0]?.countryGroupSa? item.servedCountryList[0]?.countryGroupSa : item.servedCountryList[0]?.tsmCode }}
                              </span>
                            </td>
                            <td>
                              <span class="ellipsis" title="{{ item.esgStatus }}">
                              {{ item.esgStatus }}
                              </span></td>
                            <td>{{ item.lastUpdatedDate.slice(0, 10) | date: 'd.MM.yyyy' }}</td>
                          </tr>
                      </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <p class="errorMessage" *ngIf="primaryProfileErrorMessage" style="color: red;">
            {{ primaryProfileErrorMessage }}
          </p>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 100%;position:absolute;top: 0;z-index: 1000;"
    *ngIf="loading">
    <div class="loadEffect" style="position: fixed;left: 47%;top:30%;z-index: 1000;">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
    <div class="row">
      <div class="col-12">
        <div class="ms-5 errorMessage" *ngIf="errorMessage" style="color: red;">{{ errorMessage }}</div>
      </div>
    </div>
    <div class="row">
      <div class="d-flex justify-content-center mt-4">
        <div class="align-self-center">
          <sh-button [props]="mergeButton" (click)="mergeButton.onClick()"></sh-button>
        </div>
      </div>
    </div>
  </div>
</div>