enum SAStatus {
    New = "New",
    InProgress = "In Progress",
    Passed = "Passed",
    Failed = "Failed",
    Success = 'Success'
}

enum SAStatusCheck {
    New = "NEW",
    InProgress = "INPROGRESS",
    Passed = "PASSED",
    Failed = "FAILED",
    Success = 'SUCCESS'
}



export { SAStatus, SAStatusCheck};

