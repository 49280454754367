import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminBoardLogItem, ButtonPropsType } from '@lsl16/sustainability-shared-components';

@Component({
  selector: 'tsm-system-admin-board-user-item',
  templateUrl: './system-admin-board-user-item.component.html',
  styleUrls: ['./system-admin-board-user-item.component.sass']
})
export class SystemAdminBoardUserItemComponent implements OnInit {
  @Input() model: AdminBoardLogItem;
  @Input() currentLoginEmail: string;
  props: ButtonPropsType;
  url: string;
  PROFILE_TYPE = {
    BUYER: "buyer",
    SUPPLIER: "supplier",
    AUTHORITY: "authority"
  };

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.props = {
      label: "",
      color: "purple",
      fontSize: 14,
      backgroundColor: "white",
      borderColor: "palePurple",
      hoverColor: "purple",
      hoverBackgroundColor: "white",
      hoverBorderColor: "palePurple",
      height: 39,
      width: 96,
      padding: "10px",
      iconPosition: "left",
      onClick: () => this.jumpToEdit()
    };
    if (this.model.currentRole && this.model.currentRole.length > 0) {
      this.model.role = this.model.currentRole[0];
    }
    this.url =
      "https://search.accenture.com/Handlers/PictureHandler.ashx?eid=";
  }

  showEditUser() {
    return this.currentLoginEmail !== this.model.userName;
  }

  jumpToEdit() {
    this.router.navigateByUrl(`system-admin-board/${this.model.userId}`);
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multilanguageJson.body != undefined) {
        const labelTranslations = multilanguageJson.body.main.systemAdminBoardUser;
        this.props.label = labelTranslations.editBtn;
      }
    }
  }
}
