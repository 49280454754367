<div class="item">
  <div class="row-item">
    <div class="col10">
      <div class="collabel dateLabel" >{{ item.timestamp | date: "d MMM y" }}</div>
    </div>

    <div class="col50">
      <div>
        <span class="collabel eventLabel" >{{ translate(item.payload.event) }}</span>
        <span *ngIf="isCommentEnabled">
          <sh-question-explanations
            [props]="commentProps"
          ></sh-question-explanations>
        </span>
      </div>
    </div>

    <div class="col30">
      <div *ngIf="isParent; else naContent">
        <div class="collabel supplierNameLabel">
          {{ item.parent.supplierName }}
        </div>
        <div class="collabel">{{ item.parent.countryLocation }}</div>
      </div>
      <ng-template #naContent
        ><div class="collabel supplierNameLabel noSupplierName" >{{ initJson.na }}</div></ng-template
      >
    </div>

    <div class="col10">
      <div class="collabel" *ngIf="isParent; else noDuns">
        {{ item.parent.dunsNumber }}
      </div>
      <ng-template #noDuns>
        <div class="collabel noDunsNumber">{{ initJson.na }}</div>
      </ng-template>
    </div>
  </div>
</div>
